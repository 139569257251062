import { get, ref, set } from "firebase/database";
import { rtdb } from "../firebase/fire";

export const sendFormNotification = async (locationID, data, id) => {
  try {
    await set(ref(rtdb, `userNotification/${locationID}/notifications/${id}`), data);
  } catch (error) {
    console.error("Error adding message:", error);
  }
};

export const addChatMessage = async (messageData, chatID, link) => {
  try {
    await set(ref(rtdb, `chats/${link}/${messageData?.id}/${chatID}`), messageData);
    console.log("Chat added successfully.");
  } catch (error) {
    console.error("Error adding message:", error);
  }
};

export const updatChat = async (chatData, chatID) => {
  try {
    await set(ref(rtdb, `chats/${chatID}`), chatData);
    console.log("Chat updated successfully.");
  } catch (error) {
    console.error("Error updating chat:", error);
  }
};

export const getChat = async (chatID) => {
  const chatRef = ref(rtdb, `chats/${chatID}`);
  const snapshot = await get(chatRef);
  if (snapshot.exists()) {
    console.log(snapshot.val());
    return snapshot.val();
  } else {
    console.log("No data available");
    return null;
  }
};

export const deleteChat = async (chatID) => {
  try {
    await set(ref(rtdb, `chats/${chatID}`), null);
    console.log("Chat deleted successfully.");
  } catch (error) {
    console.error("Error deleting chat:", error);
  }
};

export const createLead = async (data) => {
  try {
    await set(ref(rtdb, `leads/${data.leadParams}/${data?.id}/0`), data);
    console.log("Lead added successfully.");
  } catch (error) {
    console.error("Error adding lead:", error);
  }
};

export const getLead = async (locLink, leadID) => {
  const leadRef = ref(rtdb, `leads/${locLink}/${leadID}`);
  const snapshot = await get(leadRef);
  if (snapshot.exists()) {
    console.log(snapshot.val());
    return snapshot.val();
  } else {
    console.log("No data available");
  }
};

export const updateLead = async (data, leadID) => {
  try {
    await set(ref(rtdb, `leads/${data.leadParams}/${leadID}`), data);
    console.log("Lead updated successfully.");
  } catch (error) {
    console.error("Error updating lead:", error);
  }
};  
