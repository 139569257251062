import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { getAccount } from "../../functions/stripe";
import { FaCheck, FaExclamation } from "react-icons/fa6";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ConnectComplete() {
  const [account, setAccount] = useState({});
  const [isEnabled, setIsEnabled] = useState(false);
  const [canProcessPayments, setCanProcessPayments] = useState(0);
  const [transfers, setTransfers] = useState(false);
  const [requirements, setRequirements] = useState([]);
  const [disabled_reason, setDisabledReason] = useState("");

  const { id, uid } = useParams();

  const handleGetAccount = async (acc) => {
    const res = await getAccount(acc);
    setAccount(res);
    const isAccEnabled = res?.account?.charges_enabled;
    const canProcess = res?.account?.external_accounts?.total_count;
    const canTransfers =
      res?.account?.capabilities?.transfers === "active" ? true : false;
    const reqs = res?.account?.requirements?.currently_due;
    setIsEnabled(isAccEnabled);
    setCanProcessPayments(canProcess);
    setTransfers(canTransfers);
    setRequirements(reqs);
  };

  useEffect(() => {
    handleGetAccount(id);
  }, [id]);

  return (
    <div className="flex items-center justify-center max-w-5xl mx-auto">
      <div className="text-center">
        <h1 className="text-5xl font-bold text-gray-900">
          Connection {isEnabled ? "Complete" : "Incomplete"}
        </h1>
        <div className="mt-1">
          <h2 className="text-sm font-medium text-gray-500">
            Connection Status{" "}
            {isEnabled &&
            canProcessPayments &&
            canProcessPayments &&
            requirements?.length < 1 ? (
              <span className="inline-flex items-center rounded-md bg-blue-100 px-2 py-1 text-xs font-medium text-blue-700 ring-1 ring-inset ring-green-600/20">
                Active
              </span>
            ) : (
              <span className="inline-flex items-center rounded-md bg-yellow-100 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-red-600/10">
                Pending
              </span>
            )}
          </h2>
          <ul className="mt-3 space-y-4">
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={classNames(
                  isEnabled ? "bg-emerald-600" : "bg-red-600",
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {isEnabled ? (
                  <FaCheck
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <FaExclamation
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600">
                    Charges Enabled
                  </div>
                  <p className="text-gray-500">Status</p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                  </button>
                </div>
              </div>
            </li>
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={classNames(
                  canProcessPayments ? "bg-emerald-600" : "bg-red-600",
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {canProcessPayments ? (
                  <FaCheck
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <FaExclamation
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600">
                    External Account Connected
                  </div>
                  <p className="text-gray-500">{canProcessPayments} Accounts</p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                  </button>
                </div>
              </div>
            </li>
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={classNames(
                  transfers ? "bg-emerald-600" : "bg-red-600",
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {transfers ? (
                  <FaCheck
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <FaExclamation
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600">
                    Transfers Enabled
                  </div>
                  <p className="text-gray-500">
                    {transfers ? "Active" : "Not Active"}
                  </p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                  </button>
                </div>
              </div>
            </li>
            <li className="col-span-1 flex rounded-md shadow-sm">
              <div
                className={classNames(
                  requirements?.length < 1 ? "bg-emerald-600" : "bg-red-600",
                  "flex w-16 flex-shrink-0 items-center justify-center rounded-l-md text-sm font-medium text-white"
                )}
              >
                {requirements?.length < 1 ? (
                  <FaCheck
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                ) : (
                  <FaExclamation
                    className="h-6 w-6 flex-none text-white"
                    aria-hidden="true"
                  />
                )}
              </div>
              <div className="flex flex-1 items-center justify-between truncate rounded-r-md border-b border-r border-t border-gray-200 bg-white">
                <div className="flex-1 truncate px-4 py-2 text-sm">
                  <div className="font-medium text-gray-900 hover:text-gray-600">
                    Pending Requirements
                  </div>
                  <p className="text-gray-500">{requirements?.length} Due</p>
                </div>
                <div className="flex-shrink-0 pr-2">
                  <button
                    type="button"
                    className="inline-flex h-8 w-8 items-center justify-center rounded-full bg-transparent bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  >
                    <span className="sr-only">Open options</span>
                  </button>
                </div>
              </div>
            </li>
          </ul>
          <div className="mt-4">
            <Link
              to={`/`}
              className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-inemeralddigo-500"
            >
              Go to Dashbord
            </Link>
          </div>
        </div>
        <div>
          {requirements?.length > 0 && (
            <div className="mt-5">
              <p className="text-sm text-gray-500">
                You have pending requirements to complete before you can process
                payments. Sometimes this can take a few days to process. If you
                need help, please contact support.
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
