import { UserAuth } from "../context/AuthContext";
import Bookings from "./components/Bookings";
import BookingsList from "./components/BookingsList";
import Calendar from "./components/Calendar";
import Leads from "./components/Leads";
import SpaceSlider from "./components/SpaceSlider";
import TrainingInfo from "./components/TrainingInfo";

export default function Overview() {
  const { user } = UserAuth();

 

  return (
    <div className="pb-12 -mt-2">
      <div className="w-full mb-2">
        <TrainingInfo />
      </div>
      <h1 className="text-2xl font-bold text-zinc-800">Home</h1>
      <p className="text-sm text-zinc-500">
        Welcome to your dashboard. Here you can view your bookings, leads, and
        training information.
      </p>
   

      <div className="mt-4 grid grid-cols-12 gap-2">
        <div className="xl:col-span-4 md:col-span-4 row-span-6 col-span-full">
          <SpaceSlider />
        </div>
        <div className="xl:col-span-8 md:col-span-8 col-span-full">
          <BookingsList />
        </div>

        <div className="xl:col-span-8 md:col-span-8 col-span-full">
          <Leads />
        </div>
      </div>
    </div>
  );
}
