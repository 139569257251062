import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { updateUser, verifyUserEmail } from "../../functions/crud";
import { useNavigate } from "react-router-dom";
const api = process.env.REACT_APP_SERVER_URL;

export default function StripePaymentSetup() {
  const { user } = UserAuth();
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleEmailVerify = () => {
    verifyUserEmail();
    setEmailVerificationSent(true);
  };

  const handleUpdateAccount = (accountID, link) => {
    console.log(accountID, link);
    updateUser(user?.id, {
      stripeAccountID: accountID,
      stripeSetupLink: link,
      stripeSetup: true,
      canProcessPayments: true,
    });
  };

  const navigate = useNavigate();

  const handleSkipPayments = () => {
    updateUser(user?.id, {
      stripeSetup: true,
      stripeAccountID: "skipped",
      stripeSetupLink: "skipped",
      canProcessPayments: false,
    });
  };

  const handleStripeSetup = async () => {
    // take user to stripe
    setLoading(true);

    await fetch(api + "stripe/accountLink", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: user?.email,
        uid: user?.id,
      }),
    })
      .then((res) => res.json())
      .then((data) => {
        console.log(data);
        if (data.accountLink.url) {
          handleUpdateAccount(data.account.id, data.accountLink.url);
          window.open(data.accountLink.url);
          navigate("/");
          setLoading(false);
        } else {
          alert("Error setting up account. Please refresh page and try again.");
          setLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setLoading(false);
      });
  };

  return (
    <div className="max-w-3xl mx-auto">
      <div className="space-y-4 mt-4">
        {!user?.emailVerified ? (
          <div className="overflow-hidden rounded-2xl bg-white shadow border border-zinc-300">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="text-lg font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    Verify your email
                  </h2>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-gray-500">
                  Before you can set up your payment details, you need to verify
                  your email address.
                </p>
              </div>
              {emailVerificationSent ? (
                <p className="text-sm text-emerald-600 mt-5">
                  Email verification sent! Please check your email to verify
                  your account
                </p>
              ) : (
                <div className="mt-5 flex items-center gap-x-2">
                  <h3 className="text-lg font-bold tracking-tight leading-7 text-emerald-600 sm:text-2xl sm:truncate">
                    {user?.email}
                  </h3>

                  <button
                    type="button"
                    className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-zinc-950 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
                    onClick={handleEmailVerify}
                  >
                    Verify email
                  </button>
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className="overflow-hidden rounded-2xl bg-white shadow border border-zinc-300">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-between">
                <div className="flex-1 min-w-0">
                  <h2 className="text-lg font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate">
                    Set up your payment details
                  </h2>
                </div>
              </div>
              <div className="mt-4">
                <p className="text-sm text-gray-500">
                  Provide your business and ownership information to get paid
                  for your bookings.
                </p>
              </div>
              <div className="mt-5">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-zinc-950 hover:bg-zinc-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-zinc-500"
                  onClick={handleStripeSetup}
                >
                  {loading ? (
                    <div className="flex items-center justify-center">
                      <svg
                        className="animate-spin h-5 w-5 mr-3 "
                        viewBox="0 0 24 24"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          className="lucide lucide-loader-circle"
                        >
                          <path d="M21 12a9 9 0 1 1-6.219-8.56" />
                        </svg>
                      </svg>
                      <div>Redirecting</div>
                    </div>
                  ) : (
                    "Take me to Stripe"
                  )}
                </button>
                <button
                  className="text-sm ml-2 hover:text-emerald-600"
                  onClick={handleSkipPayments}
                >
                  Skip for now
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
