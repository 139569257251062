const api = process.env.REACT_APP_SERVER_URL;

export const handleCreateCustomer = async (email, name) => {
  const res = await fetch(api + "stripe/createCustomer", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({ email, name }),
  });
  return res.json();
};

export const subscribeCustomer = async (customer, priceId, payID) => {
  const res = await fetch(api + "stripe/subscribe", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      customer,
      priceId: priceId,
      payID,
    }),
  });
  return res.json();
};

export const getPayIntent = async (payID) => {
  const res = await fetch(api + `stripe/getPaymenentIntent/${payID}`, {
    method: "GET",
  });
  return res.json();
};

export const getAccount = async (uid) => {
  const res = await fetch(api + `stripe/getAccount/${uid}`, {
    method: "GET",
  });
  return res.json();
};

export const createLoginLink = async (uid) => {
  const res = await fetch(api + `stripe/getAccountLoginLink/${uid}`, {
    method: "GET",
  });
  return res.json();
};
