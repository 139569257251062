import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Mail, Phone, X, User, Edit, Sparkles } from "lucide-react";
import { CgGoogle } from "react-icons/cg";
import { MagicIcon } from "@shopify/polaris-icons";
import { FaXmark } from "react-icons/fa6";
import { handleNewSaveLocationData } from "../../functions/crud";
import { UserAuth } from "../../context/AuthContext";

const colors = [
  {
    name: "Indigo",
    color: "bg-indigo-500",
    tag: "indigo",
    hex: "#4F46E5",
  },
  {
    name: "Emerald",
    color: "bg-emerald-500",
    tag: "emerald",
    hex: "#10B981",
  },
  {
    name: "Yellow",
    color: "bg-yellow-500",
    tag: "yellow",
    hex: "#F59E0B",
  },
  {
    name: "Red",
    color: "bg-red-500",
    tag: "red",
    hex: "#EF4444",
  },
  {
    name: "Blue",
    color: "bg-blue-500",
    tag: "blue",
    hex: "#3B82F6",
  },
  {
    name: "Pink",
    color: "bg-pink-500",
    tag: "pink",
    hex: "#EC4899",
  },
  {
    name: "Purple",
    color: "bg-purple-500",
    tag: "purple",
    hex: "#8B5CF6",
  },
  {
    name: "Gray",
    color: "bg-gray-500",
    tag: "gray",
    hex: "#6B7280",
  },
];

export default function EditSmartPage({
  viewLocationStyle,
  setViewLocationStyle,
  setOpen,
}) {
  const { updated, setUpdated, setClosed } = UserAuth();

  const handleUpdate = async () => {
    const res = await handleNewSaveLocationData(viewLocationStyle);
    if (res.status === "success") {
      setOpen(false);
      setUpdated(!updated);
      alert("Smart Page Updated Successfully");
    }
  };

  const handleClose = () => {
    setOpen(false);
    setClosed(false);
  };

  return (
    <>
      <div className="fixed inset-y-0 right-0 flex max-w-full pl-10 z-50">
        <div className="flex h-full w-[500px] flex-col divide-y divide-gray-200 bg-zinc-50 shadow-xl">
          <div className="flex min-h-0 flex-1 flex-col overflow-y-scroll py-6">
            <div className="px-4 sm:px-6">
              <div className="flex items-start justify-between">
                <div className="text-base font-semibold leading-6 text-gray-900">
                  Edit your Smart Page
                </div>
                <div className="ml-3 flex h-7 items-center">
                  <button
                    type="button"
                    onClick={handleClose}
                    className="relative rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500"
                  >
                    <span className="absolute -inset-2.5" />
                    <span className="sr-only">Close panel</span>
                    <FaXmark aria-hidden="true" className="h-6 w-6" />
                  </button>
                </div>
              </div>
            </div>
            <div className="relative mt-6 flex-1 px-4 sm:px-6">
              <div>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Page Title
                  </label>
                  <input
                    type="text"
                    name="title"
                    id="title"
                    value={viewLocationStyle?.pageTitle}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                    onChange={(e) =>
                      setViewLocationStyle({
                        ...viewLocationStyle,
                        pageTitle: e.target.value,
                      })
                    }
                  />
                </div>
                <div className="mt-4">
                  <label
                    htmlFor="name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Subheading
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    value={viewLocationStyle?.pageSubheading}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                    onChange={(e) =>
                      setViewLocationStyle({
                        ...viewLocationStyle,
                        pageSubheading: e.target.value,
                      })
                    }
                  />
                </div>
                <div>
                  <label
                    htmlFor="message"
                    className="block mt-4 text-sm font-medium text-gray-700"
                  >
                    Page Description
                  </label>
                  <textarea
                    id="pageDescription"
                    name="pageDescription"
                    rows={4}
                    value={viewLocationStyle?.pageDescription}
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                    onChange={(e) =>
                      setViewLocationStyle({
                        ...viewLocationStyle,
                        pageDescription: e.target.value,
                      })
                    }
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Primary Color Scheme
                </label>
                <div className="flex items-center gap-4 mt-2">
                  {colors?.map((color) => (
                    <button
                      key={color.tag}
                      className={`w-6 h-6 rounded-full ${color.color} ${
                        viewLocationStyle?.pageColor === color.tag
                          ? "border-4   border-black"
                          : ""
                      }`}
                      onClick={() => {
                        setViewLocationStyle({
                          ...viewLocationStyle,
                          pageColor: color.tag,
                        });
                      }}
                    ></button>
                  ))}
                </div>
              </div>
              <div>
                <label
                  htmlFor="message"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Secondary Color Scheme
                </label>
                <div className="flex items-center gap-4 mt-2">
                  {colors?.map((color) => (
                    <button
                      key={color.tag}
                      className={`w-6 h-6 rounded-full ${color.color} ${
                        viewLocationStyle?.secondaryColor === color.tag
                          ? "border-4   border-black"
                          : ""
                      }`}
                      onClick={() =>
                        setViewLocationStyle({
                          ...viewLocationStyle,
                          secondaryColor: color.tag,
                          hex: color.hex,
                        })
                      }
                    ></button>
                  ))}
                </div>
              </div>
              <div>
                <label
                  htmlFor="Extra Tag"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Subheading
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={viewLocationStyle?.extraTag}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                  onChange={(e) =>
                    setViewLocationStyle({
                      ...viewLocationStyle,
                      extraTag: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="Extra Tag"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Headline Title
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={viewLocationStyle?.extraTitle}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                  onChange={(e) =>
                    setViewLocationStyle({
                      ...viewLocationStyle,
                      extraTitle: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="extraDescription"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Second Description
                </label>
                <textarea
                  id="extraDescription"
                  name="extraDescription"
                  rows={4}
                  value={viewLocationStyle?.extraDescription}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                  onChange={(e) =>
                    setViewLocationStyle({
                      ...viewLocationStyle,
                      extraDescription: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="Extra Tag"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Call To Action Headline
                </label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={viewLocationStyle?.ctaHeadline}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                  onChange={(e) =>
                    setViewLocationStyle({
                      ...viewLocationStyle,
                      ctaHeadline: e.target.value,
                    })
                  }
                />
              </div>
              <div>
                <label
                  htmlFor="extraDescription"
                  className="block mt-4 text-sm font-medium text-gray-700"
                >
                  Call To Action Description
                </label>
                <textarea
                  id="extraDescription"
                  name="extraDescription"
                  rows={4}
                  value={viewLocationStyle?.ctaDescription}
                  className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                  onChange={(e) =>
                    setViewLocationStyle({
                      ...viewLocationStyle,
                      ctaDescription: e.target.value,
                    })
                  }
                />
              </div>
            </div>
          </div>
          <div className="flex flex-shrink-0 justify-end px-4 py-4">
            <button
              type="button"
              className="rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
              onClick={handleClose}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="ml-4 inline-flex justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-500"
              onClick={handleUpdate}
            >
              <Sparkles className="h-5 w-5 mr-2 " aria-hidden="true" />
              Update Smart Page
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
