import {
  ArchiveIcon,
  CalendarPlus,
  ChevronDownIcon,
  Eye,
  MessageCircle,
  MessagesSquare,
  PencilIcon,
  PlusCircleIcon,
  ShieldBanIcon,
  ShieldCheck,
  Trash2,
  UserPlus,
  UserX,
} from "lucide-react";
import { UserAuth } from "../../context/AuthContext";
import { useEffect, useState } from "react";
import { Menu } from "@headlessui/react";
import { Link } from "react-router-dom";
import { onValue, ref } from "firebase/database";
import { rtdb } from "../../firebase/fire";
import Tippy from "@tippyjs/react";
import { convertPhoneNumber } from "../../functions/shared";

export default function Leads() {
  const { notifications, spaces } = UserAuth();
  const [leads, setLeads] = useState([]);
  const leadItems = [
    {
      title: "New Lead",
      color: "bg-zinc-100 text-zinc-900 ring-zinc-600/20",
    },
    {
      title: "Contacted",
      color: "bg-blue-100 text-blue-900 ring-blue-600/20",
    },
    {
      title: "Converted",
      color: "bg-green-100 text-green-900 ring-green-600/20",
    },
    {
      title: "Unqualified",
      color: "bg-red-100 text-red-900 ring-red-600/20",
    },
    {
      title: "Lost",
      color: "bg-gray-100 text-gray-900 ring-gray-600/20",
    },
  ];

  const leadTypes = [
    {
      title: "Form Submission",
      color: "bg-blue-100 text-blue-900 ring-blue-600/20",
    },
    {
      title: "Requested Quote",
      color: "bg-red-100 text-red-900 ring-red-600/20",
    },
    {
      title: "Tour Request",
      color: "bg-zinc-100 text-zinc-900 ring-zinc-600/20",
    },

    {
      title: "Sent Offer",
      color: "bg-green-100 text-green-900 ring-green-600/20",
    },

    {
      title: "Lost",
      color: "bg-gray-100 text-gray-900 ring-gray-600/20",
    },
  ];

  useEffect(() => {
    setLeads(notifications);
  }, [notifications]);

  return (
    <div className="shadow overflow-hidden rounded-lg  bg-blue-600">
      <div className="sm:flex sm:items-center px-4 sm:px-6 py-5 mt-2 bg-white">
        <div className="sm:flex-auto">
          <h1 className="text-base font-semibold leading-6 text-gray-900">
            Leads
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the people who have shown interest in your space and
            are potential leads.
          </p>
        </div>
        <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
          <button
            type="button"
            className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          >
            <PlusCircleIcon className="w-5 h-5 inline-block -mt-0.5 mr-1" />
            Create Lead
          </button>
        </div>
      </div>
      <div className="-mx-4 sm:-mx-0 bg-white rounded-b-lg pb-96">
        <table className="min-w-full divide-y divide-gray-300">
          <thead className="bg-gray-200">
            <tr className="divide-x divide-gray-200 pl-4">
              <th
                scope="col"
                className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 "
              >
                Name
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell"
              >
                Contact
              </th>
              <th
                scope="col"
                className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 xl:table-cell"
              >
                Space
              </th>
              <th
                scope="col"
                className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 hidden md:table-cell"
              >
                Type
              </th>
              <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-0">
                <span className="sr-only">Edit</span>
              </th>
            </tr>
          </thead>
          {leads?.length === 0 ? (
            <tbody>
              <tr>
                <td
                  colSpan="5"
                  className="text-center py-4 text-sm text-gray-500"
                >
                  No leads yet.
                </td>
              </tr>
            </tbody>
          ) : (
            <tbody className="divide-y divide-gray-200 bg-white px-2">
              {leads?.map((person) => (
                <tr key={person.email} className="divide-x divide-gray-200 ">
                  <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm  text-gray-900 sm:w-auto sm:max-w-none">
                    <div className="pl-4 sm:pl-0 flex items-center gap-1 whitespace-nowrap font-bold">
                      {person?.hasAccount ? (
                        <Tippy content="Has Account">
                          <ShieldCheck className="text-green-500" />
                        </Tippy>
                      ) : (
                        <Tippy content="No Account">
                          <ShieldBanIcon className="text-red-500" />
                        </Tippy>
                      )}
                      {person.name}
                      
                    </div>

                    <dl className="font-normal xl:hidden pl-4">
                      <dt className="sr-only">Title</dt>
                      <dd className="mt-1 truncate text-gray-700 font-semibold">
                        {person.email || ""}
                      </dd>
                      <dd className="mt-1 truncate text-gray-700">
                        {convertPhoneNumber(person.phone) || ""}
                      </dd>
                      <dt className="sr-only sm:hidden">Email</dt>
                      <dd className="mt-1 truncate text-gray-500 xl:hidden">
                        {person.space === "smart"
                          ? "Smart Page"
                          : spaces.find((space) => space.id === person.space)
                              ?.title}
                      </dd>
                      <dd className="md:hidden">
                        {" "}
                        <span
                          className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${
                            leadItems[person.status]?.color
                          }`}
                        >
                          {leadItems[person.status]?.title}
                        </span>
                      </dd>
                    </dl>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-800 xl:table-cell">
                    <div className="font-semibold">{person.email}</div>
                    <div>{convertPhoneNumber(person.phone)}</div>
                  </td>
                  <td className="hidden px-3 py-4 text-sm text-gray-500 xl:table-cell">
                    {person.space === "smart"
                      ? "Smart Page"
                      : spaces.find((space) => space.id === person.space)
                          ?.title}
                  </td>

                  <td className="hidden md:table-cell whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                    <span
                      className={`inline-flex items-center rounded-md  px-2 py-1 text-xs font-medium  ring-1 ring-inset ${
                        leadTypes[person.type]?.color
                      }`}
                    >
                      {leadTypes[person.type]?.title}
                    </span>
                  </td>
                  <td className="py-4 text-right text-sm font-medium pr-4 px-2">
                    <Menu as="div" className="relative inline-block text-left">
                      <div>
                        <Menu.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                          Actions
                          <ChevronDownIcon
                            aria-hidden="true"
                            className="-mr-1 h-5 w-5 text-gray-400"
                          />
                        </Menu.Button>
                      </div>

                      <Menu.Items
                        transition
                        className="absolute right-0 z-50 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                      >
                        <div className="py-1">
                          <Menu.Item>
                            <Link
                              to={`/view-lead/${person.id}`}
                              className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              <Eye
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              />
                              View
                            </Link>
                          </Menu.Item>
                          <Menu.Item>
                            {person?.hasAccount ? (
                              <Link
                                to={`/messages/${person.id}`}
                                className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                              >
                                <MessagesSquare
                                  aria-hidden="true"
                                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                />
                                Contact
                              </Link>
                            ) : (
                              <button className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900">
                                <MessagesSquare
                                  aria-hidden="true"
                                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                />
                                Send Invite to Chat
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            {person?.isRenter ? (
                              <button
                                href="#"
                                className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                              >
                                <PencilIcon
                                  aria-hidden="true"
                                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                />
                                Edit Renter
                              </button>
                            ) : (
                              <button
                                href="#"
                                className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                              >
                                <UserPlus
                                  aria-hidden="true"
                                  className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                                />
                                Convert to Renter
                              </button>
                            )}
                          </Menu.Item>
                          <Menu.Item>
                            <a
                              href="#"
                              className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              <UserX
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              />
                              Disqualify
                            </a>
                          </Menu.Item>
                        </div>

                        <div className="py-1">
                          <Menu.Item>
                            <a
                              href="#"
                              className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              <CalendarPlus
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              />
                              Create Booking
                            </a>
                          </Menu.Item>
                        </div>
                        <div className="py-1">
                          <Menu.Item>
                            <a
                              href="#"
                              className="group flex items-center px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900"
                            >
                              <Trash2
                                aria-hidden="true"
                                className="mr-3 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                              />
                              Delete
                            </a>
                          </Menu.Item>
                        </div>
                      </Menu.Items>
                    </Menu>
                  </td>
                </tr>
              ))}
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
}
