import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  deleteUser,
  getAuth,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
} from "firebase/auth";
import { auth, db } from "../firebase/fire";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

export const signUp = async (email, password, data) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      ...data,
      email: email,
      id: user.uid,
      emailVerified: false,
      bookingsCreated: 0,
    });
    return { status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signUpRenter = async (email, password, data) => {
  try {
    const userCredential = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const userRef = doc(db, "users", user.uid);
    await setDoc(userRef, {
      ...data,
      email: email,
      id: user.uid,
      bookingsCreated: 0,
    });
    const userRef2 = doc(db, "renters", data.id);
    await setDoc(userRef2, {
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      name: data.name || "",
      phone: data.phone || "",
      id: data.id,
      renterID: user.uid,
      email: user.email,
      emailVerified: false,
      finisedSetup: false,
      stripeCustomerID: "",
      city: data.city || "",
      state: data.state || "",
      profilePic: "",
      hasAccount: true,
    }, { merge: true });
    const leadsRef = collection(db, "leads");
    const q = query(leadsRef, where("email", "==", email));
    const querySnapshot = await getDocs(q);
    querySnapshot.forEach(async (doc) => {
      await updateDoc(
        doc.ref,
        { hasAccount: true, renterID: user.uid },
        { merge: true }
      );
    });
    return { status: "success", user: user.uid };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signIn = async (email, password) => {
  try {
    const userCredential = await signInWithEmailAndPassword(
      auth,
      email,
      password
    );
    const user = userCredential.user;
    const userRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userRef);
    return { ...userSnapshot.data(), status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signUpWithGoogle = async (data) => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const userRef = doc(db, "users", user.uid);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      return { ...userSnapshot.data(), status: "success" };
    } else {
      await setDoc(userRef, {
        ...data,
        id: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        bookingsCreated: 0,
      });
      return { status: "success" };
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signUpRenterWithGoogle = async (data) => {
  try {
    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    const userRef = doc(db, "users", user.uid);
    const useRef2 = doc(db, "renters", data.id);
    const userSnapshot = await getDoc(userRef);
    if (userSnapshot.exists()) {
      return {
        ...userSnapshot.data(),
        status: "success",
        user: user.uid,
        email: user.email,
      };
    } else {
      await setDoc(userRef, {
        ...data,
        id: user.uid,
        email: user.email,
        emailVerified: user.emailVerified,
        bookingsCreated: 0,
      });
      await setDoc(useRef2, {
        firstName: data.firstName || "",
        lastName: data.lastName || "",
        name: data.name || "",
        phone: data.phone || "",
        id: data.id,
        renterID: user.uid,
        email: user.email,
        emailVerified: false,
        finisedSetup: false,
        stripeCustomerID: "",
        city: data.city || "",
        state: data.state || "",
        profilePic: "",
        hasAccount: true,
      }, { merge: true });
      const leadsRef = collection(db, "leads");
      const q = query(leadsRef, where("email", "==", user.email));
      const querySnapshot = await getDocs(q);
      querySnapshot.forEach(async (doc) => {
        await updateDoc(
          doc.ref,
          { hasAccount: true, renterID: user.uid },
          { merge: true }
        );
      });
      return { status: "success", user: user.uid, email: user.email };
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const signInWithGoogle = async () => {
  try {
    //first make sure the user already has an account before they sign in

    const provider = new GoogleAuthProvider();
    const result = await signInWithPopup(auth, provider);

    const user = result.user;
    const userRef = doc(db, "users", user.uid);
    const userCheck = await getDoc(userRef);
    if (userCheck.exists()) {
      return { status: "success" };
    } else {
      console.log(user.uid);
      deleteUser(user);
      signOut(auth);
      return { status: "No account found!" };
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const resetPassword = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    return { status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const logout = async () => {
  try {
    await signOut(auth);
    return { status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  }
};
