import { useState } from "react";
import { CheckCircleIcon, TrashIcon } from "@heroicons/react/20/solid";
import { RadioGroup } from "@headlessui/react";
import { states } from "../../assets/js/states";
import { UserAuth } from "../../context/AuthContext";
import { createAndAddRenter, generateRandomID } from "../../functions/crud";
import { CgSpinner } from "react-icons/cg";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function AddRenter() {
  const { spaces, user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(null);
  const [renter, setRenter] = useState({
    email: "",
    phone: "",
    firstName: "",
    lastName: "",
    company: "",
    address: "",
    apartment: "",
    city: "",
    state: "",
    postalCode: "",
    notes: "",
  });

  const navigate = useNavigate();

  const handleAddRenter = async (e) => {
    e.preventDefault();
    setLoading(true);
    const renterID = generateRandomID();
    const renterData = {
      ...renter,
      spaceID: selectedDeliveryMethod?.id,
      providerWhoAdded: user?.id,
      providerEmail: user?.email,
      providerName: user?.firstName + " " + user?.lastName,
      status: 0,
      id: renterID,
    };
    const res = await createAndAddRenter(renterData, user?.link, renterID);
    if (res.status === "success") {
      console.log("Renter added successfully");
      setTimeout(() => {
        setLoading(false);
        navigate(`/renters`);
      }, 2000);
      
    } else {
      setTimeout(() => {
        setLoading(false);
      }, 2000);
      console.log(res.message);
    }
  };

  return (
    <div className="bg-gray-50 rounded-md shadow mx-auto max-w-2xl">
      <div className=" p-6 ">
        <h2 className="text-center font-semibold text-3xl">
          Create a New Renter Profile
        </h2>

        <form className="mt-6">
          <div>
            <div>
              <h2 className="text-lg font-medium text-gray-900">
                Contact information
              </h2>

              <div className="mt-4">
                <label
                  htmlFor="email-address"
                  className="block text-sm font-medium text-gray-700"
                >
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email-address"
                    name="email-address"
                    type="email"
                    autoComplete="email"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      setRenter({ ...renter, email: e.target.value })
                    }
                  />
                </div>
              </div>
              <div className="mt-4">
                <label
                  htmlFor="phone"
                  className="block text-sm font-medium text-gray-700"
                >
                  Phone
                </label>
                <div className="mt-1">
                  <input
                    id="phone"
                    name="phone"
                    type="text"
                    autoComplete="tel"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                    onChange={(e) =>
                      setRenter({ ...renter, phone: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <h2 className="text-lg font-medium text-gray-900">
                Renter information
              </h2>

              <div className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4">
                <div>
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    First name
                  </label>
                  <div className="mt-1">
                    <input
                      id="first-name"
                      name="first-name"
                      type="text"
                      autoComplete="given-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, firstName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Last name
                  </label>
                  <div className="mt-1">
                    <input
                      id="last-name"
                      name="last-name"
                      type="text"
                      autoComplete="family-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, lastName: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="company"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Company
                  </label>
                  <div className="mt-1">
                    <input
                      id="company"
                      name="company"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, company: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <div className="mt-1">
                    <input
                      id="address"
                      name="address"
                      type="text"
                      autoComplete="street-address"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, address: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div className="sm:col-span-2">
                  <label
                    htmlFor="apartment"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Apartment, suite, etc.
                  </label>
                  <div className="mt-1">
                    <input
                      id="apartment"
                      name="apartment"
                      type="text"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, apartment: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="city"
                    className="block text-sm font-medium text-gray-700"
                  >
                    City
                  </label>
                  <div className="mt-1">
                    <input
                      id="city"
                      name="city"
                      type="text"
                      autoComplete="address-level2"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, city: e.target.value })
                      }
                    />
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State
                  </label>
                  <div className="mt-1">
                    <select
                      id="state"
                      name="state"
                      autoComplete="state-name"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, state: e.target.value })
                      }
                    >
                      {states?.map((state) => (
                        <option
                          key={state?.abbreviation}
                          value={state?.abbreviation}
                        >
                          {state?.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div>
                  <label
                    htmlFor="postal-code"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Postal code
                  </label>
                  <div className="mt-1">
                    <input
                      id="postal-code"
                      name="postal-code"
                      type="text"
                      autoComplete="postal-code"
                      className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                      onChange={(e) =>
                        setRenter({ ...renter, postalCode: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <fieldset>
                <legend className="text-lg font-medium text-gray-900">
                  Space
                </legend>
                <RadioGroup
                  value={selectedDeliveryMethod}
                  onChange={setSelectedDeliveryMethod}
                  className="mt-4 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-4"
                >
                  {spaces?.map((deliveryMethod, index) => (
                    <RadioGroup.Option
                      key={deliveryMethod.id}
                      value={deliveryMethod}
                      className={({ checked }) =>
                        classNames(
                          index === 0 ? "rounded-tl-md rounded-tr-md" : "",
                          index === spaces.length - 1
                            ? "rounded-bl-md rounded-br-md"
                            : "",
                          checked
                            ? "z-10 border-2 border-emerald-800 bg-emerald-50"
                            : "rounded-lg border border-gray-300 bg-white",
                          "group relative flex cursor-pointer  p-4 shadow-sm focus:outline-none"
                        )
                      }
                      onClick={() => {
                        if (deliveryMethod?.id === selectedDeliveryMethod?.id)
                          setSelectedDeliveryMethod(null);
                      }}
                    >
                      <span className="flex flex-1">
                        <span className="flex flex-col">
                          <span className="block text-sm font-medium text-gray-900">
                            {deliveryMethod.title}
                          </span>
                          <span className="mt-1 flex items-center text-sm text-gray-500">
                            {deliveryMethod.city}, {deliveryMethod.state}
                          </span>
                          <span className="mt-6 text-sm font-medium text-gray-900">
                            {deliveryMethod.sqft} sqft
                          </span>
                        </span>
                      </span>
                      {deliveryMethod?.id === selectedDeliveryMethod?.id && (
                        <CheckCircleIcon
                          aria-hidden="true"
                          className="h-5 w-5 text-emerald-600 "
                        />
                      )}
                    </RadioGroup.Option>
                  ))}
                </RadioGroup>
              </fieldset>
            </div>

            <div className="mt-10 border-t border-gray-200 pt-10">
              <label
                htmlFor="notes"
                className="block text-sm font-medium text-gray-700"
              >
                Notes
              </label>
              <div className="mt-1">
                <textarea
                  id="notes"
                  name="notes"
                  rows={5}
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                  placeholder={"Add any notes here."}
                  onChange={(e) =>
                    setRenter({ ...renter, notes: e.target.value })
                  }
                />
              </div>
            </div>
            <div>
              <button
                type="submit"
                className="w-full mt-4 rounded-md border border-transparent bg-emerald-600 flex items-center justify-center gap-1 px-4 py-3 text-base font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-50 disabled:bg-emerald-300 disabled:cursor-not-allowed"
                onClick={handleAddRenter}
                disabled={loading}
              >
                {loading ? (
                  <>
                    <CgSpinner className="w-5 h-5 animate-spin" /> Adding
                    Renter...{" "}
                  </>
                ) : (
                  "Add Renter"
                )}
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
