import React from "react";
import { UserAuth } from "../../context/AuthContext";
import CurrentBalance from "../components/CurrentBalance";
import CurrentContracts from "../components/CurrentContracts";
import ActionItems from "../components/ActionItems";

export default function RenterHome() {
  const {user } = UserAuth();
  return (
    <div>
      <h1 className="text-xl font-bold text-gray-900 sm:text-2xl">
       Dashboard
      </h1>   
      <div className="grid sm:grid-cols-2 grid-cols-1 gap-4 mt-4">
        <CurrentBalance />
        <CurrentContracts />
        <ActionItems />
      </div>
    </div>
  );
}
