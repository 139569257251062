import { collection, getDocs, query, where } from "firebase/firestore";
import { createContext, useContext, useEffect, useState } from "react";
import { db } from "../firebase/fire";

const RenterContext = createContext();

export default function RenterContextProvider({ children }) {
  const [searchSpaces, setSearchSpaces] = useState([]);
  const [selectedSpace, setSelectedSpace] = useState(null);
  const [testValue, setTestValue] = useState("This is a test value");

  const getAllSpaces = async () => {
    const spacesRef = collection(db, "spaces");
    const q = query(spacesRef);
    const querySnapshot = await getDocs(q);
    let spacesArray = [];
    querySnapshot.forEach((doc) => {
      spacesArray.push({ ...doc.data(), id: doc.id });
    });
    setSearchSpaces(spacesArray);
  };

  useEffect(() => {
    getAllSpaces();
  }, []);

  return (
    <RenterContext.Provider
      value={{
        searchSpaces,
        selectedSpace,
        setSelectedSpace,
        testValue,
      }}
    >
      {children}
    </RenterContext.Provider>
  );
}

export const RenterAuth = () => {
  return useContext(RenterContext);
};
