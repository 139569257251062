import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";

//test firebase config
// const firebaseConfig = {
//   apiKey: "AIzaSyBUffc7yqiQpNEZ9-jmyqhYYCdXPO4Peic",
//   authDomain: "competeshare-73441.firebaseapp.com",
//   projectId: "competeshare-73441",
//   storageBucket: "competeshare-73441.appspot.com",
//   messagingSenderId: "314570822320",
//   appId: "1:314570822320:web:51afde16bd3691f9a56175",
//   measurementId: "G-83JJGNRFYD"
// };

//prod firebase config
const firebaseConfig = {
  apiKey: "AIzaSyC8ACxKHpv--NCJuDPQbmOWu1193wuE-4c",
  authDomain: "competesocial-dd2a2.firebaseapp.com",
  databaseURL: "https://competesocial-dd2a2-default-rtdb.firebaseio.com",
  projectId: "competesocial-dd2a2",
  storageBucket: "competesocial-dd2a2.appspot.com",
  messagingSenderId: "737847485276",
  appId: "1:737847485276:web:aa93b6cbe5139f54791799"
};


// Initialize the default Firebase app
const app = initializeApp(firebaseConfig);

// Get references to Firestore, Auth, and the existing storage bucket
export const storage = getStorage(app);
export const auth = getAuth(app);
export const db = getFirestore(app);
export const rtdb = getDatabase(app);
