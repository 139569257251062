import { Fragment, useEffect, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import logo from "../../assets/logos/darkicon.png";
import {
  ArrowLeft,
  ArrowRight,
  Bell,
  Cog,
  MenuIcon,
  X,
} from "lucide-react";
import {
  HomeIcon,
  CalendarCheckIcon,
  InventoryIcon,
  PersonIcon,
  ChartVerticalFilledIcon,
  PageIcon,
  ChatIcon,
  CashDollarIcon,
} from "@shopify/polaris-icons";
import { UserAuth } from "../../context/AuthContext";
import { logout } from "../../functions/auth";
import { Link, useLocation } from "react-router-dom";
import LogoSVG from "../../assets/logos/LogoSVG";
import toast, { Toaster } from "react-hot-toast";

const navigationInit = [
  { name: "Home", href: "/", icon: HomeIcon, current: true },

  { name: "Spaces", href: "/spaces", icon: InventoryIcon, current: false },
  {
    name: "Stripe Connect",
    href: "/stripe-account",
    icon: CashDollarIcon,
    current: false,
  },
  { name: "Renters", href: "/renters", icon: PersonIcon, current: false },
  {
    name: "Bookings",
    href: "/bookings",
    icon: CalendarCheckIcon,
    current: false,
  },
  {
    name: "Analytics",
    href: "/analytics",
    icon: ChartVerticalFilledIcon,
    current: false,
  },
  { name: "Documents", href: "/documents", icon: PageIcon, current: false },
  { name: "Messages", href: "/messages", icon: ChatIcon, current: false },
];
const teamsInit = [
  // {
  //   id: 1,
  //   name: "Smart Promote",
  //   href: "/smart-promote",
  //   initial: "SP",
  //   current: false,
  // },
  {
    id: 2,
    name: "Your Space Profile",
    href: "/space-profile",
    initial: "YP",
    current: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Dashboard({ children, messages }) {
  const { user, closed, setClosed } = UserAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [navigation, setNavigation] = useState(navigationInit);
  const [teams, setTeams] = useState(teamsInit);

  const handleLogout = () => {
    logout();
  };

  const notificationBGColors = {
    touch: "bg-blue-50",
    quote: "bg-green-50",
    default: "bg-blue-50",
    offer: "bg-red-50",
  };

  const notificationFontColors = {
    touch: "text-blue-700",
    quote: "text-green-700",
    default: "text-blue-700",
    offer: "text-red-700",
  };

  const notificationRingColors = {
    touch: "ring-blue-600",
    quote: "ring-green-600",
    default: "ring-blue-600",
    offer: "ring-red-600",
  };

  const path = useLocation().pathname;

  useEffect(() => {
    setNavigation(
      navigation.map((item) => {
        if (item.href === path) {
          return { ...item, current: true };
        } else {
          return { ...item, current: false };
        }
      })
    );
    setTeams(
      teams.map((team) => {
        if (team.href === path) {
          return { ...team, current: true };
        } else {
          return { ...team, current: false };
        }
      })
    );
  }, [path]);

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://competeshare.com/profile/${user?.link}`
    );
    toast.success("Link Copied!");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <>
      <Toaster position="top-center" reverseOrder={false} />
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <X className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                    <div className="flex h-16 shrink-0 items-center">
                      <LogoSVG />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul role="list" className="-mx-2 space-y-1">
                            {navigation.map((item) => (
                              <li key={item.name}>
                                <Link
                                  to={item.href}
                                  className={classNames(
                                    item.current
                                      ? "bg-gray-50 text-emerald-600"
                                      : "text-gray-700 hover:text-emerald-600 hover:bg-gray-50",
                                    "group flex gap-x-2 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <item.icon
                                    className={classNames(
                                      item.current
                                        ? "text-emerald-600"
                                        : "text-gray-400 group-hover:text-emerald-600",
                                      "h-6 w-6 shrink-0"
                                    )}
                                    aria-hidden="true"
                                  />
                                  {item.name}
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li>
                          <div className="text-xs font-semibold leading-6 text-gray-400">
                            Tools
                          </div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            {teams.map((team) => (
                              <li key={team.name}>
                                <Link
                                  to={team.href}
                                  className={classNames(
                                    team.current
                                      ? "bg-gray-50 text-emerald-600"
                                      : "text-gray-700 hover:text-emerald-600 hover:bg-gray-50",
                                    "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                  )}
                                >
                                  <span
                                    className={classNames(
                                      team.current
                                        ? "text-emerald-600 border-emerald-600"
                                        : "text-gray-400 border-gray-200 group-hover:border-emerald-600 group-hover:text-emerald-600",
                                      "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                                    )}
                                  >
                                    {team.initial}
                                  </span>
                                  <span className="truncate">{team.name}</span>
                                </Link>
                              </li>
                            ))}
                          </ul>
                        </li>
                        <li className="mt-auto">
                          <Link
                            to="/settings"
                            className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                          >
                            <Cog
                              className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                              aria-hidden="true"
                            />
                            Settings
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div
          className={`hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex  lg:flex-col ${
            closed ? "w-14" : "lg:w-56"
          }`}
        >
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div
            className={`flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-zinc-200/50 pb-4 ${
              !closed && "px-6"
            }`}
          >
            {closed ? (
              <div
                className={`flex h-16 shrink-0 items-center justify-center pl-2
              } `}
              >
                <img src={logo} alt="logo" className="h-8 w-auto" />
              </div>
            ) : (
              <div
                className={`flex h-16 shrink-0 items-center ${
                  closed && "justify-center pl-2"
                } `}
              >
                <LogoSVG color={"#56BF92"} />
              </div>
            )}

            <nav className={`flex flex-1 flex-col pt-4 ${closed && "pl-6"}`}>
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1 mt-2">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? "bg-gray-50 text-zinc-950"
                              : "text-zinc-500 hover:text-zinc-800 hover:bg-zinc-100",
                            "group flex gap-x-2 rounded-xl p-2 text-sm leading-6 font-bold"
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current
                                ? "text-zinc-950"
                                : "text-zinc-300 group-hover:text-zinc-800 ",
                              "h-6 w-6 shrink-0"
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                          {(item.name === "Messages" && messages?.length) >
                          0 ? (
                            <span
                              aria-hidden="true"
                              className="ml-auto w-9 min-w-max whitespace-nowrap rounded-md bg-zinc-100 px-2.5 py-0.5 text-center text-xs font-medium leading-5 text-zinc-950 ring-1 ring-inset ring-zinc-700"
                            >
                              {messages?.length}
                            </span>
                          ) : null}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li>
                  {!closed && (
                    <div className="text-xs font-semibold leading-6 text-zinc-600">
                      Tools
                    </div>
                  )}

                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <Link
                          to={team.href}
                          className={classNames(
                            team.current
                              ? "bg-gray-50 text-emerald-600"
                              : "text-zinc-700 hover:text-emerald-600 hover:bg-gray-50",
                            "group flex gap-x-3 rounded-xl p-2 text-sm leading-6 font-semibold"
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? "text-emerald-600 border-emerald-600"
                                : "text-zinc-600 border-gray-200 group-hover:border-emerald-600 group-hover:text-emerald-600",
                              "flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border text-[0.625rem] font-medium bg-white"
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                <li className="mt-auto">
                  <Link
                    to="/settings"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                  >
                    <Cog
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                      aria-hidden="true"
                    />
                    Settings
                  </Link>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        {closed && (
          <div className="absolute lg:block hidden">
            <button
              type="button"
              className={`relative ${
                closed ? "left-10" : "left-48 ml-2"
              } top-14 z-50 bg-zinc-50 hover:bg-white rounded-full p-2 shadow-md`}
              onClick={() => setClosed(!closed)}
            >
              <span className="sr-only">Toggle sidebar</span>
              {closed ? (
                <ArrowRight className="h-6 w-6" aria-hidden="true" />
              ) : (
                <ArrowLeft className="h-6 w-6" aria-hidden="true" />
              )}
            </button>
          </div>
        )}

        <div className={`${closed ? "lg:pl-14" : "lg:pl-56"}`}>
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button
              type="button"
              className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </button>

            {/* Separator */}
            <div
              className="h-6 w-px bg-gray-200 lg:hidden"
              aria-hidden="true"
            />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              {user?.link && (
                <div className="hidden sm:flex items-center">
                  <div className="flex rounded-md shadow-sm md:w-3/4">
                    <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-800 sm:text-sm bg-gray-100">
                      https://competeshare.com/profile/
                      <span className="font-bold">{user?.link}</span>
                    </span>
                    <button
                      type="text"
                      name="company-website"
                      id="company-website"
                      className="block w-full bg-emerald-500 hover:bg-emerald-700 text-white font-semibold flex-1 rounded-r-md border-0 py-1.5 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      onClick={handleCopy}
                    >
                      Copy
                    </button>
                  </div>
                </div>
              )}

              <div className="flex items-center gap-x-4 lg:gap-x-6 ml-auto">
                {/* {(!user?.plan || user?.plan === "temp" || user?.plan === "basic") ? (
                  <Link
                    to="/pricing"
                    type="button"
                    className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  >
                    Upgrade
                  </Link>
                ) : (
                  <button
                    type="button"
                    className="rounded-md bg-blue-600 px-2.5 py-1.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
                  >
                    <PlusCircle className="h-5 w-5 inline-block -mt-0.5 mr-1" />
                    Add Space
                  </button>
                )} */}
                <Menu as="div" className="relative -m-2">
                  <Menu.Button
                    type="button"
                    className=" p-2.5 text-gray-950 hover:text-zinc-950 bg-zinc-100 rounded-full hover:bg-zinc-200 hover:border hover:border-emerald-300"
                  >
                    <span className="sr-only">View notifications</span>
                    <span className="relative ">
                      <Bell className="h-5 w-5" aria-hidden="true" />
                      {messages?.length > 0 && (
                        <span className="absolute right-0 top-0 block h-3 w-3 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-green-500 ring-2 ring-white animate-pulse" />
                      )}
                    </span>
                  </Menu.Button>
                  <Menu.Items
                    transition
                    className="absolute right-0 z-10 mt-2 sm:w-96 lg:w-[450px] w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="divide-y divide-gray-300">
                      {messages?.length > 0 ? (
                        messages?.map((message, index) => (
                          <Menu.Item key={index}>
                            {({ active }) => (
                              <div className="pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 bg-white ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                                <div className="flex w-0 flex-1 items-center p-4">
                                  <div className="w-full">
                                    <p className="text-sm font-medium text-gray-900">
                                      {message[0]?.name}{" "}
                                      <span
                                        className={`inline-flex items-center rounded-md ${
                                          notificationBGColors[message[0]?.type]
                                        } px-2 py-1 text-xs font-medium ${
                                          notificationFontColors[message[0]?.type]
                                        } ring-1 ring-inset ${
                                          notificationRingColors[message[0]?.type]
                                        }`}
                                      >
                                        {message[0]?.type === 1
                                          ? "Form Submission"
                                          : message[0]?.type === 3
                                          ? "Requested Quote"
                                          : message[0]?.type === 2
                                          ? "Sent Offer"
                                          : "General"}
                                      </span>
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                                      {message[0]?.message}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="flex flex-col divide-y divide-gray-200">
                                    <div className="flex h-0 flex-1">
                                      <button
                                        type="button"
                                        className="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-emerald-600 hover:text-emerald-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                      >
                                        View
                                      </button>
                                    </div>
                                    <div className="flex h-0 flex-1">
                                      <button
                                        type="button"
                                        className="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500"
                                      >
                                        Ignore
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        ))
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/messages"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 text-center"
                              )}
                            >
                              No new messages
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Menu>

                {/* Separator */}
                <div
                  className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-400"
                  aria-hidden="true"
                />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={user?.profilePic || logo}
                      alt=""
                    />
                    <div className="hidden lg:flex lg:items-center ml-2">
                      <div className="text-left">
                        <div
                          className="text-sm font-bold tracking-tight text-gray-950"
                          aria-hidden="true"
                        >
                          {user?.firstName} {user?.lastName}
                        </div>
                        <div className="text-xs font-semibold text-gray-500 -mt-1">
                          {user?.email}
                        </div>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/settings"
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left"
                            )}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleLogout}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left"
                            )}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>

            </div>
          </div>

          <main className={`${!path.includes("/messages") && "py-10"}`}>
            <div
              className={`${
                !path.includes("/messages") && "px-4 sm:px-6 lg:px-8"
              }`}
            >
              {children}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
