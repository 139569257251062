import { states } from "../../assets/js/states";
import { useEffect, useState } from "react";

import availableTimes from "../../assets/json/availableTimeBlocks.json";
import LicensePreview from "../components/LicensePreview";
import { UserAuth } from "../../context/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import { createBooking } from "../../functions/crud";

const initialProviderState = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  phone: "",
  email: "",
};

const initialTenantState = {
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  email: "",
};

const initialSpaceState = {
  description: "",
  accessTerms: "",
  guidelines: "",
  intendedUse: "",
};

const initialLicenseState = {
  startDate: "",
  endDate: "",
  monthlyRent: 0,
  paymentDates: [],
  totalMonths: 0,
  securityDeposit: 0,
  monday: false,
  tuesday: false,
  wednesday: false,
  thursday: false,
  friday: false,
  saturday: false,
  sunday: false,
};

const insuruanceInit = {
  coverageLimitsAggregate: 0,
  coverageLimitsPerOccurrence: 0,
  responsibleForInsurance: "landlord",
};

export default function CreateOffer() {
  const { spaces, user } = UserAuth();
  const [loading, setLoading] = useState(false);

  const [provider, setProvider] = useState(initialProviderState);
  const [tenant, setTenant] = useState(initialTenantState);
  const [space, setSpace] = useState(initialSpaceState);
  const [license, setLicense] = useState(initialLicenseState);
  const [insurance, setInsurance] = useState(insuruanceInit);
  const timeOptions = Object.values(availableTimes)[0];
  const [spaceID, setSpaceID] = useState("");

  const [totalAmount, setTotalAmount] = useState(0);
  const [totalFees, setTotalFees] = useState(0);
  const [totalEarned, setTotalEarned] = useState(0);
  const [totalPerMonth, setTotalPerMonth] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (license.startDate && license.endDate) {
      const start = new Date(license.startDate);
      const end = new Date(license.endDate);
      const startDay = start.getDate();
      const diffTime = Math.abs(end - start);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      const diffMonths =
        (end.getFullYear() - start.getFullYear()) * 12 +
        (end.getMonth() - start.getMonth());
      // Generate payment dates for each month
      const paymentDates = [];
      for (let i = 0; i <= diffMonths; i++) {
        const date = new Date(start);
        date.setMonth(start.getMonth() + i);
        date.setDate(startDay); // Set the date to the 15th of each month
        // Ensure the date is before or on the end date
        if (date <= end) {
          paymentDates.push(date);
        }
      }
      if (diffDays < 30) {
        return setLicense({ ...license, totalMonths: 1 });
      } else if (diffDays > 30) {
        setLicense({
          ...license,
          totalMonths: diffDays / 30,
          paymentDates: paymentDates,
        });
      }
    }
  }, [license.startDate, license.endDate]);

  useEffect(() => {
    if (license.monthlyRent && license.totalMonths) {
      const totalAmount =
        parseInt(license.monthlyRent) * Math.floor(license.totalMonths);
      const totalFees = totalAmount * 0.03;
      const totalPerMonthFee = parseInt(license.monthlyRent) * 0.03;
      setTotalFees(totalFees);
      setTotalAmount(totalAmount + totalFees);
      setTotalEarned(totalAmount);
      setTotalPerMonth(parseInt(license.monthlyRent) + totalPerMonthFee);
      setLicense({ ...license, totalAmount: totalAmount, totalFees: totalFees });
    } else {
      setTotalAmount(0);
    }
  }, [license.monthlyRent, license.totalMonths]);

  ///removehtml from description
  const removeHtml = (html) => {
    var tmp = document.createElement("DIV");
    tmp.innerHTML = html;
    return tmp.textContent || tmp.innerText || "";
  };

  useEffect(() => {
    if (spaceID) {
      const selectedSpace = spaces.find((space) => space.id === spaceID);
      setSpace({
        ...space,
        description: removeHtml(selectedSpace.description),
      });
      setProvider({
        ...provider,
        address: selectedSpace.address,
        city: selectedSpace.city,
        state: selectedSpace.state,
        zip: selectedSpace.zip,
      });
    }
  }, [spaceID, spaces]);

  const bookingData = {
    provider,
    tenant,
    space,
    license,
    insurance,
    spaceID,
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    if (!spaceID) {
      setLoading(false);
      return alert("Please select a space");
    } else if (!provider.firstName) {
      setLoading(false);
      return alert("Please enter the provider's first name");
    } else if (!provider.lastName) {
      setLoading(false);
      return alert("Please enter the provider's last name");
    } else if (!provider.address) {
      setLoading(false);
      return alert("Please enter the provider's address");
    } else if (!provider.city) {
      setLoading(false);
      return alert("Please enter the provider's city");
    } else if (!provider.state) {
      setLoading(false);
      return alert("Please enter the provider's state");
    } else if (!provider.zip) {
      setLoading(false);
      return alert("Please enter the provider's zip code");
    } else if (!provider.phone) {
      setLoading(false);
      return alert("Please enter the provider's phone number");
    } else if (!provider.email) {
      setLoading(false);
      return alert("Please enter the provider's email");
    } else if (!tenant.firstName) {
      setLoading(false);
      return alert("Please enter the tenant's first name");
    } else if (!tenant.lastName) {
      setLoading(false);
      return alert("Please enter the tenant's last name");
    } else if (!tenant.phone) {
      setLoading(false);
      return alert("Please enter the tenant's phone number");
    } else if (!tenant.address) {
      setLoading(false);
      return alert("Please enter the tenant's address");
    } else if (!tenant.city) {
      setLoading(false);
      return alert("Please enter the tenant's city");
    } else if (!tenant.state) {
      setLoading(false);
      return alert("Please enter the tenant's state");
    } else if (!tenant.zip) {
      setLoading(false);
      return alert("Please enter the tenant's zip code");
    } else if (!tenant.email) {
      setLoading(false);
      return alert("Please enter the tenant's email");
    } else if (!space.description) {
      setLoading(false);
      return alert("Please enter the space description");
    } else if (!space.intendedUse) {
      setLoading(false);
      return alert("Please enter the intended use");
    } else if (!license.startDate) {
      setLoading(false);
      return alert("Please enter the start date");
    } else if (!license.endDate) {
      setLoading(false);
      return alert("Please enter the end date");
    } else if (!license.monthlyRent) {
      setLoading(false);
      return alert("Please enter the monthly rent");
    } else if (!license.paymentDates) {
      setLoading(false);
      return alert("Please enter the payment dates");
    } else if (!license.totalMonths) {
      setLoading(false);
      return alert("Please enter the total months");
    } else if (!license.securityDeposit) {
      setLoading(false);
      return alert("Please enter the security deposit");
    } else if (!insurance.coverageLimitsAggregate) {
      setLoading(false);
      return alert("Please enter the coverage aggregation amount");
    } else if (!insurance.coverageLimitsPerOccurrence) {
      setLoading(false);
      return alert("Please enter the coverage per occurrence");
    } else if (!insurance.responsibleForInsurance) {
      setLoading(false);
      return alert("Please enter who is responsible for insurance");
    } else {
      createBooking(bookingData, user.id)
        .then((data) => {
          if (data.status === "success") {
            setLoading(false);
            alert("Booking created successfully");
            navigate("/bookings");
          } else {
            setLoading(false);
            alert("Error creating booking");
          }
        })
        .catch((error) => {
          setLoading(false);
          alert("Error creating booking");
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  return (
    <>
      <div className="border-b border-gray-200 pb-5">
        <div className="max-w-full mx-auto">
          <h3 className="text-3xl font-bold  text-gray-900">
            Create a Booking
          </h3>
          <p className="mt-4 text-sm text-gray-700">
            If you need to create your own booking offer, you can do so here.
            This tool is designed to help you create a booking offer that will
            be sent to the customer for review and acceptance. Once the customer
            accepts the offer, you will be notified and the booking will be
            added to your calendar.
          </p>
          <p className="mt-4 text-sm text-red-500">
            *Only create an offer if you have already spoken to the customer and
            have agreed on the details of the booking. If you have not yet
            spoken to the customer, please use the{" "}
            <a href="/dashboard/messages" className="text-blue-500">
              Messages
            </a>{" "}
            tool to negotiate with them.
          </p>
        </div>
      </div>
      <form>
        <div className="space-y-12 p-4">
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Select a Space
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Please select the space you would like to create a booking offer
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow border ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Space
                    </label>
                    <select
                      id="space"
                      name="space"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:max-w-xs sm:text-sm sm:leading-6 h-12"
                      value={spaceID}
                      onChange={(e) => setSpaceID(e.target.value)}
                    >
                      {spaces?.length > 0 ? (
                        <>
                          <option value="">Select a space</option>
                          {spaces?.map((space) => (
                            <option key={space.id} value={space.id}>
                              {space.title}
                            </option>
                          ))}
                        </>
                      ) : (
                        <option value="">No spaces available</option>
                      )}
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Provider Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Please enter your information in this form. This information
                will be used to create the booking offer.
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow border ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="First name of property representative"
                      value={provider.firstName ? provider.firstName : ""}
                      onChange={(e) =>
                        setProvider({
                          ...provider,
                          firstName: e.target.value,
                        })
                      }
                      autoComplete="off"
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Last name of property representative"
                      value={provider.lastName ? provider.lastName : ""}
                      onChange={(e) =>
                        setProvider({ ...provider, lastName: e.target.value })
                      }
                      autoComplete="off"
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Address of the location"
                      value={provider.address ? provider.address : ""}
                      onChange={(e) =>
                        setProvider({ ...provider, address: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      value={provider.city ? provider.city : ""}
                      onChange={(e) =>
                        setProvider({ ...provider, city: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <div className="flex items-center col-span-2 text-sm font-medium leading-6 text-gray-900">
                      State
                    </div>
                    <select
                      id="space-type"
                      name="space-type"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:max-w-xs sm:text-sm sm:leading-6 h-12"
                      value={JSON.stringify(provider.state)} // Convert the spaceType object to a JSON string
                      onChange={(e) =>
                        setProvider({
                          ...provider,
                          state: JSON.parse(e.target.value),
                        })
                      } // Parse the JSON string back to an object when the selection changes
                    >
                      {states?.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={JSON.stringify(state.abbreviation)} // Convert the object to a JSON string as the option value
                        >
                          {state.abbreviation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="zip"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Zip
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      value={provider.zip ? provider.zip : ""}
                      onChange={(e) =>
                        setProvider({ ...provider, zip: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <input
                      type="tel"
                      name="tel"
                      id="tel"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Phone number of property representative"
                      value={provider.phone ? provider.phone : ""}
                      onChange={(e) =>
                        setProvider({ ...provider, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Email of property representative"
                      value={provider.email ? provider.email : ""}
                      onChange={(e) =>
                        setProvider({ ...provider, email: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Tenant Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Please enter the renters information below. This information
                will be used to create the booking offer.
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow border  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      First name
                    </label>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="First name of tenant"
                      value={tenant.firstName ? tenant.firstName : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, firstName: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="last-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Last name
                    </label>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Last name of tenant"
                      value={tenant.lastName ? tenant.lastName : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, lastName: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="address"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Address
                    </label>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Address of the tenant"
                      value={tenant.address ? tenant.address : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, address: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="city"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      City
                    </label>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      value={tenant.city ? tenant.city : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, city: e.target.value })
                      }
                    />
                  </div>
                  <div>
                    <div className="flex items-center col-span-2 text-sm font-medium leading-6 text-gray-900">
                      State
                    </div>
                    <select
                      id="space-type"
                      name="space-type"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:max-w-xs sm:text-sm sm:leading-6 h-12"
                      value={JSON.stringify(tenant.state)} // Convert the spaceType object to a JSON string
                      onChange={(e) =>
                        setTenant({
                          ...tenant,
                          state: JSON.parse(e.target.value),
                        })
                      } // Parse the JSON string back to an object when the selection changes
                    >
                      {states?.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={JSON.stringify(state.abbreviation)} // Convert the object to a JSON string as the option value
                        >
                          {state.abbreviation}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="zip"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Zip
                    </label>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      value={tenant.zip ? tenant.zip : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, zip: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="about"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Phone number
                    </label>
                    <input
                      type="tel"
                      name="tel"
                      id="tel"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Phone number of tenant"
                      value={tenant.phone ? tenant.phone : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, phone: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-6">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Email
                    </label>
                    <input
                      type="email"
                      name="email"
                      id="email"
                      className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Email of tenant"
                      value={tenant.email ? tenant.email : ""}
                      onChange={(e) =>
                        setTenant({ ...tenant, email: e.target.value })
                      }
                    />
                    <p className="mt-1 text-xs text-yellow-700 flex items-center justify-center">
                    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-triangle-alert"><path d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3"/><path d="M12 9v4"/><path d="M12 17h.01"/></svg>
                      This is the email we will use to send the booking offer for the tenant to review and accept.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Space Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This is the information you provided when you created your
                space. Please review and make any changes as necessary.
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl border ">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="col-span-full">
                    <label
                      htmlFor="space-description"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Space Description
                    </label>
                    <p className="text-sm text-zinc-500 col-span-4">
                      Please provide a description of the space you are
                      offering. Be as detailed as possible.
                    </p>
                    <textarea
                      id="space-description"
                      name="space-description"
                      rows={4}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Describe the space you are offering"
                      value={space.description}
                      onChange={(e) =>
                        setSpace({ ...space, description: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="access-terms"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Access Terms and Instructions
                    </label>
                    <p className="text-sm text-zinc-500 col-span-4">
                      This information lets the tenant know how to access the
                      space. Please provide clear instructions.
                    </p>
                    <textarea
                      id="access-terms"
                      name="access-terms"
                      rows={4}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Enter the access terms and instructions for the tenant"
                      value={space.accessTerms}
                      onChange={(e) =>
                        setSpace({ ...space, accessTerms: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="guidlines"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Rules and Guidlines
                    </label>
                    <p className="text-sm text-zinc-500 col-span-4">
                      Please provide any rules or guidelines the tenant should
                      be aware of.
                    </p>
                    <textarea
                      id="access-terms"
                      name="access-terms"
                      rows={4}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Enter the access terms and instructions for the tenant"
                      value={space.guidelines}
                      onChange={(e) =>
                        setSpace({ ...space, guidelines: e.target.value })
                      }
                    />
                  </div>
                  <div className="col-span-full">
                    <label
                      htmlFor="space-description"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Intended Use For Space
                    </label>
                    <p className="text-sm text-zinc-500 col-span-4">
                      Please specify the intended use case for the space. This
                      is what the renter will be using the space for. This
                      information will be used to create the booking offer.
                    </p>
                    <textarea
                      id="space-description"
                      name="space-description"
                      rows={4}
                      className="mt-1 block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      placeholder="Describe the space you are offering"
                      value={space.intendedUse}
                      onChange={(e) =>
                        setSpace({ ...space, intendedUse: e.target.value })
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                License Period and Times
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                Please provide the license period for the space. This
                information will be used to create the booking offer.
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl border ">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="start-date"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Start Date
                    </label>
                    <input
                      type="date"
                      name="start-date"
                      id="start-date"
                      className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      value={license.startDate ? license.startDate : ""}
                      onChange={(e) =>
                        setLicense({ ...license, startDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="end-date"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      End Date
                    </label>
                    <input
                      type="date"
                      name="end-date"
                      id="end-date"
                      className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                      value={license.endDate ? license.endDate : ""}
                      onChange={(e) =>
                        setLicense({ ...license, endDate: e.target.value })
                      }
                    />
                  </div>
                  <div className="sm:col-span-full ">
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Select the days and time slots available for the tenant
                    </label>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          checked={license.monday}
                          type="checkbox"
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              monday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Monday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              mondayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              mondayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          checked={license.tuesday}
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              tuesday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Tuesday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              tuesdayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              tuesdayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          checked={license.wednesday}
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              wednesday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Wednesday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              wednesdayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              wednesdayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          checked={license.thursday}
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              thursday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Thursday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              thursdayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              thursdayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          checked={license.friday}
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              friday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Friday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              fridayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              fridayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          checked={license.saturday}
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              saturday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Saturday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              saturdayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              saturdayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div className="relative flex items-start mt-6">
                      <div className="flex items-center gap-x-2 w-full">
                        <input
                          id="comments"
                          aria-describedby="comments-description"
                          name="comments"
                          type="checkbox"
                          checked={license.sunday}
                          className="h-6 w-6 rounded border-gray-300 text-emerald-600 focus:ring-emerald-600"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              sunday: e.target.checked,
                            });
                          }}
                        />
                        <label
                          htmlFor="comments"
                          className="font-medium text-gray-900 ml-2 w-full"
                        >
                          Sunday
                        </label>{" "}
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="Start Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              sundayStart: e.target.value,
                            });
                          }}
                        >
                          <option disabled>Start Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <select
                          className="h-12 ml-2 w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                          defaultValue="End Time"
                          onChange={(e) => {
                            setLicense({
                              ...license,
                              sundayEnd: e.target.value,
                            });
                          }}
                        >
                          <option disabled>End Time</option>
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                License Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This it the payment information for the space, and fees.
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow border  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Security Deposit
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        className="block w-full h-12 rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        onChange={(e) =>
                          setLicense({
                            ...license,
                            securityDeposit: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-xs text-zinc-500 ml-2">
                      This is the amount the tenant will be required to pay as a
                      security deposit. This amount will be refunded to the
                      tenant at the end of the license period.
                    </p>
                  </div>
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Monthly Rent
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        className="block w-full h-12 rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        onChange={(e) =>
                          setLicense({
                            ...license,
                            monthlyRent: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-xs text-zinc-500 ml-2">
                      This is the amount the tenant will be required to pay
                      monthly to rent the space.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-zinc-50 shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-end-4 md:col-start-2 max-w-2xl border ">
              <div className="px-4 py-6 sm:p-8">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Payment Schedule
                </h2>
                <p>
                  The tenant will be required to make payments on the following
                  dates:
                </p>
                <table className="w-full text-left">
                  <thead className="border-b border-b-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 "
                      >
                        Date
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900 table-cell"
                      >
                        Fees
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900 table-cell"
                      >
                        Amount Due
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {license?.paymentDates.map((date, index) => (
                      <tr key={index}>
                        <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                          <div>{new Date(date).toDateString()}</div>
                          <div className="text-zinc-600 font-normal text-xs">
                            {index === 0 &&
                              "First Payment includes deposit and fees"}
                          </div>
                          <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                          <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                        </td>
                        <td className="px-3 py-4 text-sm text-gray-500 table-cell">
                          +$
                          {index === 0
                            ? (
                                parseInt(license?.monthlyRent) * 0.03 +
                                parseInt(license?.securityDeposit)
                              ).toFixed(2)
                            : (parseInt(license?.monthlyRent) * 0.03).toFixed(
                                2
                              )}
                        </td>
                        <td className="text-right px-3 py-4 text-sm text-zinc-900 font-semibold table-cell">
                          +$
                          {index === 0
                            ? (
                                parseInt(totalPerMonth) +
                                parseInt(license?.securityDeposit)
                              ).toFixed(2)
                            : parseInt(totalPerMonth)?.toFixed(2)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="bg-zinc-50 shadow ring-1 ring-gray-900/5 sm:rounded-xl md:col-end-4 md:col-start-2 max-w-2xl border ">
              <div className="px-4 py-6 sm:p-8">
                <h2 className="text-base font-semibold leading-7 text-gray-900">
                  Totals
                </h2>
                <table className="w-full text-left">
                  <thead className="border-b border-b-gray-200">
                    <tr>
                      <th
                        scope="col"
                        className="relative isolate py-3.5 pr-3 text-left text-sm font-semibold text-gray-900 "
                      >
                        Total Balance
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Compete Share Fees
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                      >
                        Sripe Fees
                      </th>
                      <th
                        scope="col"
                        className="hidden px-3 py-3.5 text-sm font-semibold text-gray-900 sm:table-cell text-right"
                      >
                        Estimated Earnings
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className="relative py-4 pr-3 text-sm font-medium text-gray-900">
                        <div> ${totalAmount?.toFixed(2)}</div>

                        <div className="absolute bottom-0 right-full h-px w-screen bg-gray-100" />
                        <div className="absolute bottom-0 left-0 h-px w-screen bg-gray-100" />
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-red-500 sm:table-cell">
                        -${totalFees?.toFixed(0)}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-red-500 sm:table-cell">
                        -${((totalAmount * 0.029) + .30)?.toFixed(0)}
                      </td>
                      <td className="hidden px-3 py-4 text-sm text-green-500 sm:table-cell font-bold text-right">
                        ${totalEarned - ((totalAmount * 0.029) + .30)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-1 gap-x-8 gap-y-10 border-b border-gray-900/10 pb-12 md:grid-cols-3">
            <div>
              <h2 className="text-base font-semibold leading-7 text-gray-900">
                Insurance Information
              </h2>
              <p className="mt-1 text-sm leading-6 text-gray-600">
                This it the payment information for the space, and fees.
              </p>
            </div>

            <div className="bg-zinc-50 mt-4 shadow border  ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 max-w-2xl">
              <div className="px-4 py-6 sm:p-8">
                <div className="grid grid-cols-1 gap-4 sm:grid-cols-6">
                  <div className="sm:col-span-full">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Who is responsible for insurance?
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <select
                        id="insurance"
                        name="insurance"
                        className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        value={insurance.responsibleForInsurance}
                        onChange={(e) =>
                          setInsurance({
                            ...insurance,
                            responsibleForInsurance: e.target.value,
                          })
                        }
                      >
                        <option value="tenant">Tenant</option>
                        <option value="landlord">Landlord</option>
                      </select>
                    </div>
                    <p className="text-xs text-zinc-500 ml-2">
                      If you select tenant, the tenant will be responsible for
                      the general liability insurance with coverage limits. If
                      you select landlord, the landlord will be responsible for
                      the general liability insurance with coverage limits.
                    </p>
                  </div>

                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Coverage Limits Per Occurrence
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        className="block w-full h-12 rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        onChange={(e) =>
                          setInsurance({
                            ...insurance,
                            coverageLimitsPerOccurrence: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-xs text-zinc-500 ml-2">
                      This is the maximum amount the insurance will cover for a
                      single occurrence.
                    </p>
                  </div>
                  <div className="sm:col-span-3">
                    <label
                      htmlFor="first-name"
                      className="block text-sm font-medium leading-6 text-gray-900"
                    >
                      Coverage Limits Aggregate
                    </label>
                    <div className="relative rounded-md shadow-sm">
                      <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                        <span className="text-gray-500 sm:text-sm">$</span>
                      </div>
                      <input
                        type="text"
                        name="price"
                        id="price"
                        className="block w-full h-12 rounded-md border-0 py-1.5 pl-7 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                        placeholder="0.00"
                        aria-describedby="price-currency"
                        onChange={(e) =>
                          setInsurance({
                            ...insurance,
                            coverageLimitsAggregate: e.target.value,
                          })
                        }
                      />
                    </div>
                    <p className="text-xs text-zinc-500 ml-2">
                      This is the maximum amount the insurance will cover for
                      all occurrences.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mt-6 flex items-center justify-end gap-x-6">
          {/* <LicensePreview /> */}
          <button
            type="submit"
            className="rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
            onClick={handleSubmit}
          >
            Create Booking
          </button>
        </div>
      </form>
      {/* <div className="p-4 max-w-7xl mx-auto pb-48">
        <div className="space-y-10 divide-y divide-gray-900/10">
       
          <LeaseTermDatesAndPricing />
          <PayStructureInformation />
          <PreOfferInfo />
        </div>
      </div> */}
    </>
  );
}
