import profilPic from "../../assets/images/profile.png";
import { useEffect, useState } from "react";
import { addLeadNotes, getLead } from "../../functions/crud";
import { Link, useNavigate, useParams } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { convertPhoneNumber } from "../../functions/shared";
import { Mail } from "lucide-react";
import ConvertToRenter from "../components/ConvertToRenter";
import toast, { Toaster } from "react-hot-toast";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ViewLead() {
  const { spaces, user } = UserAuth();
  const [lead, setLead] = useState(null);
  const [note, setNote] = useState({
    name: user?.firstName + " " + user?.lastName,
    profilePic: user?.profilePic,
    body: "",
    date: new Date().toDateString(),
  });
  const navigate = useNavigate();
  const { leadID } = useParams();

  const handleGetLead = async (id) => {
    const lead = await getLead(id);
    if (lead !== null) {
      setLead(lead);
    } else {
      console.log("No data available");
      toast.error("No lead ID found");
      navigate("/");
    }
  };

  useEffect(() => {
    if (leadID) {
      handleGetLead(leadID);
    } else {
      console.log("No lead ID found");
    }
  }, [leadID]);

  const handleAddNote = (e) => {
    e.preventDefault();
    const newNote = {
      name: note.name,
      profilePic: note.profilePic,
      body: note.body,
      date: new Date().toDateString(),
    };
    addLeadNotes(lead.id, newNote);
    setLead({ ...lead, notes: [...lead.notes, newNote] });
    setNote({
      name: user?.firstName + " " + user?.lastName,
      profilePic: user?.profilePic,
      body: "",
      date: "",
    });
  };

  return (
    <>
      <Toaster />
      <div className="min-h-full max-w-4xl mx-auto">
        <main className="py-10">
          {/* Page header */}
          <div className="mx-auto px-4 sm:px-6 md:flex md:items-center md:justify-between md:space-x-5  lg:px-8">
            <div className="flex items-center space-x-5">
              <div className="flex-shrink-0">
                <div className="relative">
                  <img
                    alt=""
                    src={lead?.profilePic || profilPic}
                    className="h-16 w-16 rounded-full"
                  />
                  <span
                    aria-hidden="true"
                    className="absolute inset-0 rounded-full shadow-inner"
                  />
                </div>
              </div>
              <div>
                <h1 className="text-2xl font-bold text-gray-900">
                  {lead?.firstName || lead?.name} {lead?.lastName}
                </h1>
                <p className="text-sm font-medium text-gray-500">
                  Interested in{" "}
                  <a href="#" className="text-gray-900">
                    {lead?.space !== "smart"
                      ? spaces?.find((space) => space.id === lead?.space)?.title
                      : "Your Location"}
                  </a>{" "}
                  on{" "}
                  <time dateTime={new Date(lead?.createdAt).toDateString()}>
                    {new Date(lead?.createdAt).toDateString()}
                  </time>
                </p>
              </div>
            </div>
            <div className="mt-6 flex flex-col-reverse justify-stretch space-y-4 space-y-reverse sm:flex-row-reverse sm:justify-end sm:space-x-3 sm:space-y-0 sm:space-x-reverse md:mt-0 md:flex-row md:space-x-3">
              <button
                type="button"
                className="inline-flex items-center justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
              >
                Delete
              </button>
              <ConvertToRenter renter={lead} />
            </div>
          </div>

          <div className="mx-auto mt-8  sm:px-6  ">
            <div className="space-y-6 ">
              {/* Description list*/}
              <section aria-labelledby="applicant-information-title">
                <div className="bg-white shadow sm:rounded-lg">
                  <div className="px-4 py-5 sm:px-6">
                    <h2
                      id="applicant-information-title"
                      className="text-lg font-medium leading-6 text-gray-900"
                    >
                      Lead Information
                    </h2>
                    <p className="mt-1 max-w-2xl text-sm text-gray-500">
                      Personal details and lead information.
                    </p>
                  </div>
                  <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Interested in
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {lead?.space !== "smart" ? (
                            <Link
                              to={`/space/${lead?.space}`}
                              className="font-medium text-blue-600 hover:text-blue-500"
                            >
                              {
                                spaces?.find(
                                  (space) => space.id === lead?.space
                                )?.title
                              }
                            </Link>
                          ) : (
                            "Your Location"
                          )}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Email address
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {lead?.email}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Quoted Amount
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {lead?.quoteAmount
                            ? `$${lead?.quoteAmount}`
                            : "No Quote Yet"}
                        </dd>
                      </div>
                      <div className="sm:col-span-1">
                        <dt className="text-sm font-medium text-gray-500">
                          Phone
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {convertPhoneNumber(lead?.phone)}
                        </dd>
                      </div>
                      <div className="sm:col-span-2">
                        <dt className="text-sm font-medium text-gray-500">
                          Message
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          {lead?.message}
                        </dd>
                      </div>
                    </dl>
                  </div>
                  <div>
                    <button className="flex items-center justify-center w-full bg-gray-50 px-4 py-4 text-center text-sm font-medium text-gray-500 hover:text-gray-700 sm:rounded-b-lg">
                      <Mail className="h-5 w-5 mr-1" />
                      Send Invite to Create Account
                    </button>
                  </div>
                </div>
              </section>

              {/* Comments*/}
              <section aria-labelledby="notes-title">
                <div className="bg-white shadow sm:overflow-hidden sm:rounded-lg">
                  <div className="divide-y divide-gray-200">
                    <div className="px-4 py-5 sm:px-6">
                      <h2
                        id="notes-title"
                        className="text-lg font-medium text-gray-900"
                      >
                        Notes
                      </h2>
                    </div>
                    <div className="px-4 py-6 sm:px-6">
                      {lead?.notes?.length > 0 ? (
                        <ul role="list" className="space-y-8">
                          {lead?.notes?.map((comment) => (
                            <li key={comment.id}>
                              <div className="flex space-x-3">
                                <div className="flex-shrink-0">
                                  <img
                                    alt=""
                                    src={user?.profilePic || profilPic}
                                    className="h-10 w-10 rounded-full"
                                  />
                                </div>
                                <div>
                                  <div className="text-sm">
                                    <a
                                      href="#"
                                      className="font-medium text-gray-900"
                                    >
                                      {comment.name}
                                    </a>
                                  </div>
                                  <div className="mt-1 text-sm text-gray-700">
                                    <p>{comment.body}</p>
                                  </div>
                                  <div className="mt-2 space-x-2 text-sm">
                                    <span className="font-medium text-gray-500">
                                      {comment.date}
                                    </span>{" "}
                                  </div>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                      ) : (
                        <p className="text-sm text-gray-500">
                          No notes available
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="bg-gray-50 px-4 py-6 sm:px-6">
                    <div className="flex space-x-3">
                      <div className="flex-shrink-0">
                        <img
                          alt=""
                          src={user?.profilePic || profilPic}
                          className="h-10 w-10 rounded-full"
                        />
                      </div>
                      <div className="min-w-0 flex-1">
                        <form action="#">
                          <div>
                            <label htmlFor="comment" className="sr-only">
                              About
                            </label>
                            <textarea
                              id="comment"
                              name="comment"
                              rows={3}
                              placeholder="Add a note"
                              className="block w-full rounded-md border-0 py-1.5 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6"
                              value={note.body}
                              onChange={(e) =>
                                setNote({
                                  ...note,
                                  body: e.target.value,
                                })
                              }
                            />
                          </div>
                          <div className="mt-3 flex items-center justify-between">
                            <button
                              type="submit"
                              className="ml-auto inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                              onClick={handleAddNote}
                            >
                              Add Note
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </main>
      </div>
    </>
  );
}
