import { ArrowRight } from "lucide-react";
import React, { useState } from "react";
import { states } from "../../../assets/js/states";

export default function TenantInfo({ setCurrent, formData, setFormData }) {

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className=" bg-zinc-50 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 overflow-hidden rounded-lg  shadow max-w-xl mx-auto">
      <div className="px-4 py-5 sm:p-6">
        <h2 className="text-xl font-semibold">Tenant Information</h2>
        <p className="text-gray-600 mb-6">
          Please fill in the following details:
        </p>
        <form>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-full">
              <label
                htmlFor="businessName"
                className="block text-sm font-medium text-gray-700"
              >
                Business Name (if applicable)
              </label>
              <input
                type="text"
                name="businessName"
                id="businessName"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.businessName}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="firstName"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.firstName}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="lastName"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.lastName}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-full">
              <label
                htmlFor="address"
                className="block text-sm font-medium text-gray-700"
              >
                Address
              </label>
              <input
                type="text"
                name="address"
                id="address"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.address}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="city"
                className="block text-sm font-medium text-gray-700"
              >
                City
              </label>
              <input
                type="text"
                name="city"
                id="city"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.city}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-1">
              <label
                htmlFor="state"
                className="block text-sm font-medium text-gray-700"
              >
                State
              </label>
              <select
                id="state"
                name="state"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:max-w-xs sm:text-sm sm:leading-6 h-12"
                value={formData.state}
                onChange={handleChange}
              >
                <option value="">Select State</option>
                {states?.map((state) => (
                  <option
                    key={state.abbreviation}
                    value={JSON.stringify(state.abbreviation)}
                  >
                    {state.abbreviation}
                  </option>
                ))}
              </select>
            </div>
            <div className="col-span-2">
              <label
                htmlFor="zip"
                className="block text-sm font-medium text-gray-700"
              >
                Zip
              </label>
              <input
                type="text"
                name="zip"
                id="zip"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.zip}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.phone}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-3">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-md border-0 py-1.5 h-12 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.email}
                onChange={handleChange}
              />
            </div>
            <div className="col-span-full">
              <button
                type="submit"
                className="ml-auto bg-emerald-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrent(1);
                }}
              >
                Next <ArrowRight className="h-4 w-4 ml-2" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
