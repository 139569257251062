import React, { useEffect, useState } from "react";
import availableTimes from "../../assets/json/availableTimeBlocks.json";
import { CheckCircle, Circle } from "lucide-react";
import { Slider } from "./Slider";

const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export default function SmartPricing({ setSmartData }) {
  const [timeSlots, setTimeSlots] = useState([]);
  const timeOptions = Object.values(availableTimes)[0];
  const [baseMonth, setBaseMonth] = useState(0);
  const [baseWeek, setBaseWeek] = useState(0);
  const [baseHour, setBaseHour] = useState(0);
  const [baseDay, setBaseDay] = useState(0);
  const [averagePricePerHour, setAveragePricePerHour] = useState(0);
  const [averagePricePerDay, setAveragePricePerDay] = useState(0);
  const [securityDeposit, setSecurityDeposit] = useState(0);
  const [mondayBaseHour, setMondayBaseHour] = useState(0);
  const [mondayBaseDay, setMondayBaseDay] = useState(0);
  const [mondayTimes, setMondayTimes] = useState([]);
  const [tuesdayBaseHour, setTuesdayBaseHour] = useState(0);
  const [tuesdayBaseDay, setTuesdayBaseDay] = useState(0);
  const [tuesdayTimes, setTuesdayTimes] = useState([]);
  const [wednesdayBaseHour, setWednesdayBaseHour] = useState(0);
  const [wednesdayBaseDay, setWednesdayBaseDay] = useState(0);
  const [wednesdayTimes, setWednesdayTimes] = useState([]);
  const [thursdayBaseHour, setThursdayBaseHour] = useState(0);
  const [thursdayBaseDay, setThursdayBaseDay] = useState(0);
  const [thursdayTimes, setThursdayTimes] = useState([]);
  const [fridayBaseHour, setFridayBaseHour] = useState(0);
  const [fridayBaseDay, setFridayBaseDay] = useState(0);
  const [fridayTimes, setFridayTimes] = useState([]);
  const [saturdayBaseHour, setSaturdayBaseHour] = useState(0);
  const [saturdayBaseDay, setSaturdayBaseDay] = useState(0);
  const [saturdayTimes, setSaturdayTimes] = useState([]);
  const [sundayBaseHour, setSundayBaseHour] = useState(0);
  const [sundayBaseDay, setSundayBaseDay] = useState(0);
  const [sundayTimes, setSundayTimes] = useState([]);
  const [selectedDays, setSelectedDays] = useState([]);

  const [sliderValue, setSliderValue] = useState(50);
  const [extra, setExtra] = useState(0);
  const [mortage, setMortage] = useState(0);
  const [bills, setBills] = useState(0);
  const [locationSqft, setLocationSqft] = useState(0);
  const [spaceSqft, setSpaceSqft] = useState(0);

  const handleAddDay = (day) => {
    setSelectedDays([...selectedDays, day]);
  };

  const handleRemoveDay = (day) => {
    setSelectedDays(selectedDays.filter((d) => d !== day));
    switch (day) {
      case "Monday":
        setMondayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Monday"));
        setMondayBaseDay(0);
        setMondayBaseHour(0);
        break;
      case "Tuesday":
        setTuesdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Tuesday"));
        setTuesdayBaseDay(0);
        setTuesdayBaseHour(0);
        break;
      case "Wednesday":
        setWednesdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Wednesday"));
        setWednesdayBaseDay(0);
        setWednesdayBaseHour(0);
        break;
      case "Thursday":
        setThursdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Thursday"));
        setThursdayBaseDay(0);
        setThursdayBaseHour(0);
        break;
      case "Friday":
        setFridayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Friday"));
        setFridayBaseDay(0);
        setFridayBaseHour(0);
        break;
      case "Saturday":
        setSaturdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Saturday"));
        setSaturdayBaseDay(0);
        setSaturdayBaseHour(0);
        break;
      case "Sunday":
        setSundayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Sunday"));
        setSundayBaseDay(0);
        setSundayBaseHour(0);
        break;
      default:
        break;
    }
  };

  const handleClearAll = () => {
    setSelectedDays([]);
    setTimeSlots([]);
    setMondayBaseDay(0);
    setMondayBaseHour(0);
    setMondayTimes([]);
    setTuesdayBaseDay(0);
    setTuesdayBaseHour(0);
    setTuesdayTimes([]);
    setWednesdayBaseDay(0);
    setWednesdayBaseHour(0);
    setWednesdayTimes([]);
    setThursdayBaseDay(0);
    setThursdayBaseHour(0);
    setThursdayTimes([]);
    setFridayBaseDay(0);
    setFridayBaseHour(0);
    setFridayTimes([]);
    setSaturdayBaseDay(0);
    setSaturdayBaseHour(0);
    setSaturdayTimes([]);
    setSundayBaseDay(0);
    setSundayBaseHour(0);
    setSundayTimes([]);
  };

  const handleAddTime = (day, time) => {
    if (timeSlots.some((slot) => slot.day === day && slot.time === time)) {
      setTimeSlots(
        timeSlots.filter((slot) => !(slot.day === day && slot.time === time))
      );
      switch (day) {
        case "Monday":
          setMondayTimes(mondayTimes.filter((t) => t !== time));
          break;
        case "Tuesday":
          setTuesdayTimes(tuesdayTimes.filter((t) => t !== time));
          break;
        case "Wednesday":
          setWednesdayTimes(wednesdayTimes.filter((t) => t !== time));
          break;
        case "Thursday":
          setThursdayTimes(thursdayTimes.filter((t) => t !== time));
          break;
        case "Friday":
          setFridayTimes(fridayTimes.filter((t) => t !== time));
          break;
        case "Saturday":
          setSaturdayTimes(saturdayTimes.filter((t) => t !== time));
          break;
        case "Sunday":
          setSundayTimes(sundayTimes.filter((t) => t !== time));
          break;
        default:
          break;
      }
      return;
    } else {
      switch (day) {
        case "Monday":
          setMondayTimes([...mondayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Monday", time }]);
          break;
        case "Tuesday":
          setTuesdayTimes([...tuesdayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Tuesday", time }]);
          break;
        case "Wednesday":
          setWednesdayTimes([...wednesdayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Wednesday", time }]);
          break;
        case "Thursday":
          setThursdayTimes([...thursdayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Thursday", time }]);
          break;
        case "Friday":
          setFridayTimes([...fridayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Friday", time }]);
          break;
        case "Saturday":
          setSaturdayTimes([...saturdayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Saturday", time }]);
          break;
        case "Sunday":
          setSundayTimes([...sundayTimes, time]);
          setTimeSlots([...timeSlots, { day: "Sunday", time }]);
          break;
        default:
          break;
      }
    }
  };

  //makes sure the base price is a number
  const handleBasePricePerHour = (price) => {
    if (isNaN(price)) {
      return;
    }
    // setAveragePricePerHour(price);
  };

  const handleSetMortgage = (price) => {
    if (isNaN(price)) {
      return;
    }
    setMortage(price);
  };

  const handleSetBills = (price) => {
    if (isNaN(price)) {
      return;
    }
    setBills(price);
  };

  const handleLocationSqft = (feet) => {
    if (isNaN(feet)) {
      return;
    }
    setLocationSqft(feet);
  };

  const handleSpaceSqft = (feet) => {
    if (isNaN(feet)) {
      return;
    }
    setSpaceSqft(feet);
  };

  const handleSetExtra = (price) => {
    if (isNaN(price)) {
      return;
    }
    setExtra(price);
  };

  const handleCheckAll = (day) => {
    let newTimeSlots = [];

    switch (day) {
      case "Monday":
        setMondayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Monday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      case "Tuesday":
        setTuesdayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Tuesday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      case "Wednesday":
        setWednesdayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Wednesday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      case "Thursday":
        setThursdayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Thursday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      case "Friday":
        setFridayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Friday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      case "Saturday":
        setSaturdayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Saturday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      case "Sunday":
        setSundayTimes(timeOptions.map((time) => time.dbvalue));
        newTimeSlots = timeOptions
          .map((time) => ({ day: "Sunday", time: time.dbvalue }))
          .filter(
            (slot) =>
              !timeSlots.some(
                (existingSlot) =>
                  existingSlot.day === slot.day &&
                  existingSlot.time === slot.time
              )
          );
        break;
      default:
        break;
    }

    // Concatenate the newTimeSlots with the existing timeSlots
    setTimeSlots((prevTimeSlots) => [...prevTimeSlots, ...newTimeSlots]);
  };

  const handleUncheckAll = (day) => {
    switch (day) {
      case "Monday":
        setMondayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Monday"));
        break;
      case "Tuesday":
        setTuesdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Tuesday"));
        break;
      case "Wednesday":
        setWednesdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Wednesday"));
        break;
      case "Thursday":
        setThursdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Thursday"));
        break;
      case "Friday":
        setFridayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Friday"));
        break;
      case "Saturday":
        setSaturdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Saturday"));
        break;
      case "Sunday":
        setSundayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Sunday"));
        break;
      default:
        break;
    }
  };

  const totalHoursSelected =
    mondayTimes.length +
    tuesdayTimes.length +
    wednesdayTimes.length +
    thursdayTimes.length +
    fridayTimes.length +
    saturdayTimes.length +
    sundayTimes.length;

    const totalDaysSelected = selectedDays.length * 4;

  // starting values
  const avgRateSqftNationWide = 37.35;
  const totalHoursInAMonth = 672;

  const calculateCostPerMonth = () => {
    const currentTotal = parseInt(mortage) + parseInt(bills);
    const nationalAvgFprSpace =
      (parseInt(locationSqft) * avgRateSqftNationWide) / 12;
    const avgMonthCost = (currentTotal + nationalAvgFprSpace) / 2;

    const percentageOfHours = (totalHoursSelected * 4) / totalHoursInAMonth;
    const sqftPercentage = parseInt(spaceSqft) / parseInt(locationSqft);
    const subtractedCost = avgMonthCost * percentageOfHours * sqftPercentage;

    const avgMortgageCostPerHour = currentTotal / 160;
    const nationalAvgCosePerHour = nationalAvgFprSpace / 160;

    const avgCostPerHour =
      avgMortgageCostPerHour > nationalAvgCosePerHour
        ? avgMortgageCostPerHour
        : (avgMortgageCostPerHour + nationalAvgCosePerHour) / 2;

    const maxCostPerMonthPerHour = avgCostPerHour * (totalHoursSelected * 4);

    const scaleHourValue = (sliderValue / 50) * maxCostPerMonthPerHour;
    const scaleMonthValue = (sliderValue / 50) * subtractedCost;

    const costForExtra = totalHoursSelected < 168 ? 25 : 50;

    const newBaseMonth = extra > 0 ? scaleMonthValue + parseInt(extra) * costForExtra : scaleMonthValue + 0 * costForExtra; 
    const newBaseHour = extra > 0 ? scaleHourValue + parseInt(extra) * costForExtra : scaleHourValue + 0 * costForExtra;
    const newBaseDay = ((((newBaseHour / totalHoursSelected) + (newBaseMonth / totalDaysSelected)) / 2) + avgCostPerHour) / 2  

    const hourVar = ((newBaseMonth / 28 + newBaseHour / 28) / 2)
    const newBaseWeek = hourVar * selectedDays.length;
    const avgCostPerDay = avgCostPerHour * 24;

    setBaseMonth(newBaseMonth);
    setBaseHour(newBaseHour);
    setBaseWeek(newBaseWeek);
    setBaseDay(avgCostPerDay);
    setAveragePricePerDay(avgCostPerDay);
    setAveragePricePerHour(newBaseDay);
  };

  useEffect(() => {
    calculateCostPerMonth();
  }, [
    mortage,
    bills,
    locationSqft,
    spaceSqft,
    totalHoursSelected,
    sliderValue,
    extra,
  ]);

  console.log(baseMonth, "baseMonth");

  const allPricingData = {
    type: 3,
    totalHoursSelected,
    impactScore: sliderValue,
    averagePricePerHour: parseInt(averagePricePerHour),
    averagePricePerDay: parseInt(averagePricePerDay),
    securityDeposit: 250,
    baseMonth: parseInt(baseMonth),
    baseWeek: parseInt(baseWeek),
    baseHour: parseInt(baseHour),
    baseDay: parseInt(baseDay),
    smartQuote: true,
    mondayBaseHour: selectedDays.includes("Monday")
      ? parseInt(averagePricePerHour)
      : 0,
    mondayBaseDay: selectedDays.includes("Monday")
      ? parseInt(averagePricePerDay)
      : 0,
    mondayTimes,
    tuesdayBaseHour: selectedDays.includes("Tuesday")
      ? parseInt(averagePricePerHour)
      : 0,
    tuesdayBaseDay: selectedDays.includes("Tuesday")
      ? parseInt(averagePricePerDay)
      : 0,
    tuesdayTimes,
    wednesdayBaseHour: selectedDays.includes("Wednesday")
      ? parseInt(averagePricePerHour)
      : 0,
    wednesdayBaseDay: selectedDays.includes("Wednesday")
      ? parseInt(averagePricePerDay)
      : 0,
    wednesdayTimes,
    thursdayBaseHour: selectedDays.includes("Thursday")
      ? parseInt(averagePricePerHour)
      : 0,
    thursdayBaseDay: selectedDays.includes("Thursday")
      ? parseInt(averagePricePerDay)
      : 0,
    thursdayTimes,
    fridayBaseHour: selectedDays.includes("Friday")
      ? parseInt(averagePricePerHour)
      : 0,
    fridayBaseDay: selectedDays.includes("Friday")
      ? parseInt(averagePricePerDay)
      : 0,
    fridayTimes,
    saturdayBaseHour: selectedDays.includes("Saturday")
      ? parseInt(averagePricePerHour)
      : 0,
    saturdayBaseDay: selectedDays.includes("Saturday")
      ? parseInt(averagePricePerDay)
      : 0,
    saturdayTimes,
    sundayBaseHour: selectedDays.includes("Sunday")
      ? parseInt(averagePricePerHour)
      : 0,
    sundayBaseDay: selectedDays.includes("Sunday")
      ? parseInt(averagePricePerDay)
      : 0,
    sundayTimes,
    selectedDays,
  };

  useEffect(() => {
    setSmartData(allPricingData);
  }, [
    securityDeposit,
    baseMonth,
    baseWeek,
    baseHour,
    baseDay,
    mondayBaseHour,
    mondayBaseDay,
    mondayTimes,
    tuesdayBaseHour,
    tuesdayBaseDay,
    tuesdayTimes,
    wednesdayBaseHour,
    wednesdayBaseDay,
    wednesdayTimes,
    thursdayBaseHour,
    thursdayBaseDay,
    thursdayTimes,
    fridayBaseHour,
    fridayBaseDay,
    fridayTimes,
    saturdayBaseHour,
    saturdayBaseDay,
    saturdayTimes,
    sundayBaseHour,
    sundayBaseDay,
    sundayTimes,
  ]);

  return (
    <div className="p-4">
      <div className="grid grid-cols-4 gap-1 mt-6">
        <div className="flex items-center col-span-4 font-medium leading-6 text-gray-900 mb-1">
          Days Available for this Space
        </div>
        {daysOfWeek?.map((day) => (
          <button
            key={day}
            className={`border p-1 rounded-md ${
              selectedDays.includes(day)
                ? "border-emerald-600 bg-emerald-50 text-sm py-2"
                : "bg-gray-100 text-gray-900 text-sm py-2"
            }`}
            onClick={() => {
              if (selectedDays.includes(day)) {
                handleRemoveDay(day);
              } else {
                handleAddDay(day);
              }
            }}
          >
            {day}
          </button>
        ))}
        <button
          className={`border p-2 rounded-md text-white text-sm ${
            selectedDays.length === 7 ? "bg-red-600 " : "bg-emerald-600 "
          }`}
          onClick={() => {
            if (selectedDays?.length === daysOfWeek?.length) {
              handleClearAll();
            } else {
              setSelectedDays([...daysOfWeek]);
            }
          }}
        >
          {selectedDays.length === daysOfWeek.length ? "Clear" : "Select All"}
        </button>
      </div>
      {daysOfWeek
        .filter((day) => selectedDays.includes(day))
        .sort((a, b) => daysOfWeek.indexOf(a) - daysOfWeek.indexOf(b))
        .map((day) => (
          <div
            key={day}
            className="grid grid-cols-6 gap-1 mt-4 border border-1 p-4 rounded-md bg-gray-100"
          >
            <div className="flex items-center col-span-6 text-sm font-medium leading-6 text-gray-800">
              {day} Available Time Blocks{" "}
              {timeSlots.filter((slot) => slot.day === day).length === 24 ? (
                <button
                  type="button"
                  className="bg-amber-50 text-amber-600 border border-amber-600 px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-amber-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 ml-auto  inline-flex items-center gap-x-1.5 rounded-md"
                  onClick={() => handleUncheckAll(day)}
                >
                  <CheckCircle className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Unselect All
                </button>
              ) : (
                <button
                  type="button"
                  className="bg-white text-amber-600 border border-amber-600 px-2.5 py-1.5 text-sm font-semibold shadow-sm hover:bg-amber-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-amber-600 ml-auto  inline-flex items-center gap-x-1.5 rounded-md"
                  onClick={() => handleCheckAll(day)}
                >
                  <Circle className="-ml-0.5 h-5 w-5" aria-hidden="true" />
                  Select All
                </button>
              )}
            </div>
            {timeOptions.map((time, index) => (
              <button
                key={index}
                className={`border p-1 rounded-md flex justify-center items-center gap-x-1 ${
                  timeSlots.some(
                    (slot) => slot.day === day && slot.time === time.dbvalue
                  )
                    ? "bg-amber-500 text-white text-sm py-2"
                    : "bg-white text-gray-900 text-sm py-2"
                }`}
                onClick={() => handleAddTime(day, time.dbvalue)}
              >
                {timeSlots.some(
                  (slot) => slot.day === day && slot.time === time.dbvalue
                ) && <CheckCircle size={16} />}
                {time.displayValue}
              </button>
            ))}
          </div>
        ))}

      <div className="border border-1 p-4 rounded-md bg-gray-100 mt-4">
        <h1 className="font-bold text-lg">Location Information</h1>
        <p className="text-sm text-gray-500">
          These questions pertain the the entire location, not just this space.
        </p>
        <div className="grid md:grid-cols-2 grid-cols-1 gap-4 mt-4">
          <div className="md:mt-0 mt-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600 bg-white">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              What is the cost of your current mortage/rent for the entire
              location per month?
            </label>
            <div className="relative mt-1 rounded-md ">
              <input
                type="text"
                name="hour"
                id="hour"
                className={`block w-full border-0 p-0  placeholder:text-gray-400 focus:ring-0 sm:text-3xl sm:leading-6 font-bold ${
                  mortage < 1 ? "text-stone-400" : "text-gray-900"
                }`}
                onClick={() => {
                  mortage < 1 && setMortage("");
                }}
                value={"$" + mortage}
                onChange={(e) => {
                  handleSetMortgage(e.target.value.replace("$", ""));
                }}
              />
            </div>
          </div>
          <div className="md:mt-0 mt-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600 bg-white">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              How much do you pay in utilities and bills for the entire location
              per month?
            </label>
            <div className="relative mt-1 rounded-md ">
              <input
                type="text"
                name="hour"
                id="hour"
                onClick={() => {
                  bills < 1 && setBills("");
                }}
                className={`block w-full border-0 p-0  placeholder:text-gray-400 focus:ring-0 sm:text-3xl sm:leading-6 font-bold ${
                  bills < 1 ? "text-stone-400" : "text-gray-900"
                }`}
                value={"$" + bills}
                onChange={(e) => {
                  handleSetBills(e.target.value.replace("$", ""));
                }}
              />
            </div>
          </div>
          <div className="md:mt-0 mt-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600 bg-white">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              What is the square footage of the entire location?
            </label>
            <div className="relative mt-1 rounded-md ">
              <input
                type="text"
                name="hour"
                id="hour"
                onClick={() => {
                  locationSqft < 1 && setLocationSqft("");
                }}
                className={`block w-full border-0 p-0  placeholder:text-gray-400 focus:ring-0 sm:text-3xl sm:leading-6 font-bold ${
                  locationSqft < 1 ? "text-stone-400" : "text-gray-900"
                }`}
                value={locationSqft}
                onChange={(e) => {
                  handleLocationSqft(e.target.value);
                }}
              />
              <p className="text-xs text-gray-500">
                *This is the total square footage of the entire location.
              </p>
            </div>
          </div>
          <div className="md:mt-0 mt-2 rounded-md px-3 pb-1.5 pt-2.5 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600 bg-white">
            <label
              htmlFor="name"
              className="block text-xs font-medium text-gray-900"
            >
              What is the square footage of this space?
            </label>
            <div className="relative mt-1 rounded-md">
              <input
                type="text"
                name="hour"
                id="hour"
                onClick={() => {
                  spaceSqft < 1 && setSpaceSqft("");
                }}
                className={`block w-full border-0 p-0  placeholder:text-gray-400 focus:ring-0 sm:text-3xl sm:leading-6 font-bold ${
                  spaceSqft < 1 ? "text-stone-400" : "text-gray-900"
                }`}
                value={spaceSqft}
                onChange={(e) => {
                  handleSpaceSqft(e.target.value);
                }}
              />
              <p className="text-xs text-gray-500">
                *The total square footage of this space/ out.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="border border-1 p-4 rounded-md bg-gray-100 mt-4">
        <h1 className="font-bold text-lg">Price Adjustments</h1>
        <div className="mt-6 border grid grid-cols-5 border-1 p-4 rounded-md bg-white items-center">
          <div className="flex items-center col-span-5 text-sm font-medium leading-6 text-zinc-900">
            Using the scale below what is most important to you?
          </div>
          <div className="mt-4 text-sm text-left col-span-2">
            Community Impact
          </div>
          <div className="text-center mt-4"></div>
          <div className="mt-4 text-sm text-right col-span-2">
            Increased Revenue
          </div>
          <div className="mt-4 col-span-5">
            <Slider
              defaultValue={[sliderValue]}
              onValueChange={(e) => {
                setSliderValue(e);
              }}
              max={100}
              className="w-full"
            />
          </div>
        </div>

        <div className="rounded-md mt-6 p-4 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-emerald-600 bg-white">
          <label
            htmlFor="name"
            className="block text-xs font-medium text-gray-900"
          >
            How many assets valued over $500 come included with this space?
          </label>
          <div className="relative mt-1 rounded-md ">
            <input
              type="text"
              name="hour"
              id="hour"
              className={`block w-full border-0 p-0  placeholder:text-gray-400 focus:ring-0 sm:text-3xl sm:leading-6 font-bold ${
                extra < 1 ? "text-stone-400" : "text-gray-900"
              }`}
              onClick={() => {
                extra < 1 && setExtra("");
              }}
              value={extra}
              onChange={(e) => {
                handleSetExtra(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
