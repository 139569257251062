import React, { useEffect } from "react";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate, useParams } from "react-router-dom";
import { getPayIntent, subscribeCustomer } from "../../functions/stripe";
import { updateUser } from "../../functions/crud";

const plans = {
  starter: "price_1PmRRVDL6Hu3W7krOm9mTYET",
  studio: "price_1PmRS1DL6Hu3W7kr1vwteDzS",
  enterprise: "price_1PmRSYDL6Hu3W7kr0jXb7hZS",
};


export default function ChargeSubscription() {
  const { id, uid } = useParams();

  const navigate = useNavigate();

  const handleSubscribeCustomer = async (payIntnetID) => {
    const res = await getPayIntent(payIntnetID);
    const customer = res.paymentIntent.customer;
    const payID = res.paymentIntent.payment_method;
    const planPrice = plans[id];
    const subscribe = await subscribeCustomer(customer, planPrice, payID);
    const datatToUpdate = {
      plan: id,
      planStartDate: new Date(),
      trialEnds: new Date().setDate(new Date().getDate() + 7),
      stripeSubscriptionID: subscribe.subscription.id,
      latestInvoice: subscribe.subscription.latest_invoice,
      stripePriceID: id,
      defaultPaymentMethod: payID,
      stripeCustomerID: customer,
    };
    const updatedUser = await updateUser(uid, datatToUpdate);
    if (updatedUser.status === "success") {
      navigate("/");
    } else {
      console.log("Failed to update user", updatedUser);
      navigate("/");
    }
  };

  useEffect(() => {
    const payIntent = new URLSearchParams(window.location.search).get(
      "payment_intent"
    );
    if (!payIntent) {
      return;
    } else {
      handleSubscribeCustomer(payIntent);
    }
  }, []);

  return (
    <div>
      <h1>Processing Payment...</h1>
    </div>
  );
}
