import React from "react";

export default function PrivacyPolicy() {
  return (
    <div className="max-w-7xl mx-auto sm:p-12 p-4">
        <h1 className="font-bold text-4xl">Compete Share</h1>
      <h1 className="font-bold text-2xl">Privacy Policy</h1>
      <p className="text-gray-600">
        Last Updated: 08/09/2024
        <br />
        <br />
        At Compete Share, we value your privacy and are committed to protecting
        the personal information you provide when filling out our contact form.
        This Privacy Policy explains how we collect, use, and protect your
        information.
      </p>
      <h2 className="font-bold text-xl">1. Information We Collect</h2>
      <p className="text-gray-600">
        When you fill out our contact form, we may collect the following types
        of information:
      </p>
      <ul className="list-disc pl-4">
        <li className="text-gray-600">
          Personal Information: This includes your name, email address, phone
          number, and any other information you choose to provide.
        </li>
        <li className="text-gray-600">
          Request Information: Details related to your inquiry about space
          sharing, including preferences, requirements, or any additional
          comments.
        </li>
      </ul>
      <h2 className="font-bold text-xl">2. How We Use Your Information</h2>
      <p className="text-gray-600">We use the information collected to:</p>
      <ul className="list-disc pl-4">
        <li className="text-gray-600">
          Respond to your inquiries and provide you with information about
          space-sharing opportunities.
        </li>
        <li className="text-gray-600">
          Communicate with you regarding your requests.
        </li>
        <li className="text-gray-600">
          Improve our services and enhance user experience.
        </li>
      </ul>
      <h2 className="font-bold text-xl">3. How We Protect Your Information</h2>
      <p className="text-gray-600">
        We take the security of your information seriously and implement
        industry-standard measures to protect it. This includes:
      </p>
      <ul className="list-disc pl-4">
        <li className="text-gray-600">
          Data Encryption: Information submitted through our contact form is
          encrypted to protect against unauthorized access.
        </li>
        <li className="text-gray-600">
          Access Controls: Only authorized personnel have access to your
          information, and they are bound by confidentiality obligations.
        </li>
      </ul>
      <h2 className="font-bold text-xl">4. Sharing Your Information</h2>
      <p className="text-gray-600">
        We do not sell, trade, or otherwise transfer your personal information
        to outside parties without your consent. We may share your information
        with trusted partners only for the purposes of fulfilling your request
        for space-sharing information.
      </p>
      <h2 className="font-bold text-xl">5. Your Rights</h2>
      <p className="text-gray-600">You have the right to:</p>
      <ul className="list-disc pl-4">
        <li className="text-gray-600">
          Access the personal information we hold about you.
        </li>
        <li className="text-gray-600">
          Request corrections to any inaccurate information.
        </li>
        <li className="text-gray-600">
          Request the deletion of your personal information, subject to certain
          conditions.
        </li>
      </ul>
      <p className="text-gray-600">
        To exercise these rights, please contact us at [contact information].
      </p>
      <h2 className="font-bold text-xl">6. Changes to This Privacy Policy</h2>
      <p className="text-gray-600">
        We may update this Privacy Policy from time to time. We will notify you
        of any changes by posting the new Privacy Policy on this page.
      </p>
      <h2 className="font-bold text-xl">7. Contact Us</h2>
      <p className="text-gray-600">
        If you have any questions or concerns about this Privacy Policy, please
        contact us at:
      </p>
      <ul className="list-disc pl-4">
        <li className="text-gray-600">Email: info@competeservices.com</li>
        <li className="text-gray-600">Phone: (719) 271-4069</li>
      </ul>
      <p className="text-gray-600">
        Thank you for trusting Compete Share with your information.
      </p>
    </div>
  );
}
