import { Link, useNavigate } from "react-router-dom";
import mago from "../assets/images/magoprofile.jpg";
import LogoSVG from "../assets/logos/LogoSVG";
import { useState } from "react";
import { createNewLead, generateRandomID } from "../functions/crud";

export default function ContactForm({
  secColor = "emerald",
  hexColor = "#10B981",
  link,
  userID
}) {
  const [form, setForm] = useState({
    email: "",
    name: "",
    phone: "",
    message: "",
  });

  const navigate = useNavigate();

  const handleSendEmail = (e) => {
    e.preventDefault();
    if (!form.email) {
      alert("Please enter your email address");
      return;
    } else if (!form.name) {
      alert("Please enter your full name");
      return;
    } else {
      const id = generateRandomID();       
      const leadInfo = {
        name: form.name,
        email: form.email,
        phone: form.phone,
        message: form.message,
        viewed: false,
        status: 0,
        id: id,
        profilePic: "",
        chatID: 0,
        hasAccount: false,
        replied: false,
        isRenter: false,
        type: 0,
        leadParams: link,
        sender: "user",
        space: "smart",
        startDate: "",
        createdAt: new Date().toISOString(),
        userID: userID,
      };
      console.log(leadInfo);
      createNewLead(leadInfo);
      navigate(`/thank-you/${id}`);
    }
  };







  return (
    <div className="relative isolate px-6 py-24 sm:py-32 lg:px-8">
      <svg
        aria-hidden="true"
        className="absolute inset-0 -z-10 h-full w-full stroke-gray-200 [mask-image:radial-gradient(100%_100%_at_top_right,white,transparent)]"
      >
        <defs>
          <pattern
            x="50%"
            y={-64}
            id="83fd4e5a-9d52-42fc-97b6-718e5d7ee527"
            width={200}
            height={200}
            patternUnits="userSpaceOnUse"
          >
            <path d="M100 200V.5M.5 .5H200" fill="none" />
          </pattern>
        </defs>
        <svg x="50%" y={-64} className="overflow-visible fill-gray-50">
          <path
            d="M-100.5 0h201v201h-201Z M699.5 0h201v201h-201Z M499.5 400h201v201h-201Z M299.5 800h201v201h-201Z"
            strokeWidth={0}
          />
        </svg>
        <rect
          fill="url(#83fd4e5a-9d52-42fc-97b6-718e5d7ee527)"
          width="100%"
          height="100%"
          strokeWidth={0}
        />
      </svg>
      <div className="mx-auto max-w-4xl">
        <h2 className="text-4xl font-bold tracking-tight text-gray-900">
          Let’s talk about your Space Needs
        </h2>
        <p className="mt-2 text-lg leading-8 text-gray-600">
          We are here to help you find the perfect space for your business,
          event, or project.
        </p>
        <div className="mt-12 flex flex-col gap-16 sm:gap-y-20 lg:flex-row">
          <form
            action="#"
            method="POST"
            className="lg:flex-auto max-w-xl mx-auto "
          >
            <div className=" space-y-2">
              <div>
                <label
                  htmlFor="first-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Email
                </label>
                <div className="">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                    placeholder="Enter your email address"
                    onChange={(e) => setForm({ ...form, email: e.target.value })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="last-name"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Full name
                </label>
                <div className="">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                    placeholder="Enter your full name"
                    onChange={(e) => setForm({ ...form, name: e.target.value })}
                  />
                </div>
              </div>
              <div>
                <label
                  htmlFor="budget"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Phine Number
                </label>
                <div className="">
                  <input
                    type="tel"
                    name="phone"
                    id="phone"
                    className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                    placeholder="Enter your phone number"
                    onChange={(e) => setForm({ ...form, phone: e.target.value })}
                  />
                </div>
              </div>
              <div className="sm:col-span-2">
                <label
                  htmlFor="message"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Message
                </label>
                <div>
                  <textarea
                    id="message"
                    name="message"
                    rows={4}
                    className={`block w-full rounded-md border-0 px-3.5 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-${secColor}-600 sm:text-sm sm:leading-6`}
                    placeholder="Send us a message"
                    onChange={(e) => setForm({ ...form, message: e.target.value })}
                  />
                </div>
              </div>
            </div>
            <div className="mt-10">
              <button
                type="submit"
                className={`block w-full rounded-md bg-${secColor}-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-${secColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${secColor}-600`}
                onClick={handleSendEmail}
              >
                Let’s talk
              </button>
            </div>
            <p className="mt-4 text-sm leading-6 text-gray-500">
              By submitting this form, I agree to the{" "}
              <Link to="/info/privacy-policy" className={`font-semibold text-${secColor}-600`} target="_blank">
                privacy&nbsp;policy
              </Link>
              .
            </p>
          </form>
          <div className="lg:mt-6 lg:w-80 lg:flex-none">
            <LogoSVG color={hexColor} />

            <figure className="mt-10">
              <blockquote className="text-lg font-semibold leading-8 text-gray-900">
                <p>
                  “We designed Compete Share to make it easy, fast, and
                  affordable to get the space you need for your business, event,
                  or project. We’re changing the way people connect.”
                </p>
              </blockquote>
              <figcaption className="mt-10 flex gap-x-6">
                <img
                  alt=""
                  src={mago}
                  className="h-12 w-12 flex-none rounded-full bg-gray-50 object-cover object-center"
                />
                <div>
                  <div className="text-base font-semibold text-gray-900">
                    Mago Lauritzen
                  </div>
                  <div className="text-sm leading-6 text-gray-600">
                    CEO of Compete Share
                  </div>
                </div>
              </figcaption>
            </figure>
          </div>
        </div>
      </div>
    </div>
  );
}
