import React, { useState } from "react";
import { states } from "../assets/js/states";
import { Link, useNavigate } from "react-router-dom";
import { renterFormSubmission } from "../functions/crud";

export default function NeedASpaceForm() {
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    spaceType: "",
    size: "",
    duration: "",
    city: "",
    state: "",
    budget: "",
    purpose: "",
    renterID: "",
    hasAccount: false,
    specialRequirements: "",
  });
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    console.log("Form Data Submitted:", formData);
    const res = await renterFormSubmission(formData);
    setTimeout(() => {
      setLoading(false);
      if (res.status === "success") {
        alert("Form Submitted Successfully");
        //add email param to navigate
        navigate(`/thank-you/${res.id}/0`);
        setFormData({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          spaceType: "",
          size: "",
          duration: "",
          city: "",
          state: "",
          budget: "",
          purpose: "",
          specialRequirements: "",
        });
      } else {
        alert("Form Submission Failed");
      }
    }, 2000);

    // You can handle the form submission here
  };

  return (
    <>
      {" "}
      <main className="mt-16 sm:mt-24">
        <div className="mx-auto max-w-7xl">
          <div className="lg:grid lg:grid-cols-12 lg:gap-8">
            <div className="px-6 sm:text-center md:mx-auto md:max-w-2xl lg:col-span-6 lg:flex lg:items-center lg:text-left">
              <div>
                <div className="hidden sm:mb-4 sm:flex sm:justify-center lg:justify-start">
                  <Link
                    to={"/create-account/host/1"}
                    className="flex items-center rounded-full bg-gray-900 p-1 pr-2 text-white hover:text-gray-200 sm:text-base lg:text-sm xl:text-base"
                  >
                    <span className="rounded-full bg-emerald-600 px-3 py-0.5 text-sm font-semibold leading-5 text-white">
                      Have a space?
                    </span>
                    <span className="ml-4 text-sm">
                      Visit our host page to sign up
                    </span>
                  </Link>
                </div>
                <h1 className="text-4xl font-bold tracking-tight text-white sm:text-5xl md:text-6xl">
                  Get connected with the{" "}
                  <span className="text-yellow-400">perfect space</span> for
                  your needs
                </h1>
                <p className="mt-3 text-base text-gray-300 sm:mt-5 sm:text-xl lg:text-lg xl:text-xl">
                  Compete Share is a platform that connects people with spaces
                  to rent with those who need a space to rent. We are here to
                  help you find the perfect space for your business, event, or
                  project.
                </p>      
              </div>
            </div>
            <div className="mt-16 sm:mt-24 lg:col-span-6 lg:mt-0 px-3 sm:px-0">
              <div className="bg-gray-50 sm:mx-auto sm:w-full sm:max-w-md sm:overflow-hidden sm:rounded-lg">
                <div className="px-6 py-8 sm:px-10">
                  <div>
                    <h1 className="text-3xl text-center font-bold">
                      Need A Space?
                    </h1>
                    <p className="text-center text-sm text-gray-600">
                      Fill out the form below and we will help match you with
                      the perfect space for your needs.
                    </p>
                  </div>
                  <form
                    className=" grid grid-cols-4 gap-4 mt-6"
                    onSubmit={handleSubmit}
                  >
                    <div className="col-span-2">
                      <label className="sr-only">First Name:</label>
                      <input
                        type="text"
                        name="firstName"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.firstName}
                        onChange={handleChange}
                        placeholder="First Name"
                        required
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="sr-only">Last Name:</label>
                      <input
                        type="text"
                        name="lastName"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.lastName}
                        onChange={handleChange}
                        placeholder="Last Name"
                        required
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="sr-only">Email:</label>
                      <input
                        type="email"
                        name="email"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Email"
                        required
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="sr-only">Phone:</label>
                      <input
                        type="tel"
                        name="phone"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.phone}
                        onChange={handleChange}
                        placeholder="Phone Number"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="sr-only">Type of Space Needed:</label>
                      <input
                        type="text"
                        name="spaceType"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.spaceType}
                        onChange={handleChange}
                        placeholder="Type of Space Needed"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="sr-only">Size Requirements:</label>
                      <input
                        type="text"
                        name="size"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.size}
                        onChange={handleChange}
                        placeholder="Size Requirements"
                      />
                    </div>
                    <div className="col-span-3">
                      <label className="sr-only">Preferred City:</label>
                      <input
                        type="text"
                        name="city"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        required
                        value={formData.city}
                        onChange={handleChange}
                        placeholder="Preferred City"
                      />
                    </div>
                    <div>
                      <label className="sr-only">State:</label>
                      <select
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.state}
                        onChange={handleChange}
                        name="state"
                        required
                      >
                        {states?.map((state) => (
                          <option
                            key={state}
                            value={state?.abbreviation}
                            className=" text-zinc-950"
                          >
                            {state.abbreviation}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-span-2">
                      <label className="sr-only">Duration of Rent:</label>
                      <input
                        type="text"
                        name="duration"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.duration}
                        onChange={handleChange}
                        required
                        placeholder="Duration of Rent"
                      />
                    </div>
                    <div className="col-span-2">
                      <label className="sr-only">Budget Range:</label>
                      <input
                        type="text"
                        name="budget"
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.budget}
                        onChange={handleChange}
                        required
                        placeholder="Budget Range"
                      />
                    </div>
                    <div className="col-span-full">
                      <label className="sr-only">Purpose of Rental:</label>
                      <textarea
                        name="purpose"
                        rows={3}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.purpose}
                        onChange={handleChange}
                        required
                        placeholder="Purpose of Rental"
                      ></textarea>
                    </div>
                    <div className="col-span-full">
                      <label className="sr-only">Special Requirements:</label>
                      <textarea
                        name="specialRequirements"
                        rows={2}
                        className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                        value={formData.specialRequirements}
                        onChange={handleChange}
                        placeholder="Any Special Requirements or Equiptment Needed"
                      ></textarea>
                    </div>
                    <div className="col-span-full">
                      <button
                        type="submit"
                        className="flex w-full justify-center rounded-md border border-transparent bg-emerald-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
                        disabled={loading}
                      >
                        {loading ? "Submitting..." : "Submit Request"}
                      </button>
                    </div>
                  </form>
                </div>
                <div className="border-t-2 border-gray-200 bg-gray-50 px-6 py-6 sm:px-10">
                  <p className="text-xs leading-5 text-gray-500">
                    By submitting this form, I agree to the{" "}
                    <Link
                      to="/info/privacy-policy"
                      className="font-medium text-gray-900 hover:underline"
                    >
                      Privacy Policy.
                    </Link>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}
