import rentersImg from "../../assets/images/renters.svg";

export default function Analytics() {
  return (
    <div>
      <div className="sm:flex-auto">
        <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
          Analytics
        </h1>
      </div>

      <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center justify-center py-24">
            <div className="text-center">
              <img
                src="https://tailwindui.com/img/ecommerce/icons/icon-delivery-light.svg"
                alt="Renters"
                className="w-1/2 h-1/2 mx-auto"
              />
              <h3 className="text-lg font-bold text-zinc-800">
                Coming Soon
              </h3>
              <p className="text-sm font-normal text-gray-500 ml-2">
                Analytics will be available soon.
              </p>
           
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
