import { Listbox, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import mago from "../assets/images/mago.png";
import { Check, ChevronsUpDown } from "lucide-react";
import spaces from "../assets/js/spacetypes";
import { Link } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ListingSignUp() {
  const [selected, setSelected] = useState(spaces[1]);
  return (
    <div className="mx-auto max-w-7xl px-6 py-32 lg:px-8">
      <div className="mx-auto max-w-2xl lg:mx-0 lg:grid lg:max-w-none lg:grid-cols-2 lg:gap-x-16 lg:gap-y-6 xl:grid-cols-1 xl:grid-rows-1 xl:gap-x-8">
        <h1 className="max-w-2xl text-4xl font-bold tracking-tight text-white sm:text-6xl lg:col-span-2 xl:col-auto">
          We’re changing the way people connect.
        </h1>
        <div className="mt-6 max-w-xl lg:mt-0 xl:col-end-1 xl:row-start-1">
          <p className="text-lg leading-8 text-gray-50">
            Join our<span className="text-emerald-400"> space sharing</span>{" "}
            platform and connect with people who are looking for a place to
            connect, netowrk, and work. We are a community of people who are
            looking to share their space with others.
          </p>
          <div className="mt-10">   
            <Link
              type="button"
              to={`/create-account/host/${selected.id}`}
              className="mt-4 w-full h-14 bg-emerald-600 hover:bg-emerald-800 text-white text-lg font-semibold py-2 px-4 rounded-md flex items-center justify-center"
            >
              Share your Space
            </Link>
            <Link
              type="button"
              to={`/need-space`}
              className="w-full text-white font-semibold mt-3 flex items-center justify-center hover:text-yellow-500"
            >
              Need a Space?
            </Link>
          </div>
          <div className="mt-6 text-center">
            <p className="text-base text-gray-50">
              Already have an account?{" "}
              <Link to="/login" className="font-semibold text-yellow-400">
                Log in
              </Link>
            </p>
          </div>
        </div>
        <img
          src={mago}
          alt=""
          className="mt-10 aspect-[4/5] w-full max-w-lg rounded-2xl object-cover sm:mt-16 lg:mt-0 lg:max-w-none xl:row-span-2 xl:row-end-2"
        />
      </div>
    </div>
  );
}
