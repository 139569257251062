import React, { useEffect, useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm from "./Checkout";
import { useNavigate, useParams } from "react-router-dom";
import { updateUser } from "../../functions/crud";
import { UserAuth } from "../../context/AuthContext";

const api = process.env.REACT_APP_SERVER_URL;
const key = process.env.REACT_APP_STRIPE_PUBLIC_KEY;

const stripePromise = loadStripe("pk_test_AzN0dNPHyuaa4iEhC1ZXSydP");

export default function PlanCheckout() {
  const { user } = UserAuth();
  const [clientSecret, setClientSecret] = useState("");
  const [error, setError] = useState("");
  const [payID, setPayID] = useState("");

  const { plan } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const createPaymentIntent = async () => {
      try {
        const response = await fetch(api + "stripe/createPaymentIntent", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            customer: user?.stripeCustomerID || "",
            receipt_email: user?.email,
          }),
        });

        if (!response.ok) {
          const res = await updateUser(user?.id, {
            plan: "temp",
            planStartDate: new Date(),
          });
          console.log("Failed to create payment intent", res);
          navigate("/");
          throw new Error("Failed to create payment intent");
        }
        const data = await response.json();
        setClientSecret(data.clientSecret);
        setPayID(data.payID);
      } catch (error) {
        const res = await updateUser(user?.id, {
          plan: "temp",
          planStartDate: new Date(),
        });
        console.log("Failed to create payment intent", res);
        console.error("Error fetching payment intent:", error);
        navigate("/");
        setError("Something went wrong. Please try again later.");
      }
    };

    createPaymentIntent();
  }, []);

  const appearance = {
    theme: "flat",
    variables: {
      colorPrimary: "#10b981",

      // See all possible variables below
    },
  };
  const options = {
    clientSecret,
    appearance,
  };

  console.log(payID);

  return (
    <div>
      {clientSecret && (
        <Elements options={options} stripe={stripePromise} disableLink={true}>
          <CheckoutForm planType={plan} payID={payID} />
        </Elements>
      )}
    </div>
  );
}
