import React, { useState } from "react";

const LogoSVG = ({ color = "#56BF92" }) => {
  return (
    <div className="h-12 w-auto">
      <svg
        version="1.1"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="0px"
        y="0px"
        width="auto"
        height="48px"
        viewBox="0 0 1703.8 249.4"
        xmlSpace="preserve"
      >
        <style type="text/css">
          {`.st0{fill:#242122;}`}
          {`.st1{fill:${color};}`}
        </style>
        <path
          className="st0"
          d="M115,0c20,11.6,39.9,23.2,59.9,34.7c13.4,7.7,26.7,15.4,40.9,23.6c-10.5,6.2-20.2,12.1-30.2,17.6
	c-13.9,7.8-9.9,8.8-24.9,0.2c-14.3-8.2-28.6-16.3-42.7-24.7c-4.1-2.4-7.4-2.4-11.5,0c-19,11.2-38.2,22.3-57.4,33.2
	c-4,2.3-5.7,5.2-5.6,9.6c0.6,22.1,0.5,44.3,0,66.4c-0.1,4.6,1.8,7.3,5.7,9.5c15.6,8.8,31.1,18,46.7,26.8c4.6,2.6,6.5,5.8,6.4,11.2
	c-0.3,12-0.1,24-0.1,36c0,5.9-0.1,6-5.1,3.1c-23.6-13.6-47.2-27.2-70.9-40.8c-6.6-3.8-13.2-7.8-19.9-11.4c-4.1-2.1-5.5-5-5.5-9.6
	c0.1-35.8,0.1-71.6,0-107.4c0-1.7,0.9-3.6-0.8-5.1c0-2.7,0-5.3,0-8c2.7-3,5.9-5.1,9.4-7.1C42.9,38.6,76.5,19.3,110,0
	C111.7,0,113.3,0,115,0z"
        />
        <path
          className="st1"
          d="M1703.8,137.9c-18.3,0.1-36.6,0.1-54.9,0.2c-1.5,0-3.8-1-4.5,0.8c-0.7,1.7,0.7,3.5,1.9,4.9
	c4.4,5,10.3,6.5,16.5,7.1c10.8,1,21.6,0.4,32.3-1.8c4.2-0.9,5.2,0.1,4.3,4.3c-1.7,8.1-2.9,16.3-4.3,24.5c-0.3,1.9-0.7,3.3-3,4
	c-23.8,6.6-47.4,6.8-70.2-3.5c-17.7-8-26.3-22.7-28-41.7c-0.6-7.5-0.4-15,1.3-22.3c5.7-26,29.2-42.9,57.5-41.2
	c7.1,0.4,14.3,1.1,21.2,3.2c17.5,5.4,25.6,18.4,28.7,35.5c0.2,1.4-0.5,3.1,1.2,4C1703.8,123.2,1703.8,130.6,1703.8,137.9z
	 M1652.7,116.1c2.5,0,5-0.2,7.5,0c2.8,0.2,3.8-0.7,3.7-3.6c-0.3-5.7-3.2-9.2-9.1-9.9c-6.4-0.8-11.2,2.3-12.8,8.3
	c-0.9,3.3-1,5.9,3.8,5.2C1648.1,115.8,1650.4,116.1,1652.7,116.1z"
        />
        <path
          className="st0"
          d="M532.5,89.8c16-23.2,56-21.6,63,1.2c4.9-8.5,11.6-14.2,21-16.4c6-1.4,12.2-2.1,18.3-1.2
	c15.5,2.4,24.9,13.3,26.1,30.6c1.8,25.6,0.5,51.3,1,76.9c0.1,3-1.4,3.1-3.7,3.1c-14.2-0.1-28.3-0.1-42.5,0.1c-3.1,0-3.8-0.8-3.8-3.9
	c0.1-19.2,0.1-38.3,0-57.5c0-2.5-0.2-5-0.6-7.4c-0.4-3.3-2.6-4.9-5.8-5.1c-3.4-0.2-5.7,1.3-6.7,4.7c-1,3.1-1,6.2-1,9.4
	c0,18.3-0.1,36.6,0.1,55c0.1,4.1-1.2,4.9-5,4.8c-13.5-0.2-27-0.3-40.5,0c-3.9,0.1-4.6-1.1-4.5-4.7c0.2-18.8,0.1-37.6,0.1-56.5
	c0-2.3-0.1-4.7-0.4-7c-0.4-3.4-2.2-5.7-5.8-5.7c-3.4,0-6.1,1.3-7.1,5c-0.8,2.8-0.8,5.6-0.8,8.4c0,18.7-0.1,37.3,0.1,56
	c0,3.4-0.7,4.5-4.3,4.5c-14-0.2-28-0.2-42,0c-2.7,0-3.7-0.6-3.7-3.5c0.1-32.8,0.1-65.6,0-98.4c0-2.7,1-3.5,3.3-3.7
	c13.9-1.8,27.7-3.5,41.6-5.4c2.8-0.4,3.9,0.2,3.8,3.2C532.3,80.2,532.5,84.4,532.5,89.8z"
        />
        <path
          className="st1"
          d="M122.1,249.4c0-14.9,0.1-29.6,0-44.2c0-4.3,2.7-6.2,5.8-7.9c15.3-8.8,30.5-17.7,46-26.2c5.4-2.9,8-6.5,7.1-12.7
	c-0.5-3.1-0.1-6.3-0.1-9.6c-2.2-0.3-3.7,1.1-5.3,2.1c-19.2,11-38.4,22-57.5,33.2c-4.1,2.4-7.5,2.4-11.6-0.1
	c-13.3-7.9-26.7-15.6-40.2-23.2c-4.3-2.4-6-5.3-5.9-10.4c0.3-15,0.2-30,0.1-45c-0.1-5.4,1.7-9,6.6-11.7
	c13.5-7.5,26.8-15.4,40.2-23.2c2.6-1.5,5.3-2.6,8-1.1c13.6,7.6,27.1,15.4,41.4,23.6c-20.5,12-40.2,23.6-60.7,35.7
	c5.7,3.6,10.8,7,16.1,9.9c2.9,1.6,5.4-0.7,7.8-2c28.5-16.4,57-32.9,85.6-49.4c4.8-2.7,9.6-5.4,14.3-8.2c3.1-1.9,4.3-1.6,4.3,2.3
	c-0.1,18.5,0.1,37,0.1,55.4c0,15.8-0.2,31.6-0.1,47.5c0,5.2-1.8,8.5-6.5,11.2c-30.3,17.3-60.5,34.9-90.8,52.4
	C125.4,248.4,124.3,249.6,122.1,249.4z"
        />
        <path
          className="st0"
          d="M731.1,179.2c0,15.9-0.2,31,0.1,46.1c0.1,4.2-1.1,5.2-5.2,5.2c-13.3-0.3-26.6-0.3-40,0
	c-3.8,0.1-4.7-1.1-4.7-4.8c0.1-47.6,0.1-95.2,0-142.8c0-2.7,0.3-4.3,3.5-4.7c13.5-1.6,27.1-3.4,40.6-5.4c2.9-0.4,3.7,0.3,3.6,3.2
	c-0.2,4.8-0.1,9.5-0.1,15.7c7.1-11.5,16.6-16.8,28.7-18.2c17.4-2,32.1,5.3,39,21.3c9.4,21.9,9.6,44.5-0.6,66.2
	c-10.3,21.8-38.5,30.7-60.9,20.1C734,180.7,733,180.2,731.1,179.2z M730.9,132.2c0.2,3.8-0.3,7.9,0.6,12.1c1.4,6.1,4.4,9.3,9.3,9.4
	c5,0.1,8.7-3,10.1-9.2c2-8.9,1.9-17.8,0.1-26.7c-0.9-4.7-2.8-8.6-8.5-8.8c-5.4-0.2-8.9,2.6-10.7,8.5
	C730.5,122.2,731,127,730.9,132.2z"
        />
        <path
          className="st1"
          d="M1298.2,88.1c11.1-14,28-18.9,44.5-12.9c13.8,5,19.1,16.1,19.7,29.6c1.3,25.5,0.3,50.9,0.6,76.4
	c0,2.4-1.1,2.7-3.1,2.7c-14.5,0-29-0.1-43.5,0c-3.4,0-3.2-1.8-3.2-4.1c0-19,0-38,0-57c0-2.2-0.1-4.3-0.3-6.5c-0.3-3.6-2.3-5.9-6-6.3
	c-3.8-0.3-6.9,1-8.3,4.9c-1.3,3.4-1.2,6.9-1.2,10.3c0,17.8-0.2,35.6,0.1,53.5c0.1,4.3-1.3,5.2-5.3,5.1c-13.5-0.3-27-0.2-40.5,0
	c-3.3,0-4.1-1-4.1-4.2c0.1-46.3,0.1-92.6,0-138.9c0-3.7,1.2-4.7,4.7-5c13.4-1.4,26.8-3,40.1-5.1c4.8-0.8,5.1,1,5,4.8
	c-0.2,15.8-0.1,31.6,0,47.5C1297.4,84.5,1296.9,86.2,1298.2,88.1z"
        />
        <path
          className="st0"
          d="M409.4,186.3c-19.9,0.3-38.4-3.4-51.6-19.8c-15.1-18.7-14.6-39.8-6.7-60.9c7.6-20.2,24.5-28.7,44.9-31.6
	c10-1.4,20-1.5,29.8,0.4c27.9,5.4,44.6,27.1,42.8,55.6c-1.4,22.4-9.3,40.8-31.1,50.5C428.7,184.5,419.2,186,409.4,186.3z
	 M418.2,130.8c-0.4-4.3-0.7-8.6-1.3-12.8c-0.9-6.5-4-9.7-9.2-9.9c-5.3-0.2-8.6,3.1-9.8,9.8c-1.5,8.2-1.3,16.5,0,24.7
	c0.9,5.8,3.7,8.2,9.2,8.4c5.5,0.2,8.3-2.1,9.6-7.9C417.7,139.1,418.1,135,418.2,130.8z"
        />
        <path
          className="st1"
          d="M1438.8,170.1c-8.8,14.6-24.8,19.9-41.1,14.2c-14.5-5-23.1-19.3-21.4-35c1.6-15,9.7-24.3,23.9-28.6
	c10.2-3.1,20.5-3.9,31.1-3.2c1.7,0.1,4.5,1.6,5.1-0.9c0.6-2.5-1.1-4.8-3.6-6c-4.8-2.3-10-2.4-15.1-2.5c-8.5-0.3-17,0.5-25.3,2.4
	c-3,0.7-3.9,0.4-3.1-2.9c2.1-9.1,3.9-18.2,5.8-27.3c0.4-2,1.2-3.6,3.6-4c18.8-3.6,37.7-4.9,56.4,0.1c19.7,5.3,28.9,18.3,29.3,40.9
	c0.3,20.8,0.2,41.6,0.4,62.4c0,3.1-0.6,4.3-4.1,4.3c-12.7-0.2-25.3-0.2-38,0c-3,0-4.2-0.6-3.9-3.8
	C1439.1,177.2,1438.8,174.1,1438.8,170.1z M1431.4,138.7c-7.7,0-11.7,3.1-11.7,9.1c0,4.7,3,7.5,8,7.5c5.8,0,8.8-4.4,9.3-12.4
	C1437.3,138,1434.3,138.8,1431.4,138.7z"
        />
        <path
          className="st0"
          d="M893.3,138.1c-8.8,0-17.7,0.1-26.5-0.1c-3.9-0.1-3.9,1.5-2.3,4.1c3.9,6.3,10.4,8,17.1,8.8
	c11,1.3,21.9,0.5,32.7-1.8c4.2-0.9,5.1,0.3,4.3,4.3c-1.5,7.7-2.8,15.4-3.9,23.1c-0.5,3.3-1.7,5.2-5.2,6.1
	c-19.4,5.1-38.9,5.4-58.2-0.1c-24.4-6.9-37.5-24.1-38.4-49.6c-0.8-23.8,7-43.4,29.8-54c18.5-8.5,37.6-7.7,56.2-0.1
	c14,5.7,19.8,18,22.6,31.9c1.6,8,1.5,16.2,1.3,24.4c-0.1,2.5-0.9,3-3.2,3C911,138,902.1,138.1,893.3,138.1z M871.7,116.1
	c2.7,0,5.3-0.1,8,0c2.5,0.2,3.5-0.5,3.4-3.2c-0.2-5.8-2.7-9.1-8.2-10.1c-6.5-1.2-10.9,1.2-13.2,6.9c-2.1,5.5-1.6,6.4,4,6.4
	C867.8,116.1,869.7,116.1,871.7,116.1z"
        />
        <path
          className="st0"
          d="M1101.2,138.1c-8.7,0-17.3,0-26,0c-1.2,0-2.9-0.8-3.5,0.9c-0.5,1.3,0.3,2.6,1.1,3.7c4.2,5.8,10.4,7.5,16.9,8.2
	c11,1.2,21.9,0.4,32.7-1.8c3.7-0.8,5.1-0.3,4.3,3.9c-1.6,8.1-2.8,16.4-4.2,24.5c-0.3,1.9-0.6,3.7-3.2,4.4
	c-23.8,6.4-47.4,6.8-70.2-3.5c-18.7-8.5-27-24.3-28.1-44.2c-0.4-7.8,0.2-15.6,2.3-23.2c5.6-19.8,22.6-34.4,43.2-37
	c11.7-1.5,23.3-0.9,34.6,2.4c17.3,5.2,25.5,18,28.6,34.9c1.4,7.7,1.3,15.5,1.2,23.4c0,2.9-1.1,3.5-3.8,3.5
	C1118.6,138,1109.9,138.1,1101.2,138.1z M1079.7,116.1c2.7,0,5.3-0.2,8,0c2.5,0.2,3.5-0.5,3.4-3.3c-0.2-5.5-2.7-9-7.7-10
	c-6.6-1.3-11.4,1.1-13.7,6.9c-2.1,5.5-1.6,6.3,4,6.4C1075.7,116.1,1077.7,116.1,1079.7,116.1z"
        />
        <path
          className="st0"
          d="M943.1,129.8c0-6.7-0.1-12.2,0-17.7c0.1-2.4-0.8-3.4-3.2-3.1c-0.3,0-0.7,0-1,0c-10.1-0.2-10.1-0.2-10.1-10
	c0-6.7,0.1-13.3-0.1-20c-0.1-2.5,0.6-3.4,3.3-3.4c4.1,0,9.6,2,11.9-0.9c2.2-2.6,1.3-7.9,1.7-12c0.4-4.3,0.8-8.6,1-12.9
	c0.1-2.2,0.9-2.7,3-3c12.7-1.6,25.4-3.1,38.1-5c4.4-0.7,5.3,0.8,5.1,4.9c-0.3,8.5,0,17-0.2,25.5c-0.1,2.9,0.9,3.7,3.7,3.6
	c4.8-0.2,9.7,0.1,14.5-0.1c2.4-0.1,3.1,0.6,3.1,3.1c-0.1,9-0.1,18,0,27c0,2.5-0.6,3.5-3.3,3.4c-4.7-0.2-9.3,0.1-14-0.1
	c-3-0.1-4.1,0.6-4,3.8c0.3,9,0,18,0.3,27c0.3,9.2,4.5,12.7,13.7,12.4c0.8,0,1.7-0.1,2.5-0.1c7.9-0.4,7.9-0.4,6.6,7.2
	c-1.3,7.2-2.7,14.4-3.8,21.6c-0.3,2.2-1.2,3.2-3.3,3.6c-13.1,2.1-26.2,3.3-39.2,0.1c-15.7-3.9-24.1-15.2-25.5-33.4
	C943.3,143.6,942.8,136.1,943.1,129.8z"
        />
        <path
          className="st1"
          d="M1142.6,147.9c11.2,3.4,22,5.1,33.1,5c1.9,0,4.5,0.5,5-2c0.6-2.7-2-3.4-4-4.2c-4.8-2-9.6-3.7-14.2-6
	c-24.7-11.8-21.2-42.7-6.6-54.9c7.8-6.6,16.8-10.1,26.7-11.5c13-1.9,25.9-1.3,38.7,1.4c3.1,0.6,4.1,2,4.4,4.9
	c0.9,8.3,1.9,16.5,3,24.8c0.4,3,0.3,4.6-3.6,3.7c-7.3-1.6-14.8-2.2-22.3-2.5c-1.5,0-3-0.1-4.5,0.1c-1.4,0.2-3.2,0.1-3.5,2.1
	c-0.3,1.9,1.2,2.6,2.6,3.1c3.4,1.4,6.8,2.7,10.2,4c2.5,1,4.9,2.1,7.3,3.2c11.6,5.6,18.6,14.3,18.6,27.7
	c-0.1,13.7-5.2,24.6-17.5,31.4c-12.8,7.1-26.8,8.5-41.1,8.1c-8.3-0.3-16.6-0.7-24.8-2.7c-2.9-0.7-4.5-1.9-4.7-5.2
	C1144.6,168.4,1143.6,158.4,1142.6,147.9z"
        />
        <path
          className="st0"
          d="M329.1,151.4c11-0.7,11-0.7,9.1,9.3c-1.2,6.2-2.2,12.4-3.4,18.6c-0.4,2-0.8,3.8-3.3,4.3
	c-21.2,4.9-41.8,4.1-60.5-7.9c-14.4-9.2-20.3-23.7-21.2-40.3c-1-17.8,1.8-34.5,15.3-47.7c7.9-7.7,17.7-11.7,28.3-13.5
	c12.6-2.1,25.2-1.7,37.7,0.6c2.9,0.5,4.6,1.7,4.8,5c0.6,8.8,1.5,17.5,2.6,26.3c0.3,2.7-0.5,3.1-2.9,2.9c-6.5-0.5-12.9-1.1-19.4-0.3
	c-10,1.2-15.3,6.5-16.1,16.4c-1.4,17.4,6.3,25.9,23.8,26.1C325.8,151.4,327.8,151.4,329.1,151.4z"
        />
        <path
          className="st1"
          d="M1552.8,93.1c6.6-14,15.8-20.7,28.1-20.2c3.2,0.1,4.6,0.9,5,4.5c1.2,10.4,2.8,20.8,4.4,31.1
	c0.5,3.1-0.3,3.7-3.2,3.4c-5.6-0.6-11.3-0.8-17-0.1c-9.8,1.1-14.1,5.4-15.4,15.2c-1.3,9.6-0.7,19.3-0.8,28.9c-0.1,8-0.1,16,0,24
	c0.1,2.9-0.5,4.1-3.8,4c-14.2-0.2-28.3-0.2-42.5,0c-3,0-3.6-1.1-3.6-3.8c0.1-32.6,0.1-65.3,0-97.9c0-2.9,1.1-3.8,3.7-4.1
	c13.7-1.7,27.4-3.4,41.1-5.3c3.2-0.4,4,0.4,3.9,3.6C1552.6,82,1552.8,87.6,1552.8,93.1z"
        />
      </svg>
    </div>
  );
};

export default LogoSVG;
