import React, { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { Plus, Trash2 } from "lucide-react";
import availableTimes from "../../assets/json/availableTimeBlocks.json";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const daysAvailableInitial = {
  sunday: { available: false, times: [], timeSlots: [] },
  monday: { available: false, times: [], timeSlots: [] },
  tuesday: { available: false, times: [], timeSlots: [] },
  wednesday: { available: false, times: [], timeSlots: [] },
  thursday: { available: false, times: [], timeSlots: [] },
  friday: { available: false, times: [], timeSlots: [] },
  saturday: { available: false, times: [], timeSlots: [] },
};
const timesInit = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
  22, 23,
];


export default function SetPricing({ setAvailability }) {
  const [daysAvailable, setDaysAvailable] = useState(daysAvailableInitial);
  const [enabled, setEnabled] = useState(false);
  const timeOptions = Object.values(availableTimes)[0];
  const [mondayTimes, setMondayTimes] = useState([]);
  const [tuesdayTimes, setTuesdayTimes] = useState([]);
  const [wednesdayTimes, setWednesdayTimes] = useState([]);
  const [thursdayTimes, setThursdayTimes] = useState([]);
  const [fridayTimes, setFridayTimes] = useState([]);
  const [saturdayTimes, setSaturdayTimes] = useState([]);
  const [sundayTimes, setSundayTimes] = useState([]);

  const [sundayTimeSlots, setSundayTimeSlots] = useState([]);
  const [mondayTimeSlots, setMondayTimeSlots] = useState([]);
  const [tuesdayTimeSlots, setTuesdayTimeSlots] = useState([]);
  const [wednesdayTimeSlots, setWednesdayTimeSlots] = useState([]);
  const [thursdayTimeSlots, setThursdayTimeSlots] = useState([]);
  const [fridayTimeSlots, setFridayTimeSlots] = useState([]);
  const [saturdayTimeSlots, setSaturdayTimeSlots] = useState([]);

  const [overallTimes, setOverallTimes] = useState([
    { day: "sunday", times: [], available: false, timeSlots: [] },
    { day: "monday", times: [], available: false, timeSlots: [] },
    { day: "tuesday", times: [], available: false, timeSlots: [] },
    { day: "wednesday", times: [], available: false, timeSlots: [] },
    { day: "thursday", times: [], available: false, timeSlots: [] },
    { day: "friday", times: [], available: false, timeSlots: [] },
    { day: "saturday", times: [], available: false, timeSlots: [] },
  ]);

  const addTimeSlot = (day) => {
    const newTimeSlot = {
      startTime: "12:00am", // Set default values for start and end time
      endTime: "11:00pm",
    };
    switch (
      day // Corrected typo from 'swtich' to 'switch'
    ) {
      case "sunday":
        setSundayTimeSlots([...sundayTimeSlots, newTimeSlot]);
        break;
      case "monday":
        setMondayTimeSlots([...mondayTimeSlots, newTimeSlot]);
        break;
      case "tuesday":
        setTuesdayTimeSlots([...tuesdayTimeSlots, newTimeSlot]);
        break;
      case "wednesday":
        setWednesdayTimeSlots([...wednesdayTimeSlots, newTimeSlot]);
        break;
      case "thursday":
        setThursdayTimeSlots([...thursdayTimeSlots, newTimeSlot]);
        break;
      case "friday":
        setFridayTimeSlots([...fridayTimeSlots, newTimeSlot]);
        break;
      case "saturday":
        setSaturdayTimeSlots([...saturdayTimeSlots, newTimeSlot]);
        break;
      default:
        break;
    }
  };

  const removeTimeSlot = (index, day) => {
    let updatedTimeSlots;
    switch (day) {
      case "sunday":
        updatedTimeSlots = [...sundayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setSundayTimeSlots(updatedTimeSlots);
        break;
      case "monday":
        updatedTimeSlots = [...mondayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setMondayTimeSlots(updatedTimeSlots);
        break;
      case "tuesday":
        updatedTimeSlots = [...tuesdayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setTuesdayTimeSlots(updatedTimeSlots);
        break;
      case "wednesday":
        updatedTimeSlots = [...wednesdayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setWednesdayTimeSlots(updatedTimeSlots);
        break;
      case "thursday":
        updatedTimeSlots = [...thursdayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setThursdayTimeSlots(updatedTimeSlots);
        break;
      case "friday":
        updatedTimeSlots = [...fridayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setFridayTimeSlots(updatedTimeSlots);
        break;
      case "saturday":
        updatedTimeSlots = [...saturdayTimeSlots];
        updatedTimeSlots.splice(index, 1);
        setSaturdayTimeSlots(updatedTimeSlots);
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    if (enabled) {
      setDaysAvailable({
        sunday: true,
        monday: true,
        tuesday: true,
        wednesday: true,
        thursday: true,
        friday: true,
        saturday: true,
      });
      setSundayTimes(timesInit);
      setMondayTimes(timesInit);
      setTuesdayTimes(timesInit);
      setWednesdayTimes(timesInit);
      setThursdayTimes(timesInit);
      setFridayTimes(timesInit);
      setSaturdayTimes(timesInit);
    } else {
      setDaysAvailable({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
      });
      setSundayTimes([]);
      setMondayTimes([]);
      setTuesdayTimes([]);
      setWednesdayTimes([]);
      setThursdayTimes([]);
      setFridayTimes([]);
      setSaturdayTimes([]);
    }
  }, [enabled]);

  const trulyEnabled =
    daysAvailable.sunday &&
    daysAvailable.monday &&
    daysAvailable.tuesday &&
    daysAvailable.wednesday &&
    daysAvailable.thursday &&
    daysAvailable.friday &&
    daysAvailable.saturday;

  useEffect(() => {
    // add time values from availableTimes to sundayTimes
    if (daysAvailable.sunday && sundayTimeSlots.length === 0) {
      setSundayTimes(timesInit);
    } else {
      const timeSlots = [];
      sundayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setSundayTimes(timeSlots);
    }
  }, [sundayTimeSlots, timeOptions]);

  useEffect(() => {
    // add time values from availableTimes to saturdayTimes
    if (daysAvailable.saturday && saturdayTimeSlots.length === 0) {
      setSaturdayTimes(timesInit);
    } else {
      const timeSlots = [];
      saturdayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setSaturdayTimes(timeSlots);
    }
  }, [saturdayTimeSlots, timeOptions]);

  useEffect(() => {
    if (daysAvailable.tuesday && tuesdayTimeSlots.length === 0) {
      setTuesdayTimes(timesInit);
    } else {
      // add time values from availableTimes to tuesdayTimes
      const timeSlots = [];
      tuesdayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setTuesdayTimes(timeSlots);
    }
  }, [tuesdayTimeSlots, timeOptions]);

  useEffect(() => {
    if (daysAvailable.monday && mondayTimeSlots.length === 0) {
      setMondayTimes(timesInit);
    } else {
      const timeSlots = [];
      mondayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setMondayTimes(timeSlots);
    }
  }, [mondayTimeSlots, timeOptions]);

  useEffect(() => {
    if (daysAvailable.wednesday && wednesdayTimeSlots.length === 0) {
      setWednesdayTimes(timesInit);
    } else {
      const timeSlots = [];
      wednesdayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setWednesdayTimes(timeSlots);
    }
  }, [wednesdayTimeSlots, timeOptions]);

  useEffect(() => {
    // add time values from availableTimes to thursdayTimes
    if (daysAvailable.thursday && thursdayTimeSlots.length === 0) {
      setThursdayTimes(timesInit);
    } else {
      const timeSlots = [];
      thursdayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setThursdayTimes(timeSlots);
    }
  }, [thursdayTimeSlots, timeOptions]);

  useEffect(() => {
    // add time values from availableTimes to fridayTimes
    if (daysAvailable.friday && fridayTimeSlots.length === 0) {
      setFridayTimes(timesInit);
    } else {
      const timeSlots = [];
      fridayTimeSlots.forEach((timeSlot) => {
        const startTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.startTime
        );
        const endTime = timeOptions.find(
          (time) => time.displayValue === timeSlot.endTime
        );

        // Convert start and end times to integers
        const startHour = parseInt(startTime.dbvalue);
        const endHour = parseInt(endTime.dbvalue);

        // Create an array of hours from start to end
        for (let i = startHour; i < endHour; i++) {
          // Check if the time slot already exists in sundayTimes
          if (!timeSlots.includes(i)) {
            timeSlots.push(i);
          }
        }
      });
      setFridayTimes(timeSlots);
    }
  }, [fridayTimeSlots, timeOptions]);

  const handleSetAvailableDaysAndTimes = (day, setDayTimes) => {
    if (day) {
      setDaysAvailable({ ...daysAvailable, [day]: !daysAvailable[day] });
      const boool = daysAvailable[day];
      if (boool) {
        setDayTimes([]);
      } else {
        setDayTimes(timesInit);
      }
    }
  };

  useEffect(() => {
    setAvailability([
      {
        day: "sunday",
        times: sundayTimes,
        available: daysAvailable.sunday,
        timeSlots: sundayTimeSlots,
      },
      {
        day: "monday",
        times: mondayTimes,
        available: daysAvailable.monday,
        timeSlots: mondayTimeSlots,
      },
      {
        day: "tuesday",
        times: tuesdayTimes,
        available: daysAvailable.tuesday,
        timeSlots: tuesdayTimeSlots,
      },
      {
        day: "wednesday",
        times: wednesdayTimes,
        available: daysAvailable.wednesday,
        timeSlots: wednesdayTimeSlots,
      },
      {
        day: "thursday",
        times: thursdayTimes,
        available: daysAvailable.thursday,
        timeSlots: thursdayTimeSlots,
      },
      {
        day: "friday",
        times: fridayTimes,
        available: daysAvailable.friday,
        timeSlots: fridayTimeSlots,
      },
      {
        day: "saturday",
        times: saturdayTimes,
        available: daysAvailable.saturday,
        timeSlots: saturdayTimeSlots,
      },
    ]);
  }, [
    daysAvailable,
    sundayTimes,
    mondayTimes,
    tuesdayTimes,
    wednesdayTimes,
    thursdayTimes,
    fridayTimes,
    saturdayTimes,
    sundayTimeSlots,
    mondayTimeSlots,
    tuesdayTimeSlots,
    wednesdayTimeSlots,
    thursdayTimeSlots,
    fridayTimeSlots,
    saturdayTimeSlots,
  ]);

  

  return (
    <div className="">
      <div className="divide-y divide-gray-200 overflow-hidden rounded-xl text-white bg-zinc-900 shadow md:col-span-3">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold leading-6 flex items-center">
              Availability
            </h3>
            <div className="flex items-center">
              <label htmlFor="availability" className="mr-2">
                Every day
              </label>
              <Switch
                checked={trulyEnabled}
                onChange={setEnabled}
                className={classNames(
                  trulyEnabled ? "bg-lime-300" : "bg-zinc-500",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-300 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  className={classNames(
                    trulyEnabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-zinc-900 shadow ring-0 transition duration-200 ease-in-out"
                  )}
                >
                  <span
                    className={classNames(
                      trulyEnabled
                        ? "opacity-0 duration-100 ease-out"
                        : "opacity-100 duration-200 ease-in",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-gray-300"
                      fill="none"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      trulyEnabled
                        ? "opacity-100 duration-200 ease-in"
                        : "opacity-0 duration-100 ease-out",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-lime-400"
                      fill="currentColor"
                      viewBox="0 0 12 12"
                    >
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </div>
          </div>
          <div className="flex items-center justify-between mt-6">
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.sunday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("sunday", setSundayTimes)
              }
            >
              S
            </button>
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.monday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("monday", setMondayTimes)
              }
            >
              M
            </button>
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.tuesday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("tuesday", setTuesdayTimes)
              }
            >
              T
            </button>
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.wednesday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("wednesday", setWednesdayTimes)
              }
            >
              W
            </button>
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.thursday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("thursday", setThursdayTimes)
              }
            >
              T
            </button>
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.friday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("friday", setFridayTimes)
              }
            >
              F
            </button>
            <button
              className={`rounded-full w-12 h-12 p-2 font-bold ${
                daysAvailable.saturday
                  ? "text-zinc-950 bg-lime-300 hover:bg-lime-200 "
                  : "hover:bg-zinc-800"
              }`}
              onClick={() =>
                handleSetAvailableDaysAndTimes("saturday", setSaturdayTimes)
              }
            >
              S
            </button>
          </div>
          <div>
            {daysAvailable.sunday && (
              <div className="grid grid-cols-3 mt-6">
                <div className="font-medium">Sunday</div>
                <div className="text-zinc-400">
                  {sundayTimes.length === 24
                    ? "Full day"
                    : sundayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white font-semibold hover:text-lime-400"
                    onClick={() => {
                      addTimeSlot("sunday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {sundayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2 ">
                    {sundayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...sundayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setSundayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...sundayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setSundayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "sunday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {daysAvailable.monday && (
              <div className="grid grid-cols-3 mt-4">
                <div className="font-medium">Monday</div>
                <div className="text-zinc-400">
                  {mondayTimes.length === 24
                    ? "Full day"
                    : mondayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white  font-semibold"
                    onClick={() => {
                      addTimeSlot("monday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {mondayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2 ">
                    {mondayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...mondayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setMondayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...mondayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setMondayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "monday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {daysAvailable.tuesday && (
              <div className="grid grid-cols-3 mt-4">
                <div className="font-medium">Tuesday</div>
                <div className="text-zinc-400">
                  {tuesdayTimes.length === 24
                    ? "Full day"
                    : tuesdayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white  font-semibold"
                    onClick={() => {
                      addTimeSlot("tuesday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {tuesdayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2">
                    {tuesdayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...tuesdayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setTuesdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...tuesdayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setTuesdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "tuesday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {daysAvailable.wednesday && (
              <div className="grid grid-cols-3  mt-4">
                <div className="font-medium">Wednesday</div>
                <div className="text-zinc-400">
                  {" "}
                  {wednesdayTimes.length === 24
                    ? "Full day"
                    : wednesdayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white  font-semibold"
                    onClick={() => {
                      addTimeSlot("wednesday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {wednesdayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2">
                    {wednesdayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...wednesdayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setWednesdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...wednesdayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setWednesdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "wednesday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {daysAvailable.thursday && (
              <div className="grid grid-cols-3 mt-6">
                <div className="font-medium">Thursday</div>
                <div className="text-zinc-400">
                  {thursdayTimes.length === 24
                    ? "Full day"
                    : thursdayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white  font-semibold"
                    onClick={() => {
                      addTimeSlot("thursday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {thursdayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2 ">
                    {thursdayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...thursdayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setThursdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...thursdayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setThursdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "thursday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {daysAvailable.friday && (
              <div className="grid grid-cols-3 mt-4">
                <div className="font-medium">Friday</div>
                <div className="text-zinc-400">
                  {" "}
                  {fridayTimes.length === 24
                    ? "Full day"
                    : fridayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white  font-semibold"
                    onClick={() => {
                      addTimeSlot("friday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {fridayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2">
                    {fridayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...fridayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setFridayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...fridayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setFridayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "friday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
            {daysAvailable.saturday && (
              <div className="grid grid-cols-3 mt-4">
                <div className="font-medium">Saturday</div>
                <div className="text-zinc-400">
                  {saturdayTimes.length === 24
                    ? "Full day"
                    : saturdayTimes?.length + " hours"}
                </div>

                <div className="ml-auto">
                  <button
                    className="text-white  font-semibold"
                    onClick={() => {
                      addTimeSlot("saturday"); // Add a new time slot when the plus icon is clicked
                    }}
                  >
                    <Plus size={28} />
                  </button>
                </div>
                {saturdayTimeSlots?.length > 0 && (
                  <div className="col-span-3 space-y-2">
                    {saturdayTimeSlots.map((timeSlot, index) => (
                      <div
                        key={index}
                        className="flex items-center gap-1 bg-zinc-700/80 rounded-xl p-2"
                      >
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.startTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...saturdayTimeSlots];
                            updatedTimeSlots[index].startTime = e.target.value;
                            setSaturdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="font-bold">-</div>
                        <select
                          className="block w-full rounded-md border-0 py-1.5 pl-3 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                          defaultValue="Canada"
                          value={timeSlot.endTime}
                          onChange={(e) => {
                            const updatedTimeSlots = [...saturdayTimeSlots];
                            updatedTimeSlots[index].endTime = e.target.value;
                            setSaturdayTimeSlots(updatedTimeSlots);
                          }}
                        >
                          {timeOptions.map((time) => (
                            <option
                              key={time.dbvalue}
                              value={time.displayValue}
                            >
                              {time.displayValue}
                            </option>
                          ))}
                        </select>
                        <div className="ml-auto flex items-center justify-center xl:pl-6">
                          <button
                            className="text-white font-semibold hover:text-red-400"
                            onClick={() => removeTimeSlot(index, "saturday")}
                          >
                            <Trash2 size={28} />
                          </button>
                        </div>
                      </div>
                    ))}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
      {/* <div className="divide-y divide-gray-200 overflow-hidden rounded-xl text-white bg-zinc-900 shadow md:col-span-4">
        <div className="px-4 py-5 sm:p-6">
          <div className="flex items-center justify-between">
            <h3 className="text-base font-semibold leading-6 flex items-center">
              Pricing
            </h3>

            <div className="flex items-center">
              <label htmlFor="availability" className="mr-2">
                Same Price for all days
              </label>
              <Switch
                checked={sameEnabled}
                onChange={setSameEnabled}
                className={classNames(
                  sameEnabled ? "bg-lime-300" : "bg-zinc-500",
                  "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-lime-300 focus:ring-offset-2"
                )}
              >
                <span className="sr-only">Use setting</span>
                <span
                  className={classNames(
                    sameEnabled ? "translate-x-5" : "translate-x-0",
                    "pointer-events-none relative inline-block h-5 w-5 transform rounded-full bg-zinc-900 shadow ring-0 transition duration-200 ease-in-out"
                  )}
                >
                  <span
                    className={classNames(
                      sameEnabled
                        ? "opacity-0 duration-100 ease-out"
                        : "opacity-100 duration-200 ease-in",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-gray-300"
                      fill="none"
                      viewBox="0 0 12 12"
                    >
                      <path
                        d="M4 8l2-2m0 0l2-2M6 6L4 4m2 2l2 2"
                        stroke="currentColor"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </span>
                  <span
                    className={classNames(
                      sameEnabled
                        ? "opacity-100 duration-200 ease-in"
                        : "opacity-0 duration-100 ease-out",
                      "absolute inset-0 flex h-full w-full items-center justify-center transition-opacity"
                    )}
                    aria-hidden="true"
                  >
                    <svg
                      className="h-3 w-3 text-lime-400"
                      fill="currentColor"
                      viewBox="0 0 12 12"
                    >
                      <path d="M3.707 5.293a1 1 0 00-1.414 1.414l1.414-1.414zM5 8l-.707.707a1 1 0 001.414 0L5 8zm4.707-3.293a1 1 0 00-1.414-1.414l1.414 1.414zm-7.414 2l2 2 1.414-1.414-2-2-1.414 1.414zm3.414 2l4-4-1.414-1.414-4 4 1.414 1.414z" />
                    </svg>
                  </span>
                </span>
              </Switch>
            </div>
          </div>
          <p className="text-sm text-yellow-300 mt-2">
            Set the pricing for each day. Make sure to set both the{" "}
            <span className="font-bold ">price per hour</span> and the{" "}
            <span className="font-bold">price per day</span>.
          </p>
          <div className="mt-6">
            <p className="text-sm text-gray-300">
              <span className="font-bold text-white">Price Per Hour: </span>
              Price if the renter doesn't select all hours available for the day
            </p>
            <p className="text-sm text-gray-300">
              <span className="font-bold text-white">Price Per Day: </span>
              Price if the renter selects ALL hours available for the day
            </p>
          </div>
          {sameEnabled ? (
            <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
              <div className="font-medium">Price for all days</div>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    per hour
                  </span>
                </div>
              </div>
              <div className="relative rounded-md shadow-sm">
                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                  <span className="text-gray-500 sm:text-sm">$</span>
                </div>
                <input
                  type="text"
                  name="price"
                  id="price"
                  className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                  placeholder="0.00"
                  aria-describedby="price-currency"
                />
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <span
                    className="text-gray-500 sm:text-sm"
                    id="price-currency"
                  >
                    per day
                  </span>
                </div>
              </div>
            </div>
          ) : (
            <div className="mt-6">
              {daysAvailable.sunday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Sunday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {daysAvailable.monday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Monday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {daysAvailable.tuesday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Tuesday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {daysAvailable.wednesday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Wednesday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {daysAvailable.thursday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Thursday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {daysAvailable.friday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Friday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
              {daysAvailable.saturday && (
                <div className="grid grid-cols-3 gap-x-4 mt-6 bg-zinc-700/80 rounded-xl p-4 items-center">
                  <div className="font-medium">Saturday</div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per hour
                      </span>
                    </div>
                  </div>
                  <div className="relative rounded-md shadow-sm">
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      type="text"
                      name="price"
                      id="price"
                      className="block w-full rounded-md border-0 py-1.5 pl-7 pr-10 text-white ring-2 ring-inset ring-zinc-400 focus:ring-2 focus:ring-lime-600 sm:text-sm sm:leading-6 bg-zinc-800 h-10"
                      placeholder="0.00"
                      aria-describedby="price-currency"
                    />
                    <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                      <span
                        className="text-gray-500 sm:text-sm"
                        id="price-currency"
                      >
                        per day
                      </span>
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div> */}
      {/* <div className="md:col-start-4 md:col-end-8">
        <p className="text-xs text-gray-500 mt-2">
          *
          <span className="font-bold">
            All prices are negotiable, this is just a base price to help us give renter the most accurate estimated quote.
          </span>{" "}
          A renter will be required to tell you the use case and you can adjust
          the price accordingly. You can also update a security deposit to
          protect your space.
        </p>
      </div> */}
    </div>
  );
}
