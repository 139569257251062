import { CheckCircle, Circle } from "lucide-react";
import React from "react";

const QuoteButton = ({
  day,
  timeRefs,
  isSelected,
  timeSlots,
  setTimeSlots,
  timeOptions,
  isDisabled,
  handleAddTimeToDate,
}) => {
    
  const handleAddTime = (day, time) => {
    const newTime = { day, time };
    if (timeSlots?.some((slot) => slot.day === day && slot.time === time)) {
      setTimeSlots(
        timeSlots?.filter((slot) => slot.day !== day || slot.time !== time)
      );
    } else {
      setTimeSlots([...timeSlots, newTime]);
    }
  };

  const buttonsForOptions = (day) => {
    const dayTimeRefs = timeRefs[day];
    // Get the time references for the current day

    return (
      <div className="space-y-1">
        {timeOptions.map((time, index) => {
          const isEnabled = dayTimeRefs.includes(time.dbvalue); // Check if the time is found in the day's time references

          return (
            <button
              key={index}
              className={`border p-1 rounded-md flex justify-center items-center gap-x-1  ${
                timeSlots.some(
                  (slot) => slot.day === day && slot.time === time.dbvalue
                )
                  ? "bg-amber-500 text-white text-xs py-2 w-full"
                  : isEnabled
                  ? "bg-white text-gray-950 font-semibold text-xs py-2 w-full  hover:bg-amber-50"
                  : "line-through text-gray-300 bg-transparent  text-xs py-2 w-full cursor-not-allowed"
              }`}
              onClick={() => {
                if (isEnabled) {
                  handleAddTime(day, time.dbvalue);
                }
              }}
              disabled={!isEnabled}
            >
              {time.displayValue}
            </button>
          );
        })}
      </div>
    );
  };

  return (
    <div
      className={`space-y-2 p-1  ${
        isDisabled ? "row-span-2 sm:block hidden" : "row-span-2 "
      }`}
    >
      <div
        className={`w-full justify-center text-sm font-semibold text-center focus-visible:outline-offset-2  inline-flex items-center gap-x-1.5 ${
          timeRefs[day]?.length === 0 ? "text-zinc-300" : " text-zinc-950"
        }`}
      >
        {day}'s
      </div>

      {isSelected && !isDisabled && buttonsForOptions(day)}
    </div>
  );
};

export default QuoteButton;
