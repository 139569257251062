import { X } from "lucide-react";
import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { updateUser } from "../../functions/crud";

export default function TrainingInfo() {
  const { userID, user } = UserAuth();
  const [show, setShow] = useState(true); // Initially set to false
  const [isLoading, setIsLoading] = useState(true); // Loading state

  const handleDismiss = () => {
    setShow(false);
    updateUser(userID, { showTrainingInfo: new Date() });
  };

  useEffect(() => {
    if (user?.showTrainingInfo) {
      // Convert seconds and nanoseconds to milliseconds
      const timestampInMilliseconds = user.showTrainingInfo.seconds * 1000 + user.showTrainingInfo.nanoseconds / 1000000;
      const showTrainingDate = new Date(timestampInMilliseconds);
      const currentDate = new Date();
      const sevenDaysAgo = new Date(currentDate);
      sevenDaysAgo.setDate(currentDate.getDate() - 1);
  
      if (showTrainingDate < sevenDaysAgo) {
        setShow(true); // Show if training info is older than 7 days
      } else {
        setShow(false);
      }
    } 
  
    setIsLoading(false); // Set loading to false after checking logic
  }, [user?.showTrainingInfo]);
  

  // If still loading, don't render anything
  if (isLoading) {
    return null;
  }

  return (
    <div>
      {" "}
      {show && (
        <div className="relative isolate flex items-center gap-x-6 overflow-hidden bg-emerald-200 px-6 py-2.5 sm:px-3.5 sm:before:flex-1">
          <div
            className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-blue-300 to-emerald-600 opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div
            className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
            aria-hidden="true"
          >
            <div
              className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-emerald-300 opacity-30"
              style={{
                clipPath:
                  "polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)",
              }}
            />
          </div>
          <div className="flex flex-wrap items-center gap-x-4 gap-y-2">
            <p className="text-sm leading-6 text-gray-900">
              <strong className="font-semibold">
                Free Host Training Webinar
              </strong>
              <svg
                viewBox="0 0 2 2"
                className="mx-2 inline h-0.5 w-0.5 fill-current"
                aria-hidden="true"
              >
                <circle cx={1} cy={1} r={1} />
              </svg>
              Join us online from September 2 – 6 to see learn more on how to host
            </p>
            <a
              href="#"
              className="flex-none rounded-full bg-gray-900 px-3.5 py-1 text-sm font-semibold text-white shadow-sm hover:bg-gray-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-900"
            >
              Register now <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
          <div className="flex flex-1 justify-end">
            <button
              type="button"
              className="-m-3 p-3 focus-visible:outline-offset-[-4px]"
              onClick={() => handleDismiss()}
            >
              <span className="sr-only">Dismiss</span>
              <X className="h-5 w-5 text-gray-900" aria-hidden="true" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
}
