import { onAuthStateChanged } from "firebase/auth";
import { createContext, useContext, useEffect, useState } from "react";
import { auth, db, rtdb } from "../firebase/fire";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  onSnapshot,
  query,
  where,
} from "firebase/firestore";
import { ref } from "firebase/storage";
import { onValue, set } from "firebase/database";
const UserContext = createContext();

export default function AuthContextProvider({ children }) {
  const [isLoggedOut, setIsLoggedOut] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [user, setUser] = useState(null);
  const [spaces, setSpaces] = useState([]);
  const [pricingModules, setPricingModules] = useState([]);
  const [listedSpacesCO, setListedSpacesCO] = useState([{}]);
  const [notifications, setNotifications] = useState([{}]);
  const [bookings, setBookings] = useState([{}]);
  const [userID, setUserID] = useState("");
  const [spaceViews, setSpaceViews] = useState(0);
  const [locationInfo, setLocationInfo] = useState({});
  const [closed, setClosed] = useState(false);
  const [updated, setUpdated] = useState(false);
  const [renters, setRenters] = useState([]);

  function isRenter() {
    return user?.type === 2;
  }

  function isProvider() {
    return user?.type === 1;
  }

  const getAllSpaces = async (uid) => {
    const spacesRef = collection(db, "spaces");
    const q = query(spacesRef, where("userID", "==", uid));
    const querySnapshot = await getDocs(q);
    let spacesArray = [];
    let spaceViewCount = 0;
    querySnapshot.forEach((doc) => {
      spacesArray.push({ ...doc.data(), id: doc.id });
      if (doc.data().views) {
        spaceViewCount += doc.data().views;
      }
    });
    setSpaces(spacesArray);
    setSpaceViews(spaceViewCount);
    handleGetBookings(spacesArray);
  };

  const getSpaceLocation = async (locLinkName) => {
    if (locLinkName) {
      const locationRef = doc(db, "locations", locLinkName);
      const locationData = await getDoc(locationRef);
      if (locationData.exists()) {
        setLocationInfo(locationData.data());
        return locationData.data();
      } else {
        return [];
      }
    } else {
      return;
    }
  };

  const getBookings = async (id) => {
    const bookingRef = doc(db, "bookings", id);
    const bookingData = await getDoc(bookingRef);
    if (bookingData.exists()) {
      return bookingData.data();
    } else {
      return [];
    }
  };

  const getAllRenters = async (locLink) => {
    if (!locLink) {
      return;
    } else {
      const locationRef = doc(db, "locations", locLink);
      const locationData = await getDoc(locationRef);
      const locData = locationData.data();
      const renterProfiles = [];
      if (user?.type === 1) {
        for (let i = 0; i < locData?.renters?.length; i++) {
          const renterRef = doc(db, "renters", locData?.renters[i]);
          const renterData = await getDoc(renterRef);
          console.log(renterData.data());
          if (renterData.exists()) {
            renterProfiles.push(renterData.data());
          }
        }
      }
      setRenters(renterProfiles);
    }
  };

  const handleGetBookings = async (spacesArr) => {
    const bookings = [];
    const promises = [];
    spacesArr?.forEach((space) => {
      space?.bookings?.forEach((booking) => {
        promises.push(
          getBookings(booking).then((data) => {
            bookings.push(data);
          })
        );
      });
    });

    await Promise.all(promises); // Wait for all promises to resolve
    setBookings(bookings); // Update state after all promises have resolved
  };

  const getListedSpaces = async () => {
    const docRef = collection(db, "CO");
    const q = query(docRef);
    const querySnapshot = await getDocs(q);
    let spacesArray = [];
    querySnapshot.forEach((doc) => {
      spacesArray.push({ ...doc.data(), id: doc.id });
    });
    setListedSpacesCO(spacesArray);
  };

  const getLeads = async (locLink) => {
    if (!locLink) {
      return;
    } else {
      const leadsRef = collection(db, "leads");
      const q = query(leadsRef, where("leadParams", "==", locLink));
      const querySnapshot = await getDocs(q);
      let leadsArray = [];
      querySnapshot.forEach((doc) => {
        leadsArray.push(doc.data());
      });
      setNotifications(leadsArray);
    }
  };

  useEffect(() => {
    console.log("User changed");
    getSpaceLocation(user?.link);
    getAllRenters(user?.link);
    getLeads(user?.link);
  }, [user, updated]);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (currentUser) => {
      if (currentUser) {
        setIsLoggedOut(false);
        onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
          setUser({
            ...doc.data(),
            emailVerified:
              currentUser.emailVerified || doc.data().emailVerified,
          });
          getAllSpaces(currentUser.uid);
          setUserID(currentUser.uid);
        });

        console.log("It ran again");
      } else {
        setIsLoggedOut(true);
        getListedSpaces();
      }
    });
    return () => {
      unsubscribe();
    };
  }, []);

  return (
    <UserContext.Provider
      value={{
        isLoggedOut,
        user,
        spaces,
        pricingModules,
        setPricingModules,
        listedSpacesCO,
        isLoading,
        setIsLoading,
        notifications,
        bookings,
        isRenter,
        isProvider,
        userID,
        spaceViews,
        locationInfo,
        closed,
        setClosed,
        updated,
        setUpdated,
        renters,
        setRenters,
      }}
    >
      {children}
    </UserContext.Provider>
  );
}

export const UserAuth = () => {
  return useContext(UserContext);
};
