import { Fragment, useEffect, useState } from 'react'
import {
    Dialog,
  Menu,
  Transition,
} from '@headlessui/react'
import {
    Bell,
    Cog,
    MenuIcon,
    Search,
    X,
  } from "lucide-react";
  import {
    HomeIcon,
    CalendarCheckIcon,
    PageIcon,
    PaymentIcon,
    EmailIcon,
  } from "@shopify/polaris-icons";
import { Link, useLocation } from 'react-router-dom';
import { UserAuth } from '../context/AuthContext';
import icon from "../assets/logos/darkicon.png";
import LogoSVG from "../assets/logos/LogoSVG";
import { logout } from '../functions/auth';



const navigationInit = [
  { name: 'Dashboard', href: '/', icon: HomeIcon, current: true },
  { name: 'Inbox', href: '/messages', icon: EmailIcon, current: false },
  { name: 'Bookings', href: '/bookings', icon: CalendarCheckIcon, current: false },
  { name: 'Documents', href: '/documents', icon: PageIcon, current: false },
  { name: 'Payments', href: '/payments', icon: PaymentIcon, current: false },
]
// const teams = [
//   { id: 1, name: 'Heroicons', href: '#', initial: 'H', current: false },
//   { id: 2, name: 'Tailwind Labs', href: '#', initial: 'T', current: false },
//   { id: 3, name: 'Workcation', href: '#', initial: 'W', current: false },
// ]
// const userNavigation = [
//   { name: 'Your profile', href: '#' },
//   { name: 'Sign out', href: '#' },
// ]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function RenterDashboard({children, messages}) {
    const { user } = UserAuth();
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [navigation, setNavigation] = useState(navigationInit);

  const handleLogout = () => {
    logout();
  };

  const path = useLocation().pathname;

  useEffect(() => {
    setNavigation(
      navigation.map((item) => {
        if (item.href === path) {
          return { ...item, current: true };
        } else {
          return { ...item, current: false };
        }
      })
    );   
  }, [path]);

  return (
    <>
      {/*
        This example requires updating your template:

        ```
        <html className="h-full bg-white">
        <body className="h-full">
        ```
      */}
      <div>
        <Dialog open={sidebarOpen} onClose={setSidebarOpen} className="relative z-50 lg:hidden">
          <Dialog
            transition
            className="fixed inset-0 bg-gray-900/80 transition-opacity duration-300 ease-linear data-[closed]:opacity-0"
          />

          <div className="fixed inset-0 flex">
            <Dialog.Panel
              transition
              className="relative mr-16 flex w-full max-w-xs flex-1 transform transition duration-300 ease-in-out data-[closed]:-translate-x-full"
            >
              <Transition.Child>
                <div className="absolute left-full top-0 flex w-16 justify-center pt-5 duration-300 ease-in-out data-[closed]:opacity-0">
                  <button type="button" onClick={() => setSidebarOpen(false)} className="-m-2.5 p-2.5">
                    <span className="sr-only">Close sidebar</span>
                    <X aria-hidden="true" className="h-6 w-6 text-white" />
                  </button>
                </div>
              </Transition.Child>
              {/* Sidebar component, swap this element with another sidebar if you like */}
              <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                <div className="flex h-16 shrink-0 items-center">
                  <img
                    alt="Compete Share"
                    src={icon}
                    className="h-8 w-auto"
                  />
                </div>
                <nav className="flex flex-1 flex-col">
                  <ul role="list" className="flex flex-1 flex-col gap-y-7">
                    <li>
                      <ul role="list" className="-mx-2 space-y-1">
                        {navigation.map((item) => (
                          <li key={item.name}>
                            <Link
                              to={item.href}
                              className={classNames(
                                item.current
                                  ? 'bg-gray-50 text-emerald-600'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-emerald-600',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <item.icon
                                aria-hidden="true"
                                className={classNames(
                                  item.current ? 'text-emerald-600' : 'text-gray-400 group-hover:text-emerald-600',
                                  'h-6 w-6 shrink-0',
                                )}
                              />
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    </li>
                    {/* <li>
                      <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                      <ul role="list" className="-mx-2 mt-2 space-y-1">
                        {teams.map((team) => (
                          <li key={team.name}>
                            <a
                              href={team.href}
                              className={classNames(
                                team.current
                                  ? 'bg-gray-50 text-emerald-600'
                                  : 'text-gray-700 hover:bg-gray-50 hover:text-emerald-600',
                                'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                              )}
                            >
                              <span
                                className={classNames(
                                  team.current
                                    ? 'border-emerald-600 text-emerald-600'
                                    : 'border-gray-200 text-gray-400 group-hover:border-emerald-600 group-hover:text-emerald-600',
                                  'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium',
                                )}
                              >
                                {team.initial}
                              </span>
                              <span className="truncate">{team.name}</span>
                            </a>
                          </li>
                        ))}
                      </ul>
                    </li> */}
                    <li className="mt-auto">
                      <a
                        href="#"
                        className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                      >
                        <Cog
                          aria-hidden="true"
                          className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                        />
                        Settings
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </Dialog.Panel>
          </div>
        </Dialog>

        {/* Static sidebar for desktop */}
        <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-56 lg:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
            <div className="flex h-16 shrink-0 items-center">
            <LogoSVG />
            </div>
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul role="list" className="-mx-2 space-y-1">
                    {navigation.map((item) => (
                      <li key={item.name}>
                        <Link
                          to={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-50 text-emerald-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-emerald-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <item.icon
                            aria-hidden="true"
                            className={classNames(
                              item.current ? 'text-emerald-600' : 'text-gray-400 group-hover:text-emerald-600',
                              'h-6 w-6 shrink-0',
                            )}
                          />
                          {item.name}
                        </Link>
                      </li>
                    ))}
                  </ul>
                </li>
                {/* <li>
                  <div className="text-xs font-semibold leading-6 text-gray-400">Your teams</div>
                  <ul role="list" className="-mx-2 mt-2 space-y-1">
                    {teams.map((team) => (
                      <li key={team.name}>
                        <a
                          href={team.href}
                          className={classNames(
                            team.current
                              ? 'bg-gray-50 text-emerald-600'
                              : 'text-gray-700 hover:bg-gray-50 hover:text-emerald-600',
                            'group flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6',
                          )}
                        >
                          <span
                            className={classNames(
                              team.current
                                ? 'border-emerald-600 text-emerald-600'
                                : 'border-gray-200 text-gray-400 group-hover:border-emerald-600 group-hover:text-emerald-600',
                              'flex h-6 w-6 shrink-0 items-center justify-center rounded-lg border bg-white text-[0.625rem] font-medium',
                            )}
                          >
                            {team.initial}
                          </span>
                          <span className="truncate">{team.name}</span>
                        </a>
                      </li>
                    ))}
                  </ul>
                </li> */}
                <li className="mt-auto">
                  <a
                    href="#"
                    className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-gray-700 hover:bg-gray-50 hover:text-emerald-600"
                  >
                    <Cog
                      aria-hidden="true"
                      className="h-6 w-6 shrink-0 text-gray-400 group-hover:text-emerald-600"
                    />
                    Settings
                  </a>
                </li>
              </ul>
            </nav>
          </div>
        </div>

        <div className="lg:pl-56">
          <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
            <button type="button" onClick={() => setSidebarOpen(true)} className="-m-2.5 p-2.5 text-gray-700 lg:hidden">
              <span className="sr-only">Open sidebar</span>
              <MenuIcon aria-hidden="true" className="h-6 w-6" />
            </button>

            {/* Separator */}
            <div aria-hidden="true" className="h-6 w-px bg-gray-200 lg:hidden" />

            <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
              <form action="#" method="GET" className="relative flex flex-1">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <Search
                  aria-hidden="true"
                  className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                />
                <input
                  id="search-field"
                  name="search"
                  type="search"
                  placeholder="Search..."
                  className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                />
              </form>
              <div className="flex items-center gap-x-4 lg:gap-x-6">
              <Menu as="div" className="relative -m-2">
                  <Menu.Button
                    type="button"
                    className=" p-2.5 text-gray-950 hover:text-zinc-950 bg-zinc-100 rounded-full hover:bg-zinc-200 hover:border hover:border-emerald-300"
                  >
                    <span className="sr-only">View notifications</span>
                    <span className="relative ">
                      <Bell className="h-5 w-5" aria-hidden="true" />
                      {messages?.length > 0 && (
                        <span className="absolute right-0 top-0 block h-3 w-3 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-green-500 ring-2 ring-white animate-pulse" />
                      )}
                    </span>
                  </Menu.Button>
                  <Menu.Items
                    transition
                    className="absolute right-0 z-10 mt-2 sm:w-96 lg:w-[450px] w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
                  >
                    <div className="divide-y divide-gray-300">
                      {messages?.length > 0 ? (
                        messages?.map((message) => (
                          <Menu.Item key={message.id}>
                            {({ active }) => (
                              <div className="pointer-events-auto flex w-full max-w-md divide-x divide-gray-200 bg-white ring-1 ring-black ring-opacity-5 transition data-[closed]:data-[enter]:translate-y-2 data-[enter]:transform data-[closed]:opacity-0 data-[enter]:duration-300 data-[leave]:duration-100 data-[enter]:ease-out data-[leave]:ease-in data-[closed]:data-[enter]:sm:translate-x-2 data-[closed]:data-[enter]:sm:translate-y-0">
                                <div className="flex w-0 flex-1 items-center p-4">
                                  <div className="w-full">
                                    <p className="text-sm font-medium text-gray-900">
                                      {message?.name}{" "}
                                      <span>
                                        {message?.type === 1
                                          ? "Form Submission"
                                          : message?.type === 3
                                          ? "Requested Quote"
                                          : message?.type === 2
                                          ? "Sent Offer"
                                          : "General"}
                                      </span>
                                    </p>
                                    <p className="mt-1 text-sm text-gray-500 line-clamp-2">
                                      {message?.message}
                                    </p>
                                  </div>
                                </div>
                                <div className="flex">
                                  <div className="flex flex-col divide-y divide-gray-200">
                                    <div className="flex h-0 flex-1">
                                      <button
                                        type="button"
                                        className="flex w-full items-center justify-center rounded-none rounded-tr-lg border border-transparent px-4 py-3 text-sm font-medium text-emerald-600 hover:text-emerald-500 focus:z-10 focus:outline-none focus:ring-2 focus:ring-emerald-500"
                                      >
                                        View
                                      </button>
                                    </div>
                                    <div className="flex h-0 flex-1">
                                      <button
                                        type="button"
                                        className="flex w-full items-center justify-center rounded-none rounded-br-lg border border-transparent px-4 py-3 text-sm font-medium text-gray-700 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-red-500"
                                      >
                                        Ignore
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            )}
                          </Menu.Item>
                        ))
                      ) : (
                        <Menu.Item>
                          {({ active }) => (
                            <Link
                              to="/messages"
                              className={classNames(
                                active ? "bg-gray-100" : "",
                                "block px-4 py-2 text-sm text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900 text-center"
                              )}
                            >
                              No new messages
                            </Link>
                          )}
                        </Menu.Item>
                      )}
                    </div>
                  </Menu.Items>
                </Menu>

                {/* Separator */}
                <div aria-hidden="true" className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200" />

                {/* Profile dropdown */}
                <Menu as="div" className="relative">
                  <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                    <img
                      className="h-8 w-8 rounded-full bg-gray-50"
                      src={user?.profilePic || icon}
                      alt=""
                    />
                    <div className="hidden lg:flex lg:items-center ml-2">
                      <div className="text-left">
                        <div
                          className="text-sm font-bold tracking-tight text-gray-950"
                          aria-hidden="true"
                        >
                          {user?.firstName} {user?.lastName}
                        </div>
                        <div className="text-xs font-semibold text-gray-500 -mt-1">
                          {user?.email}
                        </div>
                      </div>
                    </div>
                  </Menu.Button>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                      <Menu.Item>
                        {({ active }) => (
                          <Link
                            to="/settings"
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left"
                            )}
                          >
                            Settings
                          </Link>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={handleLogout}
                            className={classNames(
                              active ? "bg-gray-50" : "",
                              "block w-full px-3 py-1 text-sm leading-6 text-gray-900 text-left"
                            )}
                          >
                            Sign out
                          </button>
                        )}
                      </Menu.Item>
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className={`${!path.includes("/messages") && "py-10"}`}>
            <div
              className={`${
                !path.includes("/messages") && "px-4 sm:px-6 lg:px-8"
              }`}
            >{children}</div>
          </main>
        </div>
      </div>
    </>
  )
}
