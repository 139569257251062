import { Link } from "react-router-dom";
import icon from "../assets/logos/icon.png";
import { CgGoogle } from "react-icons/cg";
import { signIn, signInWithGoogle } from "../functions/auth";
import { UserAuth } from "../context/AuthContext";
import { useState } from "react";

export default function Login() {
  const { setIsLoading } = UserAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    signIn(email, password).then((res) => {
      if (res.status === "success") {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        console.log(res.message);
      }
    });
  };

  const handleGoogleSignIn = async () => {
    setIsLoading(true);
    const res = await signInWithGoogle();
    if (res.status === "success") {
      setIsLoading(false);
    } else {
      setIsLoading(false);
      alert(res.status);
    }
  };

  return (
    <>
      <div className="mt-14">
        <div className="h-full">
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img src={icon} alt="logo" className="mx-auto h-12 w-auto" />
              <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-400">
                Login to your account
              </h2>
            </div>

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md h-12 border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-500 sm:text-sm sm:leading-6"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <div className="flex items-center justify-between">
                    <label
                      htmlFor="password"
                      className="block text-sm font-medium leading-6 text-white"
                    >
                      Password
                    </label>
                    <div className="text-sm">
                      <Link
                        to="/forgot-password"
                        className="font-semibold text-emerald-400 hover:text-emerald-300"
                      >
                        Forgot password?
                      </Link>
                    </div>
                  </div>
                  <div className="mt-2">
                    <input
                      id="password"
                      name="password"
                      type="password"
                      autoComplete="current-password"
                      required
                      className="block w-full rounded-md border-0 h-12 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-500 sm:text-sm sm:leading-6"
                      onChange={(e) => setPassword(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-emerald-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500 h-12"
                    onClick={handleLogin}
                  >
                    Sign in
                  </button>
                </div>
              </form>

              <div className="mt-6">
                <div className="relative">
                  <div className="absolute inset-0 flex items-center">
                    <div className="w-full border-t border-gray-300" />
                  </div>
                  <div className="relative flex justify-center text-sm">
                    <span className="px-2 bg-zinc-900 text-gray-100">
                      Or continue with
                    </span>
                  </div>
                </div>
              </div>
              <div className="mt-4">
                <button
                  type="submit"
                  className="flex w-full items-center justify-center rounded-md bg-blue-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500 h-12"
                  onClick={handleGoogleSignIn}
                >
                  <CgGoogle className="h-6 w-6 mr-1" />
                  Google
                </button>
              </div>

              <p className="mt-10 text-center text-sm text-zinc-400">
                Not a member?{" "}
                <Link
                  to="/create-account"
                  className="font-semibold leading-6 text-emerald-400 hover:text-emerald-300"
                >
                  Create an account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
