import { useState } from "react";
import { createNewLead, generateRandomID } from "../../functions/crud";
import {
  addChatMessage,
  createLead,
  sendFormNotification,
} from "../../functions/realtime";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function QuoteCard({ locationLink, spaceID, userID }) {
  const [tab, setTab] = useState(0);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [startDate, setStartDate] = useState("");
  const [emailSent, setEmailSent] = useState(false);

  const navigate = useNavigate();

  const handleSendEmail = () => {
    if (!email) {
      alert("Please enter your email address");
      return;
    } else if (!name) {
      alert("Please enter your full name");
      return;
    } else {
      const id = generateRandomID();       
      const leadInfo = {
        name,
        email,
        phone,
        message,
        viewed: false,
        status: 0,
        id: id,
        profilePic: "",
        chatID: 0,
        hasAccount: false,
        replied: false,
        isRenter: false,
        type: tab,
        leadParams: locationLink,
        sender: "user",
        space: spaceID,
        startDate,
        createdAt: new Date().toISOString(),
        userID
      };
      console.log(leadInfo);
      createNewLead(leadInfo);
      setEmailSent(true);
      navigate(`/thank-you/${id}`);
    }
  };

  return (
    <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow max-w-xl mx-auto">
      <div className="isolate flex divide-x divide-gray-200 rounded-lg shadow">
        <button
          className={classNames(
            tab === 0 ? "text-gray-900 bg-emerald-50" : "text-gray-500 hover:text-gray-700 bg-white ",
            tab === 0 ? "rounded-t-lg" : "",
            "group relative min-w-0 flex-1 overflow-hidden  px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
          )}
          onClick={() => setTab(0)}
        >
          Contact Host
          <span
            aria-hidden="true"
            className={classNames(
              tab === 0 ? "bg-emerald-500" : "bg-transparent",
              "absolute inset-x-0 bottom-0 h-0.5"
            )}
          />
        </button>
        <button
          className={classNames(
            tab === 1 ? "text-gray-900 bg-emerald-50" : "text-gray-500 hover:text-gray-700 bg-white",
            tab === 1 ? "rounded-t-lg" : "",
            "group relative min-w-0 flex-1 overflow-hidden  px-4 py-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10"
          )}
          onClick={() => setTab(1)}
        >
          Request A Quote
          <span
            aria-hidden="true"
            className={classNames(
              tab === 1 ? "bg-emerald-500" : "bg-transparent",
              "absolute inset-x-0 bottom-0 h-0.5"
            )}
          />
        </button>
      </div>
      <div className="px-4 py-5 sm:p-6">
        {tab === 0 ? (
          <div>
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                placeholder="Enter your full name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                placeholder="Enter your phone number"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block mt-4 text-sm font-medium text-gray-700"
              >
                Message
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                placeholder="Enter your message"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        ) : (
          <div>
            <div>
              <label
                htmlFor="date"
                className="block text-sm font-medium text-gray-700"
              >
                What date are you looking to start?
              </label>
              <input
                type="date"
                name="date"
                id="date"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                onChange={(e) => setStartDate(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="email"
                name="email"
                id="email"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                placeholder="Enter your email address"
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="mt-4">
              <label
                htmlFor="name"
                className="block text-sm font-medium text-gray-700"
              >
                Full Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                placeholder="Enter your full name"
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="mt-4">
              <label
                htmlFor="phone"
                className="block text-sm font-medium text-gray-700"
              >
                Phone Number
              </label>
              <input
                type="tel"
                name="phone"
                id="phone"
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                placeholder="Enter your phone number"
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>
            <div>
              <label
                htmlFor="message"
                className="block mt-4 text-sm font-medium text-gray-700"
              >
                In as much detail as possible, please describe how long you need
                the space for and what you will be using it for.
              </label>
              <textarea
                id="message"
                name="message"
                rows={4}
                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                placeholder="Enter your message"
                onChange={(e) => setMessage(e.target.value)}
              />
            </div>
          </div>
        )}
      </div>
      <div className="px-4 py-4 sm:px-6">
        <button
          type="submit"
          className="w-full inline-flex justify-center rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
            onClick={handleSendEmail}
        >
          {tab === 0 ? "Send Message" : "Request Quote"}
        </button>
      </div>
    </div>
  );
}
