import { CheckIcon } from "lucide-react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { handleCreateCustomer } from "../../functions/stripe";
import { useEffect } from "react";
import { updateUser } from "../../functions/crud";

const tiers = [
  {
    name: "Starter",
    id: "tier-freelancer",
    href: "/pricing/starter",
    priceMonthly: "$19",
    description: "For single space owners just getting started.",
    features: [
      "1 space",
      "1 location",
      "Lead Manager",
      "Basic analytics",
      "48-hour support response time",
      "6.2% per booking",
    ],
    mostPopular: false,
  },
  {
    name: "Studio",
    id: "tier-startup",
    href: "/pricing/studio",
    priceMonthly: "$29",
    description: "For businesses with one or more space at a single location.",
    features: [
      "5 spaces",
      "1 location",
      "Lead Manager",
      "Basic analytics",
      "24-hour support response time",
      "5.2% per booking",
      "Marketing automations",
      "Custom branding",
    ],
    mostPopular: true,
  },
  {
    name: "Enterprise",
    id: "tier-enterprise",
    href: "/pricing/enterprise",
    priceMonthly: "$59",
    description: "For large businesses with multiple spaces and location.",
    features: [
      "Unlimited spaces",
      "Unlimited locations",
      "Lead Manager",
      "Advanced analytics",
      "12-hour support response time",
      "3.6% per booking",
      "Marketing automations",
      "Custom branding",
      "Dedicated account manager",
      "API access",
    ],
    mostPopular: false,
  },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function PickAPlan() {
  const { user } = UserAuth();

  const handleCheckAndCreateCustomer = async () => {
    if (!user?.stripeCustomerID) {
      console.log("Creating customer...");
      try {
        const name = `${user.firstName} ${user.lastName}`;
        const res = await handleCreateCustomer(user.email, name);
        await updateUser(user.id, { stripeCustomerID: res.customer });
      } catch (error) {
        console.log(
          "Failed to create customer, proceeding without customer data:",
          error
        );
      }
    }
  };

  useEffect(() => {
    handleCheckAndCreateCustomer();
  }, []);

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-4xl text-center">
          <h2 className="text-base font-semibold leading-7 text-emerald-600">
            Pricing
          </h2>
          <p className="mt-2 text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
            Pick a plan that works for you
          </p>
        </div>
        <p className="mx-auto mt-2 max-w-2xl text-center text-lg leading-8 text-gray-600">
          Cancel anytime. No contracts. No hidden fees.
        </p>
        <div className="isolate mx-auto mt-10 grid max-w-md grid-cols-1 gap-y-8 gap-x-4 lg:mx-0 lg:max-w-none lg:grid-cols-3">
          {tiers.map((tier, tierIdx) => (
            <div
              key={tier.id}
              className={classNames(
                tier.mostPopular ? "lg:z-10 " : "lg:mt-8",
                tierIdx === 0 ? "" : "",
                tierIdx === tiers.length - 1 ? "" : "",
                "flex flex-col justify-between rounded-3xl bg-white p-8 ring-1 ring-gray-200 xl:p-10 relative"
              )}
            >
              <div className="absolute top-0 text-center right-0 left-0 bg-emerald-300 rounded-t-3xl font-semibold tracking-tight py-1">
                $1 for your first 7 Days
              </div>
              <div>
                <div className="flex items-center justify-between gap-x-4 mt-4">
                  <h3
                    id={tier.id}
                    className={classNames(
                      tier.mostPopular ? "text-emerald-600" : "text-gray-900",
                      "text-lg font-semibold leading-8"
                    )}
                  >
                    {tier.name}
                  </h3>
                  {tier.mostPopular ? (
                    <p className="rounded-full bg-emerald-600/10 px-2.5 py-1 text-xs font-semibold leading-5 text-emerald-600">
                      Most popular
                    </p>
                  ) : null}
                </div>
                <p className="text-sm leading-6 text-gray-600">
                  {tier.description}
                </p>
                <p className="mt-6 flex items-baseline gap-x-1">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    {tier.priceMonthly}
                  </span>
                  <span className="text-sm font-semibold leading-6 text-gray-600">
                    /month
                  </span>
                </p>
                <ul
                  role="list"
                  className="mt-8 space-y-3 text-sm leading-6 text-gray-600"
                >
                  {tier.features.map((feature) => (
                    <li key={feature} className="flex gap-x-3">
                      <CheckIcon
                        aria-hidden="true"
                        className="h-6 w-5 flex-none text-zinc-950"
                      />
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
              <Link
                to={tier.href}
                aria-describedby={tier.id}
                className={classNames(
                  tier.mostPopular
                    ? "bg-emerald-600 text-white shadow-sm hover:bg-emerald-500"
                    : "text-emerald-600 ring-1 ring-inset ring-indigo-200 hover:ring-indigo-300",
                  "mt-8 block rounded-md px-3 py-2 text-center text-sm font-semibold leading-6 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                )}
              >
                Start $1 trial
              </Link>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
