import {
  ArrowLeft,
  ArrowRight,
  CheckCircle2,
  InfoIcon,
  Minus,
  Plus,
} from "lucide-react";
import { useEffect, useState } from "react";

import useEmblaCarousel from "embla-carousel-react";
import EmblaCarousel from "./EmblaCarousel";
import { Disclosure } from "@headlessui/react";

const OPTIONS = { loop: true };

export default function AboutSpace({ space, id }) {
  const [showFullDescription, setShowFullDescription] = useState(false);

  const [emblaRef, emblaApi] = useEmblaCarousel({ loop: true });

  const handlePrevious = () => {
    emblaApi?.scrollPrev();
  };
  const handleNext = () => {
    emblaApi?.scrollNext();
  };

  const daysOfWeek = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  const handleScrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = element.offsetTop - 30; // Subtract 10 pixels for the margin
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  useEffect(() => {
    if (id) {
      handleScrollToSection(id);
    }
  }, [id]);

  return (
    <div className="bg-white pb-96 mt-12">
      <div
        className="mx-auto max-w-7xl text-base leading-7 text-zinc-700"
        id="about"
      >
        <div id="photos"></div>
        <div className="mt-8">
          <EmblaCarousel slides={space?.photos} options={OPTIONS} />
        </div>
        <h2 className="text-2xl mt-12 font-bold tracking-tight text-zinc-900">
          About
        </h2>
        <div>
          <p
            className={`mt-6 leading-8 ${
              showFullDescription ? "" : "line-clamp-6"
            }`}
            dangerouslySetInnerHTML={{ __html: space?.description }}
          ></p>
          <div className="flex">
          <button
            onClick={toggleDescription}
            className="text-blue-500 hover:text-blue-800 ml-auto font-semibold"
          >
            {showFullDescription ? "Read less" : "Read more"}
          </button>
          </div>
        </div>

        <div className="mt-8" id="amenities">
          <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
            Amenities
          </h2>

          <div className=" mt-4 text-gray-500">
            <div className="grid sm:grid-cols-4 grid-cols-2 gap-y-2 text-lg font-semibold">
              {" "}
              {space?.amenities?.map((amenity) => (
                <div key={amenity} className="flex items-center gap-x-1">
                  <CheckCircle2 className="h-5 w-5 text-emerald-500 shrink-0" />
                  {amenity}
                </div>
              ))}
            </div>
          </div>
        </div>
        <div id="availability"></div>
        <div className="mt-8">
          <h2 className="text-2xl font-bold tracking-tight text-zinc-900 mb-4">
            Availability
          </h2>
          <div className="p-4 border rounded-lg bg-zinc-100 flex items-center justify-between w-full">
            <div>
          {space?.availability?.map((day, index) => (
            <>
              {day?.available && (
                <div key={index}>
                  <div className="flex items-center justify-between bg-white py-2 px-3 border my-1 rounded">
                    <h3 className="text-lg font-bold text-zinc-900 capitalize">
                      {day?.day}
                    </h3>
                    <div className="ml-4">
                      {day?.timeSlots?.length > 0 ? (
                        day?.timeSlots?.map((slot, index) => (
                          <div key={index} className="flex items-center gap-x-2">
                            <p className="text-emerald-500">
                              {slot?.startTime} - {slot?.endTime}
                            </p>
                          </div>
                        ))
                      ) : (
                        <p className="text-emerald-500">All Day</p>
                      )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ))}
          </div>
          <button className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600">
            Request Quote
          </button>
          </div>
        </div>
        {/* <div id="reviews"></div>
        <div className="mt-8">
          <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
            Reviews{" "}
            <span className="font-normal">({space?.reviews?.length || 0})</span>
          </h2>
          <div className="mt-4" id="licenses">
            {space?.reviews?.map((review, index) => (
              <div key={index} className="mt-4">
                <div className="flex items-center">
                  <img
                    src={review?.user?.avatar}
                    alt={review?.user?.name}
                    className="h-10 w-10 rounded-full"
                  />
                  <div className="ml-4">
                    <h3 className="text-lg font-bold text-zinc-900">
                      {review?.user?.name}
                    </h3>
                    <p className="text-gray-500">
                      {review?.date} - {review?.rating} stars
                    </p>
                  </div>
                </div>
                <p className="mt-4">{review?.review}</p>
              </div>
            ))}
          </div>
          {(space?.reviews?.length === 0 || !space?.reviews) && (
            <p className="mt-4 text-gray-500">No reviews yet</p>
          )}
        </div> */}

        <div className="mt-8" id="licenses">
          <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
            Licenses & Certifications
          </h2>
        </div>

        <div className="mt-4">
          {space?.licenses?.map((license, index) => (
            <div key={index} className="mt-4">
              <div className="w-full">
                <div className="p-4 border rounded-lg bg-zinc-100 w-full">
                  <h3 className="text-lg font-bold text-zinc-900">{license}</h3>
                  <p className="text-gray-500">{license?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
        {space?.licenses?.length === 0 || !space?.licenses ? (
          <p className="mt-4 text-gray-500">
            No licenses or certifications posted
          </p>
        ) : (
          <p className="mt-4 text-gray-500 flex items-center gap-x-2">
            <InfoIcon className="h-5 w-5 " />
            Providers report their own licenses. Please ask them for more
            details if required.
          </p>
        )}
        <div id="qs"></div>
        <div className="mt-8" id="licenses">
          <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
            Q&As
          </h2>
          <dl className="space-y-6 divide-y divide-gray-900/10">
            {space?.faqs?.map((faq) => (
              <Disclosure as="div" key={faq.question} className="pt-6">
                {({ open }) => (
                  <div className="p-4 border rounded-lg bg-zinc-100 w-full">
                    <dt>
                      <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                        <span className="text-base font-semibold leading-7">
                          {faq.question}
                        </span>
                        <span className="ml-6 flex h-7 items-center">
                          {open ? (
                            <Minus className="h-6 w-6" aria-hidden="true" />
                          ) : (
                            <Plus className="h-6 w-6" aria-hidden="true" />
                          )}
                        </span>
                      </Disclosure.Button>
                    </dt>
                    <Disclosure.Panel as="dd" className="mt-2 pr-12">
                      <p className="text-base leading-7 text-gray-600">
                        {faq.answer}
                      </p>
                    </Disclosure.Panel>
                  </div>
                )}
              </Disclosure>
            ))}
          </dl>
          {(space?.faqs?.length === 0 || !space?.faqs) && (
            <p className="mt-4 text-gray-500">No licenses or FAQS posted</p>
          )}
        </div>
      </div>
    </div>
  );
}
