import { Link } from "react-router-dom";
import rentersImg from "../../assets/images/renters.svg";
import { UserAuth } from "../../context/AuthContext";
import toast, { Toaster } from "react-hot-toast";
import DeleteRenter from "../components/DeleteRenter";
import Tippy from "@tippyjs/react";
import { ShieldBanIcon, ShieldCheck } from "lucide-react";
import SendMessage from "../components/SendMessage";

export default function Renters() {
  const { renters, spaces } = UserAuth();

  const status = [
    {
      id: 1,
      name: "No Contract",
      colors: "bg-red-50 text-red-700 ring-red-600/20",
    },
    {
      id: 2,
      name: "Waiting Approval",
      colors: "bg-yellow-50 text-yellow-700  ring-yellow-600/20",
    },
    {
      id: 3,
      name: "Pending Contract",
      colors: "bg-blue-50 text-blue-700 ring-blue-600/20",
    },
    {
      id: 4,
      name: "Active",
      colors: "bg-green-50 text-green-700 ring-green-600/20",
    },
    {
      id: 5,
      name: "Inactive",
      colors: "bg-gray-50 text-gray-700 ring-gray-600/20",
    },
  ];


  //modal confirmation
  const handleDeleteRenter = (id) => {
    console.log("Deleting renter with id: ", id);
    toast.success("Renter deleted successfully");
  };



 
  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between">
        <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
          Renters
        </h1>
        <div className="mt-4">
          <Link
            to="/add-renter"
            type="button"
            className="rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
          >
            Add Renter
          </Link>
        </div>
      </div>
      {renters.length === 0 ? (
        <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
          <div className="px-4 py-5 sm:p-6">
            <div className="flex items-center justify-center py-24">
              <div className="text-center">
                <img
                  src={rentersImg}
                  alt="Renters"
                  className="w-1/2 h-1/2 mx-auto"
                />
                <h3 className="text-lg font-bold text-zinc-800">
                  Everything renter-related in one place
                </h3>
                <p className="text-sm font-normal text-gray-500 ml-2">
                  Manage your renters, view their payment history, and more.
                </p>
                <div className="mt-4">
                  <Link
                    to="/add-renter"
                    type="button"
                    className="rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  >
                    Add Renter
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
          <div className="">
            <div className="overflow-x-auto">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Contact
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Space
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Status
                    </th>
                    <th scope="col" className="relative px-6 py-3">
                      <span className="sr-only">Edit</span>
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {renters.map((renter) => (
                    <tr key={renter.id}>
                      <td className="px-6 py-4 whitespace-nowrap flex items-center gap-1">
                      {renter?.hasAccount ? (
                        <Tippy content="Has Account">
                          <ShieldCheck className="text-green-500" />
                        </Tippy>
                      ) : (
                        <Tippy content="No Account">
                          <ShieldBanIcon className="text-red-500" />
                        </Tippy>
                      )}
                        <div className="text-sm font-medium text-gray-900">
                          {renter.firstName || renter?.name} {renter.lastName}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900 font-semibold">
                          {renter.email}
                        </div>
                        <div className="text-sm text-gray-900">
                          {renter.phone}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div className="text-sm text-gray-900">
                          {
                            spaces?.find((space) => space.id === renter.spaceID)
                              ?.title
                          }
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap">
                        <div
                          className={`inline-flex items-center rounded-md ${
                            status[renter.status || 0]?.colors
                          } px-2 py-1 text-xs font-medium ring-1 ring-inset`}
                        >
                          {status[renter.status || 0]?.name}
                        </div>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium" >
                      <DeleteRenter renter={renter} />
                        <Link
                          to={`/renter/${renter.id}`}
                          className="text-emerald-600 hover:text-emerald-900 ml-2"
                        >
                          Edit
                        </Link>
                        
                        <SendMessage renter={renter} />
                      </td>
                      
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
