import React, { useEffect, useRef, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { getBookings, updateBooking } from "../../functions/crud";
import { useNavigate, useParams } from "react-router-dom";
import ReactSignatureCanvas from "react-signature-canvas";
import Loading from "../../components/Loading";
import toast, { Toaster } from "react-hot-toast";

export default function LicenseAgreement() {
  const { user } = UserAuth();

  const [loading, setLoading] = useState(false);

  const [whoIS, setWhoIS] = useState("provider");
  const [alreadySigned, setAlreadySigned] = useState(false);

  const [contract, setContract] = useState({});
  const [signature, setSignature] = useState({});
  const [sigSaved, setSigSaved] = useState(false);
  const [openProviderSig, setOpenProviderSig] = useState(false);
  const [tenantSigns, setTenantSigns] = useState(false);
  const [rentSigSaved, setRentSigSaved] = useState(false);
  const [providerSignatureTyped, setProviderSignatureTyped] = useState("");
  const [renterSignatureTyped, setRenterSignatureTyped] = useState("");

  const [providerSigned, setProviderSigned] = useState(false);
  const [renterSigned, setRenterSigned] = useState(false);

  const todaysDayNumber = new Date().getDate();
  const todaysYear = new Date().getFullYear();
  const todaysMonthName = new Date().toLocaleString("default", {
    month: "long",
  });
  const todaysMonth = todaysMonthName.charAt(0).toUpperCase() + todaysMonthName.slice(1);

  const navigate = useNavigate();

  useEffect(() => {
    if (contract?.provider?.email === user?.email) {
      setWhoIS("provider");
      if (contract?.providerSigned) {
        navigate("/documents");
      }
    } else {
      setWhoIS("tenant");
      if (contract?.renterSigned) {
        navigate("/documents");
      }
    }
  }, [contract, user]);

  const signPad = useRef(null);
  const handleClear = () => {
    setSignature({});
    signPad.current.clear();
  };

  const handleProviderSigns = () => {
    setOpenProviderSig(false);
    setSigSaved(true);
    setSignature(signPad.current.getTrimmedCanvas().toDataURL());
    setProviderSigned(true);
  };

  const handleTenantSigns = () => {
    setTenantSigns(false);
    setRentSigSaved(true);
    setSignature(signPad.current.getTrimmedCanvas().toDataURL());
    setRenterSigned(true);
  };

  /// set day value based on the number of the day, like st th nd rd
  function nth(d) {
    if (d > 3 && d < 21) return "th";
    switch (d % 10) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  }

  const { id } = useParams();

  useEffect(() => {
    getBookings(id).then((data) => {
      setContract(data);
    });
  }, [id]);

  function addDays(date, days) {
    const result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }

  function formatDate(date) {
    const bookedDate = new Date(date);
    const newDate = addDays(bookedDate, 1); // Adding 1 day to the original date
    return newDate.toLocaleDateString("en-US");
  }

  const [ip, setIp] = useState("");
  const [timestamp, setTimestamp] = useState("");
  const [checkbox, setCheckbox] = useState(false);

  const handleGetIP = async () => {
    const response = await fetch("https://api.ipify.org/?format=json");
    const data = await response.json();
    const timestamp = new Date();
    setIp(data.ip);
    setTimestamp(timestamp);
    setCheckbox(!checkbox);
    console.log(data.ip);
  };

  const handleSubmitSignature = () => {
    setLoading(true);
    updateBooking(id, {
      signature: signature,
      providerSigned: providerSigned,
      renterSigned: renterSigned,
      providerSignatureTyped: providerSignatureTyped,
      renterSignatureTyped: renterSignatureTyped,
      providerIP: whoIS === "provider" ? ip : "",
      renterIP: whoIS === "tenant" ? ip : "",
    }, user?.id)
      .then(() => {
        setLoading(false);
        setSigSaved(true);
        toast.success("Signature Saved");
        navigate("/documents");
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div>
      <Toaster />
      {loading ? (
        <div className="z-50">
          <div className="flex justify-center items-center h-screen w-full ">
            <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-zinc-900"></div>
          </div>
        </div>
      ) : (
        <div className="overflow-hidden bg-white px-6 py-16 lg:px-8 font-serif ">
          <div className="mx-auto max-w-max lg:max-w-7xl">
            <div className="relative z-10 mb-8 md:mb-2 md:px-6">
              <div className="max-w-prose text-base lg:max-w-none">
                <h2 className="font-semibold leading-6 text-indigo-600">
                  License Agreement
                </h2>
                <p className="mt-2 text-3xl font-bold leading-8 tracking-tight text-zinc-900 sm:text-4xl">
                  REAL ESTATE LICENSE AGREEMENT
                </p>
              </div>
            </div>
            <div className="relative">
              <div className="grid grid-cols-12 gap-4">
                <div className="col-span-6 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold">Licensor</h3>
                  <p>
                    Licensor Name:{" "}
                    <span className="font-bold">
                      {contract?.tenant?.firstName} {contract?.tenant?.lastName}{" "}
                    </span>
                  </p>
                  <p>
                    Address:{" "}
                    <span className="font-bold capitalize">
                      {contract?.tenant?.address}
                    </span>
                  </p>
                  <p>
                    Phone Number:{" "}
                    <span className="font-bold">{contract?.tenant?.phone}</span>
                  </p>
                  <p>
                    Email:{" "}
                    <span className="font-bold">{contract?.tenant?.email}</span>
                  </p>
                </div>
                <div className="col-span-6 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold">Licensee</h3>
                  <p>
                    Licensee Name:{" "}
                    <span className="font-bold">
                      {contract?.provider?.firstName}{" "}
                      {contract?.provider?.lastName}{" "}
                    </span>
                  </p>
                  <p>
                    Address:{" "}
                    <span className="font-bold">
                      {contract?.provider?.address} {contract?.provider?.city}{" "}
                      {contract?.provider?.state} {contract?.provider?.zip}
                    </span>
                  </p>
                  <p>
                    Phone Number:{" "}
                    <span className="font-bold">
                      {contract?.provider?.phone}
                    </span>
                  </p>
                  <p>
                    Email:{" "}
                    <span className="font-bold">
                      {contract?.provider?.email}
                    </span>
                  </p>
                </div>
                <div className="col-span-12 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    Space Description
                  </h3>
                  <p className="mt-4">{contract?.space?.description}</p>
                </div>
                <div className="col-span-12 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    Access Terms
                  </h3>
                  <p className="mt-4">{contract?.space?.accessTerms}</p>
                </div>
                <div className="col-span-6 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    License Start Date
                  </h3>
                  <p className="mt-4">
                    {formatDate(new Date(contract?.license?.startDate), 1)}
                  </p>
                </div>
                <div className="col-span-6 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    License End Date
                  </h3>
                  <p className="mt-4">
                    {formatDate(new Date(contract?.license?.endDate), 1)}
                  </p>
                </div>
                <div className="col-span-12 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    License Terms
                  </h3>
                  <p className="mt-4">Length </p>
                  <p className="font-bold">
                    {contract?.license?.totalMonths > 1
                      ? contract?.license?.totalMonths?.toFixed(2) + " months"
                      : contract?.license?.totalMonths?.toFixed(1) + " month"}
                  </p>
                  <p className="mt-4">Days and Times: </p>
                  {contract.license?.monday && (
                    <div className="font-bold">
                      Mon:{" "}
                      <span className="font-normal">
                        {contract.license?.mondayStart} -{" "}
                        {contract.license?.mondayEnd}
                      </span>
                    </div>
                  )}
                  {contract.license?.tuesday && (
                    <div className="font-bold">
                      Tue:{" "}
                      <span className="font-normal">
                        {contract.license?.tuesdayStart} -{" "}
                        {contract.license?.tuesdayEnd}
                      </span>
                    </div>
                  )}
                  {contract.license?.wednesday && (
                    <div className="font-bold">
                      Wed:{" "}
                      <span className="font-normal">
                        {contract.license?.wednesdayStart} -{" "}
                        {contract.license?.wednesdayEnd}
                      </span>
                    </div>
                  )}
                  {contract.license?.thursday && (
                    <div className="font-bold">
                      Thu:{" "}
                      <span className="font-normal">
                        {contract.license?.thursdayStart} -{" "}
                        {contract.license?.thursdayEnd}
                      </span>
                    </div>
                  )}
                  {contract.license?.friday && (
                    <div className="font-bold">
                      Fri:{" "}
                      <span className="font-normal">
                        {contract.license?.fridayStart} -{" "}
                        {contract.license?.fridayEnd}
                      </span>
                    </div>
                  )}
                  {contract.license?.saturday && (
                    <div className="font-bold">
                      Sat:{" "}
                      <span className="font-normal">
                        {contract.license?.saturdayStart} -{" "}
                        {contract.license?.saturdayEnd}
                      </span>
                    </div>
                  )}
                  {contract.license?.sunday && (
                    <div className="font-bold">
                      Sun:{" "}
                      <span className="font-normal">
                        {contract.license?.sundayStart} -{" "}
                        {contract.license?.sundayEnd}
                      </span>
                    </div>
                  )}
                </div>

                <div className="col-span-6 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    Deposits
                  </h3>
                  <p className="mt-4">
                    Security Deposit:{" "}
                    <span className="font-bold">
                      ${contract.license?.securityDeposit}
                    </span>
                  </p>
                </div>
                <div className="col-span-6 p-4 text-zinc-900">
                  <h3 className="text-2xl font-semibold bg-zinc-200 px-2">
                    Fees
                  </h3>
                  <p className="mt-4">
                    License Fee Per Month:{" "}
                    <span className="font-bold">
                      ${parseInt(contract.license?.monthlyRent)?.toFixed(2)}
                    </span>
                  </p>
                  <p>
                    Compete Share Renter Fee:{" "}
                    <span className="font-bold mr-1">
                      $
                      {(
                        parseInt(contract.license?.monthlyRent) * 0.03
                      )?.toFixed(2)}
                    </span>
                    (Renter Pays)
                  </p>
                  <p>
                    Compete Share Provider Fee:{" "}
                    <span className="font-bold mr-1">
                      $
                      {(
                        parseInt(contract.license?.monthlyRent) * 0.03 +
                        0.3
                      )?.toFixed(2)}
                    </span>
                    (Provider Pays)
                  </p>
                </div>

                <div className="col-span-12 p-4 text-zinc-900">
                  <p>
                    Location Address{" "}
                    <span className="font-bold underline capitalize">
                      {contract?.provider?.address} {contract?.provider?.city}{" "}
                      {contract?.provider?.state} {contract?.provider?.zip}
                    </span>
                  </p>
                </div>

                <div className="relative col-span-12 mt-12 ">
                  <div
                    className="absolute inset-0 flex items-center"
                    aria-hidden="true"
                  >
                    <div className="w-full border-t border-zinc-300" />
                  </div>
                  <div className="relative flex justify-center">
                    <span className="bg-white px-2 text-sm text-zinc-900">
                      Continue
                    </span>
                  </div>
                </div>
                <div className="col-span-12 text-zinc-900 mt-12 ">
                  <h1 className="text-2xl font-semibold text-center">
                    LICENSE TERMS AND CONDITIONS
                  </h1>
                </div>
                <div className="col-span-12 text-zinc-900 mt-12 leading-2 text-left">
                  <p className="font-bold">WITNESSETH:</p>
                  <p className="mt-4 ml-4">
                    {" "}
                    WHEREAS, the parties desire, by this License Agreement, to
                    provide for the licensing by Licensor to Licensee of the
                    right to use and occupy certain spaces as set forth annexed
                    hereto and made a part hereof (each space to be referred to
                    herein as a &quot;License Area&quot; and collectively the
                    &quot;License Areas&quot;), and NOW, THEREFORE, in
                    consideration of the mutual agreements herein contained and
                    other good and valuable consideration, the receipt and
                    sufficiency of which are hereby acknowledged, the parties
                    hereby covenant and agree as follows:
                  </p>
                  <h4 className="text-xl font-semibold underline mt-12">
                    1. License
                  </h4>
                  <p className="mt-4 ml-4">
                    <span className="font-bold">a. </span>. Licensor hereby
                    grants to Licensee a license (the &quot;License&quot;) to
                    use and occupy the License Areas and rights of access
                    thereto for the purposes hereinafter provided as of the
                    &quot;Commencement Date&quot; (as such term is defined
                    hereafter), for the applicable &quot;License Period&quot;
                    (as such term is defined in paragraph 2 hereof). In
                    connection with its use of each License Area and to the
                    extent applicable, Licensee shall also have the
                    non-exclusive right to use (a) in common with Licensor and
                    the other occupants of the building in which the Premises
                    are located, the common areas outside the Premises that
                    Licensor has the right to use that may be reasonably
                    necessary for Licensee’s use of the Premises, except those
                    areas that Licensor may reasonably designate as private.
                  </p>
                  <p className="mt-4 ml-4">
                    <span className="font-bold">b. </span>
                    Licensee has inspected and is familiar with the License
                    Areas and accepts same and the contents thereof in their
                    &quot;as is&quot; condition as of the Commencement Date.
                    Licensor shall not be required to perform any work or
                    furnish any materials in order to prepare the License Areas
                    for Licensee’s use.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    2. License Period
                  </h4>
                  <p className="mt-4 ml-4">
                    <span className="font-bold">a. </span>
                    The License shall commence on the &quot;Commencement
                    Date&quot; as defined in this Agreement and shall continue
                    for a period commencing on{" "}
                    <span className="font-bold">
                      {formatDate(new Date(contract?.license?.startDate), 1)}{" "}
                    </span>
                    and ending on{" "}
                    <span className="font-bold">
                      {formatDate(new Date(contract?.license?.endDate), 1)}{" "}
                    </span>
                    (the &quot;Initial License Period&quot;).
                  </p>

                  <p className="mt-4 ml-4">
                    <span className="font-bold">b.</span> Upon expiration of the
                    Initial License Period, the License may be renewed for
                    successive periods (each, a &quot;Renewal Period&quot;) upon
                    mutual written agreement of both parties, unless terminated
                    earlier in accordance with the terms herein.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> The Licensor reserves
                    the right to terminate this License Agreement at any time by
                    providing written notice to the Licensee. Upon receipt of
                    such notice, the Licensee shall cease all use of the License
                    Areas and vacate the Premises.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    3. Licensing Fees
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> In consideration for
                    the License granted herein, Licensee shall pay to Licensor a
                    licensing fee of{" "}
                    <span className="font-bold">
                      ${contract?.license?.monthlyRent}{" "}
                    </span>{" "}
                    per month (the &quot;License Fee&quot;). The License Fee
                    shall be payable in advance on the{" "}
                    <span className="font-bold">
                      {new Date(contract?.license?.startDate)?.getDate()}
                      {nth(
                        new Date(contract?.license?.startDate)?.getDate()
                      )}{" "}
                    </span>
                    of each month throughout the Initial License Period and any
                    Renewal Periods.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> Upon renewal of the
                    License for each Renewal Period, the License Fee may be
                    subject to adjustment as agreed upon by both parties in
                    writing.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> Licensee shall also
                    provide a refundable deposit of{" "}
                    <span className="font-bold">
                      ${contract?.license?.securityDeposit}{" "}
                    </span>{" "}
                    (the &quot;Deposit&quot;) to secure performance of its
                    obligations under this Agreement. The Deposit shall be paid
                    on or before the Commencement Date and shall be held by
                    Licensor as security for any damages to the Premises or
                    other breaches of this Agreement by Licensee. The Deposit
                    shall be returned to following the termination of this
                    Agreement, less any deductions for damages or unpaid fees.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">d. </span> Notwithstanding
                    anything to the contrary, the Deposit is non-refundable in
                    the event of early termination of this Agreement by Licensee
                    or due to any breach of this Agreement by Licensee.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">e. </span>Any License Fees not
                    paid by Licensee by the due date specified in this Agreement
                    shall be considered late. Licensee shall pay a late fee
                    equal to 10% of the monthly License Fee.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">f. </span>Licensee shall be
                    responsible for any taxes, fees, or other charges imposed by
                    governmental authorities in connection with the License
                    granted herein.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">g. </span>Licensor reserves the
                    right to review and adjust the License Fees periodically
                    with reasonable notice to Licensee.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    4. Allowed Uses of the Space
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a. </span> Licensee shall use
                    the licensed space (the &quot;Space&quot;) solely for the
                    operation of{" "}
                    <span className="font-bold">
                      {contract?.space?.intendedUse}{" "}
                    </span>{" "}
                    and related activities as agreed upon by both parties.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b. </span> Licensee shall not
                    use the Space for any unlawful purpose or in any manner that
                    may cause nuisance, disturbance, or inconvenience to other
                    occupants of the building or neighboring properties.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c. </span> Licensee shall comply
                    with all applicable laws, regulations, and zoning ordinances
                    in the use of the Space.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">d. </span> Licensee shall not
                    make any alterations, modifications, or additions to the
                    Space without the prior written consent of Licensor.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">e. </span> Licensee shall be
                    responsible for maintaining the cleanliness and orderliness
                    of the Space and shall return the Space to Licensor in the
                    same condition as received, reasonable wear and tear
                    excepted.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">f. </span> Licensor reserves the
                    right to inspect the Space periodically to ensure compliance
                    with the terms of this Agreement.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">g. </span> Licensee shall not
                    sub-license, assign, or otherwise transfer its rights under
                    this Agreement without the prior written consent of
                    Licensor.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">h. </span> Any unauthorized use
                    of the Space may result in immediate termination of this
                    Agreement and revocation of the License by Licensor.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">i. </span> Licensee shall
                    indemnify and hold harmless Licensor from and against any
                    claims, damages, liabilities, costs, and expenses arising
                    out of or related to Licensee&#39;s use of the Space.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    5. Insurance
                  </h4>
                  {contract?.insurance?.responsibleForInsurance ===
                  "landlord" ? (
                    <>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">a.</span> During the term of
                        this Agreement, Licensor shall maintain comprehensive
                        general liability insurance covering the License Areas
                        and common areas of the premises, with coverage limits
                        of not less than{" "}
                        <span className="font-bold">
                          ${contract?.insurance?.coverageLimitsPerOccurrence}
                        </span>{" "}
                        per occurrence and{" "}
                        <span className="font-bold">
                          ${contract?.insurance?.coverageLimitsAggregate}
                        </span>{" "}
                        in the aggregate.
                      </p>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">b.</span> Licensor shall
                        also maintain property insurance covering the building
                        and improvements thereon, including but not limited to
                        fire, theft, vandalism, and other perils, with coverage
                        limits sufficient to cover the replacement cost of the
                        premises.
                      </p>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">c.</span> Licensee shall be
                        named as an additional insured on all insurance policies
                        maintained by Licensor, and Licensor shall provide
                        Licensee with certificates of insurance evidencing such
                        coverage upon request.
                      </p>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">d.</span> Licensor shall
                        provide notice to Licensee in the event of any material
                        changes or cancellations to the insurance coverage
                        described herein.
                      </p>
                    </>
                  ) : (
                    <>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">a.</span> Throughout the
                        term of this Agreement, Licensee shall obtain and
                        maintain comprehensive general liability insurance with
                        coverage limits of not less than{" "}
                        <span className="font-bold">
                          ${contract?.insurance?.coverageLimitsPerOccurrence}
                        </span>{" "}
                        per occurrence and{" "}
                        <span className="font-bold">
                          ${contract?.insurance?.coverageLimitsAggregate}
                        </span>{" "}
                        in the aggregate, covering its activities on the
                        premises.
                      </p>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">b.</span> Licensee shall
                        also maintain property insurance covering its personal
                        property, equipment, and improvements made to the
                        premises by Licensee, with coverage limits sufficient to
                        cover the replacement cost of such items.
                      </p>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">c.</span> Licensee shall
                        provide Licensor with certificates of insurance
                        evidencing the coverage required herein prior to the
                        commencement of the License Period and upon renewal of
                        insurance policies.
                      </p>
                      <p className="ml-4 mt-4">
                        <span className="font-bold">d.</span> Licensee shall
                        name Licensor as an additional insured on its liability
                        insurance policy and shall provide Licensor with notice
                        of any material changes or cancellations to the
                        insurance coverage.
                      </p>
                    </>
                  )}
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    6. Services
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> Licensee acknowledges
                    that in some locations of the License Areas, third-party
                    landlords provide services to such locations. Licensor shall
                    reasonably cooperate with Licensee to facilitate
                    Licensee&#39;s access to such services. However, Licensor is
                    not obligated to initiate any action or proceeding against a
                    landlord.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> To the extent that
                    services to a License Area are directly supplied by
                    Licensor, Licensor shall continue to provide such services
                    during the related License Period. Licensee shall be
                    responsible for its pro rata share of Licensor’s
                    out-of-pocket costs associated with these services.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> Licensor shall ensure
                    that services provided to the License Areas are of
                    substantially the same manner and quality as provided prior
                    to the Commencement Date or as provided by Licensor to
                    itself.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">d.</span> Licensor grants
                    Licensee the right to receive all services and benefits
                    provided by the related landlord under the Leases. However,
                    in the event of default or failure of such performance by
                    any landlord, Licensor, upon specific written request from
                    Licensee, will demand such landlord(s) to fulfill their
                    obligations under the Lease.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    7. Indemnification:
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> Licensee shall
                    indemnify, defend, and hold harmless Licensor from and
                    against any and all claims, damages, liabilities, costs, and
                    expenses, including but not limited to reasonable
                    attorney&#39;s fees, arising out of or related to:
                  </p>
                  <p className="ml-8 mt-4">
                    <span className="font-bold">i.</span> Any breach of this
                    License Agreement by Licensee;
                  </p>
                  <p className="ml-8 mt-4">
                    <span className="font-bold">ii.</span> Any negligence or
                    willful misconduct of Licensee, its employees, agents, or
                    contractors;
                  </p>
                  <p className="ml-8 mt-4">
                    <span className="font-bold">iii.</span> Any injury to
                    persons or property occurring on or about the License Areas
                    caused by Licensee, its employees, agents, or contractors;
                  </p>
                  <p className="ml-8 mt-4">
                    <span className="font-bold">iv.</span> Any violation of
                    laws, regulations, or ordinances by Licensee in connection
                    with its use of the License Areas.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> Licensor shall
                    promptly notify Licensee in writing of any claim subject to
                    indemnification hereunder and shall provide Licensee with
                    reasonable cooperation and assistance in the defense of such
                    claim. Licensee shall have the right to control the defense
                    and settlement of any such claim, provided that Licensee
                    shall not settle any claim without the prior written consent
                    of Licensor, which shall not be unreasonably withheld.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> This indemnification
                    obligation shall survive the termination or expiration of
                    this License Agreement.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    8. Termination
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> The Licensor reserves
                    the exclusive right to terminate this License Agreement at
                    any time by providing written notice to the Licensee. Upon
                    receipt of such notice, the Licensee shall cease all use of
                    the License Areas and vacate the Premises within{" "}
                    <span className="font-bold">3</span> days of the date of
                    termination.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> The Licensor may
                    terminate this License Agreement immediately and without
                    prior notice in the event of any breach of this Agreement by
                    the Licensee, including but not limited to non- payment of
                    License Fees, violation of permitted use, or failure to
                    comply with applicable laws and regulations.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> The Licensor may
                    terminate this License Agreement upon the occurrence of any
                    event or circumstance that, in the sole discretion of the
                    Licensor, materially affects the Licensor&#39;s ability to
                    continue providing the License Areas to the Licensee.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">d.</span> The Licensor may
                    terminate this License Agreement for any reason or no
                    reason, at its sole discretion, upon providing written
                    notice to the Licensee.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">e.</span> Upon termination of
                    this License Agreement by the Licensor, whether for cause or
                    not, the Licensee shall promptly return all keys, access
                    cards, or other means of access to the License Areas to the
                    Licensor.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">f.</span> Termination of this
                    License Agreement shall not relieve either party from any
                    obligations accrued prior to the date of termination,
                    including but not limited to the payment of any outstanding
                    License Fees or damages incurred by either party.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    9. Notices
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> Any notices, requests,
                    demands, or other communications required or permitted to be
                    given under this Agreement shall be in writing and shall be
                    deemed to have been duly given if delivered through the
                    Compete Share software platform.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> Either party may
                    change its address or email address for notice purposes by
                    giving written notice thereof to the other party in
                    accordance with this section.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> Notwithstanding the
                    above, the parties agree that all communications regarding
                    this Agreement, including but not limited to notices,
                    requests, demands, or other communications, shall be
                    conducted exclusively through the Compete Share software
                    platform. Any communication sent through Compete Share shall
                    be deemed to satisfy the requirements of this section.
                  </p>
                </div>

                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    10. Subletting and Assignment
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> Licensee shall not
                    sublet, license, share, or assign all or any part of the
                    licensed premises or any rights under this Agreement without
                    the prior written consent of Licensor.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> Any proposed
                    subletting, licensing, sharing, or assignment by Licensee
                    shall be subject to the sole discretion of Licensor, and
                    Licensor may withhold consent for any reason or no reason,
                    in its sole discretion.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> If Licensor consents
                    to a subletting, licensing, sharing, or assignment by
                    Licensee, Licensee shall remain fully responsible for
                    compliance with all terms and conditions of this Agreement,
                    and any sublessee, licensee, or assignee approved by
                    Licensor shall be bound by the terms of this Agreement.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">d.</span> Licensor may condition
                    its consent to any subletting, licensing, sharing, or
                    assignment by Licensee upon such terms and conditions as
                    Licensor deems appropriate, including but not limited to
                    requiring the sublessee, licensee, or assignee to execute a
                    separate agreement with Licensor.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">e.</span> Any subletting,
                    licensing, sharing, or assignment undertaken by Licensee
                    without the prior written consent of Licensor shall be void
                    and shall constitute a material breach of this Agreement,
                    entitling Licensor to exercise all rights and remedies
                    available under this Agreement and at law.
                  </p>
                </div>

                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    11. Governing Law:
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> This License Agreement
                    shall be governed by and construed in accordance with the
                    laws of [State where facility is located], without giving
                    effect to any choice of law or conflict of law principles.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> Any disputes arising
                    under or related to this License Agreement shall be subject
                    to the exclusive jurisdiction of the courts located in
                    [State where facility is located].
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> The parties hereby
                    waive any objection to the jurisdiction of such courts on
                    grounds of venue or inconvenient forum.
                  </p>
                </div>
                <div className="col-span-12 text-zinc-900 mt-6 leading-2 text-left">
                  <h4 className="text-xl font-semibold underline">
                    12. Entire Agreement:
                  </h4>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">a.</span> This License Agreement
                    constitutes the entire understanding and agreement between
                    the parties with respect to the subject matter hereof and
                    supersedes all prior agreements and understandings, whether
                    written or oral, relating to such subject matter.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">b.</span> No modification,
                    amendment, or waiver of any provision of this License
                    Agreement shall be effective unless in writing and signed by
                    both parties.
                  </p>
                  <p className="ml-4 mt-4">
                    <span className="font-bold">c.</span> In the event of any
                    conflict or inconsistency between this License Agreement and
                    any other document or agreement referenced herein, the terms
                    and conditions of this License Agreement shall prevail.
                  </p>
                </div>
              </div>
              <div className="grid gird-cols-2 gap-2">
                <div className="col-span-2 p-4 text-zinc-900">
                  <p>
                    This REAL ESTATE LICENSE AGREEMENT (this &quot;License
                    Agreement&quot;) is made as of the _
                    <span className="underline">
                      {todaysDayNumber}
                      {nth(todaysDayNumber)}
                    </span>
                    _ day of _<span className="underline">{todaysMonth}</span>_,{" "}
                    {todaysYear}, between (&quot;Licensor&quot;),{" "}
                    {contract?.provider?.firstName}{" "}
                    {contract?.provider?.lastName} and{" "}
                    {contract?.tenant?.firstName} {contract?.tenant?.lastName}{" "}
                    (&quot;Licensee&quot;).
                  </p>
                </div>
                <div className=" p-4 text-zinc-900">
                  <p>
                    By:{" "}
                    <span className="font-bold">
                      {contract?.provider?.email}
                    </span>
                  </p>
                  <p>
                    Name:{" "}
                    <span className="font-bold">
                      {contract?.provider?.firstName}{" "}
                      {contract?.provider?.lastName}
                    </span>
                  </p>
                  <p>
                    Title: <span className="font-bold">Licensor</span>
                  </p>
                  <p>
                    Date:{" "}
                    <span className="font-bold">
                      {new Date().toLocaleDateString("en-US")}
                    </span>
                  </p>
                  {openProviderSig ? (
                    <>
                      <div className="border-2">
                        <ReactSignatureCanvas
                          canvasProps={{
                            width: 500,
                            height: 200,
                            className: "sigCanvas",
                          }}
                          ref={signPad}
                        />
                      </div>
                      <p className="text-center">Sign Here</p>
                      <div className="text-right">
                        <button
                          className="ml-auto text-xs border px-3 py-1 hover:bg-zinc-200"
                          onClick={handleClear}
                        >
                          Clear
                        </button>
                        <button
                          className="text-xs border px-3 py-1 hover:bg-zinc-200"
                          onClick={handleProviderSigns}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : sigSaved ? (
                    <>
                      <img alt="signature" src={signature} />
                      <div className="flex items-center border-l-4 border-red-400 mt-8">
                        <input
                          type="text"
                          placeholder="Type your name"
                          className="border-2 w-1/2 ml-4"
                          onChange={(e) =>
                            setProviderSignatureTyped(e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <button
                      className={`${
                        user?.email !== contract?.provider?.email
                          ? "bg-zinc-300"
                          : "bg-blue-500 hover:bg-blue-700"
                      } text-white font-bold py-2 px-4 rounded mt-4`}
                      disabled={user?.email !== contract?.provider?.email}
                      onClick={() => setOpenProviderSig(true)}
                    >
                      {user?.email === contract?.provider?.email
                        ? "Sign Now"
                        : "Provider's Signature"}
                    </button>
                  )}
                </div>
                <div className=" p-4 text-zinc-900">
                  <p>
                    By:{" "}
                    <span className="font-bold">{contract?.tenant?.email}</span>
                  </p>
                  <p>
                    Name:{" "}
                    <span className="font-bold">
                      {contract?.tenant?.firstName} {contract?.tenant?.lastName}
                    </span>
                  </p>
                  <p>
                    Title: <span className="font-bold">Licensee</span>
                  </p>
                  <p>
                    Date:{" "}
                    <span className="font-bold">
                      {new Date().toLocaleDateString("en-US")}
                    </span>
                  </p>
                  {tenantSigns ? (
                    <>
                      <div className="border-2">
                        <ReactSignatureCanvas
                          canvasProps={{
                            width: 500,
                            height: 200,
                            className: "sigCanvas",
                          }}
                          ref={signPad}
                        />
                      </div>
                      <p className="text-center">Sign Here</p>
                      <div className="text-right">
                        <button
                          className="ml-auto text-xs border px-3 py-1 hover:bg-zinc-200"
                          onClick={handleClear}
                        >
                          Clear
                        </button>
                        <button
                          className="text-xs border px-3 py-1 hover:bg-zinc-200"
                          onClick={handleTenantSigns}
                        >
                          Save
                        </button>
                      </div>
                    </>
                  ) : rentSigSaved ? (
                    <>
                      <img alt="signature" src={signature} />
                      <div className="flex items-center border-l-4 border-red-400 mt-8">
                        <input
                          type="text"
                          placeholder="Type your name"
                          className="border-2 w-1/2 ml-4"
                          onChange={(e) =>
                            setRenterSignatureTyped(e.target.value)
                          }
                        />
                      </div>
                    </>
                  ) : (
                    <button
                      className={`${
                        user?.email !== contract?.tenant?.email
                          ? "bg-zinc-300"
                          : "bg-blue-500 hover:bg-blue-700"
                      } text-white font-bold py-2 px-4 rounded mt-4`}
                      disabled={user?.email !== contract?.tenant?.email}
                      onClick={() => setTenantSigns(true)}
                    >
                      {user?.email !== contract?.tenant?.email
                        ? "Renters Signature"
                        : "Sign Now"}
                    </button>
                  )}
                </div>
              </div>
              <div className="flex items-center mt-8 border-l-4 border-red-400">
                <input
                  type="checkbox"
                  checked={checkbox}
                  className="form-checkbox h-4 w-4 text-indigo-600 transition duration-150 ease-in-out ml-4"
                  onChange={handleGetIP}
                />
                <label className="ml-2 block text-sm leading-5 text-zinc-900">
                  I hereby acknowledge that I have read and understood the above
                  conditions.
                </label>
              </div>
              <div className="flex items-center justify-center mt-8">
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
                  onClick={handleSubmitSignature}
                >
                  Sign and Save Lease Agreement
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
