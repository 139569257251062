import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { updateUser } from "../../functions/crud";

export default function StripeAuthPage() {
  const { account, uid } = useParams();

  const navigate = useNavigate();

  const handleUpdateAccount = async (accountID, id) => {
    updateUser(id, {
      stripeAccountID: accountID,
      stripeSetup: true,
    });
    navigate("/");
  };

  useEffect(() => {
    handleUpdateAccount(account, uid);
  }, [account, uid]);

  return <div>StripeAuthPage</div>;
}
