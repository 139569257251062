import React, { useState } from "react";
import { usePrevNextButtons } from "./EmblaCarouselArrowButtons";
import useEmblaCarousel from "embla-carousel-react";
import { ArrowLeft, ArrowRight } from "lucide-react";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const EmblaCarousel = (props) => {
  const { slides, options } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);
  const images = slides?.map((slide) => slide.url);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <>
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <section className="embla">
        <div className="flex items-center justify-between mb-4">
          <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
            Photos <span className="font-normal">({slides?.length})</span>
          </h2>
          <div>
            <button
              className="text-emerald-600 hover:text-emerald-500 p-3 border border-emerald-600 rounded-md mr-2"
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            >
              <ArrowLeft size={24} />
            </button>
            <button
              className="text-emerald-600 hover:text-emerald-500 p-3 border border-emerald-600 rounded-md hover:bg-emerald-50"
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            >
              <ArrowRight size={24} />
            </button>
          </div>
        </div>
        <div className="mt-4">
          <div className="embla__viewport" ref={emblaRef}>
            <div className="embla__container">
              {slides?.map((slide, index) => (
                <button 
                className="embla__slide" 
                key={index}
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(index);
                }}
                >
                  <div className="embla__slide__number">
                    <img
                      key={index}
                      src={slide?.url}
                      alt={`Space ${index}`}
                      className="w-full h-full object-center object-cover rounded-lg"
                    />
                  </div>
                </button>
              ))}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default EmblaCarousel;
