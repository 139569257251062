import React from "react";

export default function ActionItems() {
  return (
    <div className="overflow-hidden rounded-lg bg-indigo-500 shadow">
      <div className="px-4 py-5 mt-2 bg-white">
        <h3 className="text-lg font-semibold text-gray-900">Action Items</h3>
        <p className="mt-2 text-sm text-gray-600 text-center">
          You have no items that require your attention
        </p>
        
      </div>
    </div>
  );
}
