import { CheckCircle2, Edit, Share, ViewIcon, XCircle } from "lucide-react";
import { UserAuth } from "../../context/AuthContext";
import Contact from "../components/Contact";
import { useEffect, useState } from "react";
import { handleGetSpacesFromUids } from "../../functions/crud";
import EditSmartPage from "../components/EditSmartPage";
import TourRequest from "../../components/TourRequest";
import ContactForm from "../../pages/ContactForm";
import { Link } from "react-router-dom";

export default function CollectionProfile() {
  const { user, locationInfo, closed, setClosed } = UserAuth();
  const [spaces, setSpaces] = useState([]);
  const [emailSent, setEmailSent] = useState(false);
  const [viewLocationStyle, setViewLocationStyle] = useState({});
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (locationInfo) {
      setViewLocationStyle(locationInfo);
    }
  }, [locationInfo]);

  const handleGoGetSpaces = async () => {
    const res = await handleGetSpacesFromUids(viewLocationStyle?.spaces);
    setSpaces(res);
  };

  useEffect(() => {
    handleGoGetSpaces();
  }, [viewLocationStyle]);

  const handleScrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = element.offsetTop - 30; // Subtract 10 pixels for the margin
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  const handleSendEmail = () => {
    console.log("Send Email");
    setEmailSent(true);
  };

  const handleOpen = () => {
    setOpen(true);
    setClosed(true);
  };

  const handleClose = () => {
    setOpen(false);
    setClosed(false);
  };

  return (
    <>
      {user?.finishedSetup ? (
        <>
          <div className="md:flex md:items-center md:justify-between border-b-2 border-zinc-400 pb-4">
            <div className="min-w-0 flex-1 ">
              <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                Space Profile
              </h2>
              <p className="mt-1 text-sm text-gray-500">
                You can share, edit, and delete your space profile here.
              </p>
            </div>

            <div className="mt-4 flex md:ml-4 md:mt-0">
              <Link
                type="button"
                to={`/profile/${viewLocationStyle?.locationLink}`}
                target="_blank"
                className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "
              >
                <ViewIcon className="h-5 w-5 mr-2" aria-hidden="true" />
                View as Renter
              </Link>
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              >
                {" "}
                <Share className="h-5 w-5 mr-2" aria-hidden="true" />
                Share Profile
              </button>
              <button
                type="button"
                className="ml-3 inline-flex items-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm ring-1 ring-inset ring-indigo-300 hover:bg-indigo-800"
                onClick={(e) => handleOpen(e)}
              >
                {" "}
                <Edit className="h-5 w-5 mr-2" aria-hidden="true" />
                Edit Smart Page
              </button>
            </div>
          </div>
          <div className={`${open && "grid grid-cols-4"}`}>
            <div className={`${open && "col-span-3"}`}>
              <div className="overflow-hidden  py-12 relative isolate">
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 -top-40 -z-10 transform-gpu overflow-hidden blur-3xl sm:-top-80"
                >
                  <div
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className={`relative left-[calc(50%-11rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-emerald-200 to-${viewLocationStyle?.pageColor}-200 opacity-30 sm:left-[calc(50%-30rem)] sm:w-[72.1875rem]`}
                  />
                </div>
                <div className="mx-auto max-w-5xl">
                  <div className="text-center">
                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                      {viewLocationStyle?.pageTitle}
                    </h1>
                    <h4
                      className={`text-2xl font-semibold leading-7 text-${viewLocationStyle?.pageColor}-600 mt-4`}
                    >
                      {viewLocationStyle?.pageSubheading}
                    </h4>

                    <p className="mt-6 text-lg leading-8 text-gray-600">
                      {viewLocationStyle?.pageDescription}
                    </p>
                    <div className="mt-10 flex items-center justify-center gap-x-6">
                      <Contact
                        title="touch"
                        pageColor={viewLocationStyle?.pageColor}
                        locationLink={viewLocationStyle?.locationLink}
                        userID={locationInfo?.userID}
                      />
                      <button
                        className="text-sm font-semibold leading-6 text-gray-900"
                        onClick={() => handleScrollToSection("allspaces")}
                      >
                        View All Current Spaces{" "}
                        <span aria-hidden="true">→</span>
                      </button>
                    </div>
                  </div>
                </div>
                <div
                  aria-hidden="true"
                  className="absolute inset-x-0 top-[calc(100%-13rem)] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[calc(100%-30rem)]"
                >
                  <div
                    style={{
                      clipPath:
                        "polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)",
                    }}
                    className="relative left-[calc(50%+3rem)] aspect-[1155/678] w-[36.125rem] -translate-x-1/2 bg-gradient-to-tr from-[#ff80b5] to-[#9089fc] opacity-30 sm:left-[calc(50%+36rem)] sm:w-[72.1875rem]"
                  />
                </div>

                <div className="mx-auto max-w-7xl px-6 lg:px-8 pt-24 sm:pt-32">
                  <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
                    <div className="lg:ml-auto lg:pl-4 lg:pt-4">
                      <div className="lg:max-w-lg">
                        <h2
                          className={`text-base font-semibold leading-7 text-${viewLocationStyle?.secondaryColor}-600`}
                        >
                          {spaces[0]?.city}, {spaces[0]?.state}
                        </h2>
                        <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                          {spaces[0]?.title}
                        </p>
                        <p
                          className="mt-2 text-lg text-gray-600 line-clamp-6"
                          dangerouslySetInnerHTML={{
                            __html: spaces[0]?.description,
                          }}
                        ></p>
                        <div className="mt-10 flex items-center gap-x-6">
                          <Contact
                            title="quote"
                            pageColor={viewLocationStyle?.secondaryColor}
                            locationLink={viewLocationStyle?.locationLink}
                            userID={locationInfo?.userID}
                          />
                          <Link
                            to={`/space/${spaces[0]?.id}`}
                            className="text-sm font-semibold leading-6 text-gray-900"
                          >
                            View Space <span aria-hidden="true">→</span>
                          </Link>
                        </div>

                        <div className="mt-8" id="amenities">
                          <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
                            Amenities
                          </h2>

                          <div className=" mt-4 text-gray-500">
                            <div className="grid 2xl:grid-cols-3 grid-cols-2 gap-y-2 gap-4 text-lg font-semibold">
                              {" "}
                              {spaces[0]?.amenities?.map((amenity) => (
                                <div
                                  key={amenity}
                                  className="flex items-center gap-x-1"
                                >
                                  <CheckCircle2 className="h-5 w-5 text-green-500 shrink-0" />
                                  {amenity}
                                </div>
                              ))}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="flex items-start justify-end lg:order-first">
                      <img
                        alt="Product screenshot"
                        src={spaces[0]?.mainPhoto || spaces[0]?.photos[0]?.url}
                        width={2432}
                        height={1442}
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem]"
                      />
                    </div>
                  </div>
                  {/* {spaces[1] ? (
                    <div className="mt-24 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-start">
                      <div className="lg:pr-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                          <h2
                            className={`text-base font-semibold leading-7 text-${viewLocationStyle?.pageColor}-600`}
                          >
                            {spaces[1]?.city}, {spaces[1]?.state}
                          </h2>
                          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {spaces[1]?.title}
                          </p>
                          <p className="mt-6 text-lg leading-8 text-gray-600 truncate-description-2">
                            {spaces[1]?.description}
                          </p>
                          <div className="mt-8">
                            <a
                              href="#"
                              className={`inline-flex rounded-md bg-${viewLocationStyle?.pageColor}-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-${viewLocationStyle?.pageColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${viewLocationStyle?.pageColor}-600`}
                            >
                              Get a quote
                            </a>
                          </div>
                          <figure className="mt-16 border-l border-gray-200 pl-8 text-gray-600">
                            <blockquote className="text-base leading-7 ">
                              <p>
                                “Vel ultricies morbi odio facilisi ultrices
                                accumsan donec lacus purus. Lectus nibh
                                ullamcorper ac dictum justo in euismod. Risus
                                aenean ut elit massa. In amet aliquet eget cras.
                                Sem volutpat enim tristique.”
                              </p>
                            </blockquote>
                            <figcaption className="mt-6 flex gap-x-4 text-sm leading-6">
                              <img
                                alt=""
                                src="https://images.unsplash.com/photo-1509783236416-c9ad59bae472?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=1024&h=1024&q=80"
                                className="h-6 w-6 flex-none rounded-full"
                              />
                              <div>
                                <span className="font-semibold text-gray-900">
                                  Maria Hill
                                </span>{" "}
                                – Marketing Manager
                              </div>
                            </figcaption>
                          </figure>
                        </div>
                      </div>
                      <img
                        alt="Product screenshot"
                        src={spaces[1]?.mainPhoto || spaces[1]?.photos[0]?.url}
                        width={2432}
                        height={1442}
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
                      />
                    </div>
                  ) : (
                    <div className="mt-24 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center">
                      <div className="lg:pr-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                          <h2
                            className={`text-base font-semibold leading-7 text-${viewLocationStyle?.pageColor}-600`}
                          >
                            {viewLocationStyle?.extraTag}
                          </h2>
                          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {viewLocationStyle?.extraTitle}
                          </p>
                          <p className="mt-6 text-lg leading-8 text-gray-600 truncate-description-2">
                            {viewLocationStyle?.extraDescription}
                          </p>
                          <div className="mt-8">
                          <TourRequest secColor={locationInfo?.pageColor} locationLink={locationInfo?.locationLink} userID={locationInfo?.userID} />
                          </div>
                          <div className="mt-4">
                            <Contact
                              title="offer"
                              pageColor={viewLocationStyle?.pageColor}
                              locationLink={viewLocationStyle?.locationLink}
                              userID={locationInfo?.userID}
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        alt="Product screenshot"
                        src={spaces[0]?.photos[1]?.url}
                        width={2432}
                        height={1442}
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
                      />
                    </div>
                  )} */}

<div className="mt-24 mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2 lg:items-center">
                      <div className="lg:pr-4 lg:pt-4">
                        <div className="lg:max-w-lg">
                          <h2
                            className={`text-base font-semibold leading-7 text-${viewLocationStyle?.pageColor}-600`}
                          >
                            {viewLocationStyle?.extraTag}
                          </h2>
                          <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                            {viewLocationStyle?.extraTitle}
                          </p>
                          <p className="mt-6 text-lg leading-8 text-gray-600 truncate-description-2">
                            {viewLocationStyle?.extraDescription}
                          </p>
                          <div className="mt-8">
                          <TourRequest secColor={locationInfo?.pageColor} locationLink={locationInfo?.locationLink} userID={locationInfo?.userID} />
                          </div>
                          <div className="mt-4">
                            <Contact
                              title="offer"
                              pageColor={viewLocationStyle?.pageColor}
                              locationLink={viewLocationStyle?.locationLink}
                              userID={locationInfo?.userID}
                            />
                          </div>
                        </div>
                      </div>
                      <img
                        alt="Product screenshot"
                        src={spaces[0]?.photos[1]?.url}
                        width={2432}
                        height={1442}
                        className="w-[48rem] max-w-none rounded-xl shadow-xl ring-1 ring-gray-400/10 sm:w-[57rem] md:-ml-4 lg:ml-0"
                      />
                    </div>

                  <ContactForm
                    secColor={viewLocationStyle?.secondaryColor}
                    hexColor={viewLocationStyle?.hex}
                    link={viewLocationStyle?.locationLink}
                    userID={locationInfo?.userID}
                  />
                </div>
                <div className="max-w-full -mt-4">
                  <div className={`bg-${viewLocationStyle?.pageColor}-700`}>
                    <div className="px-6 py-24 sm:px-6 sm:py-32 lg:px-8">
                      <div className="mx-auto max-w-2xl text-center">
                        <h2 className="text-3xl font-bold tracking-tight text-white sm:text-4xl">
                          {viewLocationStyle?.ctaHeadline}
                        </h2>
                        <p
                          className={`mx-auto mt-6 max-w-xl text-lg leading-8 text-${viewLocationStyle?.pageColor}-200`}
                        >
                          {viewLocationStyle?.ctaDescription}
                        </p>
                        <div className="mt-10 flex items-center justify-center gap-x-6">
                        <Contact
                        title="quote"
                        pageColor={locationInfo?.secondaryColor}
                        locationLink={locationInfo?.locationLink}
                        userID={locationInfo?.userID}
                      />
                          <a
                            href="#"
                            className={`rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-${viewLocationStyle?.pageColor}-600 shadow-sm hover:bg-${viewLocationStyle?.pageColor}-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white`}
                          >
                            Request a Quote
                          </a>
                          <button
                            className="text-sm font-semibold leading-6 text-white"
                            onClick={() => handleScrollToSection("allspaces")}
                          >
                            View All Spaces <span aria-hidden="true">→</span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="mx-auto max-w-2xl px-4 py-16 sm:px-6 sm:py-24 lg:max-w-7xl lg:px-8"
                    id="allspaces"
                  >
                    <div className="flex items-center justify-between space-x-4">
                      <h2 className="text-lg font-medium text-gray-900">
                        All Shared Spaces Available{" "}
                        {viewLocationStyle?.name && (
                          <span
                            className={`text-${viewLocationStyle?.pageColor}-600`}
                          >
                            for {viewLocationStyle?.name} ({spaces?.length})
                          </span>
                        )}{" "}
                      </h2>
                    </div>

                    <div
                      className={`mt-6 grid lg:grid-cols-4 sm:grid-cols-2 grid-cols-1 gap-x-8 gap-y-8 sm:gap-y-10`}
                    >
                      {spaces?.map((space) => (
                        <div key={space.id} className="group relative">
                          <div className="aspect-h-3 aspect-w-4 overflow-hidden rounded-lg bg-gray-100">
                            <img
                              alt={space.title}
                              src={space.mainPhoto || space.photos[0]?.url}
                              className="object-cover object-center"
                            />
                            <div
                              aria-hidden="true"
                              className="flex items-end p-4 opacity-0 group-hover:opacity-100"
                            >
                              <div className="w-full rounded-md bg-white bg-opacity-75 px-4 py-2 text-center text-sm font-medium text-gray-900 backdrop-blur backdrop-filter">
                                View Space
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 flex items-center justify-between space-x-8 text-base font-medium text-gray-900">
                            <h3>
                              <a href="#">
                                <span
                                  aria-hidden="true"
                                  className="absolute inset-0"
                                />
                                {space.title}
                              </a>
                            </h3>
                            <p
                              className={`text-${viewLocationStyle?.pageColor}-500`}
                            >
                              Available
                            </p>
                          </div>
                          <p className="mt-1 text-sm text-gray-500">
                            {space.city} {space?.state}
                          </p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {open && (
              <div className="col-span-1">
                <EditSmartPage
                  viewLocationStyle={viewLocationStyle}
                  setViewLocationStyle={setViewLocationStyle}
                  setOpen={setOpen}
                />
              </div>
            )}
          </div>
        </>
      ) : (
        <div className="flex items-center justify-center h-96">
          <div className="text-center">
            <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:text-3xl sm:tracking-tight">
              You have not finished setting up your profile yet
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Please complete your profile to view your space profile page.
            </p>
            <Link to="/" className="mt-4 text-sm font-semibold text-emerald-600">
              Go to Profile
            </Link>
          </div>
        </div>
      )}
    </>
  );
}
