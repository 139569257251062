import { ArrowLeft, ArrowRight, Copy, VenetianMask, Wand } from "lucide-react";
import React from "react";
import toast, { Toaster } from "react-hot-toast";
import { useParams, Link } from "react-router-dom";
const site = process.env.REACT_APP_SITE_URL;

export default function ShareAndPromote() {
  const { id } = useParams();

  const handleCopy = () => {
    navigator.clipboard.writeText(`${site}space/${id}`);
    toast.success("Link Copied!");
  };

  return (
    <div className=" ">
      <Toaster position="top-center" reverseOrder={false} />
      <h1 className="sm:text-4xl  text-2xl font-bold text-gray-900 text-center pt-36 tracking-tight">
        Congrats on posting your space!
      </h1>
      <div className="flex items-center justify-center h-full mt-6  max-w-4xl mx-auto px-4">
        <div className="bg-white shadow rounded-lg w-auto border border-zinc-300">
          <div className="px-4 py-5 sm:p-6">
            <h3 className="text-lg  font-semibold leading-6 text-gray-900">
              Now it's time to broadcast your space!
            </h3>
            <div className="mt-2 max-w-xl text-sm text-gray-500">
              <p>
                You can now share your space and post this link on your social
                media, website, or anywhere else you want to promote your space.
              </p>
            </div>
            <div className="mt-5 sm:flex sm:items-center">
              <div className="w-full">
                <label htmlFor="email" className="sr-only">
                  link
                </label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="block w-full rounded-md bg-zinc-100 border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-500 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm h-12 sm:leading-6"
                  placeholder={`${site}space/${id}`}
                />
              </div>
              <button
                className="mt-3 inline-flex w-full items-center justify-center rounded-md bg-emerald-600 px-3 py-3 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 sm:ml-3 sm:mt-0 sm:w-auto whitespace-nowrap"
                onClick={handleCopy}
              >
                <Copy size={20} className="mr-2" />
                Copy
              </button>
            </div>
            <div className="flex items-center justify-between mt-4">
            <Link to={`/info/space/${id}`} className="text-lg mt-3 w-full font-semibold leading-6 text-indigo-600 hover:text-indigo-800 text-center flex items-center justify-center">
            <VenetianMask size={20} className="mr-2 " /> View as Renter
                
              </Link>
              <Link to={`/`} className="text-lg mt-3 w-full font-semibold leading-6 text-gray-900 text-center flex items-center justify-center">
                Go to dashboard
                <ArrowRight size={20} className="ml-2 hover:text-700" />
              </Link>
            </div>

            {/* Share Icons */}
            {/* <div className="flex items-center justify-center mt-5">
                <div className="flex items-center gap-x-4">
                  <a
                    href={`https://twitter.com/intent/tweet?text=Check out this space on CompeteShare!&url=https://competeshare.com/space/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/twitter--v2.png"
                      alt="twitter"
                    />
                  </a>
                  <a
                    href={`http://www.facebook.com/share.php?u=https://competeshare.com/space/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/facebook-new.png"
                      alt="facebook"
                    />
                  </a>
                  <a
                    href={`https://www.linkedin.com/sharing/share-offsite/?url=https://competeshare.com/space/${id}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img
                      src="https://img.icons8.com/color/48/000000/linkedin.png"
                      alt="linkedin"
                    />
                  </a>
                </div>
              </div> */}

            {/* <div className="relative mt-4">
              <div
                className="absolute inset-0 flex items-center"
                aria-hidden="true"
              >
                <div className="w-full border-t border-gray-300" />
              </div>
              <div className="relative flex justify-center"></div>
            </div> */}
            {/* <div className="w-full mt-5">
              <button
                className="mt-2 inline-flex w-full items-center justify-center rounded-md bg-zinc-950 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-zinc-800 focus-visible:outline focus-visible:outline-2 h-12 focus-visible:outline-offset-2 focus-visible:outline-zinc-950  whitespace-nowrap disabled:opacity-50 disabled:cursor-not-allowed disabled:bg-gray-600 disabled:border-gray-400 disabled:text-gray-400"
                disabled
                onClick={handleCopy}
              >
                {" "}
                <Wand size={20} className="mr-2" />
                Smart Promote
              </button>

              <button className="text-lg mt-3 w-full font-semibold leading-6 text-gray-900 text-center">
                Find out more about Smart Promote
              </button>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
}
