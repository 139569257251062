import React, { useState } from "react";
import Alert from "../../components/Alert";
import { finishSetUp, uploadImage } from "../../functions/crud";
import { UserAuth } from "../../context/AuthContext";
import { useNavigate } from "react-router-dom";
import profileIMG from "../../assets/images/profile.png";
import { handleCreateCustomer } from "../../functions/stripe";

export default function SetupAccount() {
  const { user, setIsLoading } = UserAuth();
  const [profilePicture, setProfilePicture] = useState(profileIMG);
  const [file, setFile] = useState(null);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [pictureUploaded, setPictureUploaded] = useState(false);
  const [alerter, setAlerter] = useState(false);

  const handleAddProfilePicture = (e) => {
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setFile(file);
    setProfilePicture(fileUrl);
    setPictureUploaded(true);
  };

  const navigate = useNavigate();

  const handleFinishSetup = async () => {
    setIsLoading(true);
    try {
      if (firstName === "" || lastName === "") {
        setIsLoading(false);
        setAlerter(true);
      } else if (pictureUploaded) {
        const name = `${firstName} ${lastName}`;
        let customer = null;
  
        try {
          customer = await handleCreateCustomer(user?.email, name);
        } catch (error) {
          console.log(
            "Failed to create customer, proceeding without customer data:", error
          );
        }
  
        const data = {
          firstName,
          lastName,
          phoneNumber,
          companyName,
          stripeCustomerID: customer?.customer || "",
        };
  
        await uploadImage(user?.id, data, file);
  
        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 3000);
  
      } else {
        const name = `${firstName} ${lastName}`;
        let customer = null;
  
        try {
          customer = await handleCreateCustomer(user?.email, name);
        } catch (error) {
          console.log(
            "Failed to create customer, proceeding without customer data:", error
          );
        }
  
        const res = await finishSetUp(user?.id, {
          firstName,
          lastName,
          phoneNumber,
          companyName,
          stripeCustomerID: customer?.customer || "",
        });
  
        if (res.status === "success") {
          console.log("Account setup successful");
        } else {
          alert(
            "An error occurred. Please try again. if this continues please contact support"
          );
        }
  
        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.log("An unexpected error occurred:", error);
      alert(
        "An error occurred. Please try again. if this continues please contact support"
      );
    }
  
    console.log("Finish Setup");
  };
  

  return (
    <div className="max-w-2xl mx-auto">
      {alerter && <Alert message="Please enter a first name and last name" />}
      <div className="overflow-hidden rounded-lg bg-white shadow">
        <div className="px-4 py-5 sm:p-6">
          <div>
            <h3 className="text-3xl font-bold leading-6 text-gray-900 text-center">
              Complete your Account Setup
            </h3>
            <p className="mt-2 text-lg text-gray-500 text-center">
              By Setting up your account, you can start hosting your space and
              building trust with your guests.
            </p>
            <div className="grid grid-cols-2 gap-x-4 gap-y-6 mt-5">
              <div>
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    className={`block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6 ${
                      alerter && !firstName ? "ring-2 ring-red-500" : ""
                    }`}
                    onChange={(e) => setFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div>
                <div className="relative">
                  <label
                    htmlFor="name"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    name="last-name"
                    id="last-name"
                    className={`block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6 ${
                      alerter && !lastName ? "ring-2 ring-red-500" : ""
                    }`}
                    onChange={(e) => setLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-span-2">
                <div className="relative">
                  <label
                    htmlFor="tel"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Phone Number
                  </label>
                  <input
                    type="text"
                    name="tel"
                    id="tel"
                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                  />
                </div>
                <p className="ml-1 text-sm text-gray-500">
                  We only use your phone number to send you important booking
                  updates.
                </p>
              </div>
              <div className="col-span-2">
                <div className="relative">
                  <label
                    htmlFor="company"
                    className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
                  >
                    Company Name
                  </label>
                  <input
                    type="text"
                    name="company"
                    id="company"
                    className="block w-full h-12 rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                    onChange={(e) => setCompanyName(e.target.value)}
                  />
                 
                </div>
              </div>
              <div className="col-span-2">
                <label htmlFor="profile-picture" className="sr-only">
                  Profile Picture
                </label>
                <div className="mt-1 flex items-center">
                  <span className="inline-block h-16 w-16 overflow-hidden bg-gray-200 rounded-lg">
                    <img
                      className="h-full w-full object-cover object-center"
                      src={profilePicture}
                      alt="Profile"
                    />
                  </span>
                  <input
                    type="file"
                    accept="image/*"
                    id="profile-picture"
                    className="sr-only"
                    onChange={handleAddProfilePicture}
                  />
                  <label
                    htmlFor="profile-picture"
                    className="ml-5 cursor-pointer inline-block font-medium text-emerald-500 hover:text-emerald-700"
                  >
                    Add a Profile Picture
                  </label>
                </div>
              </div>
            </div>
            <div className="mt-5">
              <button
                type="submit"
                className="w-full flex items-center justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-emerald-500 hover:bg-emerald-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500 h-12"
                onClick={handleFinishSetup}
              >
                Finish Setup
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
