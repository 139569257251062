import { useEffect, useState } from "react";

import {
  Clock,
  MapPin,
  Ruler,
  Users,
} from "lucide-react";
import {
  getSpaceData,
  updateSpaceViews,
} from "../functions/crud";
import { Link, useParams } from "react-router-dom";
import { UserAuth } from "../context/AuthContext";
import AboutSpace from "../host/components/AboutSpace";
import toast, { Toaster } from "react-hot-toast";

const site = process.env.REACT_APP_SITE_URL;


export default function SpaceProfile() {
  const { setIsLoading, user } = UserAuth();
  const [space, setSpace] = useState(null);
  const [viewed, setViewed] = useState(false);

  

  useEffect(() => {
    if (user?.id) {
      return;
    } else {
      setViewed(true);
      updateSpaceViews(id);
    }
  }, [user]);



  
  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getSpaceData(id).then((data) => {
      setSpace(data);
      //   setTimeout(() => {
      //     setIsLoading(false);
      //   }, 500);
      setIsLoading(false);
    });
  }, [id]);





  // const OfferNote = () => {
  //   return (
  //     <div>
  //       You are requesting access to{" "}
  //       <span className="font-bold">{space?.title}</span> for{" "}
  //       <span className="font-bold">{totalDays}</span> days or a total of{" "}
  //       <span className="font-bold">{timeSlots.length}</span> hours per week.
  //       Starting on {datesSelected[0]?.date} and ending on{" "}
  //       {datesSelected[datesSelected.length - 1]?.date}. The estimated total is{" "}
  //       <span className="font-bold">${estimatedPrice.toFixed(2)}</span>. This
  //       will be priced at ${" "}
  //       <span className="font-bold">
  //         {estimatedRecurringTypePrice} {reccuringType}
  //       </span>
  //       .
  //     </div>
  //   );
  // };

  const convertPhone = (phone) => {
    if (phone) {
      let phoneArr = phone.split("");
      phoneArr.splice(3, 0, "-");
      phoneArr.splice(7, 0, "-");
      return phoneArr.join("");
    } else {
      return "";
    }
  };

  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(`${site}space/${id}`);
    toast.success("Link Copied!");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  return (
    <>
      <div className="flex items-center justify-between mb-4">
        <div className="mt-3 flex ml-auto">
          <button
            type="button"
            className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
            onClick={() => handleCopy()}
          >
            {copied ? "Link Copied!" : "Share"}
          </button>
          {user?.type === 1 && (
            <Link
              to={`/edit/${space?.id}`}
              type="button"
              className="ml-3 inline-flex items-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
            >
              Edit Space
            </Link>
          )}
        </div>
      </div>
      <div className="flex min-h-screen h-full flex-col bg-white rounded-lg shadow w-full max-w-full">
        <Toaster position="top-center" reverseOrder={false} />
        <div className="flex items-start gap-x-8 py-10 ">
          <main className="flex-1 px-6 lg:px-8 ">
            <div className="flex items-center justify-between">
              <div>
                <h1 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  {space?.title}
                </h1>
                <p className="mt-2 text-md font-bold text-zinc-700 flex items-center">
                  <MapPin size={16} className="h-4 w-4 mr-2 " />
                  {space?.city}, {space?.state}
                </p>
                <p className="mt-4 text-sm text-zinc-700 flex items-center ">
                  <Users size={16} className="h-4 w-4 mr-2" />
                  {space?.capacity} people
                  <Ruler size={16} className="h-4 w-4 mr-1 ml-5" />
                  {space?.sqft} sqft
                  {space?.hasMin && (
                    <>
                      <Clock size={16} className="h-4 w-4 mr-1 ml-5" />
                      {space?.minValue} {space?.minimumPeriod} min
                    </>
                  )}
                </p>
              </div>
            </div>
            {/* <div className="overflow-hidden rounded-lg bg-gray-100 relative mt-1">
            <img
              src={space?.mainPhoto}
              alt={space?.id}
              className="object-cover object-center h-36 w-full"
            />
          </div>{" "} */}

            <div>
              <AboutSpace space={space} />
            </div>
          </main>
        </div>
      </div>
    </>
  );
}
