import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { X } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { UserAuth } from "../../context/AuthContext";
import { deleteRenter, generateRandomID } from "../../functions/crud";
import { addChatMessage } from "../../functions/realtime";
import { useNavigate } from "react-router-dom";

export default function SendMessage({ renter }) {
  const { renters, setRenters, user } = UserAuth();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const [newMessage, setNewMessage] = useState("");

  const navigate = useNavigate();

  const handleSendMessage = (e) => {
    e.preventDefault();
    const chatID = generateRandomID()
    const messageData = {
      id: renter?.id,
      senderID: user?.id,
      receiverID: renter?.renterID,
      name: user?.firstName + " " + user?.lastName,
      message: newMessage,
      read: false,
      replied: false,
      chatID: chatID,
      createdAt: new Date().toISOString(),
    };
    addChatMessage(messageData, chatID, user?.link);
    setNewMessage("");
    navigate("/messages");
  };
  return (
    <>
      <Toaster />
      <button
        className="text-blue-600 hover:text-blue-900 ml-2"
        onClick={() => setOpen(true)}
      >
        Send Message
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <button
                    type="button"
                    className="absolute top-2 right-2 inline-flex items-center justify-center rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <X className="h-6 w-6" />
                  </button>
                  <div className="mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-500"
                    >
                      Send Message to{" "}
                      <span className="text-gray-900">
                        {renter?.firstName || renter?.name} {renter?.lastName}
                      </span>
                    </Dialog.Title>
                    <div className="mt-2">
                      <div>
                        <div className="mt-2">
                          <textarea
                            id="comment"
                            name="comment"
                            rows={4}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                            defaultValue={""}
                            onChange={(e) => setNewMessage(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 sm:ml-3 sm:w-auto"
                      onClick={handleSendMessage}
                    >
                      Send
                    </button>
                    <button
                      type="button"
                      data-autofocus
                      onClick={() => setOpen(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
