import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { X } from "lucide-react";
import toast, { Toaster } from "react-hot-toast";
import { UserAuth } from "../../context/AuthContext";
import {
  createAndAddRenter,
  deleteLead,
  generateRandomID,
} from "../../functions/crud";
import { useNavigate } from "react-router-dom";

export default function ConvertToRenter({ renter }) {
  const { updated, setUpdated, user } = UserAuth();
  const [open, setOpen] = useState(false);
  const cancelButtonRef = useRef(null);

  const navigate = useNavigate();

  const handleConverToRenter = async (id) => {
    console.log("Deleting renter with id: ", id);
    const renterData = {
      ...renter,
      providerWhoAdded: user?.id,
      providerEmail: user?.email,
      providerName: user?.firstName + " " + user?.lastName,
      status: 0,
      spaceID: renter.space || "",
    };
    const res = await createAndAddRenter(renterData, user?.link, id);
    if (res.status === "success") {
      setUpdated(!updated);
      deleteLead(id, renter);
      console.log("Renter added successfully");
      navigate(`/renters`);
    } else {
      console.log("Error adding renter");
    }
  };

  return (
    <>
      <Toaster />
      <button
        type="button"
        className="inline-flex items-center justify-center rounded-md bg-blue-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
        onClick={() => setOpen(true)}
      >
        Convert to Renter
      </button>
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <button
                    type="button"
                    className="absolute top-2 right-2 inline-flex items-center justify-center rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <X className="h-6 w-6" />
                  </button>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-500"
                    >
                      Convert{" "}
                      <span className="text-gray-900">
                        {renter?.firstName || renter?.name}
                      </span>{" "}
                      to Renter
                      {renter?.lastName}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Are you sure you want to convert this lead to a renter?
                      </p>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-emerald-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 sm:ml-3 sm:w-auto"
                      onClick={() => handleConverToRenter(renter?.id)}
                    >
                      Convert
                    </button>
                    <button
                      type="button"
                      data-autofocus
                      onClick={() => setOpen(false)}
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    >
                      Cancel
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
