import React, { useEffect, useState } from "react";
import { CgGoogle } from "react-icons/cg";
import { useParams } from "react-router-dom";
import { signUpRenter, signUpRenterWithGoogle } from "../functions/auth";
import {
  getLead,
  getRenterFormSubmission,
  updateRenterFormSubmission,
} from "../functions/crud";

export default function ThankYou() {
  const [emailSignup, setEmailSignup] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [form, setForm] = useState({});

  const { id } = useParams();

  const handleGetForm = async (fid) => {
    const res = await getLead(id);
    if (res !== null) {
      setEmailSignup(res.email);
      setForm(res);
    } else {
      console.log("Chat not found");
    }
  };

  useEffect(() => {
    handleGetForm(id);
  }, [id]);

  const renterData = {
    type: 2,
    finishedSetup: false,
    accountUpdated: false,
    addedBankCard: false,
    spacesBooked: [],
    favorites: form?.space !== "smart" ? [form?.space] : [],
    city: form.city || "",
    state: form.state || "",
    email: emailSignup,
    name: form.name || "",
    firstName: form.firstName || "",
    lastName: form.lastName || "",
    phone: form.phone || "",
    locationLinks: [form?.leadParams],
    id
  };

  const handleSignUp = async (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      alert("Passwords do not match");
    } else {
      const resPonse = await signUpRenter(emailSignup, password, renterData);
      // if (resPonse.status === "success" && type === 0) {
      //   if (form?.email !== emailSignup) {
      //     await updateRenterFormSubmission(id, {
      //       accountCreated: true,
      //       renterID: resPonse.user,
      //       email2: emailSignup,
      //     });
      //   } else {
      //     await updateRenterFormSubmission(id, {
      //       accountCreated: true,
      //       renterID: resPonse.user,
      //     });
      //   }
      //   alert("Account created successfully");
      // }
    }
  };

  const handleGoogleSignUp = () => {
    console.log("Google Sign Up");
    signUpRenterWithGoogle(renterData).then((res) => {
      console.log(res);
      if (res.status === "success") {
        if (res?.email !== form.email) {
          updateRenterFormSubmission(id, {
            accountCreated: true,
            renterID: res.user,
            email2: form.email,
            email: res.email,
          });
        } else {
          updateRenterFormSubmission(id, {
            accountCreated: true,
            renterID: res.user,
          });
        }
        alert("Account created successfully");
      } else {
        alert(res.message);
      }
    });
  };

  return (
    <div className="bg-white min-h-screen h-full">
      <div className=" max-w-2xl mx-auto p-5 pt-12">
        <h1 className="text-5xl font-bold text-center">
          Thank you for submitting your request!
        </h1>
        <p className="text-xl text-center mt-2">
          We’ll get back to you as soon as possible.
        </p>
        <div className="mt-5 max-w-xl mx-auto">
          <h4 className="text-xl text-center">
            To connect with space providers more quickly and efficiently,
            <span className="font-bold text-emerald-600 cursor-pointer">
              {" "}
              create an account now.
            </span>
          </h4>
        </div>
        <div className="max-w-sm mx-auto border bg-zinc-50 p-5 rounded-lg mt-4 shadow">
          <h3 className="text-2xl text-center font-bold">Create an Account</h3>
          <form className="space-y-2 mt-2">
            <div>
              <label
                htmlFor="email"
                className="block text-sm font-semibold leading-6 text-gray-900"
              >
                Email address
              </label>
              <div>
                <input
                  id="email"
                  name="email"
                  type="email"
                  autoComplete="email"
                  value={emailSignup}
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  onChange={(e) => setEmailSignup(e.target.value)}
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Password
                </label>
              </div>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  onChange={(e) => setPassword(e.target.value)}
                  placeholder="Enter your password"
                />
              </div>
            </div>
            <div>
              <div className="flex items-center justify-between">
                <label
                  htmlFor="password"
                  className="block text-sm font-semibold leading-6 text-gray-900"
                >
                  Confirm Password
                </label>
              </div>
              <div>
                <input
                  id="password"
                  name="password"
                  type="password"
                  autoComplete="current-password"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
                  onChange={(e) => setConfirmPassword(e.target.value)}
                  placeholder="Confirm your password"
                />
              </div>
            </div>
          </form>

          <button
            className="rounded-md bg-emerald-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 w-full mt-5"
            onClick={handleSignUp}
          >
            Create an Account
          </button>
          <div className="mt-4 flex items-center justify-between">
            <span className="border-b border-zinc-500 w-1/4"></span>
            <span className="text-gray-800">or continue with</span>
            <span className="border-b border-zinc-500 w-1/4"></span>
          </div>
          <div className="mt-4 flex items-center justify-between">
            <button
              type="submit"
              className="flex w-full items-center justify-center rounded-md bg-blue-500 px-3.5 py-2.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-blue-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-500"
              onClick={handleGoogleSignUp}
            >
              <CgGoogle className="h-6 w-6 mr-1" />
              Google
            </button>
          </div>
        </div>
        <h3 className="text-xl text-center mt-4">
          You’ll be able to save your favorite spaces, receive updates, and
          manage your requests seamlessly.
        </h3>
      </div>
    </div>
  );
}
