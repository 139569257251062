import React, { useEffect, useState } from "react";
import { Disclosure } from "@headlessui/react";
import { CheckIcon, CircleDashed } from "lucide-react";
import { Link } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import { updateLocationLink, updateUser } from "../../functions/crud";
const api = process.env.REACT_APP_SERVER_URL;

const initSteps = [
  {
    id: 1,
    step: "setup",
    completed: false,
  },
  {
    id: 2,
    step: "landing",
    completed: false,
  },
  {
    id: 3,
    step: "space",
    completed: false,
  },
  {
    id: 4,
    step: "link",
    completed: false,
  },
  {
    id: 5,
    step: "payments",
    completed: false,
  },
  {
    id: 6,
    step: "plan",
    completed: false,
  },
];

export default function SetupGuide() {
  const { user, spaces } = UserAuth();
  const [setup, setSetup] = useState(true);
  const [space, setSpace] = useState(false);
  const [landing, setLanding] = useState(false);
  const [link, setLink] = useState(false);
  const [payments, setPayments] = useState(false);
  const [plan, setPlan] = useState(false);
  const [addedName, setAddedName] = useState(false);
  const [name, setName] = useState("");
  const [linkAdded, setLinkAdded] = useState(false);
  const [linkName, setLinkName] = useState("");

  const [steps, setSteps] = useState(initSteps);

  useEffect(() => {
    if (user?.accountUpdated) {
      setSteps((prev) =>
        prev.map((step) => {
          if (step.step === "setup") {
            setSetup(false);
            return { ...step, completed: true };
          }
          return step;
        })
      );
    }
    if (user?.addedSpaces) {
      setSteps((prev) =>
        prev.map((step) => {
          if (step.step === "space") {
            return { ...step, completed: true };
          }
          return step;
        })
      );
    }
    if (user?.linkConfigured) {
      setSteps((prev) =>
        prev.map((step) => {
          if (step.step === "link") {
            return { ...step, completed: true };
          }
          return step;
        })
      );
    }
    if (user?.plan) {
      setSteps((prev) =>
        prev.map((step) => {
          if (step.step === "plan") {
            return { ...step, completed: true };
          }
          return step;
        })
      );
    }
    if (user?.stripeSetup) {
      setSteps((prev) =>
        prev.map((step) => {
          if (step.step === "payments") {
            return { ...step, completed: true };
          }
          return step;
        })
      );
    }
  }, [user, spaces]);

  const handleOpenAndCloseRest = (section) => {
    if (section === "setup") {
      setSetup(!setup);
      setSpace(false);
      setLanding(false);
      setLink(false);
      setPayments(false);
      setPlan(false);
    } else if (section === "landing") {
      setSetup(false);
      setSpace(false);
      setLanding(!landing);
      setLink(false);
      setPayments(false);
      setPlan(false);
    } else if (section === "space") {
      setSetup(false);
      setSpace(!space);
      setLanding(false);
      setLink(false);
      setPlan(false);
      setPayments(false);
    } else if (section === "link") {
      setSetup(false);
      setSpace(false);
      setLanding(false);
      setLink(!link);
      setPayments(false);
      setPlan(false);
    } else if (section === "payments") {
      setSetup(false);
      setSpace(false);
      setLanding(false);
      setLink(false);
      setPlan(false);
      setPayments(!payments);
    } else if (section === "plan") {
      setSetup(false);
      setSpace(false);
      setLanding(false);
      setLink(false);
      setPayments(false);
      setPlan(!plan);
    }
  };

  const spaceIDs = spaces?.map((space) => space.id);

  const handleUpdatePageLine = () => {
    // make sure linkName is a valid url, only allow letters and numbers
    const link = linkName.replace(/[^a-zA-Z0-9]/g, "").toLowerCase();
    if (link.length < 5) {
      alert("Link name must be at least 5 characters long");
    } else {
      updateLocationLink(user, link, spaceIDs, name).then((res) => {
        if (res.status === "success") {
          setLinkAdded(true);
          setLink(false);
          handleOpenAndCloseRest("space");
        } else {
          alert(res.message);
        }
      });
    }
  };

  const handleSkipSpace = () => {
    updateUser(user?.id, {
      addedSpaces: true,
    });
  };

  useEffect(() => {
    if (steps.filter((step) => step.completed).length === 5) {
      updateUser(user?.id, { finishedSetup: true });
    }
  }, [steps, user?.id]);

  const handleSkipPayments = () => {
    updateUser(user?.id, {
      stripeSetup: true,
    });
  };

  console.log(steps);

  return (
    <div className="max-w-4xl mx-auto">
      <h1 className="text-md font-bold  text-zinc-700 tracking-tight">
        Things to do next
      </h1>
      <div className="overflow-hidden rounded-lg bg-white shadow mt-2">
        <div className="px-4 py-5 sm:p-6">
          <h2 className="text-lg font-semibold text-zinc-900">Setup Guide</h2>
          <p className="text-gray-600">
            Welcome to your Host Dashboard! Here are a few things you can do to
            get started.
          </p>
          <span className="inline-flex items-center gap-x-1.5 rounded-md px-2 py-1 text-xs font-medium text-gray-900 ring-1 ring-inset ring-gray-200">
            <svg
              className="h-1.5 w-1.5 fill-red-500"
              viewBox="0 0 6 6"
              aria-hidden="true"
            >
              <circle cx={3} cy={3} r={3} />
            </svg>
            {steps.filter((step) => step.completed).length}/5 Completed
          </span>
          <div className="mt-4">
            <dl className="">
              <div
                as="div"
                className={`hover:bg-zinc-100 p-2 cursor-pointer rounded-lg ${
                  setup && "bg-zinc-100"
                } `}
              >
                <dt>
                  <button
                    className="flex w-full items-start justify-between text-left text-gray-900"
                    onClick={() => handleOpenAndCloseRest("setup")}
                  >
                    <span className="text-base font-semibold flex items-center gap-x-2">
                      {user?.accountUpdated ? (
                        <CheckIcon className="h-5 w-5 p-1  bg-zinc-950 text-white rounded-full" />
                      ) : (
                        <CircleDashed className="h-5 w-5 " />
                      )}
                      Setup Your account
                    </span>
                    <span className="ml-6 flex h-7 items-center"></span>
                  </button>
                </dt>
                {setup && (
                  <>
                    {user?.accountUpdated ? (
                      <div as="dd" className="ml-6">
                        <p className="text-base leading-7 text-gray-600">
                          You have successfully setup your account.
                        </p>
                      </div>
                    ) : (
                      <div as="dd" className=" ml-6">
                        <p className="text-base leading-7 text-gray-600">
                          Let us know who you are so we can better personalize
                          your experience.
                        </p>
                        <Link
                          to="/account-setup"
                          className="mt-2 inline-flex items-center gap-x-2.5 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-950 rounded-lg"
                        >
                          Setup Account
                        </Link>
                      </div>
                    )}
                  </>
                )}
              </div>
            </dl>

            <dl className="">
              <div
                as="div"
                className={`hover:bg-zinc-100 p-2 cursor-pointer rounded-lg ${
                  link && "bg-zinc-100"
                } `}
              >
                <dt>
                  <button
                    className="flex w-full items-start justify-between text-left text-gray-900"
                    onClick={() => handleOpenAndCloseRest("link")}
                  >
                    <span className="text-base font-semibold flex items-center gap-x-2">
                      {user?.linkConfigured ? (
                        <CheckIcon className="h-5 w-5 p-1  bg-zinc-950 text-white rounded-full" />
                      ) : (
                        <CircleDashed className="h-5 w-5 " />
                      )}{" "}
                      Configure your link to share your spaces
                    </span>
                    <span className="ml-6 flex h-7 items-center"></span>
                  </button>
                </dt>
                {link && (
                  <div as="dd" className="ml-6">
                    <p className="text-sm leading-7 text-gray-600">
                      You can configure your link to match your brand and style.
                    </p>

                    {user?.linkConfigured ? (
                      <div className="mt-4">
                        <p className="text-normal text-gray-800">
                          <span className="font-bold">Your Link:</span>{" "}
                          <a
                            href={`https://competeshare.com/${user?.link}`}
                            target="blank"
                            className="text-emerald"
                          >
                            https://competeshare.com/{user?.link}
                          </a>
                        </p>
                        <p className="text-sm leading-7 text-gray-600 ml-1">
                          Share this link with your audience to show off your
                          spaces.
                        </p>
                      </div>
                    ) : (
                      <>
                        <div className="mt-2 flex rounded-md shadow-sm md:w-3/4">
                          <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 px-3 text-gray-500 sm:text-sm bg-white">
                            https://competeshare.com/profile/
                          </span>
                          <input
                            type="text"
                            name="company-website"
                            id="company-website"
                            className="block w-full min-w-0 flex-1 rounded-r-md border-0 py-1.5 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                            placeholder="enter your link name here"
                            onChange={(e) => setLinkName(e.target.value)}
                          />
                        </div>
                        <p className="ml-1 md:w-3/4 text-xs text-gray-500">
                          This is the link that users will use to access your
                          page and see ALL your spaces. It should be unique and
                          easy to remember.
                        </p>
                        <button
                          className="mt-2 inline-flex items-center px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-950 rounded-md"
                          onClick={handleUpdatePageLine}
                        >
                          Save
                        </button>
                      </>
                    )}
                  </div>
                )}
              </div>
            </dl>
            <dl className="">
              <div
                as="div"
                className={`hover:bg-zinc-100 p-2 cursor-pointer rounded-lg ${
                  space && "bg-zinc-100"
                } `}
                onClick={() => handleOpenAndCloseRest("space")}
              >
                <dt>
                  <button
                    className="flex w-full items-start justify-between text-left text-gray-900"
                    onClick={() => handleOpenAndCloseRest("space")}
                  >
                    <span className="text-base font-semibold flex items-center gap-x-2">
                      {steps[2].completed ? (
                        <CheckIcon className="h-5 w-5 p-1  bg-zinc-950 text-white rounded-full" />
                      ) : (
                        <CircleDashed className="h-5 w-5 " />
                      )}{" "}
                      Add your first space
                    </span>
                    <span className="ml-6 flex h-7 items-center"></span>
                  </button>
                </dt>
                {space && (
                  <>
                    {user?.addedSpaces ? (
                      <div as="dd" className="ml-6">
                        <p className="text-base leading-7 text-gray-600">
                          You have successfully added your first space.
                        </p>
                      </div>
                    ) : (
                      <div as="dd" className="ml-6">
                        <p className="text-base leading-7 text-gray-600">
                          Write a description, upload photos, and set your price
                          for your space to get started.
                        </p>
                        {user?.link ? (
                          <div>
                            <Link
                              to="/post-space"
                              className="mt-2 inline-flex items-center gap-x-2.5 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-950 rounded-lg"
                            >
                              Add space
                            </Link>
                            <button
                              className="text-sm ml-2 hover:text-emerald-600"
                              onClick={handleSkipSpace}
                            >
                              I'm not quite ready yet (skip)
                            </button>
                          </div>
                        ) : (
                          <div>
                            <button className="mt-2 inline-flex items-center gap-x-2.5 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-300 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed">
                              Add space
                            </button>
                            <p className="text-xs text-gray-500">
                              Please configure your link first
                            </p>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                )}
              </div>
            </dl>
            <dl className="">
              <div
                as="div"
                className={`hover:bg-zinc-100 p-2 cursor-pointer rounded-lg ${
                  plan && "bg-zinc-100"
                } `}
              >
                <dt>
                  <button
                    className="flex w-full items-start justify-between text-left text-gray-900"
                    onClick={() => handleOpenAndCloseRest("plan")}
                  >
                    <span className="text-base font-semibold flex items-center gap-x-2">
                      {user?.plan ? (
                        <CheckIcon className="h-5 w-5 p-1  bg-zinc-950 text-white rounded-full" />
                      ) : (
                        <CircleDashed className="h-5 w-5 " />
                      )}{" "}
                      Pick Your Plan
                    </span>
                    <span className="ml-6 flex h-7 items-center"></span>
                  </button>
                </dt>
                {plan && (
                  <div as="dd" className="ml-6">
                    {user?.plan ? (
                      <div as="dd" className="ml-6">
                        <p className="text-sm leading-7 text-gray-600">
                          You are a member of the{" "}
                          <span className="font-bold capitalize">
                            {user?.plan}
                          </span>{" "}
                          Plan
                        </p>
                      </div>
                    ) : (
                      <>
                        <p className="text-sm leading-7 text-gray-600">
                          Find a plan that works for you and your business.
                        </p>
                        <Link
                          to="/pricing"
                          className="mt-2 inline-flex items-center gap-x-2.5 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-950 rounded-lg"
                        >
                          Pick a Plan
                        </Link>
                      </>
                    )}
                  </div>
                )}
              </div>
            </dl>
            <dl className="">
              <div
                as="div"
                className={`hover:bg-zinc-100 p-2 cursor-pointer rounded-lg ${
                  payments && "bg-zinc-100"
                } `}
              >
                <dt>
                  <button
                    className="flex w-full items-start justify-between text-left text-gray-900"
                    onClick={() => handleOpenAndCloseRest("payments")}
                  >
                    <span className="text-base font-semibold flex items-center gap-x-2">
                      {user?.stripeSetup ? (
                        <CheckIcon className="h-5 w-5 p-1  bg-zinc-950 text-white rounded-full" />
                      ) : (
                        <CircleDashed className="h-5 w-5 " />
                      )}{" "}
                      Setup Compete Share payments
                    </span>
                    <span className="ml-6 flex h-7 items-center"></span>
                  </button>
                </dt>
                {payments && (
                  <div as="dd" className="ml-6">
                    <p className="text-base leading-7 text-gray-600">
                      Get paid for your bookings by setting up your payment
                      method.
                    </p>
                    <div>
                      <Link
                        to="/stripe-setup"
                        className="mt-2 inline-flex items-center gap-x-2.5 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-950 rounded-lg"
                      >
                        Setup Payments
                      </Link>
                      <button
                        className="text-sm ml-2 hover:text-emerald-600"
                        onClick={handleSkipPayments}
                      >
                        Skip for now
                      </button>
                    </div>
                  </div>
                )}
              </div>
            </dl>
          </div>
        </div>
      </div>
    </div>
  );
}
