import { useEffect, useState } from "react";
import logo from "../../assets/logos/darkicon.png";

import { ScaleIcon, ArrowPathIcon } from "@heroicons/react/24/outline";
import {
  BanknotesIcon,
  BuildingOfficeIcon,
  CheckCircleIcon,
  ChevronRightIcon,
  MinusCircleIcon,
} from "@heroicons/react/20/solid";
import { createLoginLink, getAccount } from "../../functions/stripe";
import { UserAuth } from "../../context/AuthContext";
import { CheckCircle2 } from "lucide-react";
import { FaStripe } from "react-icons/fa6";
import StripePaymentSetup from "./StripePaymentSetup";

// const tempInfo = {
//   id: "acct_1PmXmwD6QhBfwoGQ",
//   object: "account",
//   business_profile: {
//     annual_revenue: null,
//     estimated_worker_count: null,
//     mcc: "5734",
//     name: "Bret Johnson",
//     support_address: null,
//     support_email: null,
//     support_phone: null,
//     support_url: null,
//     url: "techbret.com",
//   },
//   capabilities: {
//     card_payments: "pending",
//     transfers: "pending",
//   },
//   charges_enabled: false,
//   controller: {
//     fees: {
//       payer: "application",
//     },
//     is_controller: true,
//     losses: {
//       payments: "application",
//     },
//     requirement_collection: "stripe",
//     stripe_dashboard: {
//       type: "express",
//     },
//     type: "application",
//   },
//   country: "US",
//   created: 1723367411,
//   default_currency: "usd",
//   details_submitted: true,
//   email: "lavarjohnson112@gmail.com",
//   external_accounts: {
//     object: "list",
//     data: [
//       {
//         id: "ba_1PmXoDD6QhBfwoGQxcQRBew2",
//         object: "bank_account",
//         account: "acct_1PmXmwD6QhBfwoGQ",
//         account_holder_name: null,
//         account_holder_type: null,
//         account_type: null,
//         available_payout_methods: ["standard", "instant"],
//         bank_name: "STRIPE TEST BANK",
//         country: "US",
//         currency: "usd",
//         default_for_currency: true,
//         financial_account: null,
//         fingerprint: "FzXez98jzw7FxczZ",
//         future_requirements: {
//           currently_due: [],
//           errors: [],
//           past_due: [],
//           pending_verification: [],
//         },
//         last4: "6789",
//         metadata: {},
//         requirements: {
//           currently_due: [],
//           errors: [],
//           past_due: [],
//           pending_verification: [],
//         },
//         routing_number: "110000000",
//         status: "verified",
//       },
//     ],
//     has_more: false,
//     total_count: 1,
//     url: "/v1/accounts/acct_1PmXmwD6QhBfwoGQ/external_accounts",
//   },
//   future_requirements: {
//     alternatives: [],
//     current_deadline: null,
//     currently_due: [],
//     disabled_reason: null,
//     errors: [],
//     eventually_due: [],
//     past_due: [],
//     pending_verification: [],
//   },
//   login_links: {
//     object: "list",
//     data: [],
//     has_more: false,
//     total_count: 0,
//     url: "/v1/accounts/acct_1PmXmwD6QhBfwoGQ/login_links",
//   },
//   metadata: {},
//   payouts_enabled: false,
//   requirements: {
//     alternatives: [],
//     current_deadline: null,
//     currently_due: [],
//     disabled_reason: "requirements.pending_verification",
//     errors: [],
//     eventually_due: ["individual.id_number"],
//     past_due: [],
//     pending_verification: [
//       "individual.address.city",
//       "individual.address.line1",
//       "individual.address.postal_code",
//       "individual.address.state",
//     ],
//   },
//   settings: {
//     bacs_debit_payments: {
//       display_name: null,
//       service_user_number: null,
//     },
//     branding: {
//       icon: null,
//       logo: null,
//       primary_color: null,
//       secondary_color: null,
//     },
//     card_issuing: {
//       tos_acceptance: {
//         date: null,
//         ip: null,
//       },
//     },
//     card_payments: {
//       decline_on: {
//         avs_failure: false,
//         cvc_failure: false,
//       },
//       statement_descriptor_prefix: "TECHBRET.C",
//       statement_descriptor_prefix_kana: null,
//       statement_descriptor_prefix_kanji: null,
//     },
//     dashboard: {
//       display_name: "Techbret",
//       timezone: "Etc/UTC",
//     },
//     invoices: {
//       default_account_tax_ids: null,
//     },
//     payments: {
//       statement_descriptor: "TECHBRET.COM",
//       statement_descriptor_kana: null,
//       statement_descriptor_kanji: null,
//     },
//     payouts: {
//       debit_negative_balances: true,
//       schedule: {
//         delay_days: 2,
//         interval: "daily",
//       },
//       statement_descriptor: null,
//     },
//     sepa_debit_payments: {},
//   },
//   tos_acceptance: {
//     date: 1723367517,
//   },
//   type: "none",
// };

const cards = [
  { name: "Total Transactions", href: "#", icon: ScaleIcon, amount: "$0.00" },
  { name: "Pending Payouts", href: "#", icon: ArrowPathIcon, amount: "$0.00" },
  {
    name: "Processed (Last 30 days)",
    href: "#",
    icon: CheckCircle2,
    amount: "$0.00",
  },
  // More items...
];

const statusStyles = {
  success: "bg-green-100 text-green-800",
  processing: "bg-yellow-100 text-yellow-800",
  failed: "bg-gray-100 text-gray-800",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function StripeAccount() {
  const { user } = UserAuth();
  const [stripeSetup, setStripeSetup] = useState(false);
  const [accountInfo, setAccountInfo] = useState({});
  const [transactions, setTransactions] = useState([]);

  const handleGetAccount = async (acc) => {
    const res = await getAccount(acc);
    setAccountInfo(res?.account);
  };

  useEffect(() => {
    if (user?.stripeAccountID) {
      setStripeSetup(true);
      handleGetAccount(user?.stripeAccountID);
    } else {
      console.log("No stripe account ID found");
    }
  }, [user]);

  const handleCreateLoginLink = async () => {
    const link = await createLoginLink(user?.stripeAccountID);
    //open in new tab
    window.open(link.loginLink.url);
  };

  console.log(accountInfo);

  return (
    <>
      {stripeSetup ? (
        <div className="-my-10">
          <div className=" sm:-mx-6 lg:-mx-8">
            <main className="flex-1 pb-8">
              {/* Page header */}
              <div className="bg-white shadow">
                <div className="px-4 sm:px-6 lg:mx-auto lg:max-w-6xl lg:px-8">
                  <div className="py-6 md:flex md:items-center md:justify-between lg:border-t lg:border-gray-200">
                    <div className="min-w-0 flex-1">
                      {/* Profile */}
                      <div className="flex items-center">
                        <img
                          alt=""
                          src={user?.profilePic || logo}
                          className="hidden h-16 w-16 rounded-full sm:block object-center object-cover"
                        />
                        <div>
                          <div className="flex items-center">
                            <img
                              alt=""
                              src={user?.profilePic || logo}
                              className="h-16 w-16 rounded-full sm:hidden object-center object-cover"
                            />
                            <h1 className="ml-3 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:leading-9">
                              Welcome, {accountInfo?.business_profile?.name}
                            </h1>
                          </div>
                          <dl className="mt-6 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                            <dt className="sr-only">Company</dt>
                            <dd className="flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6">
                              <BuildingOfficeIcon
                                aria-hidden="true"
                                className="mr-1.5 h-5 w-5 flex-shrink-0 text-gray-400"
                              />
                              {user?.companyName}
                            </dd>
                            <dt className="sr-only">Card status</dt>
                            <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                              {accountInfo?.capabilities?.card_payments ===
                              "active" ? (
                                <CheckCircleIcon
                                  aria-hidden="true"
                                  className="mr-1 h-5 w-5 flex-shrink-0 text-green-400"
                                />
                              ) : (
                                <MinusCircleIcon
                                  aria-hidden="true"
                                  className="mr-1 h-5 w-5 flex-shrink-0 text-red-500"
                                />
                              )}
                              Card Payments  {accountInfo?.capabilities?.card_payments ===
                                "pending" && (
                                <span className="ml-1 inline-flex items-center rounded-md bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                  Pending
                                </span>
                              )}
                            </dd>
                            <dt className="sr-only">Account status</dt>
                            <dd className="mt-3 flex items-center text-sm font-medium capitalize text-gray-500 sm:mr-6 sm:mt-0">
                              {accountInfo?.capabilities?.transfers ===
                              "active" ? (
                                <CheckCircleIcon
                                  aria-hidden="true"
                                  className="mr-1 h-5 w-5 flex-shrink-0 text-green-400"
                                />
                              ) : (
                                <MinusCircleIcon
                                  aria-hidden="true"
                                  className="mr-1 h-5 w-5 flex-shrink-0 text-red-500"
                                />
                              )}
                              Transfers{" "}
                              {accountInfo?.capabilities?.transfers ===
                                "pending" && (
                                <span className="ml-1 inline-flex items-center rounded-md bg-yellow-50 px-1.5 py-0.5 text-xs font-medium text-yellow-800 ring-1 ring-inset ring-yellow-600/20">
                                  Pending
                                </span>
                              )}
                            </dd>
                          </dl>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6 flex space-x-3 md:ml-4 md:mt-0">
                      {/* <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Add money
                      </button> */}
                      <button
                        type="button"
                        className="inline-flex items-center rounded-md bg-[#5433FF] px-3  text-sm font-semibold text-white shadow-sm hover:bg-[#4379FF] focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                        onClick={handleCreateLoginLink}
                      >
                        Login to <FaStripe className="h-10 w-10 ml-1" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-8">
                <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                  <h2 className="text-lg font-medium leading-6 text-gray-900">
                    Overview
                  </h2>
                  <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                    {/* Card */}
                    {cards.map((card) => (
                      <div
                        key={card.name}
                        className="overflow-hidden rounded-lg bg-white shadow"
                      >
                        <div className="p-5">
                          <div className="flex items-center">
                            <div className="flex-shrink-0">
                              <card.icon
                                aria-hidden="true"
                                className="h-6 w-6 text-gray-400"
                              />
                            </div>
                            <div className="ml-5 w-0 flex-1">
                              <dl>
                                <dt className="truncate text-sm font-medium text-gray-500">
                                  {card.name}
                                </dt>
                                <dd>
                                  <div className="text-lg font-medium text-gray-900">
                                    {card.amount}
                                  </div>
                                </dd>
                              </dl>
                            </div>
                          </div>
                        </div>
                        <div className="bg-gray-50 px-5 py-3">
                          <div className="text-sm">
                            <a
                              href={card.href}
                              className="font-medium text-cyan-700 hover:text-cyan-900"
                            >
                              View all
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
                <div className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                  <h2 className="text-lg font-medium leading-6 text-gray-900 ">
                    Account Information
                  </h2>
                  <div className="overflow-hidden rounded-lg bg-white shadow mt-2">
                    <div className="p-5">
                      <div className="grid grid-cols-2 gap-2">
                        <div className="space-y-4">
                          <div className="w-full flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                Account ID
                              </dt>
                              <dd>
                                <div className="text-xs font-medium text-gray-900">
                                  {accountInfo?.id}
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div className="w-full flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                Type
                              </dt>
                              <dd>
                                <div className="text-xs font-medium text-gray-900 capitalize">
                                  {
                                    accountInfo?.controller?.stripe_dashboard
                                      ?.type
                                  }
                                </div>
                              </dd>
                            </dl>
                          </div>
                          <div className="w-full flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                Payout Schedule
                              </dt>
                              <dd>
                                <div className="text-xs font-medium text-gray-900 capitalize">
                                  {
                                    accountInfo?.settings?.payouts?.schedule
                                      ?.interval
                                  }{" "}
                                  -{" "}
                                  {
                                    accountInfo?.settings?.payouts?.schedule
                                      ?.delay_days
                                  }{" "}
                                  days delay
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                        <div className="space-y-4">
                          <div className="w-full flex-1">
                            <dl>
                              <dt className="truncate text-sm font-medium text-gray-500">
                                External Account
                              </dt>
                              <dd>
                                <div className="text-xs font-medium text-gray-900">
                                  {
                                    accountInfo?.external_accounts?.data[0]
                                      ?.bank_name
                                  }{" "}
                                  -{" "}
                                  {
                                    accountInfo?.external_accounts?.data[0]
                                      ?.last4
                                  }{" "}
                                  -{" "}
                                  <span className="inline-flex items-center rounded-md bg-blue-50 px-1.5 py-0.5 text-xs font-medium text-blue-700 ring-1 ring-inset ring-blue-700/10 capitalize">
                                    {
                                      accountInfo?.external_accounts?.data[0]
                                        ?.status
                                    }
                                  </span>
                                </div>
                              </dd>
                            </dl>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <h2 className="mx-auto mt-8 max-w-6xl px-4 text-lg font-medium leading-6 text-gray-900 sm:px-6 lg:px-8">
                  Recent activity
                </h2>

                {/* Activity list (smallest breakpoint only) */}
                <div className="shadow sm:hidden">
                  <ul className="mt-2 divide-y divide-gray-200 overflow-hidden shadow sm:hidden">
                    {transactions?.map((transaction) => (
                      <li key={transaction.id}>
                        <a
                          href={transaction.href}
                          className="block bg-white px-4 py-4 hover:bg-gray-50"
                        >
                          <span className="flex items-center space-x-4">
                            <span className="flex flex-1 space-x-2 truncate">
                              <BanknotesIcon
                                aria-hidden="true"
                                className="h-5 w-5 flex-shrink-0 text-gray-400"
                              />
                              <span className="flex flex-col truncate text-sm text-gray-500">
                                <span className="truncate">
                                  {transaction.name}
                                </span>
                                <span>
                                  <span className="font-medium text-gray-900">
                                    {transaction.amount}
                                  </span>{" "}
                                  {transaction.currency}
                                </span>
                                <time dateTime={transaction.datetime}>
                                  {transaction.date}
                                </time>
                              </span>
                            </span>
                            <ChevronRightIcon
                              aria-hidden="true"
                              className="h-5 w-5 flex-shrink-0 text-gray-400"
                            />
                          </span>
                        </a>
                      </li>
                    ))}
                  </ul>

                  <nav
                    aria-label="Pagination"
                    className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3"
                  >
                    <div className="flex flex-1 justify-between">
                      <a
                        href="#"
                        className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Previous
                      </a>
                      <a
                        href="#"
                        className="relative ml-3 inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                      >
                        Next
                      </a>
                    </div>
                  </nav>
                </div>

                {/* Activity table (small breakpoint and up) */}
                <div className="hidden sm:block">
                  <div className="mx-auto max-w-6xl px-4 sm:px-6 lg:px-8">
                    <div className="mt-2 flex flex-col">
                      <div className="min-w-full overflow-hidden overflow-x-auto align-middle shadow sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-200">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900"
                              >
                                Transaction
                              </th>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              >
                                Amount
                              </th>
                              <th
                                scope="col"
                                className="hidden bg-gray-50 px-6 py-3 text-left text-sm font-semibold text-gray-900 md:block"
                              >
                                Status
                              </th>
                              <th
                                scope="col"
                                className="bg-gray-50 px-6 py-3 text-right text-sm font-semibold text-gray-900"
                              >
                                Date
                              </th>
                            </tr>
                          </thead>
                          {transactions?.length === 0 ? (
                            <tbody className="divide-y divide-gray-200 bg-white">
                              <tr>
                                <td
                                  colSpan="5"
                                  className="text-center py-4 text-sm text-gray-500"
                                >
                                  No transactions yet.
                                </td>
                              </tr>
                            </tbody>
                          ) : (
                            <tbody className="divide-y divide-gray-200 bg-white">
                              {transactions.map((transaction) => (
                                <tr key={transaction.id} className="bg-white">
                                  <td className="w-full max-w-0 whitespace-nowrap px-6 py-4 text-sm text-gray-900">
                                    <div className="flex">
                                      <a
                                        href={transaction.href}
                                        className="group inline-flex space-x-2 truncate text-sm"
                                      >
                                        <BanknotesIcon
                                          aria-hidden="true"
                                          className="h-5 w-5 flex-shrink-0 text-gray-400 group-hover:text-gray-500"
                                        />
                                        <p className="truncate text-gray-500 group-hover:text-gray-900">
                                          {transaction.name}
                                        </p>
                                      </a>
                                    </div>
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                    <span className="font-medium text-gray-900">
                                      {transaction.amount}
                                    </span>
                                    {transaction.currency}
                                  </td>
                                  <td className="hidden whitespace-nowrap px-6 py-4 text-sm text-gray-500 md:block">
                                    <span
                                      className={classNames(
                                        statusStyles[transaction.status],
                                        "inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium capitalize"
                                      )}
                                    >
                                      {transaction.status}
                                    </span>
                                  </td>
                                  <td className="whitespace-nowrap px-6 py-4 text-right text-sm text-gray-500">
                                    <time dateTime={transaction.datetime}>
                                      {transaction.date}
                                    </time>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          )}
                        </table>
                        {/* Pagination */}
                        {/* <nav
                      aria-label="Pagination"
                      className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 sm:px-6"
                    >
                      <div className="hidden sm:block">
                        <p className="text-sm text-gray-700">
                          Showing <span className="font-medium">1</span> to{" "}
                          <span className="font-medium">10</span> of{" "}
                          <span className="font-medium">20</span> results
                        </p>
                      </div>
                      <div className="flex flex-1 justify-between gap-x-3 sm:justify-end">
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        >
                          Previous
                        </a>
                        <a
                          href="#"
                          className="relative inline-flex items-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:ring-gray-400"
                        >
                          Next
                        </a>
                      </div>
                    </nav> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
      ) : (
        <StripePaymentSetup />
      )}
    </>
  );
}
