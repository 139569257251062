import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Mail, Phone, X, User } from "lucide-react";
import { CgGoogle } from "react-icons/cg";
import availableTimes from "../assets/json/availableTimeBlocks.json";
import { createNewLead, generateRandomID } from "../functions/crud";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function TourRequest({ secColor = "emerald", locationLink, userID  }) {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("phone");
  const cancelButtonRef = useRef(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");
  const [startTime, setStartTime] = useState("");
  const [endTime, setEndTime] = useState("");
  const [startDate, setStartDate] = useState("");
  const timeOptions = Object.values(availableTimes)[0];

  const handleCreateUser = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      console.log("Passwords do not match");
    } else {
      console.log("Create User");
    }
  };

  const handleGoogleSignUp = () => {
    console.log("Google Sign Up");
  };

  const navigate = useNavigate();

  const handleSendEmail = () => {
    if (!email) {
      alert("Please enter your email address");
      return;
    } else if (!name) {
      alert("Please enter your full name");
      return;
    } else {
      const id = generateRandomID();       
      const leadInfo = {
        name,
        email,
        phone,
        message,
        viewed: false,
        status: 0,
        id: id,
        profilePic: "",
        chatID: 0,
        hasAccount: false,
        replied: false,
        isRenter: false,
        type: 2,
        leadParams: locationLink,
        sender: "user",
        space: "smart",
        requestStartTime: startTime,
        requestEndTime: endTime,
        startDate,
        createdAt: new Date().toISOString(),
        userID
      };
      console.log(leadInfo);
      createNewLead(leadInfo);
      setEmailSent(true);
      navigate(`/thank-you/${id}`);
    }
  };

  return (
    <>
      <button
        className={`w-full rounded-md bg-${secColor}-600 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-${secColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${secColor}-600 h-12`}
        onClick={() => setOpen(true)}
      >
        {" "}
        Book a Tour
      </button>

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <button
                    type="button"
                    className="absolute top-2 right-2 inline-flex items-center justify-center rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <X className="h-6 w-6" />
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-semibold leading-6 text-center text-gray-900"
                  >
                    Request a Tour
                    <p className="mt-2 text-sm text-gray-500">
                      We'll get back to you right away
                    </p>
                  </Dialog.Title>

                  <div className="mt-5 sm:mt-6">
                    {emailSent ? (
                      <div>
                        <div>
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter a password to create an account"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="passwordConfirm"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="passwordConfirm"
                            id="passwordConfirm"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Please Confirm Your Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div className="space-y-4">
                        <div className="flex items-end justify-between">
                          <div>
                            <label
                              htmlFor="date"
                              className="block text-sm font-medium text-gray-700"
                            >
                              What date would you like to tour?
                            </label>
                            <input
                              type="date"
                              name="date"
                              id="date"
                              className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            />
                          </div>
                          <div>
                            <label
                              htmlFor="date"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Best possible times?
                            </label>
                            <div className="flex items-center gap-2">
                              <select
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                                defaultValue="Canada"
                                value={startTime}
                                onChange={(e) => {
                                  setStartTime(e.target.value);
                                }}
                              >
                                {timeOptions.map((time) => (
                                  <option
                                    key={time.dbvalue}
                                    value={time.displayValue}
                                  >
                                    {time.displayValue}
                                  </option>
                                ))}
                              </select>
                              <div className="font-bold">-</div>
                              <select
                                className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                                defaultValue="Canada"
                                value={endTime}
                                onChange={(e) => {
                                  setEndTime(e.target.value);
                                }}
                              >
                                {timeOptions.map((time) => (
                                  <option
                                    key={time.dbvalue}
                                    value={time.displayValue}
                                  >
                                    {time.displayValue}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                        </div>
                        <div>
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Full Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter your full name"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter your email address"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>

                        <div>
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter your phone number"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="message"
                            className="block mt-4 text-sm font-medium text-gray-700"
                          >
                            Message
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                            placeholder="Enter your message"
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                        <div>
                          <button
                            type="button"
                            className={`inline-flex w-full justify-center rounded-md bg-${secColor}-600 px-3 py-4 text-lg font-semibold text-white shadow-sm hover:bg-${secColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${secColor}-600 sm:col-start-2`}
                            onClick={() => handleSendEmail()}
                          >
                            Send Tour Request
                          </button>
                        </div>
                      </div>
                    )}
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
