import { Link } from "react-router-dom";
import icon from "../assets/logos/icon.png";
import { CgGoogle } from "react-icons/cg";
import { resetPassword, signIn, signInWithGoogle } from "../functions/auth";
import { UserAuth } from "../context/AuthContext";
import { useState } from "react";
import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";

export default function ForgotPassword() {
  const { setIsLoading } = UserAuth();
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);

  const handleLogin = (e) => {
    e.preventDefault();
    setIsLoading(true);
    resetPassword(email).then((res) => {
      if (res.status === "success") {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setMessage(
          "There was an error sending the reset link. Please try again. This may be due to an incorrect email address."
        );
      }
    });
  };

  return (
    <>
      <div className="mt-14">
        <div className="h-full">
          <div className="flex min-h-full flex-1 flex-col justify-center px-6 py-12 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-sm">
              <img src={icon} alt="logo" className="mx-auto h-12 w-auto" />
              <h2 className="text-center text-xl font-bold leading-9 tracking-tight text-gray-400">
                Password Reset
              </h2>
            </div>
            {message && (
              <div className="rounded-md bg-yellow-50 p-4 mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <ExclamationTriangleIcon
                      aria-hidden="true"
                      className="h-5 w-5 text-yellow-400"
                    />
                  </div>
                  <div className="ml-3">
                    <h3 className="text-sm font-medium text-yellow-800">
                      Something went wrong
                    </h3>
                    <div className="mt-2 text-sm text-yellow-700">
                      <p>{message}</p>
                    </div>
                  </div>
                </div>
              </div>
            )}

            <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
              <form className="space-y-6" onSubmit={handleLogin}>
                <div>
                  <label
                    htmlFor="email"
                    className="block text-sm font-medium leading-6 text-white"
                  >
                    Email address
                  </label>
                  <div className="mt-2">
                    <input
                      id="email"
                      name="email"
                      type="email"
                      autoComplete="email"
                      required
                      className="block w-full rounded-md h-12 border-0 bg-white/5 py-1.5 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-emerald-500 sm:text-sm sm:leading-6"
                      placeholder="Enter your email address"
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div>
                  <button
                    type="submit"
                    className="flex w-full items-center justify-center rounded-md bg-emerald-500 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-emerald-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-500 h-12"
                    onClick={handleLogin}
                  >
                    Send Reset Link
                  </button>
                </div>
              </form>

              <p className="mt-10 text-center text-sm text-zinc-400">
                Not a member?{" "}
                <Link
                  to="/create-account"
                  className="font-semibold leading-6 text-emerald-400 hover:text-emerald-300"
                >
                  Create an account
                </Link>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
