import { ArrowDown, ArrowUp } from "lucide-react";
import React, { useState } from "react";

export default function OrderSummary({
  totalCostPerMonth,
  filteredDates,
  space,
  totalMonths,
  totalCostOverall,
}) {
  // console.log(totalHoursForEachDay, filteredDates?.length);
  const [viewDates, setViewDates] = useState(false);

  const contractFee = 5;
  return (
    <div>
      <h2 className="text-xl font-semibold">Current Estimate Quote</h2>
      <p className="text-gray-600 mb-6">
        This is just an estimate and may change based on the final quote
        provided by the host.
      </p>
      <div className="flex justify-between">
        <div className="flex flex-col">
          <p className="text-gray-600">Total Cost</p>
          <p className="text-gray-900 font-semibold">
            ${totalCostPerMonth || 0} / month
          </p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600">Total Months</p>
          <p className="text-gray-900 font-semibold">{totalMonths || 1}</p>
        </div>
        <div className="flex flex-col">
          <p className="text-gray-600">Total Days</p>
          <p className="text-gray-900 font-semibold">{filteredDates?.length}</p>
        </div>
      </div>

      <div className="mt-4">
        <button
          className="text-emerald-600 hover:text-emerald-500 flex items-center gap-x-1 text-xs"
          onClick={() => setViewDates(!viewDates)}
        >
          {viewDates ? "Hide" : "View"} Selected Dates{" "}
          {viewDates ? (
            <ArrowUp className="h-4 w-4" />
          ) : (
            <ArrowDown className="h-4 w-4" />
          )}
        </button>
        {viewDates &&
          filteredDates?.map((date) => (
            <p key={date.date} className="text-gray-600">
              {date.day} - {date.date}
            </p>
          ))}
      </div>
      <div className="py-4"></div>

      <div className="relative mt-4">
        <div className="absolute inset-0 flex items-center" aria-hidden="true">
          <div className="w-full border-t border-zinc-400" />
        </div>
     
      </div>

      {/* <form className="mt-8">
        <label
          htmlFor="discount-code-mobile"
          className="block text-sm font-medium text-gray-700"
        >
          Discount code
        </label>
        <div className="mt-1 flex space-x-4">
          <input
            type="text"
            id="discount-code-mobile"
            name="discount-code-mobile"
            className="block w-full rounded-md border-gray-300 shadow-sm focus:border-emerald-500 focus:ring-emerald-500 sm:text-sm"
          />
          <button
            type="submit"
            className="rounded-md bg-gray-200 px-4 text-sm font-medium text-gray-600 hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2 focus:ring-offset-gray-50"
          >
            Apply
          </button>
        </div>
      </form> */}

      <dl className="mt-10 space-y-4 text-sm font-medium text-gray-500">
        {/* <div className="flex justify-between">
          <dt className="flex">Total Overall</dt>
          <dd className="text-gray-900">${totalCostOverall}</dd>
        </div>
        <div className="flex justify-between">
          <dt>Fees</dt>
          <dd className="text-gray-900">${totalCostOverall * 0.03}</dd>
        </div>
        <div className="flex justify-between">
          <dt>Contract Fee</dt>
          <dd className="text-gray-900">${contractFee}</dd>
        </div>
        <div className="relative py-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-zinc-400" />
          </div>
        </div> */}
        {/* <div className="flex justify-between mt-4">
          <dt>Security Deposit</dt>
          <dd className="text-gray-900">
            ${space?.prices?.securityDeposit || 0}
          </dd>
        </div>
        <div className="flex justify-between">
          <dt>First Month Cost:</dt>
          <dd className="text-gray-900">
            ${parseInt(totalCostPerMonth)}
          </dd>
        </div> */}
        <div>
          <dt className="font-bold">Fees and Costs:</dt>
          <dd className="flex justify-between ml-4">
            <span className="text-xs">
              Security Deposit
            </span>
            <span className="text-gray-900">
              ${space?.prices?.securityDeposit || 0}
            </span>
          </dd>
          <dd className="flex justify-between ml-4">
            <span className="text-xs">
              Fees
            </span>
            <span className="text-gray-900">
              ${Math.floor(totalCostOverall * 0.03)}
            </span>
          </dd>
          <dd className="flex justify-between ml-4">
            <span className="text-xs">
              Contract Fee
            </span>
            <span className="text-gray-900">
              ${contractFee}
            </span>
          </dd>
          <dd className="flex justify-between ml-4">
            <span className="text-xs">
              First Month Cost
            </span>
            <span className="text-gray-900">
              ${totalCostPerMonth}
            </span>
          </dd>
          <dd className="text-gray-900">
            {/* ${parseInt(totalCostPerMonth)
            + (space?.prices?.securityDeposit || 0) + (totalCostOverall * 0.03) +  parseInt(contractFee)} */}
          </dd>
        </div>
        <div className="flex justify-between font-bold">
          <dt >Total Due First Month:</dt>
          <dd className="text-gray-900">
            ${parseInt(totalCostPerMonth)
            + (space?.prices?.securityDeposit || 0) + (totalCostOverall * 0.03) +  parseInt(contractFee)}
          </dd>
        </div>
        <div className="relative py-4">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-zinc-400" />
          </div>
        </div>
        <div className="flex justify-between font-bold ">
          <dt>Total Due Each Month After:</dt>
          <dd className="text-gray-900">
            ${totalCostPerMonth}
          </dd>
        </div>
        <div className="flex justify-between font-bold mt-auto">
          This is just an estimate and may change based on the final quote provided by the host.
        </div>
      </dl>
    </div>
  );
}
