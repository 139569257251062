import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { RenterAuth } from "../../context/RenterContext";
import { MapPin, Star } from "lucide-react";
import { getSpaceData } from "../../functions/crud";

export default function StartReservation() {
  const [space, setSpace] = useState(null);
  const [availableDays, setAvailableDays] = useState([]);
  const [unavailableDays, setUnavailableDays] = useState([]);
  const { id } = useParams();

  const daysOfTheWeek = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  useEffect(() => {
    getSpaceData(id).then((data) => {
      setSpace(data);
    });
  }, [id]);

  useEffect(() => {
    const checkedDays = space?.daysChecked
      ?.filter((day) => day)
      .map((day) => day);
    const unavailableDays = daysOfTheWeek.filter(
      (day) => !checkedDays.includes(day)
    );

    setAvailableDays(checkedDays);
    setUnavailableDays(unavailableDays);
  }, [space]);

  return (
    <div className="max-w-5xl mx-auto p-4">
      <div className="grid sm:grid-cols-5 grid-cols-1 gap-4">
        <div className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow col-span-3">
          <div className="px-4 py-5 sm:px-6">
            <h1 className="text-2xl font-semibold text-center">
              When do you need this space?
            </h1>
            <div className="text-right">
              From:{" "}
              <input
                type="date"
                className="border border-gray-300 rounded-lg p-2"
              />
            </div>{" "}
            <div className="text-right mt-4">
              To:{" "}
              <input
                type="date"
                className="border border-gray-300 rounded-lg p-2"
              />
            </div>
          </div>
          <div className="px-4 py-5 sm:p-6">
            What days do you need this space?
            <div className="text-left">
              {availableDays?.map((day) => (
                <div key={day}>
                  <input type="checkbox" className="mr-1" id={day} name={day} />
                  <label htmlFor={day} className="capitalize">
                    {day} - Available {space?.pricing?.[day]?.startTime} -{" "}
                    {space?.pricing?.[day]?.endTime}
                  </label>
                </div>
              ))}
              {unavailableDays?.map((day) => (
                <div key={day}>
                  <div
                    htmlFor={day}
                    className="capitalize line-through text-red-400"
                  >
                    {day}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="px-4 py-4 sm:px-6">
            Please let the host know why you need this space and what you plan
            to use it for.
            <textarea
              className="w-full h-32 border border-gray-300 rounded-lg p-2"
              placeholder="Type here..."
            ></textarea>
          </div>
        </div>
        <div className="sm:col-span-2">
          <div className="overflow-hidden rounded-lg bg-white shadow ">
            <div className="px-4 py-5 sm:p-6">
              <img
                src={space?.mainPhoto}
                alt="Main"
                className="object-cover object-center max-h-[600px] min-h-full w-full rounded-md shadow-lg"
              />
              <h3 className="text-2xl font-semibold mt-4">{space?.title}</h3>
              <div className="text-left flex items-center mt-3">
                <MapPin className="h-5 w-5 mr-1" />
                City, State
                <div className="ml-auto flex items-center text-sm">
                  <Star className="h-4 w-4 mr-1" />
                  {space?.reviews || "5.0"}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
