import React, { useEffect, Fragment, useState } from "react";
import profilPic from "../../assets/images/profile.png";
import { UserAuth } from "../../context/AuthContext";
import { Menu, Transition } from "@headlessui/react";
import {
  Paperclip,
  Reply,
  SendHorizonal,
  X,
} from "lucide-react";
import { FaEllipsisVertical } from "react-icons/fa6";
import {
  addChatMessage,
  deleteChat,
  updateLead,
} from "../../functions/realtime";
import { useParams } from "react-router-dom";

const notificationBGColors = {
  1: "bg-blue-50",
  3: "bg-green-50",
  4: "bg-blue-50",
  2: "bg-red-50",
};

const notificationFontColors = {
  1: "text-blue-700",
  3: "text-green-700",
  4: "text-blue-700",
  2: "text-red-700",
};

const notificationRingColors = {
  1: "ring-blue-600",
  3: "ring-green-600",
  4: "ring-blue-600",
  2: "ring-red-600",
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Inbox({ messages }) {
  const { user, locationInfo } = UserAuth();
  const [currentMessages, setCurrentMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [lead, setLead] = useState({});
  const [usersChatId, setUsersChatId] = useState("");

  const { id } = useParams();

  const generateTimeAgo = (timestamp) => {
    const timeAgo = new Date(timestamp).getTime();
    const now = new Date().getTime();
    const timeDifference = now - timeAgo;

    const secondsDiff = timeDifference / 1000;
    const minutes = secondsDiff / 60;
    const hours = minutes / 60;
    const days = hours / 24;
    const weeks = days / 7;
    const months = weeks / 4.345; // Approximation since months vary in length
    const years = months / 12;

    if (secondsDiff < 60) {
      return `${Math.floor(secondsDiff)} seconds ago`;
    } else if (minutes < 60) {
      return `${Math.floor(minutes)} minutes ago`;
    } else if (hours < 24) {
      return `${Math.floor(hours)} hours ago`;
    } else if (days < 7) {
      return `${Math.floor(days)} days ago`;
    } else if (weeks < 4.345) {
      return `${Math.floor(weeks)} weeks ago`;
    } else if (months < 12) {
      return `${Math.floor(months)} months ago`;
    } else {
      return `${Math.floor(years)} years ago`;
    }
  };

  useEffect(() => {
    if (user.type === 1) {
      setUsersChatId(user.link);
    } else {
      setUsersChatId(user.id);
    }
  }, [user]);

  useEffect(() => {
    if (id) {
      handleGetChat(id);
    } else if (messages?.length > 0) {
      handleGetChat(messages[0]?.id);
    } else {
      setCurrentMessages([]);
    }
  }, [messages]);

  const handleGetChat = async (messageID) => { 
    setCurrentMessages(messages);
  };

  const handleSendMessage = (e) => {
    e.preventDefault();
    const messageData = {
      id: currentMessages[0]?.id,
      senderID: locationInfo?.locationLink,
      receiverID: currentMessages[0]?.senderID,
      name: user?.firstName + " " + user?.lastName,
      message: newMessage,
      read: false,
      replied: false,
      chatID: currentMessages?.length,
      createdAt: new Date().toISOString(),
    };
    addChatMessage(messageData, currentMessages[0]?.id);
    if (lead?.status === 0) {
      const leadRep = {
        ...lead,
        status: 1,
      };
      updateLead(leadRep, id || currentMessages[0]?.id);
    }
    setNewMessage("");
    handleGetChat(currentMessages[0]?.id);
  };

  const handleDeleteChat = async (chatID) => {
    await deleteChat(chatID);
    handleGetChat(messages[0]?.id);
  }

  console.log(currentMessages)



  return (
    <>
      {messages?.length > 0 ? (
        <div className="h-full bg-gray-100">
          <div className="h-full overflow-hidden">
            <div className="flex h-full flex-col">
              {/* Bottom section */}
              <div className="flex min-h-0 flex-1 overflow-hidden">
                {/* Main area */}
                <main className="min-w-0 flex-1 border-t border-gray-200 xl:flex">
                  <section
                    aria-labelledby="message-heading"
                    className="flex h-full min-w-0 flex-1 flex-col overflow-hidden xl:order-last"
                  >
                    {/* Top section */}
                    <div className="flex-shrink-0 border-b border-gray-200 bg-white">
                      {/* Toolbar*/}
                      <div className="flex h-16 flex-col justify-center">
                        <div className="px-4 sm:px-6 lg:px-8">
                          <div className="flex justify-between py-3">
                            {/* Left buttons */}
                            {/* <div>
                              <div className="isolate inline-flex rounded-md shadow-sm sm:space-x-3 sm:shadow-none">
                                <span className="inline-flex sm:shadow-sm">
                                  <button
                                    type="button"
                                    className="relative inline-flex items-center gap-x-1.5 rounded-l-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
                                  >
                                    <ArrowUpLeft
                                      aria-hidden="true"
                                      className="-ml-0.5 h-5 w-5 text-gray-400"
                                    />
                                    Reply
                                  </button>
                                  <button
                                    type="button"
                                    className="relative -ml-px hidden items-center gap-x-1.5 bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                                  >
                                    <Pencil
                                      aria-hidden="true"
                                      className="-ml-0.5 h-5 w-5 text-gray-400"
                                    />
                                    Note
                                  </button>
                                  <button
                                    type="button"
                                    className="relative -ml-px hidden items-center gap-x-1.5 rounded-r-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                                  >
                                    <UserPlus
                                      aria-hidden="true"
                                      className="-ml-0.5 h-5 w-5 text-gray-400"
                                    />
                                    Assign
                                  </button>
                                </span>

                                <span className="hidden space-x-3 lg:flex">
                                  <button
                                    type="button"
                                    className="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                                  >
                                    <Archive
                                      aria-hidden="true"
                                      className="-ml-0.5 h-5 w-5 text-gray-400"
                                    />
                                    Archive
                                  </button>
                                  <button
                                    type="button"
                                    className="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                                  >
                                    <FolderDown
                                      aria-hidden="true"
                                      className="-ml-0.5 h-5 w-5 text-gray-400"
                                    />
                                    Move
                                  </button>
                                </span>
                              </div>
                            </div> */}

                            {/* Right buttons */}
                            <nav aria-label="Pagination" className="ml-auto">
                              <span className="isolate inline-flex rounded-md shadow-sm space-x-2">
                                <button
                                  type="button"
                                  className="relative -ml-px hidden items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10 sm:inline-flex"
                                  onClick={() => handleDeleteChat(currentMessages[0]?.id)}
                                >
                                  <X
                                    className="-ml-0.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  Delete
                                </button>
                                <button
                                  type="button"
                                  className="relative inline-flex items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 hover:z-10 hover:bg-gray-50 focus:z-10"
                                >
                                  <Reply
                                    className="-ml-0.5 h-5 w-5 text-gray-400"
                                    aria-hidden="true"
                                  />
                                  Reply
                                </button>
                              </span>
                            </nav>
                          </div>
                        </div>
                      </div>
                      {/* Message header */}
                    </div>

                    <div className="min-h-0 flex-1 overflow-y-auto ">
                      <div className="bg-white pb-6 pt-5 shadow">
                        <div className="px-4 sm:flex sm:items-baseline sm:justify-between sm:px-6 lg:px-8">
                          <div className="sm:w-0 sm:flex-1">
                            <h1
                              id="message-heading"
                              className="text-lg font-medium text-gray-900"
                            >
                              {currentMessages[0]?.name}
                            </h1>
                            <p className="mt-1 truncate text-sm text-gray-500">
                              {currentMessages[0]?.senderID}
                            </p>
                          </div>

                          <div className="mt-4 flex items-center justify-between sm:ml-6 sm:mt-0 sm:flex-shrink-0 sm:justify-start">
                            <span className="inline-flex items-center rounded-full bg-cyan-100 px-3 py-0.5 text-sm font-medium text-cyan-800">
                              {currentMessages[0]?.read ? "Read" : "Unread"}
                            </span>
                            <Menu
                              as="div"
                              className="relative ml-3 inline-block text-left"
                            >
                              <div>
                                <Menu.Button className="-my-2 flex items-center rounded-full bg-white p-2 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-blue-600">
                                  <span className="sr-only">Open options</span>
                                  <FaEllipsisVertical
                                    className="h-5 w-5"
                                    aria-hidden="true"
                                  />
                                </Menu.Button>
                              </div>

                              <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                              >
                                <Menu.Items className="absolute right-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                  <div className="py-1">
                                    <Menu.Item>
                                      {({ active }) => (
                                        <button
                                          type="button"
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex w-full justify-between px-4 py-2 text-sm"
                                          )}
                                        >
                                          <span>Copy email address</span>
                                        </button>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex justify-between px-4 py-2 text-sm"
                                          )}
                                        >
                                          <span>Previous conversations</span>
                                        </a>
                                      )}
                                    </Menu.Item>
                                    <Menu.Item>
                                      {({ active }) => (
                                        <a
                                          href="#"
                                          className={classNames(
                                            active
                                              ? "bg-gray-100 text-gray-900"
                                              : "text-gray-700",
                                            "flex justify-between px-4 py-2 text-sm"
                                          )}
                                        >
                                          <span>View original</span>
                                        </a>
                                      )}
                                    </Menu.Item>
                                  </div>
                                </Menu.Items>
                              </Transition>
                            </Menu>
                          </div>
                        </div>
                      </div>
                      {/* Thread section*/}
                      <div className="p-4 overflow-y-auto pb-96">
                        <div className="space-y-2">
                          {currentMessages?.map((message, idx) => (
                            <>
                              {message?.senderID ===
                              usersChatId ? (
                                <div className="ml-auto w-3/4 flex items-end justify-end gap-x-2">
                                  <p className="p-2 rounded-md text-white bg-blue-600 w-auto flex items-end">
                                    {message.message}
                                  </p>
                                  <img
                                    className="inline-block h-6 w-6 rounded-full object-center object-cover"
                                    src={user?.profilePic || profilPic}
                                    alt=""
                                  />
                                </div>
                              ) : (
                                <div className="w-3/4 flex items-end gap-x-2">
                                  <img
                                    className="inline-block h-6 w-6 rounded-full object-center object-cover"
                                    src={profilPic}
                                    alt=""
                                  />

                                  <div className="py-2 px-4 rounded-md text-zinc-900 bg-zinc-200 w-auto flex items-end">
                                    <div>
                                      <div className="text-base font-medium block">
                                        <span className="text-gray-900">
                                          {message.name}
                                        </span>{" "}
                                      </div>
                                      <div>{message.message}</div>
                                    </div>
                                  </div>
                                  <p className="whitespace-nowrap text-sm text-gray-600 sm:ml-3 sm:mt-0">
                                    {generateTimeAgo(message.createdAt)}
                                  </p>
                                </div>
                              )}
                            </>
                          ))}
                          <div className="w-full p-2 fixed bottom-0 right-2 pl-4 lg:pl-64 xl:pl-[650px] ">
                            <div className="bg-white rounded-lg shadow-lg p-4">
                              <form onSubmit={(e) => handleSendMessage(e)}>
                                <textarea
                                  rows={2}
                                  placeholder="Type a message"
                                  className="border-2 w-full border-gray-200 rounded-lg"
                                  value={newMessage}
                                  onChange={(e) =>
                                    setNewMessage(e.target.value)
                                  }
                                  onKeyDown={(e) => {
                                    if (e.key === "Enter" && !e.shiftKey) {
                                      e.preventDefault();
                                      handleSendMessage(e);
                                    }
                                  }}
                                />

                                <div className="w-full flex items-center justify-between ">
                                  <button
                                    type="submit"
                                    className="text-gray-700 rounded-lg p-2 flex items-center gap-3"
                                  >
                                    <Paperclip className="h-auto w-auto" />
                                  </button>
                                  <button
                                    type="submit"
                                    className="bg-emerald-500 hover:bg-emerald-700 text-white rounded-md p-2 flex items-center gap-3"
                                    onClick={(e) => handleSendMessage(e)}
                                  >
                                    Send{" "}
                                    <SendHorizonal className="h-auto w-auto" />
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>

                  {/* Message list*/}
                  <aside className="hidden xl:order-first xl:block xl:flex-shrink-0">
                    <div className="relative flex min-h-screen h-full w-96 flex-col border-r border-gray-200 bg-white">
                      <div className="flex-shrink-0">
                        <div className="flex h-16 flex-col justify-center bg-white px-6">
                          <div className="flex items-baseline space-x-3">
                            <h2 className="text-lg font-medium text-gray-900">
                              Inbox
                            </h2>
                            <p className="text-sm font-medium text-gray-500">
                              {messages.length} messages
                            </p>
                          </div>
                        </div>
                        <div className="border-b border-t border-gray-200 bg-gray-50 px-6 py-2 text-sm font-medium text-gray-500">
                          Sorted by date
                        </div>
                      </div>
                      <nav
                        aria-label="Message list"
                        className="min-h-0 flex-1 overflow-y-auto"
                      >
                        <div
                          role="list"
                          className="divide-y divide-gray-200 border-b border-gray-200"
                        >
                          {messages?.map((message) => (
                            <button
                              key={message.id}
                              className={`relative w-full text-left ${
                                currentMessages[0]?.id === message.id
                                  ? "bg-zinc-100"
                                  : "bg-white"
                              } px-6 py-5 focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 hover:bg-gray-50`}
                              onClick={() => handleGetChat(message.id)}
                            >
                              <div className="flex justify-between space-x-3">
                                <div className="min-w-0 flex-1">
                                  <a
                                    href={message.href}
                                    className="block focus:outline-none"
                                  >
                                    <span
                                      aria-hidden="true"
                                      className="absolute inset-0"
                                    />
                                    <p className="truncate text-sm font-medium text-gray-900">
                                      {message.name}
                                    </p>
                                    <p className="truncate text-sm text-gray-500">
                                      {message.message}
                                    </p>
                                  </a>
                                </div>
                                <div className="flex-shrink-0 whitespace-nowrap text-sm text-gray-500">
                                  {generateTimeAgo(message.createdAt)}
                                </div>
                              </div>
                              <div className="mt-1">
                                <span
                                  className={`inline-flex items-center rounded-md ${
                                    notificationBGColors[message?.type]
                                  } px-2 py-1 text-xs font-medium ${
                                    notificationFontColors[message?.type]
                                  } ring-1 ring-inset ${
                                    notificationRingColors[message?.type]
                                  }`}
                                >
                                  {message?.type === 1
                                    ? "Contact"
                                    : message?.type === 2
                                    ? "Quote Request"
                                    : "Message"}
                                </span>
                              </div>
                            </button>
                          ))}
                        </div>
                      </nav>
                    </div>
                  </aside>
                </main>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="min-h-screen h-full bg-zinc-100 px-4 sm:px-6 lg:px-8 py-10">
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
              Inbox
            </h1>
          </div>

          <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-center py-24">
                <div className="text-center">
                  <img
                    src="https://tailwindui.com/img/ecommerce/icons/icon-chat-light.svg"
                    alt="Inbox"
                    className="w-1/2 h-1/2 mx-auto"
                  />
                  <h3 className="text-lg font-bold text-zinc-800">
                    All Your Messages in One Place
                  </h3>
                  <p className="text-sm font-normal text-gray-500 ml-2">
                    You currently have no messages yet. Promote your space to
                    get more leads.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
