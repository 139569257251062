import React, { useEffect, useState } from "react";
import Overview from "../host/Overview";
import { UserAuth } from "../context/AuthContext";
import { Route, Routes, useLocation } from "react-router-dom";
import PostSpace from "../host/pages/PostSpace";
import SetupAccount from "../host/pages/SetupAccount";
import AlertEmailVerify from "../host/components/AlertEmailVerify";
import YourSpaces from "../host/pages/YourSpaces";
import Bookings from "../host/components/Bookings";
import ShareAndPromote from "../host/pages/ShareAndPromote";
import Inbox from "../host/pages/Inbox";
import EditSpace from "../host/pages/EditSpace";
import SmartPromote from "../host/pages/SmartPromote";
import CreateOffer from "../host/pages/CreateOffer";
import SpaceProfile from "../pages/SpaceProfile";
import RequestQuote from "../host/components/RequestQuote";
import Dashboard from "../host/components/Dashboard";
import SetupGuide from "../host/components/SetupGuide";
import Renters from "../host/pages/Renters";
import Documents from "../host/pages/Documents";
import Analytics from "../host/pages/Analytics";
import CollectionProfile from "../host/pages/CollectionProfile";
import StripePaymentSetup from "../host/pages/StripePaymentSetup";
import Settings from "../host/pages/Settings";
import LicenseAgreement from "../host/components/LicenseAgreement";
import PickAPlan from "../host/components/PickAPlan";
import PlanCheckout from "../host/components/PlanCheckout";
import StripeAuthPage from "../host/pages/StripeAuthPage";
import SmartProfile from "../host/pages/SmartProfile";
import PrivacyPolicy from "../host/pages/PrivacyPolicy";

import { onValue, ref } from "firebase/database";
import { rtdb } from "../firebase/fire";
import SpaceRenterProfile from "../pages/SpaceRenterProfile";
import NavBar from "../components/NavBar";
import ChargeSubscription from "../host/components/ChargeSubscription";
import ConnectComplete from "../host/pages/ConnectComplete";
import StripeAccount from "../host/pages/StripeAccount";
import AddRenter from "../host/components/AddRenter";
import ViewLead from "../host/pages/ViewLead";

export default function HostRoutes() {
  const { user } = UserAuth();
  const [messages, setMessages] = useState([]);
  const location = useLocation();

  // const handleListenToMessages = () => {
  //   const allMessages = ref(rtdb, `chats/${user?.link}`);
  //   // const allMessages = ref(rtdb, `userNotification/${user?.link}/notifications`);
  //   onValue(allMessages, (snapshot) => {
  //     const messages = [];
  //     snapshot.forEach((childSnapshot) => {
  //       messages.push(childSnapshot.val());
  //     });
  //     //order messages by createdAT ISO string
  //     messages.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
  //     setMessages(messages);
  //   });
  // };

  // console.log("messages", messages);

  // useEffect(() => {
  //   handleListenToMessages();
  // }, [user]);

  const isSpaceProfileRoute =
    location.pathname.includes("/profile/") ||
    location.pathname.includes("/info/");

  return (
    <div className="min-h-screen h-full bg-zinc-100">
      {/* <NavBar /> */}

      {isSpaceProfileRoute ? (
        <>
          <NavBar />
          <Routes>
            <Route path="/profile/:id" element={<SmartProfile />} />
            <Route path="/info/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/info/space/:id" element={<SpaceRenterProfile />} />
          </Routes>
        </>
      ) : (
        <Dashboard messages={messages}>
          {!user?.emailVerified && <AlertEmailVerify />}
          <Routes>
            <Route
              path="/"
              element={user?.finishedSetup ? <Overview /> : <SetupGuide />}
            />
            <Route path="/spaces" element={<YourSpaces />} />
            <Route path="/messages" element={<Inbox messages={messages} />} />
            <Route
              path="/messages/:id"
              element={<Inbox messages={messages} />}
            />
            <Route path="/space/:id" element={<SpaceRenterProfile />} />
            <Route path="/stripe-account" element={<StripeAccount />} />
            <Route path="/add-renter" element={<AddRenter />} />
            <Route path="/view-lead/:leadID" element={<ViewLead />} />
            <Route path="/bookings" element={<Bookings />} />
            <Route path="/quote/:id" element={<RequestQuote />} />
            <Route path="/renters" element={<Renters />} />
            <Route path="/return/:id/:uid" element={<ConnectComplete />} />
            <Route path="/space-profile" element={<CollectionProfile />} />
            <Route path="/license/:id" element={<LicenseAgreement />} />
            <Route path="/analytics" element={<Analytics />} />
            <Route path="/create-offer" element={<CreateOffer />} />
            <Route path="/pricing" element={<PickAPlan />} />
            <Route path="/subscription/:id/:uid" element={<ChargeSubscription />} />
            <Route path="/pricing/:plan" element={<PlanCheckout />} />
            <Route path="/documents" element={<Documents />} />
            <Route path="/settings" element={<Settings />} />
            <Route path="/smart-promote" element={<SmartPromote />} />
            <Route path="/edit/:id" element={<EditSpace />} />
            <Route path="/finish-post/:id" element={<ShareAndPromote />} />
            <Route path="/account-setup" element={<SetupAccount />} />
            <Route path="/stripe-setup" element={<StripePaymentSetup />} />
            <Route path="/return/:account/:uid" element={<StripeAuthPage />} />
            <Route path="/post-space" element={<PostSpace />} />
            <Route
              path="/*"
              element={user?.finishedSetup ? <Overview /> : <SetupGuide />}
            />
          </Routes>
        </Dashboard>
      )}
      {/* <Routes>
        <Route path="/profile/:id" element={<SmartProfile />} />
      </Routes> */}
    </div>
  );
}
