import React, { useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { Copy, EditIcon, EyeIcon, PlusCircle, Trash2 } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import DeleteSpace from "./DeleteSpace";
import toast, { Toaster } from "react-hot-toast";
const site = process.env.REACT_APP_SITE_URL;

export default function SpaceSlider() {
  const { spaces, user } = UserAuth();
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();

  const handleDelete = (title, id) => {
    setTitle(title);
    setId(id);
    setOpen(true);
  };

  const handleCopy = (id) => {
    navigator.clipboard.writeText(`${site}space/${id}`);
    toast.success("Link Copied!");    
  };

  function stripHtml(html) {
    return html.replace(/<\/?[^>]+(>|$)/g, "");
  }

  const handleAddSpace = () => {
    navigate("/post-space");
  };

  const gridItems = [];

  // Add existing spaces to the grid
  if (spaces?.length > 0) {
    spaces.slice(0, 3).forEach((space, index) => {
      gridItems.push(
        <div
          key={index}
          className="bg-white p-2 rounded-lg shadow border border-zinc-300 flex flex-col"
        >
          <div className="aspect-w-4 aspect-h-3 overflow-hidden rounded-sm bg-gray-100">
            <img
              className="object-cover object-center"
              src={space?.mainPhoto || space?.photos[0]?.url}
              alt={space?.title}
            />
          </div>
          <h3 className="text-sm font-medium mt-4">
            <Link to={`/space/${space?.id}`} className="hover:text-emerald-500">{space?.title}</Link>
          </h3>
          <h3>
            <Link  to={`/space/${space?.id}`} className="text-sm text-zinc-500 truncate-description">
              {stripHtml(space?.description)}
            </Link>
          </h3>

          <div className="flex items-center justify-between pt-4 mt-auto">
            <div>
              <Tippy content="View Space">
                <Link
                  to={`/info/space/${space?.id}`}
                  className="ml-2 inline-flex justify-center bg-white text-sm font-semibold text-sky-500 hover:text-sky-800"
                >
                  <EyeIcon className="h-5 w-5" />
                </Link>
              </Tippy>
            </div>
            <div>
              <Tippy content="Copy Link To Share Space">
                <button
                  className="ml-2 inline-flex justify-center bg-white text-sm font-semibold text-amber-600 hover:text-amber-800"
                  onClick={() => {handleCopy(space?.id)}}
                >
                  <Copy className="h-5 w-5" />
                </button>
              </Tippy>
              <Tippy content="Edit Space">
                <Link
                  to={`/edit/${space?.id}`}
                  className="ml-2 inline-flex justify-center bg-white text-sm font-semibold text-emerald-500 hover:text-emerald-800"
                >
                  <EditIcon className="h-5 w-5" />
                </Link>
              </Tippy>
              <Tippy content="Delete Space">
                <button
                  className="ml-2 inline-flex justify-center bg-white text-sm font-semibold text-red-500 hover:text-red-800"
                  onClick={() => handleDelete(space?.title, space?.id)}
                >
                  <Trash2 className="h-5 w-5" />
                </button>
              </Tippy>
            </div>
          </div>
        </div>
      );
    });
  }

  if (user?.plan === "studio" || user?.plan === "enterprise") {
    // Add "Add a new space" buttons to fill remaining slots
    for (let i = spaces?.length || 0; i < 3; i++) {
      gridItems.push(
        <button
          key={`add-space-${i}`}
          type="button"
          className="relative hidden sm:block w-full rounded-lg border-2 border-dashed border-gray-300 p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-emerald-500 focus:ring-offset-2"
          onClick={handleAddSpace}
        >
          <PlusCircle className="mx-auto h-12 w-12 text-gray-400" />
          <span className="mt-2 block text-sm font-semibold text-gray-900">
            Add a space
          </span>
        </button>
      );
    }
  }

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      <DeleteSpace title={title} id={id} open={open} setOpen={setOpen} />
      <div
        className={`grid ${
          !user?.plan || user?.plan === "temp" || user?.plan === "basic"
            ? "grid-cols-1"
            : "xl:grid-cols-2 md:grid-cols-1 sm:grid-cols-2"
        } gap-2`}
      >
        {gridItems}
      </div>
    </div>
  );
}
