import { Fragment, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { Mail, Phone, X, User } from "lucide-react";
import { CgGoogle } from "react-icons/cg";
import {
  addChatMessage,
  createLead,
  sendFormNotification,
} from "../../functions/realtime";
import { createNewLead, generateRandomID } from "../../functions/crud";
import { useNavigate } from "react-router-dom";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function Contact({
  title,
  pageColor = "emerald",
  locationLink,
  userID,
}) {
  const [open, setOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("phone");
  const cancelButtonRef = useRef(null);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [emailSent, setEmailSent] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  const navigate = useNavigate();

  const handleSendEmail = () => {
    if (!email) {
      alert("Please enter your email address");
      return;
    } else if (!name) {
      alert("Please enter your full name");
      return;
    } else {
      const id = generateRandomID();
      const leadInfo = {
        name,
        email,
        phone,
        message,
        viewed: false,
        status: 0,
        id: id,
        profilePic: "",
        chatID: 0,
        hasAccount: false,
        replied: false,
        isRenter: false,
        type: title === "touch" ? 0 : title === "quote" ? 1 : title === "quoter" ? 1 : 3,
        leadParams: locationLink,
        sender: "user",
        space: "smart",
        startDate: "",
        createdAt: new Date().toISOString(),
        userID,
      };
      console.log(leadInfo);
      createNewLead(leadInfo);
      setEmailSent(true);
      navigate(`/thank-you/${id}`);
    }
  };

  return (
    <>
      {title === "touch" ? (
        <button
          type="button"
          className={`rounded-md bg-${pageColor}-600 px-5 py-3.5 text-xl font-semibold text-white shadow-sm hover:bg-${pageColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${pageColor}-600`}
          onClick={() => setOpen(true)}
        >
          Get in Touch
        </button>
      ) : title === "quote" ? (
        <button
          className={`inline-flex rounded-md bg-${pageColor}-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-${pageColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${pageColor}-600`}
          onClick={() => setOpen(true)}
        >
          {" "}
          Request Quote
        </button>
      ) : title === "quoter" ? (
        <button
          type="button"
          className={`rounded-md bg-white px-3.5 py-2.5 text-sm font-semibold text-${pageColor}-600 shadow-sm hover:bg-${pageColor}-50 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white`}
          onClick={() => setOpen(true)}
        >
          Request Quote
        </button>
      ) : (
        <button
          type="button"
          className={`flex w-full items-center justify-center rounded-md border border-transparent bg-${pageColor}-200 hover:bg-${pageColor}-300 hover:text-${pageColor}-600 px-4 py-3 text-lg font-medium text-${pageColor}-600  shadow-md shadow-zinc-300`}
          onClick={() => setOpen(true)}
        >
          Make an Offer
        </button>
      )}

      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="relative z-50"
          initialFocus={cancelButtonRef}
          onClose={setOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <button
                    type="button"
                    className="absolute top-2 right-2 inline-flex items-center justify-center rounded-md bg-white text-gray-400 hover:text-gray-500 "
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    <X className="h-6 w-6" />
                  </button>
                  <Dialog.Title
                    as="h3"
                    className="text-2xl font-semibold leading-6 text-center text-gray-900"
                  >
                    {title === "touch"
                      ? "Get in Touch"
                      : title === "quote" || title === "quoter"
                      ? "Request a Quote"
                      : "Make an Offer"}
                    <p className="mt-2 text-sm text-gray-500">
                      We'll get back to you right away
                    </p>
                  </Dialog.Title>

                  <div className="mt-5 sm:mt-6">
                    {emailSent ? (
                      <div>
                        <div>
                          <label
                            htmlFor="password"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            id="password"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter a password to create an account"
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="passwordConfirm"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Confirm Password
                          </label>
                          <input
                            type="password"
                            name="passwordConfirm"
                            id="passwordConfirm"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Please Confirm Your Password"
                            onChange={(e) => setConfirmPassword(e.target.value)}
                          />
                        </div>
                      </div>
                    ) : (
                      <div>
                        <div>
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email Address
                          </label>
                          <input
                            type="email"
                            name="email"
                            id="email"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter your email address"
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </div>
                        <div className="mt-4">
                          <label
                            htmlFor="name"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Full Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            id="name"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter your full name"
                            onChange={(e) => setName(e.target.value)}
                          />
                        </div>

                        <div className="mt-4">
                          <label
                            htmlFor="phone"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Phone Number
                          </label>
                          <input
                            type="tel"
                            name="phone"
                            id="phone"
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6 h-12"
                            placeholder="Enter your phone number"
                            onChange={(e) => setPhone(e.target.value)}
                          />
                        </div>
                        <div>
                          <label
                            htmlFor="message"
                            className="block mt-4 text-sm font-medium text-gray-700"
                          >
                            {title === "offer"
                              ? "In a few words, tell us about your offer"
                              : title === "quote"
                              ? "Tell us what you're looking for"
                              : "Message"}
                          </label>
                          <textarea
                            id="message"
                            name="message"
                            rows={4}
                            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6"
                            placeholder="Enter your message"
                            onChange={(e) => setMessage(e.target.value)}
                          />
                        </div>
                      </div>
                    )}
                    <div className="mt-5 sm:mt-6 ">
                      <button
                        type="button"
                        className={`inline-flex w-full justify-center rounded-md bg-${pageColor}-600 px-3 py-4 text-lg font-semibold text-white shadow-sm hover:bg-${pageColor}-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-${pageColor}-600 sm:col-start-2`}
                        onClick={() => handleSendEmail()}
                      >
                        Send{" "}
                        {title === "offer"
                          ? "Offer"
                          : title === "quote" || title === "quoter"
                          ? "Request"
                          : "Message"}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
