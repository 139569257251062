import { ArrowLeft, ArrowRight } from "lucide-react";
import React, { useState } from "react";


export default function UseCase({ setCurrent, formData, setFormData }) {
 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className=" bg-zinc-50 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 overflow-hidden rounded-lg  shadow max-w-xl mx-auto">
      <div className="px-4 py-5 sm:p-6">
        <h2 className="text-xl font-semibold">Intended Use For the Space</h2>
        <p className="text-gray-600 mb-6">
          Please fill in the following details:
        </p>
        <form>
          <div className="grid grid-cols-6 gap-4">
            <div className="col-span-full">
              <label
                htmlFor="useCase"
                className="block text-sm font-medium text-gray-700"
              >
                What do you plan to use the space for?
              </label>
              <p className="text-gray-600 text-sm mb-2">
                (e.g. office, storage, retail, etc.)
              </p>
              <textarea
                rows={7}
                name="useCase"
                id="useCase"
                className="block w-full rounded-md border-0 py-1.5  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-emerald-600 sm:text-sm sm:leading-6"
                value={formData.useCase}
                onChange={handleChange}
              />
              <p className="text-gray-600 text-sm mt-2">
                Please be as detailed as possible.
              </p>
            </div>

            <div className="col-span-full flex items-center justify-between">
            <button
                type="submit"
                className=" bg-emerald-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrent(1);
                }}
              >
                <ArrowLeft className="h-4 w-4 mr-2" /> Back
              </button>
              <button
                type="submit"
                className="ml-auto bg-emerald-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrent(3);
                }}
              >
                Next <ArrowRight className="h-4 w-4 ml-2" />
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
