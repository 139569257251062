import React, { useState } from "react";
import SmartPricing from "../host/components/SmartPricing";

export default function Calculator() {
  const [smartData, setSmartData] = useState(null);

  console.log(smartData, "smartData");
  return (
    <div>
      <div className="bg-white max-w-7xl mx-auto pb-36">
        <SmartPricing setSmartData={setSmartData} />
      </div>
      <div className="fixed bottom-0 right-0 left-0 bg-emerald-600 text-white px-4 py-6 sm:px-6 lg:px-8 shadow flex">
        <div className="ml-auto">
        <div className="text-sm font-medium leading-6 text-emerald-100">
        Estimated Earnings:{" "}
        </div>
          <div>
            
            <span className="text-4xl font-semibold tracking-tight text-white">
              ${smartData?.baseMonth || 0} - ${smartData?.baseHour || 0}{" "}
            </span>{" "}
            <span className="text-sm text-emerald-50">/ month</span>
          </div>
        </div>
      </div>
    </div>
  );
}
