import React from 'react'

export default function CurrentContracts() {
  return (
    <div className="overflow-hidden rounded-lg bg-orange-500 shadow">
    <div className="px-4 py-5 mt-2 bg-white">
      <h3 className="text-lg font-semibold text-gray-900">Current Contracts</h3>
      <p className="mt-2 text-sm text-gray-600 text-center">
        You have no current contracts
      </p>
      
    </div>
  </div>
  )
}
