import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import { UserAuth } from "../../context/AuthContext";
import { X } from "lucide-react";
const site = process.env.REACT_APP_SITE_URL;

export default function CheckoutForm({ planType, payID }) {
  const stripe = useStripe();
  const elements = useElements();
  const { user } = UserAuth();
  const [todaysDate, setTodaysDate] = useState(new Date());
  // date 7 days from now
  const [planStartDate, setPlanStartDate] = useState(
    new Date().setDate(todaysDate.getDate() + 7)
  );

  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const tiers = [
    {
      name: "Starter",
      id: "tier-freelancer",
      href: "/pricing/starter",
      priceMonthly: "$19",
      price: 19,
      description: "For single space owners just getting started.",
      features: [
        "1 space",
        "1 location",
        "Lead Manager",
        "Basic analytics",
        "48-hour support response time",
        "6.2% per booking",
      ],
      mostPopular: false,
    },
    {
      name: "Studio",
      id: "tier-startup",
      href: "/pricing/studio",
      priceMonthly: "$29",
      price: 29,
      description:
        "For businesses with one or more space at a single location.",
      features: [
        "5 spaces",
        "1 location",
        "Lead Manager",
        "Basic analytics",
        "24-hour support response time",
        "5.2% per booking",
        "Marketing automations",
        "Custom branding",
      ],
      mostPopular: true,
    },
    {
      name: "Enterprise",
      id: "tier-enterprise",
      href: "/pricing/multi",
      priceMonthly: "$59",
      price: 59,
      description: "For large businesses with multiple spaces and location.",
      features: [
        "Unlimited spaces",
        "Unlimited locations",
        "Lead Manager",
        "Advanced analytics",
        "12-hour support response time",
        "3.6% per booking",
        "Marketing automations",
        "Custom branding",
        "Dedicated account manager",
        "API access",
      ],
      mostPopular: false,
    },
  ];

  useEffect(() => {
    if (!stripe) {
      return;
    }

    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );

    if (!clientSecret) {
      return;
    }

    stripe
      .retrievePaymentIntent(clientSecret)
      .then(async ({ paymentIntent }) => {
        switch (paymentIntent.status) {
          case "succeeded":
            setMessage("Payment succeeded!");
            break;
          case "processing":
            setMessage("Your payment is processing.");
            break;
          case "requires_payment_method":
            setMessage("Your payment was not successful, please try again.");
            break;
          default:
            setMessage("Something went wrong.");
            break;
        }
      });
  }, [stripe]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsLoading(true);

    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        // Make sure to change this to your payment completion page
        return_url: `${site}subscription/${planType}/${user?.id}`,
      },
    });

    // await updateUser(user?.id, {
    //   plan: planType,
    //   planStartDate: planStartDate,
    //   defaultMethod: paymentIntent.payment_method,
    // });

    // This point will only be reached if there is an immediate error when
    // confirming the payment. Otherwise, your customer will be redirected to
    // your `return_url`. For some payment methods like iDEAL, your customer will
    // be redirected to an intermediate site first to authorize the payment, then
    // redirected to the `return_url`.
    if (error.type === "card_error" || error.type === "validation_error") {
      setMessage(error.message);
    } else {
      setMessage("An unexpected error occurred.");
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };

  return (
    <div className="max-w-xl mx-auto">
      <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
        Sign Up for Your Plan
      </h1>
      <p className="mt-2 text-sm text-gray-500">
        Pay for your{" "}
        <span className="capitalize text-zinc-700 font-bold">{planType}</span>{" "}
        plan
      </p>
      {message && (
      <div className="rounded-md bg-red-50 p-4 border border-red-500 mt-4">
        <div className="flex">
          <div className="flex-shrink-0">
            <X aria-hidden="true" className="h-5 w-5 text-red-400" />
          </div>
          <div className="ml-3">
            <h3 className="text-sm font-medium text-red-800">{message}</h3>
          </div>
        </div>
      </div>
      )}
      <div className="mt-4">
        <div className="overflow-hidden rounded-lg bg-white shadow ">
          <div className="px-4 py-5 sm:p-6">
          <h2 className="text-lg font-bold leading-7 text-gray-900 sm:text-3xl sm:truncate tracking-tight">
              Plan Summary
            </h2>
            <p className="text-sm text-gray-500 uppercase">{planType} Plan</p>
            <div className="mt-6 divide-y divide-slate-900/10">
              <div className="pb-4 flex items-center justify-between gap-x-4 text-sm leading-6 text-slate-700">
                <div className="font-medium capitalize">{planType} Subtotal</div>
                <div className="font-bold text-slate-950">
                  {
                    tiers.find((tier) => tier.name.toLowerCase() === planType)
                      .priceMonthly
                  }
                  .00
                </div>
              </div>
              <div className="flex items-center justify-between gap-x-4 py-4 text-sm leading-6 text-slate-700">
                <div>New Studio Discount</div>
                <div className="font-semibold text-red-500">
                  -$
                  {tiers.find((tier) => tier.name.toLowerCase() === planType)
                    .price - 1}
                  .00
                </div>
              </div>
              <div className="flex items-center justify-between gap-x-4 pt-4 text-base leading-6 text-slate-700">
                <div className="font-medium">Total Due Today</div>
                <div className="font-bold text-slate-950">$1.00</div>
              </div>
              
             
            </div>
            <form id="payment-form" className="mt-4 border-t py-4 border-slate-900/10">
              <PaymentElement
                id="payment-element"
                options={paymentElementOptions}
              />

              {/* Show any error or success messages */}
              {message && (
                <div
                  className="text-red-500 font-bold text-center"
                  id="payment-message"
                >
                  {message}
                </div>
              )}
            </form>
            <button
                disabled={isLoading || !stripe || !elements}
                id="submit"
                className="w-full ml-auto block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600 disabled:bg-emerald-300 disabled:cursor-not-allowed h-12"
                onClick={handleSubmit}
              >
                <span id="button-text">
                  {isLoading ? "Processing..." : "Get Started for $1"}
                </span>
              </button>
              <p className="text-xs text-gray-500 mt-2">
                By clicking "Pay now", you agree to our{" "}
                <a href="#" className="text-indigo-600 underline">
                  Terms of Service
                </a>{" "}
                and{" "}
                <a href="#" className="text-indigo-600 underline">
                  Privacy Policy
                </a>
                .
              </p>
              <p className="text-xs text-gray-500 mt-4">
                Your plan will renew at the standard rate of{" "}
                {
                  tiers.find((tier) => tier.name.toLowerCase() === planType)
                    .priceMonthly
                }{" "}
                per month after the first 7 days on{" "}
                <span className="font-semibold text-zinc-800">
                  {new Date(planStartDate).toDateString()}.
                </span>
              </p>
          </div>
          
        </div>
      </div>
    </div>
  );
}
