import { Verified } from "lucide-react";
import React, { useState } from "react";
import { verifyUserEmail } from "../../functions/crud";

export default function AlertEmailVerify() {
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const [dismissed, setDismissed] = useState(false);

  const handleEmailVerify = () => {
    verifyUserEmail();
    setEmailVerificationSent(true);
  };

  const handleDismiss = (e) => {
    e.preventDefault();
    setDismissed(true);
    setEmailVerificationSent(true);
  };

  return (
    <>
      {dismissed ? (
        <></>
      ) : emailVerificationSent ? (
        <div className="px-4 pb-4">
          <div className="bg-green-50 border-l-4 border-green-600 p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <Verified
                  className="h-5 w-5 text-green-400"
                  aria-hidden="true"
                />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-green-700">
                  Email verification sent! Please check your email to verify
                  your account
                </p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <button
                    onClick={(e) => handleDismiss(e)}
                    className="whitespace-nowrap font-medium text-green-700 hover:text-green-600"
                  >
                    Dismiss
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="px-4 pb-4 max-w-7xl mx-auto">
          <div className=" bg-red-50 border-l-4 border-red-600 p-4 shadow">
            <div className="flex">
              <div className="flex-shrink-0">
                <Verified className="h-5 w-5 text-red-400" aria-hidden="true" />
              </div>
              <div className="ml-3 flex-1 md:flex md:justify-between">
                <p className="text-sm text-red-700">
                  Please verify your email to start hosting
                </p>
                <p className="mt-3 text-sm md:ml-6 md:mt-0">
                  <button
                    className="whitespace-nowrap font-medium text-red-700 hover:text-red-600"
                    onClick={handleEmailVerify}
                  >
                    Verify Email
                    <span aria-hidden="true"> &rarr;</span>
                  </button>
                </p>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
