import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import About from "../pages/About";
import NavBar from "../components/NavBar";
import Login from "../pages/Login";
import ListingSignUp from "../pages/ListingSignUp";
import CreateAccount from "../pages/CreateAccount";
import CreateHostAccount from "../pages/CreateHostAccount";

import SmartProfile from "../host/pages/SmartProfile";
import ContactForm from "../pages/ContactForm";
import PrivacyPolicy from "../host/pages/PrivacyPolicy";
import SpaceRenterProfile from "../pages/SpaceRenterProfile";
import Footer from "../components/Footer";
import NeedASpaceForm from "../pages/NeedASpaceForm";
import ThankYou from "../pages/ThankYou";
import ForgotPassword from "../pages/ForgotPassword";

export default function NonUserRoutes() {
  const location = useLocation();

  const isSpaceProfileRoute = location.pathname.includes("/space/") || location.pathname.includes("/profile/");

  return (
    <>
      {isSpaceProfileRoute ? (
        <div className="min-h-screen h-full bg-zinc-100">
          <NavBar />
          <Routes>
            <Route path="/space/:id" element={<SpaceRenterProfile />} />
            <Route path="/profile/:id" element={<SmartProfile />} />
            <Route path="/info/space/:id" element={<SpaceRenterProfile />} />
          </Routes>
        </div>
      ) : (
        <div className="min-h-screen h-full bg-zinc-950">
          <NavBar />
          <Routes>
            <Route path="/profile/:id" element={<SmartProfile />} />
            <Route path="/info/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/" element={<ListingSignUp />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/login" element={<Login />} />
            <Route path="/listing-signup" element={<ListingSignUp />} />
            <Route path="/thank-you/:id" element={<ThankYou />} />

            <Route
              path="/create-account/host/:id"
              element={<CreateHostAccount />}
            />
            <Route path="/need-space" element={<NeedASpaceForm />} />
            <Route path="/create-account/renter" element={<CreateAccount />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<ContactForm />} />
            <Route path="/inbox/:id" element={<CreateAccount />} />
            <Route path="/*" element={<ListingSignUp />} />
          </Routes>
          <Footer />
        </div>
      )}
    </>
  );
}
