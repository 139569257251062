import React, { useEffect, useState } from "react";
import { getSpaceData } from "../../functions/crud";
import { useParams } from "react-router-dom";
import Progress from "./quote/Progress";
import OrderSummary from "./quote/OrderSummary";
import TenantInfo from "./quote/TenantInfo";
import Datestimes from "./quote/Datestimes";
import StartBooking from "../../components/StartBooking";
import UseCase from "./quote/UseCase";
import SpaceAgreement from "./quote/SpaceAgreement";

const initialDateState = {
  from: "",
  to: "",
};

export default function RequestQuote() {
  const [space, setSpace] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [current, setCurrent] = useState(0);
  const [timeSlots, setTimeSlots] = useState([]);
  const [datesSelected, setDatesSelected] = useState([]);
  const [totalCostPerMonth, setTotalCostPerMonth] = useState(0);
  const [dates, setDates] = useState(initialDateState);
  const [totalMonths, setTotalMonths] = useState(0);
  const [totalCostOverall, setTotalCostOverall] = useState(0);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    phone: "",
    email: "",
    businessName: "",
    useCase: "",
    totalCostPermonth: 0,
    totalMonths: 0,
    dates: {},
    securityDeposit: 0,
    fees: 0,
    contractFee: 0,
  });

  const { id } = useParams();

  useEffect(() => {
    setIsLoading(true);
    getSpaceData(id).then((data) => {
      setSpace(data);
      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    });
  }, [id]);

  const currentStep = [
    <TenantInfo setCurrent={setCurrent} formData={formData} setFormData={setFormData}/>,
    <StartBooking
      space={space}
      timeSlots={timeSlots}
      setTimeSlots={setTimeSlots}
      datesSelected={datesSelected}
      setDatesSelected={setDatesSelected}
      dates={dates}
      setDates={setDates}
      setCurrent={setCurrent} 
      formData={formData} 
      setFormData={setFormData}
    />,
    <UseCase setCurrent={setCurrent} formData={formData} setFormData={setFormData} />,
    <SpaceAgreement setCurrent={setCurrent} formData={formData} setFormData={setFormData} />,
  ];

  const baseHours = {
    Sunday: space?.prices?.sundayBaseHour || 0,
    Monday: space?.prices?.mondayBaseHour || 0,
    Tuesday: space?.prices?.tuesdayBaseHour || 0,
    Wednesday: space?.prices?.wednesdayBaseHour || 0,
    Thursday: space?.prices?.thursdayBaseHour || 0,
    Friday: space?.prices?.fridayBaseHour || 0,
    Saturday: space?.prices?.saturdayBaseHour || 0,
  };

  const baseDays = {
    Sunday: space?.prices?.sundayBaseDay || 0,
    Monday: space?.prices?.mondayBaseDay || 0,
    Tuesday: space?.prices?.tuesdayBaseDay || 0,
    Wednesday: space?.prices?.wednesdayBaseDay || 0,
    Thursday: space?.prices?.thursdayBaseDay || 0,
    Friday: space?.prices?.fridayBaseDay || 0,
    Saturday: space?.prices?.saturdayBaseDay || 0,
  };

  const totalHoursPerDay = {
    Sunday: space?.prices?.sundayTimes?.length || 0,
    Monday: space?.prices?.mondayTimes?.length || 0,
    Tuesday: space?.prices?.tuesdayTimes?.length || 0,
    Wednesday: space?.prices?.wednesdayTimes?.length || 0,
    Thursday: space?.prices?.thursdayTimes?.length || 0,
    Friday: space?.prices?.fridayTimes?.length || 0,
    Saturday: space?.prices?.saturdayTimes?.length || 0,
  };

  const countDayOccurrences = (timeSlots) => {
    const dayCounts = {};
    timeSlots.forEach((slot) => {
      const { day } = slot;
      dayCounts[day] = (dayCounts[day] || 0) + 1;
    });
    return dayCounts;
  };

  // Calculate the total hours for each day
  const calculateTotalHours = (dayCountTotal, baseHours, hourCountsPerDay) => {
//    console.log(hourCountsPerDay, totalHoursPerDay)
    const totalHours = {};
    for (const day in hourCountsPerDay) {
      if (hourCountsPerDay[day] === totalHoursPerDay[day]) {
        totalHours[day] = baseDays[day] * dayCountTotal[day];
      } else {
        totalHours[day] = hourCountsPerDay[day] * baseHours[day] * dayCountTotal[day];
      }
    }
    return totalHours;
  };





  //   const datesForTheDays = (datesSelected) => {
  //     const dates = {};
  //     datesSelected.forEach((date) => {
  //       const { date, day } = date;
  //         dates[day] = dates[day] || [];

  const uniqueDays = new Set(timeSlots.map((slot) => slot.day));

  // Filter datesSelected based on whether the day is present in uniqueDays
  const filteredDates = datesSelected.filter((date) =>
    uniqueDays.has(date.day)
  );



    // Count occurrences of each day
const dayCounts = countDayOccurrences(filteredDates);
const hourCountsPerDay = countDayOccurrences(timeSlots);

const calculateTotalMonths = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);
    const months = (end.getFullYear() - start.getFullYear()) * 12;
    return months + end.getMonth() - start.getMonth();
  };

useEffect(() => {
    const totalForEachDay = calculateTotalHours(dayCounts, baseHours, hourCountsPerDay)
    const totalCost = Object.values(totalForEachDay).reduce((acc, curr) => acc + curr, 0);
    setTotalCostOverall(totalCost);
    const totalMonths = calculateTotalMonths(dates.from, dates.to);
    setTotalMonths(totalMonths);
    const costPerMonth = totalMonths > 0 ? totalCost / totalMonths : totalCost;
    setTotalCostPerMonth(Math.floor(costPerMonth));
  }, [timeSlots]);





 

  return (
    <div>
      {isLoading && (
        <div className="h-screen w-screen flex items-center justify-center">
          <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
        </div>
      )}
      <div className="mx-auto flex w-full max-w-full items-start gap-x-8 px-4 py-10 sm:px-6 lg:px-8">
        <aside className="sticky top-8 hidden w-96 shrink-0 lg:block">
          <Progress current={current} />
        </aside>

        <main className="flex-1">{currentStep[current]}</main>

        <aside className="sticky top-8 hidden w-96 shrink-0 xl:block">
          <OrderSummary
            totalCostPerMonth={totalCostPerMonth}
            filteredDates={filteredDates}
            space={space}
            totalMonths={totalMonths}
            totalCostOverall={totalCostOverall}
          />
        </aside>
      </div>
    </div>
  );
}
