import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";
import { states } from "../../assets/js/states";
import Alert from "../../components/Alert";
import { updateUser } from "../../functions/crud";
import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const billingInfoInit = {
  accountHolderName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
};

const stripePromise = loadStripe("pk_test_AzN0dNPHyuaa4iEhC1ZXSydP");

export default function PaymentDetails() {
  return (
    <Elements stripe={stripePromise}>
      <PayForm />
    </Elements>
  );
}

function PayForm() {
  const { user } = UserAuth();
  const [loading, setLoading] = useState(false);
  const [billingInfo, setBillingInfo] = useState(billingInfoInit);

  const [skipped, setSkipped] = useState(false);
  const [alerter, setAlerter] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");

  const stripe = useStripe();
  const elements = useElements();

  const handleSkip = () => {
    updateUser(user?.id, { stripeCardAdded: false, stripeSetup: true });
    setSkipped(true);
  };

  const handleAttachPaymentMethod = async (paymentMethodID) => {
    try {
      const response = await fetch(
        "http://127.0.0.1:5001/competesocial-dd2a2/us-central1/attatchPaymentMethodToCustomer",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            paymentMethodID: paymentMethodID,
            customer: user?.stripeCustomerID,
          }),
        }
      );
  
      const data = await response.json();
      console.log(data);
      return "success";
    } catch (error) {
      console.log(error);
      return "error";
    }
  };

  const handleCreateStripePaymentMethod = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (!stripe || !elements) {
      return;
    } else {
      setAlerter(false);
      setAlertMessage("");
      const result = await stripe.createPaymentMethod({
        type: "card",
        card: elements.getElement(CardElement),
      });

      if (result.error) {
        // Show error to your customer (for example, payment details incomplete)
        console.log(result.error.message);
        setLoading(false);
      } else {
        const data = {
          billingInfo: {
            paymentMethod: result.paymentMethod.id,
            cardBrand: result.paymentMethod.card.brand,
            cardLast4: result.paymentMethod.card.last4,
            cardExpMonth: result.paymentMethod.card.exp_month,
            cardExpYear: result.paymentMethod.card.exp_year,
          },
          stripeCardAdded: true,
          stripeSetup: true,
        };
        const attatched = await handleAttachPaymentMethod(
          result.paymentMethod.id
        );
        if (attatched === "success") {
          updateUser(user?.id, data);
          setLoading(false);
        } else {
          setAlerter(true);
          setAlertMessage("Error adding payment method. Please try again.");
          setLoading(false);
        }
      }
    }
  };

  useEffect(() => {
    if (user?.stripeSetup && user?.linkConfigured && user?.accountUpdated) {
      updateUser(user?.id, { finishedSetup: true });
    }
  }, [user]);

  return (
    <>
      <div className="isolate py-12">
        <div className="mx-auto max-w-2xl text-center">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Add a Payment Method
          </h2>
          <p className="mt-2 text-md leading-8 text-gray-600">
            Adding a payment method will allow you to make reservations and pay
            for your bookings.
          </p>
        </div>
        <div className="divide-y divide-gray-200 overflow-hidden border border-zinc-400 rounded-xl bg-zinc-400/10 shadow max-w-2xl mx-auto mt-6">
          <div className="px-4 py-5 sm:px-6">
            <h2 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
              Card Information
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Enter your card details to add a payment method.
            </p>
            {alerter && (
              <div className="my-6">
                <Alert message={alertMessage} />
              </div>
            )}
            <form onSubmit={handleCreateStripePaymentMethod}>
              <div className="bg-white p-4 mt-2 block w-full rounded-xl border border-zinc-900  text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-zinc-900 sm:text-sm sm:leading-6">
                <CardElement />
              </div>
              <p className="mt-1 ml-1 text-xs text-gray-500">
                Powered by{" "}
                <a href="https://stripe.com" className="text-emerald-600">
                  Stripe
                </a>{" "}
                for secure payments and fraud protection.
              </p>

              <div className="mt-10 flex items-center justify-between">
                <button
                  type="button"
                  className="text-emerald-600 font-semibold text-sm w-full"
                  onClick={handleSkip}
                >
                  {" "}
                  Skip for now
                </button>

                <button
                  type="submit"
                  className="block w-full rounded-md bg-emerald-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  onClick={handleCreateStripePaymentMethod}
                  disabled={!stripe}
                >
                  {loading ? "Adding..." : "Save and Continue"}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
