import Loading from "./components/Loading";
import ScrollToTop from "./components/ScrollToTop";
import { UserAuth } from "./context/AuthContext";
import NonUserRoutes from "./routes/NonUserRoutes";
import Calculator from "./pages/Calculator";
import HostRoutes from "./routes/HostRoutes";
import RenterRoutes from "./routes/RenterRoutes";
import { Suspense } from "react";

function App() {
  const { isLoggedOut, isLoading, isRenter, isProvider } = UserAuth();
  const path = window.location.pathname;
  const renter = isRenter();
  const provider = isProvider();

  return (
    <>
      <ScrollToTop />
      {path === "/calculator" ? (
        <Calculator />
      ) : (
        <>
          <Suspense fallback={<Loading />}>
            {isLoading && <Loading />}
            {isLoggedOut && <NonUserRoutes />}
            {!isLoggedOut && (
              <>
                {renter && <RenterRoutes />}
                {provider && <HostRoutes />}
              </>
            )}
          </Suspense>
        </>
      )}
    </>
  );
}

export default App;
