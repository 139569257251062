import { Link, useNavigate } from "react-router-dom";
import { UserAuth } from "../../context/AuthContext";
import spaceImg from "../../assets/images/content.svg";
import {
  EyeIcon,
  FilePenIcon,
  Trash,
  Trash2,
  VenetianMask,
} from "lucide-react";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";
import DeleteSpace from "../components/DeleteSpace";
import { useState } from "react";

export default function YourSpaces() {
  const { spaces, user } = UserAuth();
  const [title, setTitle] = useState("");
  const [id, setId] = useState("");
  const [open, setOpen] = useState(false);

  const handleDelete = (title, id) => {
    setTitle(title);
    setId(id);
    setOpen(true);
  };

  return (
    <>
      <DeleteSpace title={title} id={id} open={open} setOpen={setOpen} />
      {spaces?.length > 0 ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
                Spaces
              </h1>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Link
                to="/post-space"
                type="button"
                className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              >
                Add Space
              </Link>
            </div>
          </div>
          <div className="mt-8 flow-root ">
            <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8 ">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8 ">
                <table className="min-w-full divide-y divide-gray-300 shadow shadow-zinc-400 overflow-hidden ring-1 ring-black ring-opacity-5 rounded-xl">
                  <thead>
                    <tr className="bg-zinc-100">
                      <th
                        scope="col"
                        className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 "
                      >
                        Title
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Address
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Status
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Current Renters
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                      >
                        Total Earnings
                      </th>
                      <th
                        scope="col"
                        className="px-3 py-3.5 text-right text-sm font-semibold text-gray-900"
                      >
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200 bg-white">
                    {spaces?.map((space) => (
                      <tr key={space.id} className="">
                        <td className="whitespace-nowrap py-5 pl-4 pr-3 text-sm ">
                          <div className="flex items-center">
                            <div className="h-11 w-11 flex-shrink-0">
                              <img
                                className="h-11 w-11 rounded-md object-cover object-center"
                                src={space?.mainPhoto}
                                alt={space?.title}
                              />
                            </div>
                            <div className="ml-4">
                              <div className="font-medium text-gray-900">
                                {space.title}
                              </div>
                              <div className="mt-1 text-gray-500">
                                {space.email}
                              </div>
                            </div>
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="text-gray-900">{space.address}</div>
                          <div className="mt-1 text-gray-500">
                            {space.city}, {space.state} {space.zip}
                          </div>
                        </td>

                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/20">
                            Active
                          </span>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 font-semibold">
                          {space.renters?.length > 0 ? (
                            <span className="text-emerald-600">
                              {space.renters?.length}
                            </span>
                          ) : (
                            <span className="text-red-500">0</span>
                          )}
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500">
                          <div className="text-green-500 font-bold">
                            {" "}
                            ${space?.earnings || 0}
                          </div>
                        </td>
                        <td className="whitespace-nowrap px-3 py-5 text-sm text-gray-500 flex items-center justify-end gap-3">
                          <Tippy content="View Space">
                            <Link
                              to={`/info/space/${space.id}`}
                              className="text-blue-500 hover:text-blue-800"
                            >
                              {" "}
                              <EyeIcon className="h-6 w-6" />
                            </Link>
                          </Tippy>
                          {/* <Tippy content="View Space as Renter">
                            <Link
                              to={`/info/space/${space.id}`}
                              className="text-purple-500 hover:text-purple-800"
                              target="_blank"
                            >
                              {" "}
                              <VenetianMask className="h-6 w-6" />
                            </Link>
                          </Tippy> */}
                          <Tippy content="Edit Space">
                            <Link
                              to={`/edit/${space.id}`}
                              className="text-emerald-500 hover:text-emerald-800"
                            >
                              {" "}
                              <FilePenIcon className="h-6 w-6" />
                            </Link>
                          </Tippy>
                          <Tippy content="Delete Space">
                            <button
                              className="text-red-500 hover:text-red-800"
                              onClick={() =>
                                handleDelete(space.title, space.id)
                              }
                            >
                              {" "}
                              <Trash2 className="h-6 w-6" />
                            </button>
                          </Tippy>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
              Spaces
            </h1>
          </div>

          <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-center py-24">
                <div className="text-center">
                  <img
                    src={spaceImg}
                    alt="Renters"
                    className="w-1/2 h-1/2 mx-auto"
                  />
                  <h3 className="text-lg font-bold text-zinc-800">
                    All Your Spaces in One Place
                  </h3>
                  <p className="text-sm font-normal text-gray-500 ml-2">
                    Manage your spaces, update their availability, and more.
                  </p>
                  <div className="mt-4">
                    {user?.link ? (
                      <Link
                        to="/post-space"
                        type="button"
                        className="rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                      >
                        Add a Space
                      </Link>
                    ) : (
                      <div>
                        <button className="mt-2 inline-flex items-center gap-x-2.5 px-3 py-1.5 text-sm font-semibold leading-6 text-white bg-zinc-300 rounded-lg disabled:opacity-50 disabled:cursor-not-allowed">
                          Add space
                        </button>
                        <p className="text-xs text-gray-500">
                          Please configure your link first
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
