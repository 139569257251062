import React, { Fragment, useEffect, useState } from "react";
import {
  ArrowLeft,
  ArrowRight,
  ArrowRightLeft,
  CalendarDays,
  ChevronDown,
  ChevronUp,
} from "lucide-react";
import availableTimes from "../assets/json/availableTimeBlocks.json";
import { DayPicker } from "react-day-picker";
import "react-day-picker/dist/style.css";
import { format } from "date-fns";
import { Popover, Transition } from "@headlessui/react";
import QuoteButton from "./QuoteButton";

const initialDayState = {
  Sunday: true,
  Monday: true,
  Tuesday: true,
  Wednesday: true,
  Thursday: true,
  Friday: true,
  Saturday: true,
};

const initialDisbaledDays = {
  Sunday: false,
  Monday: false,
  Tuesday: false,
  Wednesday: false,
  Thursday: false,
  Friday: false,
  Saturday: false,
};

const initialDayCount = {
  Sunday: 0,
  Monday: 0,
  Tuesday: 0,
  Wednesday: 0,
  Thursday: 0,
  Friday: 0,
  Saturday: 0,
};

export default function StartBooking({
  space,
  timeSlots,
  setTimeSlots,
  setDatesSelected,
  datesSelected,
  dates,
  setDates,
  setCurrent,
  formData, 
  setFormData
}) {
  const [days, setDays] = useState(initialDayState);

  const [disabledDays, setDisabledDays] = useState(initialDisbaledDays);
  const [allDates, setAllDates] = useState([]);

  const [popoverStartOpen, setStartPopoverOpen] = useState(false);
  const [popoverEndOpen, setEndPopoverOpen] = useState(false);
  const [switched, setSwitched] = useState(false);

  const timeOptions = Object.values(availableTimes)[0];
  const [mondayTimes, setMondayTimes] = useState([]);
  const [tuesdayTimes, setTuesdayTimes] = useState([]);
  const [wednesdayTimes, setWednesdayTimes] = useState([]);
  const [thursdayTimes, setThursdayTimes] = useState([]);
  const [fridayTimes, setFridayTimes] = useState([]);
  const [saturdayTimes, setSaturdayTimes] = useState([]);
  const [sundayTimes, setSundayTimes] = useState([]);

  const [mondayAllDay, setMondayAllDay] = useState(true);
  const [tuesdayAllDay, setTuesdayAllDay] = useState(true);
  const [wednesdayAllDay, setWednesdayAllDay] = useState(true);
  const [thursdayAllDay, setThursdayAllDay] = useState(true);
  const [fridayAllDay, setFridayAllDay] = useState(true);
  const [saturdayAllDay, setSaturdayAllDay] = useState(true);
  const [sundayAllDay, setSundayAllDay] = useState(true);

  const [countOfEachDay, setCountOfEachDay] = useState(initialDayCount);

  const timeRefs = {
    Monday: mondayTimes,
    Tuesday: tuesdayTimes,
    Wednesday: wednesdayTimes,
    Thursday: thursdayTimes,
    Friday: fridayTimes,
    Saturday: saturdayTimes,
    Sunday: sundayTimes,
  };

  useEffect(() => {
    setMondayTimes(space?.prices?.mondayTimes);
    setTuesdayTimes(space?.prices?.tuesdayTimes);
    setWednesdayTimes(space?.prices?.wednesdayTimes);
    setThursdayTimes(space?.prices?.thursdayTimes);
    setFridayTimes(space?.prices?.fridayTimes);
    setSaturdayTimes(space?.prices?.saturdayTimes);
    setSundayTimes(space?.prices?.sundayTimes);
  }, [space]);

  const checkToSeeIfDayIsSelected = (formattedDay) => {
    space?.prices?.selectedDays?.forEach((day) => {
      if (day === formattedDay) {
        setDisabledDays((prevState) => ({
          ...prevState,
          [formattedDay]: false,
        }));
      }
    });
  };

  useEffect(() => {
    const { from, to } = dates;
    if (from && to) {
      setCountOfEachDay(initialDayCount);
      setDisabledDays(initialDisbaledDays);
      const start = new Date(from);
      const end = new Date(to);
      const selected = [];
      const newTimeSlots = [];
      const newDates = [];
      let currentDate = new Date(start);

      while (currentDate <= end) {
        const formattedDate = currentDate.toISOString().split("T")[0];
        const shortDay = currentDate.toUTCString().split(",")[0];
        const formattedDay = returnFullNames(shortDay);
        // setDays((prevState) => ({
        //   ...prevState,
        //   [formattedDay]: true,
        // }));
        checkToSeeIfDayIsSelected(formattedDay);

        if (days[formattedDay]) {
          selected.push({ date: formattedDate, day: formattedDay });
          newDates.push(new Date(currentDate));

          setCountOfEachDay((prevState) => ({
            ...prevState,
            [formattedDay]: prevState[formattedDay] + 1,
          }));
        }
        currentDate.setDate(currentDate.getDate() + 1);
      }

      setDatesSelected(selected);
      setTimeSlots(newTimeSlots);
      setAllDates(newDates);
    }
  }, [dates, switched]);

  const returnFullNames = (day) => {
    const dayMapping = {
      Sun: "Sunday",
      Mon: "Monday",
      Tue: "Tuesday",
      Wed: "Wednesday",
      Thu: "Thursday",
      Fri: "Friday",
      Sat: "Saturday",
    };
    return dayMapping[day];
  };

  const handleDayChange = (day) => {
    setDays((prevState) => ({
      ...prevState,
      [day]: !prevState[day],
    }));
  };

  const handleUncheckAll = (day) => {
    switch (day) {
      case "Monday":
        setMondayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Monday"));
        setMondayAllDay(false);
        break;
      case "Tuesday":
        setTuesdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Tuesday"));
        setTuesdayAllDay(false);
        break;
      case "Wednesday":
        setWednesdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Wednesday"));
        setWednesdayAllDay(false);
        break;
      case "Thursday":
        setThursdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Thursday"));
        setThursdayAllDay(false);
        break;
      case "Friday":
        setFridayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Friday"));
        setFridayAllDay(false);
        break;
      case "Saturday":
        setSaturdayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Saturday"));
        setSaturdayAllDay(false);
        break;
      case "Sunday":
        setSundayTimes([]);
        setTimeSlots(timeSlots.filter((slot) => slot.day !== "Sunday"));
        setSundayAllDay(false);
        break;
      default:
        break;
    }
  };

  return (
    <div className="">
      <div className="bg-zinc-50 ring-1 ring-gray-900/5 sm:rounded-xl md:col-span-2 relative">
        <div className="px-4 py-6 sm:p-8">
          <div className="grid grid-cols-2 gap-6">
            <div className="col-span-2 sm:flex sm:items-center sm:gap-4">
              <div className="w-full">
                <label
                  htmlFor="start-date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  Start Date
                </label>
                <p className="text-xs text-gray-600">
                  The date the lease term begins
                </p>
                <Popover className="relative mt-3">
                  <Popover.Button
                    className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 h-12 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50"
                    onClick={() => setStartPopoverOpen(!popoverStartOpen)}
                  >
                    {dates?.from
                      ? format(dates.from, "PP")
                      : "Select Start Date"}
                    <ChevronDown className="h-5 w-5" aria-hidden="true" />
                  </Popover.Button>

                  <Transition
                    show={popoverStartOpen}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max -translate-x-1/2 px-4 bg-white border rounded-md">
                      <DayPicker
                        mode="single"
                        selected={dates.from}
                        onSelect={(date) => {
                          setDates((prev) => ({ ...prev, from: date }));
                          setStartPopoverOpen(false);
                        }}
                        disabled={disabledDays}
                      />
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
              <div className="mt-14 hidden sm:block">
                <ArrowRightLeft className="h-6 w-6 text-gray-500 " />
              </div>
              <div className="w-full sm:mt-0 mt-12">
                {" "}
                <label
                  htmlFor="start-date"
                  className="block text-sm font-medium leading-6 text-gray-900"
                >
                  End Date
                </label>
                <p className="text-xs text-gray-600">
                  The date the lease term ends
                </p>
                <Popover className="relative mt-3">
                  <Popover.Button
                    className="inline-flex w-full justify-center items-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 h-12"
                    onClick={() => setEndPopoverOpen(!popoverEndOpen)}
                  >
                    {dates?.to ? format(dates.to, "PP") : "Select End Date"}
                    <ChevronDown className="h-5 w-5" aria-hidden="true" />
                  </Popover.Button>

                  <Transition
                    show={popoverEndOpen}
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Popover.Panel className="absolute left-1/2 z-50 mt-5 flex w-screen max-w-max rounded-md -translate-x-1/2 px-4 bg-white">
                      <DayPicker
                        mode="single"
                        selected={dates.to}
                        onSelect={(date) => {
                          setDates((prev) => ({ ...prev, to: date }));
                          setEndPopoverOpen(false);
                        }}
                        disabled={disabledDays}
                      />
                    </Popover.Panel>
                  </Transition>
                </Popover>
              </div>
            </div>

            {dates?.to && dates?.from && (
              <div className="col-span-full">
                <h2 className="block text-sm font-medium leading-6 text-gray-900">
                  Select The Time Blocks For Each Day
                </h2>
                <p className="mt-1 text-xs text-gray-600">
                  *Anything red is not available for that day
                </p>
                <p className="mt-1 text-xs text-red-600">
                  To get full day pricing all time slots must be selected for
                  that day
                </p>
                <div className="grid sm:grid-cols-7 grid-cols-3 gap-2 mt-2">
                  {Object.entries(days).map(([day, selected]) => (
                    <QuoteButton
                      key={day}
                      day={day}
                      timeRefs={timeRefs}
                      setSwitched={setSwitched}
                      switcher={switched}
                      isSelected={selected}
                      isAllDay={
                        day === "Sunday"
                          ? sundayAllDay
                          : day === "Monday"
                          ? mondayAllDay
                          : day === "Tuesday"
                          ? tuesdayAllDay
                          : day === "Wednesday"
                          ? wednesdayAllDay
                          : day === "Thursday"
                          ? thursdayAllDay
                          : day === "Friday"
                          ? fridayAllDay
                          : saturdayAllDay
                      }
                      handleDayChange={handleDayChange}
                      handleUncheckAll={handleUncheckAll}
                      timeSlots={timeSlots}
                      setTimeSlots={setTimeSlots}
                      timeOptions={timeOptions}
                      isDisabled={
                        day === "Sunday"
                          ? disabledDays.Sunday
                          : day === "Monday"
                          ? disabledDays.Monday
                          : day === "Tuesday"
                          ? disabledDays.Tuesday
                          : day === "Wednesday"
                          ? disabledDays.Wednesday
                          : day === "Thursday"
                          ? disabledDays.Thursday
                          : day === "Friday"
                          ? disabledDays.Friday
                          : disabledDays.Saturday
                      }
                      count={countOfEachDay[day]}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          {dates?.to && dates?.from && (
            <div className="flex justify-between items-center mt-4 w-full">
              <button
                type="submit"
                className=" bg-emerald-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrent(0);
                }}
              >
                <ArrowLeft className="h-4 w-4 mr-2" /> Back
              </button>
              <button
                type="submit"
                className="bg-emerald-600 border border-transparent rounded-md py-2 px-4 flex items-center justify-center text-sm font-medium text-white hover:bg-emerald-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-emerald-500"
                onClick={(e) => {
                  e.preventDefault();
                  setCurrent(2);
                }}
              >
                Next <ArrowRight className="h-4 w-4 ml-2" />
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
