import { useEffect, useState } from "react";
import { Switch } from "@headlessui/react";
import { states } from "../../assets/js/states";
import profileIMG from "../../assets/images/profile.png";
import Alert from "../../components/Alert";
import {
  finishSetUp,
  updateRenter,
  updateUser,
  uploadImage,
} from "../../functions/crud";
import { UserAuth } from "../../context/AuthContext";
import { handleCreateCustomer } from "../../functions/stripe";
import { useNavigate } from "react-router-dom";
import { getChat, updatChat } from "../../functions/realtime";

const profileInit = {
  firstName: "",
  lastName: "",
  address: "",
  city: "",
  state: "",
  zip: "",
  company: "",
  phone: "",
  businessPhone: "",
  accountUpdated: true,
};

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function RenterSetupGuide() {
  const { user } = UserAuth();
  const [file, setFile] = useState(null);
  const [pictureUploaded, setPictureUploaded] = useState(false);
  const [profile, setProfile] = useState(profileInit);
  const [isLoading, setIsLoading] = useState(false);
  const [alerter, setAlerter] = useState(false);

  const [profilePicture, setProfilePicture] = useState(profileIMG);

  const navigate = useNavigate();

  const handleAddProfilePicture = (e) => {
    const file = e.target.files[0];
    const fileUrl = URL.createObjectURL(file);
    setFile(file);
    setProfilePicture(fileUrl);
    setPictureUploaded(true);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (profile.firstName === "" || profile.lastName === "") {
        setIsLoading(false);
        setAlerter(true);
      } else if (pictureUploaded) {
        const name = `${profile.firstName} ${profile.lastName}`;
        let customer = null;

        try {
          customer = await handleCreateCustomer(user?.email, name);
        } catch (error) {
          console.log(
            "Failed to create customer, proceeding without customer data:",
            error
          );
        }

        const data = {
          firstName: profile.firstName,
          lastName: profile.lastName,
          phoneNumber: profile.phone,
          companyName: profile.company,
          stripeCustomerID: customer?.customer || "",
          finishedSetup: true,
          address: profile.address,
          city: profile.city,
          state: profile.state,
          zip: profile.zip,
          businessPhone: profile.businessPhone,
          accountUpdated: true,
        };

        await uploadImage(user?.id, data, file);

        await updateRenter(user?.id, {
          finishedSetup: true,
          firstName: profile.firstName,
          lastName: profile.lastName,
          phone: profile.phone,
          emailVerified: user.emailVerified,
          stripeCustomerID: customer?.customer || "",
          address: profile.address,
          city: profile.city,
          state: profile.state,
          zip: profile.zip,
          company: profile.company,
          businessPhone: profile.businessPhone,
        });

        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 3000);
      } else {
        const name = `${profile.firstName} ${profile.lastName}`;
        let customer = null;

        try {
          customer = await handleCreateCustomer(user?.email, name);
        } catch (error) {
          console.log(
            "Failed to create customer, proceeding without customer data:",
            error
          );
        }

        const res = await finishSetUp(user?.id, {
          firstName: profile.firstName,
          lastName: profile.lastName,
          phoneNumber: profile.phone,
          companyName: profile.company,
          stripeCustomerID: customer?.customer || "",
          finishedSetup: true,
          address: profile.address,
          city: profile.city,
          state: profile.state,
          zip: profile.zip,
          businessPhone: profile.businessPhone,
          accountUpdated: true,
        });

        await updateRenter(user?.id, {
          finishedSetup: true,
          firstName: profile.firstName,
          lastName: profile.lastName,
          phone: profile.phone,
          emailVerified: user.emailVerified,
          stripeCustomerID: customer?.customer || "",
          address: profile.address,
          city: profile.city,
          state: profile.state,
          zip: profile.zip,
          company: profile.company,
          businessPhone: profile.businessPhone,
        });

        for (let i = 0; i < user?.formRequests.length; i++) {
          let chat = await getChat(user?.formRequests[i]);
          if (chat !== null) {
            chat[i].name = `${profile.firstName} ${profile.lastName}`;
            await updatChat(chat[i], user?.formRequests[i]);
          } else {
            console.log("No chat found");
          }
        }

        if (res.status === "success") {
          console.log("Account setup successful");
        } else {
          alert(
            "An error occurred. Please try again. if this continues please contact support"
          );
        }

        setTimeout(() => {
          setIsLoading(false);
          navigate("/");
        }, 2000);
      }
    } catch (error) {
      console.log("An unexpected error occurred:", error);
      alert(
        "An error occurred. Please try again. if this continues please contact support"
      );
    }
  };

  useEffect(() => {
    if (user?.firstName && user?.lastName) {
      setProfile({
        firstName: user?.firstName,
        lastName: user?.lastName,
        address: user?.address,
        city: user?.city,
        state: user?.state,
        zip: user?.zip,
        company: user?.company,
        phone: user?.phone,
        businessPhone: user?.businessPhone,
        agreed: user?.agreed,
      });
    } else {
      setProfile({
        firstName: profile.firstName,
        lastName: profile.lastName,
        address: profile.address,
        city: profile.city,
        state: profile.state,
        zip: profile.zip,
        company: profile.company,
        phone: profile.phone,
        businessPhone: profile.businessPhone,
        agreed: profile.agreed,
      });
    }

    if (user?.profilePic) {
      setProfilePicture(user?.profilePic);
    }
  }, [user]);

  return (
    <div>
      {isLoading ? (
        <div className="flex items-center justify-center h-screen">
          <div className="w-12 h-12 border-t-2 border-b-2 border-emerald-500 rounded-full animate-spin"></div>
        </div>
      ) : (
        <div className="max-w-2xl mx-auto">
          <div className="isolate pb-12">
            <div className="mx-auto max-w-2xl text-center">
              <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Finish Setting Up Account
              </h2>
              <p className="mt-2 text-md leading-8 text-zinc-600">
                Give Providers a little more information about yourself to help
                them get to know you better.
              </p>
            </div>
            <form
              action="#"
              method="POST"
              className="mt-4 rounded-lg bg-white shadow px-4 py-5 sm:p-6"
            >
              {alerter && (
                <div className="mb-1">
                  <Alert message="Please enter a first name and last name" />
                </div>
              )}
              <div className="grid grid-cols-1 gap-x-5 gap-y-4 sm:grid-cols-6">
                <div className="sm:col-span-3">
                  <label
                    htmlFor="first-name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    First name
                  </label>
                  <div>
                    <input
                      type="text"
                      name="first-name"
                      id="first-name"
                      value={profile.firstName}
                      autoComplete="given-name"
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12 ${
                        alerter && !profile.firstName
                          ? "ring-2 ring-red-500"
                          : ""
                      }`}
                      onChange={(e) => {
                        setProfile({ ...profile, firstName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="last-name"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Last name
                  </label>
                  <div>
                    <input
                      type="text"
                      name="last-name"
                      id="last-name"
                      autoComplete="family-name"
                      value={profile.lastName}
                      className={`block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12 ${
                        alerter && !profile.lastName
                          ? "ring-2 ring-red-500"
                          : ""
                      }`}
                      onChange={(e) => {
                        setProfile({ ...profile, lastName: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="sm:col-span-full">
                  <label
                    htmlFor="company"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Company
                  </label>
                  <div>
                    <input
                      type="text"
                      name="company"
                      id="company"
                      autoComplete="organization"
                      value={profile.company}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => {
                        setProfile({ ...profile, company: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Mobile number
                  </label>
                  <div>
                    <input
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      autoComplete="tel"
                      value={profile.phone}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => {
                        setProfile({ ...profile, phone: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="sm:col-span-3">
                  <label
                    htmlFor="phone-number"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Business number
                  </label>
                  <div>
                    <input
                      type="tel"
                      name="phone-number"
                      id="phone-number"
                      autoComplete="tel"
                      value={profile.businessPhone}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => {
                        setProfile({
                          ...profile,
                          businessPhone: e.target.value,
                        });
                      }}
                    />
                  </div>
                </div>

                <div className="sm:col-span-full">
                  <label
                    htmlFor="address"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Address
                  </label>
                  <div>
                    <input
                      type="text"
                      name="address"
                      id="address"
                      autoComplete="address-line1"
                      value={profile.address}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => {
                        setProfile({ ...profile, address: e.target.value });
                      }}
                    />
                  </div>
                </div>

                <div className="sm:col-span-3">
                  <label
                    htmlFor="city"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    City
                  </label>
                  <div>
                    <input
                      type="text"
                      name="city"
                      id="city"
                      autoComplete="city"
                      value={profile.city}
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => {
                        setProfile({ ...profile, city: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="sm:col-span-1">
                  <label
                    htmlFor="state"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    State
                  </label>
                  <div>
                    <select
                      id="space-type"
                      name="space-type"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      value={JSON.stringify(profile.state)} // Convert the object to a JSON string as the value
                      onChange={(e) =>
                        setProfile({
                          ...profile,
                          state: JSON.parse(e.target.value),
                        })
                      }
                      // Parse the JSON string back to an object when the selection changes
                    >
                      {states?.map((state) => (
                        <option
                          key={state.abbreviation}
                          value={JSON.stringify(state.abbreviation)} // Convert the object to a JSON string as the option value
                        >
                          {state.abbreviation}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
                <div className="sm:col-span-2">
                  <label
                    htmlFor="zip"
                    className="block text-sm font-semibold leading-6 text-gray-900"
                  >
                    Zipcode
                  </label>
                  <div>
                    <input
                      type="text"
                      name="zip"
                      id="zip"
                      value={profile.zip}
                      autoComplete="zip"
                      className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
                      onChange={(e) => {
                        setProfile({ ...profile, zip: e.target.value });
                      }}
                    />
                  </div>
                </div>
                <div className="col-span-full">
                  <label htmlFor="profile-picture" className="sr-only">
                    Profile Picture
                  </label>
                  <div className="mt-1 flex items-center">
                    <span className="inline-block h-16 w-16 overflow-hidden bg-gray-100 rounded-lg">
                      <img
                        className="h-full w-full object-cover"
                        src={profilePicture}
                        alt="Profile"
                      />
                    </span>
                    <input
                      type="file"
                      accept="image/*"
                      id="profile-picture"
                      className="sr-only"
                      onChange={handleAddProfilePicture}
                    />
                    <label
                      htmlFor="profile-picture"
                      className="ml-5 cursor-pointer inline-block font-medium text-emerald-500 hover:text-emerald-700"
                    >
                      Add a Profile Picture
                    </label>
                  </div>
                </div>
              </div>
              <div className="mt-10">
                <button
                  type="submit"
                  className="block w-full rounded-md bg-emerald-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  onClick={handleFormSubmit}
                >
                  Save and Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}
