import React, { useEffect, useState } from "react";
import toast, { Toaster } from "react-hot-toast";
import {
  CheckCircle2,
  Clock,
  HeartIcon,
  InfoIcon,
  Link2,
  MapPinIcon,
  Minus,
  Plus,
  Ruler,
  Users,
} from "lucide-react";
import { UserAuth } from "../context/AuthContext";
import { getSpaceData, updateSpaceViews } from "../functions/crud";
import { Link, useNavigate, useParams } from "react-router-dom";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import QuoteCard from "../renter/components/QuoteCard";
import { Disclosure } from "@headlessui/react";

export default function SpaceRenterProfile() {
  const { user, setIsLoading } = UserAuth();
  const [space, setSpace] = useState({});
  const [viewed, setViewed] = useState(false);
  const [copied, setCopied] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const [showFullDescription, setShowFullDescription] = useState(false);

  const navigate = useNavigate();

  const handleCopy = () => {
    navigator.clipboard.writeText(`https://competeshare.com/space/${id}`);
    toast.success("Link Copied!");
    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 5000);
  };

  useEffect(() => {
    if (user?.id) {
      return;
    } else {
      setViewed(true);
      updateSpaceViews(id);
    }
  }, [user]);

  const { id } = useParams();

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription);
  };

  useEffect(() => {
    setIsLoading(true);
    getSpaceData(id).then((data) => {
      if (data) {
        setSpace(data);
      } else {
        toast.error("Space not found");
        navigate("/");
      }
      //   setTimeout(() => {
      //     setIsLoading(false);
      //   }, 500);
      setIsLoading(false);
    });
  }, [id]);

  const handleLike = async () => {
    if (user?.id) {
      // const res = await likeSpace(id, user?.id);
      // if (res.status === "success") {
      //   toast.success("Space liked!");
      // } else {
      //   toast.error("Failed to like space");
      // }
    } else {
      toast.error("Please login to like this space");
    }
  };

  const images = space?.photos?.map((slide) => slide.url);

  const handleScrollToSection = (id) => {
    const element = document.getElementById(id);
    if (element) {
      const offset = element.offsetTop - 30; // Subtract 10 pixels for the margin
      window.scrollTo({
        top: offset,
        behavior: "smooth",
      });
    }
  };

  return (
    <div>
      <Toaster position="top-center" reverseOrder={false} />
      {isOpen && (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={images[(photoIndex + images.length - 1) % images.length]}
          onCloseRequest={() => setIsOpen(false)}
          onMovePrevRequest={() =>
            setPhotoIndex((photoIndex + images.length - 1) % images.length)
          }
          onMoveNextRequest={() =>
            setPhotoIndex((photoIndex + 1) % images.length)
          }
        />
      )}
      <div className="max-w-7xl mx-auto p-6 pb-24">
        <div className="lg:flex lg:items-center lg:justify-between max-w-7xl mx-auto sm:px-6 mb-6">
          <div className="min-w-0 flex-1">
            <h2 className="text-2xl font-bold leading-7  sm:truncate sm:text-3xl sm:tracking-tight">
              {space?.title}
            </h2>
            <div className="mt-1 flex flex-col sm:mt-0 sm:flex-row sm:flex-wrap sm:space-x-6">
              <div className="mt-2 flex items-center text-sm text-zinc-800">
                <Users
                  aria-hidden="true"
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-emerald-600"
                />
                {space?.capacity} people
              </div>
              <div className="mt-2 flex items-center text-sm text-zinc-800">
                <Ruler
                  aria-hidden="true"
                  className="mr-1.5 h-5 w-5 flex-shrink-0 text-emerald-600"
                />
                {space?.sqft} sqft
              </div>
              {space?.hasMin && (
                <div className="mt-2 flex items-center text-sm text-zinc-800">
                  <Clock
                    aria-hidden="true"
                    className="mr-1.5 h-5 w-5 flex-shrink-0 text-emerald-600"
                  />
                  {space?.minValue} {space?.minimumPeriod} min
                </div>
              )}
            </div>
          </div>
          <div className="mt-5 flex items-center gap-x-5 lg:ml-4 lg:mt-0">
            <div className="">
              <button
                type="button"
                className="inline-flex items-center text-sm "
                onClick={handleCopy}
              >
                <Link2 aria-hidden="true" className="-ml-0.5 mr-1.5 h-5 w-5 " />
                Share
              </button>
            </div>

            <div className="">
              <button
                type="button"
                className="inline-flex items-center text-sm"
                onClick={handleLike}
              >
                <HeartIcon
                  aria-hidden="true"
                  className="-ml-0.5 mr-1 h-5 w-5"
                />
                Save
              </button>
            </div>
          </div>
        </div>
        <div>
          {space?.photos?.length > 3 ? (
            <div className="grid sm:grid-cols-4 grid-cols-2 gap-2">
              <button
                className="w-full col-span-2 row-span-2 hover:opacity-80 "
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(0);
                }}
              >
                <img
                  src={space?.mainPhoto}
                  alt={space?.id}
                  className="object-cover object-center aspect-1 rounded-lg "
                />
              </button>

              {space?.photos?.slice(0, 4).map((photo, index) => (
                <button
                  className="w-full"
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(index);
                  }}
                >
                  <img
                    key={index}
                    src={photo.url}
                    alt={space?.id}
                    className="object-cover object-center aspect-1 rounded-lg w-full hover:opacity-80 "
                  />
                </button>
              ))}
            </div>
          ) : space?.photos?.length > 2 ? (
            <div className="grid sm:grid-cols-4 grid-cols-2 gap-2">
              <button
                className="w-full col-span-2 row-span-2"
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(0);
                }}
              >
                <img
                  src={space?.mainPhoto}
                  alt={space?.id}
                  className="object-cover object-center sm:h-[500px] rounded-lg "
                />
              </button>
              {space?.photos?.slice(0, 2).map((photo, index) => (
                <button
                  className="w-full sm:col-span-2"
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(index);
                  }}
                >
                  <img
                    key={index}
                    src={photo.url}
                    alt={space?.id}
                    className="w-full h-[242px] object-cover object-center rounded-lg "
                  />
                </button>
              ))}
            </div>
          ) : space?.photos?.length === 2 ? (
            <div className="grid grid-cols-4 gap-4">
              <button
                className="w-full col-span-2 row-span-2"
                onClick={() => {
                  setIsOpen(true);
                  setPhotoIndex(0);
                }}
              >
                <img
                  src={space?.mainPhoto}
                  alt={space?.id}
                  className="object-cover object-center h-[500px] rounded-lg "
                />
              </button>
              {space?.photos?.slice(0, 2).map((photo, index) => (
                <button
                  className="w-full col-span-2"
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(index);
                  }}
                >
                  <img
                    key={index}
                    src={photo.url}
                    alt={space?.id}
                    className="w-full h-[242px] object-cover object-center rounded-lg "
                  />
                </button>
              ))}
            </div>
          ) : (
            <button
              className="w-full col-span-2 row-span-2"
              onClick={() => {
                setIsOpen(true);
                setPhotoIndex(0);
              }}
            >
              <img
                src={space?.mainPhoto}
                alt={space?.id}
                className="object-cover object-center max-h-96 rounded-lg w-full "
              />
            </button>
          )}
        </div>
        <div className="lg:flex lg:items-start lg:justify-between gap-6 mt-12">
          <div className="lg:w-2/3">
            <div className="sm:flex-none flex justify-between items-center">
              <div className="flex items-center text-large font-bold text-zinc-800">
                <MapPinIcon
                  aria-hidden="true"
                  className="mr-1.5 h-auto w-auto flex-shrink-0 text-emerald-800"
                />
                {space?.city}, {space?.state}
              </div>
              <div className="lg:hidden flex items-center gap-3">
                <button
                  type="button"
                  className="block rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  onClick={() => handleScrollToSection("quote")}
                >
                  Contact Host
                </button>
                <button
                  type="button"
                  className="block rounded-md bg-emerald-100 px-3 py-2 text-center text-sm font-semibold text-emerald-600 shadow-sm hover:bg-emerald-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                  onClick={() => handleScrollToSection("quote")}
                >
                  Request Quote
                </button>
              </div>
            </div>
            <div className="mt-8">
              <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
                About
              </h2>
              <div>
                <p
                  className={`mt-6  ${
                    showFullDescription ? "" : "line-clamp-6"
                  }`}
                  dangerouslySetInnerHTML={{ __html: space?.description }}
                ></p>
                <div className="flex">
                  <button
                    onClick={toggleDescription}
                    className="text-blue-500 hover:text-blue-800 font-semibold"
                  >
                    {showFullDescription ? "Read less" : "Read more"}
                  </button>
                </div>
              </div>
              <div className="mt-8" id="amenities">
                <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
                  Amenities
                </h2>

                <div className=" mt-4 text-gray-500">
                  <div className="grid sm:grid-cols-4 grid-cols-2 gap-y-2 text-lg font-semibold">
                    {" "}
                    {space?.amenities?.map((amenity) => (
                      <div key={amenity} className="flex items-center gap-x-1">
                        <CheckCircle2 className="h-5 w-5 text-emerald-500 shrink-0" />
                        {amenity}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
              <div className="mt-8">
                <h2 className="text-2xl font-bold tracking-tight text-zinc-900 mb-4">
                  Availability
                </h2>
                <div>
                  {space?.availability?.map((day, index) => (
                    <>
                      {day?.available && (
                        <div key={index}>
                          <div className="flex items-center justify-between bg-white py-2 px-3 border my-1 rounded">
                            <h3 className="text-lg font-bold text-zinc-900 capitalize">
                              {day?.day}
                            </h3>
                            <div className="ml-4">
                              {day?.timeSlots?.length > 0 ? (
                                day?.timeSlots?.map((slot, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center gap-x-2"
                                  >
                                    <p className="text-emerald-600 font-semibold">
                                      {slot?.startTime} - {slot?.endTime}
                                    </p>
                                  </div>
                                ))
                              ) : (
                                <p className="text-emerald-600 font-semibold">
                                  All Day
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </>
                  ))}
                </div>
              </div>
              <div className="mt-8" id="licenses">
                <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
                  Licenses & Certifications
                </h2>
              </div>

              <div className="mt-4">
                {space?.licenses?.map((license, index) => (
                  <div key={index} className="my-1">
                    <div className="w-full">
                      <div className="p-4 border rounded-lg bg-white w-full">
                        <h3 className="text-lg font-bold text-zinc-900">
                          {license}
                        </h3>
                        <p className="text-gray-500">{license?.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>

              {space?.licenses?.length === 0 || !space?.licenses ? (
                <p className="mt-4 text-gray-500">
                  No licenses or certifications posted
                </p>
              ) : (
                <p className="mt-4 text-gray-500 flex items-center gap-x-2">
                  <InfoIcon className="h-5 w-5 " />
                  Providers report their own licenses. Please ask them for more
                  details if required.
                </p>
              )}
              <div className="mt-8" id="licenses">
                <h2 className="text-2xl font-bold tracking-tight text-zinc-900">
                  Q&As
                </h2>
                <dl className="space-y-6 divide-y divide-gray-900/10">
                  {space?.faqs?.map((faq) => (
                    <Disclosure as="div" key={faq.question} className="pt-6">
                      {({ open }) => (
                        <div className="p-4 border rounded-lg bg-white w-full my-1">
                          <dt>
                            <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                              <span className="text-base font-semibold leading-7">
                                {faq.question}
                              </span>
                              <span className="ml-6 flex h-7 items-center">
                                {open ? (
                                  <Minus
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                ) : (
                                  <Plus
                                    className="h-6 w-6"
                                    aria-hidden="true"
                                  />
                                )}
                              </span>
                            </Disclosure.Button>
                          </dt>
                          <Disclosure.Panel as="dd" className="mt-2 pr-12">
                            <p className="text-base leading-7 text-gray-600">
                              {faq.answer}
                            </p>
                          </Disclosure.Panel>
                        </div>
                      )}
                    </Disclosure>
                  ))}
                </dl>
                {(space?.faqs?.length === 0 || !space?.faqs) && (
                  <p className="mt-4 text-gray-500">
                    No licenses or FAQS posted
                  </p>
                )}
              </div>
            </div>
          </div>
          <div className="lg:w-1/3 lg:mt-0 mt-6" id="quote">
            <QuoteCard spaceID={space?.id} locationLink={space?.locationLink} userID={space?.userID} />
            <p className="text-center text-sm  leading-6 text-gray-500">
              By submitting this form, I agree to the{" "}
              <Link
                to="/info/privacy-policy"
                className="font-semibold text-emerald-600"
                target="_blank"
              >
                privacy&nbsp;policy
              </Link>
              .
            </p>
          </div>
        </div>
      </div>
      <p className="text-center pb-12">
        © 2024 Compete Share LLC. All rights reserved.
      </p>
    </div>
  );
}
