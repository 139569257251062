import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import RenterHome from "../renter/pages/RenterHome";
import RenterContextProvider from "../context/RenterContext";
import RenterNav from "../renter/components/RenterNav";
import StartReservation from "../renter/pages/StartReservation";
import SetupAccount from "../host/pages/SetupAccount";
import SpaceProfile from "../pages/SpaceProfile";
import { UserAuth } from "../context/AuthContext";
import AlertEmailVerify from "../host/components/AlertEmailVerify";
import AccountSetup from "../renter/pages/AccountSetup";
import RenterSetupGuide from "../renter/pages/RenterSetupGuide";
import RenterDashboard from "../renter/RenterDashboard";
import Bookings from "../renter/pages/Bookings";
import Payments from "../renter/pages/Payments";
import Documents from "../renter/pages/Documents";
import Inbox from "../host/pages/Inbox";
import { onValue, ref } from "firebase/database";
import { rtdb } from "../firebase/fire";

export default function RenterRoutes() {
  const { user } = UserAuth();
  const [messages, setMessages] = useState([]);

  const handleListenToMessages = () => {
    const allMessages = ref(rtdb, `userNotification/${user?.id}/notifications`);
    onValue(allMessages, (snapshot) => {
      const messages = [];
      snapshot.forEach((childSnapshot) => {
        messages.push(childSnapshot.val());
      });
      //order messages by createdAT ISO string
      messages.sort((a, b) => a.createdAt.localeCompare(b.createdAt));
      setMessages(messages);
    });
  };


  useEffect(() => {
    handleListenToMessages();
  }, [user]);

  return (
    <div>
      <RenterContextProvider>
        <div className="min-h-screen h-full bg-zinc-100">
          <RenterDashboard messages={messages}>
            {!user?.emailVerified && (
              <div className="py-2">
                <AlertEmailVerify />
              </div>
            )}
            <Routes>
              <Route
                path="/"
                element={
                  user?.finishedSetup ? <RenterHome /> : <RenterSetupGuide />
                }
              />
              <Route path="/spaces/:id" element={<SpaceProfile />} />
              <Route path="/space/:id" element={<SpaceProfile />} />
              <Route path="/bookings" element={<Bookings />} />
              <Route path="/payments" element={<Payments />} />
              <Route path="/documents" element={<Documents />} />
              <Route path="/messages" element={<Inbox messages={messages} />} />
              <Route
                path="/messages/:id"
                element={<Inbox messages={messages} />}
              />
              <Route path="/account-setup" element={<SetupAccount />} />
              <Route path="/inbox/:id" element={<Inbox />} />
              <Route
                path="/spaces/reservation-request/:id"
                element={<StartReservation />}
              />
              <Route
                path="/*"
                element={
                  user?.finishedSetup ? <RenterHome /> : <RenterSetupGuide />
                }
              />
            </Routes>
          </RenterDashboard>
        </div>
      </RenterContextProvider>
    </div>
  );
}
