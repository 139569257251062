import { Link } from "react-router-dom";
import bookingsImg from "../../assets/images/bookings.svg";
import { UserAuth } from "../../context/AuthContext";
import { CheckIcon, FilePenLine, Pen } from "lucide-react";

export default function Bookings() {
  const { bookings } = UserAuth();

  return (
    <>
      {bookings?.length > 0 ? (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
                Bookings
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Manage your bookings, view their payment history, and more.
              </p>
            </div>
            <div className="mt-4 sm:ml-16 sm:mt-0 sm:flex-none">
              <Link
                to="/create-offer"
                type="button"
                className="rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              >
                Create Booking
              </Link>
            </div>
          </div>
          <div className="mt-8 ">
            <table className="min-w-full divide-y divide-gray-300 shadow shadow-zinc-400 overflow-hidden ring-1 ring-black ring-opacity-5 rounded-xl">
              <thead>
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 "
                  >
                    Tenant Name
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 lg:table-cell"
                  >
                    Start Date
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    End Date
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    Days and Times
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Monthly Rent
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    Renter Signed
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    You Signed
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 ">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {bookings?.map((person) => (
                  <tr key={person.email}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none ">
                      <div>
                        {person.tenant?.firstName} {person.tenant?.lastName}
                      </div>
                      <div className="text-zinc-600 font-normal">
                        {person.tenant?.email}
                      </div>
                      <div className="text-zinc-600 font-normal">
                        {person.tenant?.phone}
                      </div>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {new Date(person.license?.startDate).toDateString()}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {new Date(person.license?.endDate).toDateString()}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-zinc-700 sm:table-cell">
                      {person.license?.monday && (
                        <div className="font-bold">
                          Mon:{" "}
                          <span className="font-normal">
                            {person.license?.mondayStart} -{" "}
                            {person.license?.mondayEnd}
                          </span>
                        </div>
                      )}
                      {person.license?.tuesday && (
                        <div className="font-bold">
                          Tue:{" "}
                          <span className="font-normal">
                            {person.license?.tuesdayStart} -{" "}
                            {person.license?.tuesdayEnd}
                          </span>
                        </div>
                      )}
                      {person.license?.wednesday && (
                        <div className="font-bold">
                          Wed:{" "}
                          <span className="font-normal">
                            {person.license?.wednesdayStart} -{" "}
                            {person.license?.wednesdayEnd}
                          </span>
                        </div>
                      )}
                      {person.license?.thursday && (
                        <div className="font-bold">
                          Thu:{" "}
                          <span className="font-normal">
                            {person.license?.thursdayStart} -{" "}
                            {person.license?.thursdayEnd}
                          </span>
                        </div>
                      )}
                      {person.license?.friday && (
                        <div className="font-bold">
                          Fri:{" "}
                          <span className="font-normal">
                            {person.license?.fridayStart} -{" "}
                            {person.license?.fridayEnd}
                          </span>
                        </div>
                      )}
                      {person.license?.saturday && (
                        <div className="font-bold">
                          Sat:{" "}
                          <span className="font-normal">
                            {person.license?.saturdayStart} -{" "}
                            {person.license?.saturdayEnd}
                          </span>
                        </div>
                      )}
                      {person.license?.sunday && (
                        <div className="font-bold">
                          Sun:{" "}
                          <span className="font-normal">
                            {person.license?.sundayStart} -{" "}
                            {person.license?.sundayEnd}
                          </span>
                        </div>
                      )}
                    </td>

                    <td className="px-3 py-4 text-sm font-semibold text-zinc-800 ">
                      ${person.license?.monthlyRent}
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 lg:table-cell">
                      {person.renterSigned ? (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                          <CheckIcon size={16} />
                          Signed
                        </span>
                      ) : (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-red-100 px-2 py-1 text-xs font-medium text-red-700">
                          <svg
                            className="h-1.5 w-1.5 fill-red-500"
                            viewBox="0 0 6 6"
                            aria-hidden="true"
                          >
                            <circle cx={3} cy={3} r={3} />
                          </svg>
                          Awaiting Signature
                        </span>
                      )}
                    </td>
                    <td className="hidden px-3 py-4  text-gray-500 lg:table-cell">
                      {person?.providerSigned ? (
                        <span className="inline-flex items-center gap-x-1.5 rounded-md bg-green-100 px-2 py-1 text-xs font-medium text-green-700">
                          <CheckIcon size={16} />
                          Signed
                        </span>
                      ) : (
                        <Link
                          to={`/license/${person.id}`}
                          className="ml-2 text-blue-500 text-sm hover:text-blue-700 flex items-center gap-x-1 relative"
                        >
                          <FilePenLine size={16} />
                          <div className="relative">
                            Sign Now
                            <span className="absolute -right-2 top-0 block h-2 w-2 rounded-full bg-green-400 ring-2 ring-white animate-pulse" />
                          </div>
                        </Link>
                      )}
                    </td>
                    <td className="py-4 pl-1 pr-6 text-right text-sm font-medium ">
                      <a
                        href="#"
                        className="text-indigo-600 hover:text-indigo-900"
                      >
                        Edit<span className="sr-only">, {person.name}</span>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <div>
          <div className="sm:flex-auto">
            <h1 className="text-2xl font-bold leading-6 text-gray-900 tracking-tight">
              Bookings
            </h1>
          </div>

          <div className="overflow-hidden rounded-lg bg-white shadow mt-8">
            <div className="px-4 py-5 sm:p-6">
              <div className="flex items-center justify-center py-24">
                <div className="text-center">
                  <img
                    src={bookingsImg}
                    alt="bookings"
                    className="w-1/2 h-1/2 mx-auto"
                  />
                  <h3 className="text-lg font-bold text-zinc-800">
                    Your bookings will show here
                  </h3>
                  <p className="text-sm font-normal text-gray-500 ml-2">
                    Manage your bookings, view their payment history, and more.
                  </p>
                  <div className="mt-4">
                    <Link
                      to="/create-offer"
                      type="button"
                      className="rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    >
                      Create Booking
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
