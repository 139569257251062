import { Check } from "lucide-react";

const includedFeatures = [
  "We promote your space on other marketplaces like Craigslist and Facebook Marketplace",
  "We handle your communication with potential renters before they book",
  "We funnel leads to your inbox",
  "We cover Advertising",
  "We handle your payment processing",
  "We help find you renters",
];

export default function SmartPromote() {
  return (
    <div className=" min-h-screen h-full">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl sm:text-center">
          <h1 className="text-3xl font-bold tracking-tight text-emerald-500 sm:text-6xl">
            Smart Promote
          </h1>
          <h2 className="text-3xl mt-2 font-bold tracking-tight text-zinc-950 sm:text-4xl">
            Easy Lead Generator
          </h2>
          <p className="mt-4 text-lg leading-8 text-zinc-700">
            Let us help you promote your space to potential renters. Get access
            to all the tools you need to reach new renters and fill your space.
          </p>
        </div>
        <div className="mx-auto mt-10 max-w-2xl bg-zinc-900 rounded-3xl ring-1 ring-gray-200 lg:mx-0 lg:flex lg:max-w-none">
          <div className="p-8 sm:p-10 lg:flex-auto">
            <h3 className="text-2xl font-bold tracking-tight text-white">
              We do all the heavy lifting
            </h3>
            <p className="mt-6 text-base leading-7 text-gray-200">
              Compete Share offers something no competitor does, and we like to
              call it <span className="font-semibold">Smart Promote</span>. This
              feature allows you to promote your space to potential renters
              through other platforms with the help of our trained specialists.
            </p>
            <div className="mt-10 flex items-center gap-x-4">
              <h4 className="flex-none text-sm font-semibold leading-6 text-emerald-400">
                What’s included
              </h4>
              <div className="h-px flex-auto bg-gray-100" />
            </div>
            <ul
              role="list"
              className="mt-8 grid grid-cols-1 gap-4 text-sm leading-6 text-white sm:grid-cols-2 sm:gap-6"
            >
              {includedFeatures.map((feature) => (
                <li key={feature} className="flex gap-x-3">
                  <Check
                    className="h-6 w-5 flex-none text-emerald-400"
                    aria-hidden="true"
                  />
                  {feature}
                </li>
              ))}
            </ul>
          </div>
          <div className="-mt-2 p-2 lg:mt-0 lg:w-full lg:max-w-md lg:flex-shrink-0">
            <div className="rounded-2xl bg-gray-50 h-full py-10 text-center ring-1 ring-inset ring-gray-900/5 lg:flex lg:flex-col lg:justify-center lg:py-16">
              <div className="mx-auto max-w-xs px-8">
                <p className="text-base font-semibold text-gray-600">
                  Let us do the work for you
                </p>
                <p className="mt-6 flex items-baseline justify-center gap-x-2">
                  <span className="text-5xl font-bold tracking-tight text-gray-900">
                    $49
                  </span>
                  <span className="text-sm font-semibold leading-6 tracking-wide text-gray-600">
                    /mo
                  </span>
                </p>
                <a
                  href="#"
                  className="mt-10 block w-full rounded-md bg-emerald-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-emerald-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                >
                  Get access
                </a>
                <p className="mt-6 text-xs leading-5 text-gray-600">
                  Invoices and receipts available for easy company reimbursement
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-1 text-center">
          Let us help you promote your spaces!{" "}
        </div>
      </div>
    </div>
  );
}
