import {
  addDoc,
  arrayRemove,
  arrayUnion,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  increment,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { auth, db, storage } from "../firebase/fire";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { sendEmailVerification } from "firebase/auth";

const site = process.env.REACT_APP_SITE_URL;

export const generateRandomID = () => {
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let randomID = "";

  for (let i = 0; i < 16; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    randomID += characters.charAt(randomIndex);
  }
  return randomID;
};

export const finishSetUp = async (id, data) => {
  try {
    const userRef = doc(db, "users", id);
    await updateDoc(userRef, {
      ...data,
      accountUpdated: true,
    });
    return { status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  }
};

const finishSetUpWithProfilePic = async (id, data, url) => {
  try {
    const userRef = doc(db, "users", id);
    await updateDoc(userRef, {
      ...data,
      accountUpdated: true,
      profilePic: url,
    });
    return { status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const uploadImage = async (id, data, file) => {
  const storageRef = ref(storage, `profilePics/${id}`);
  await uploadBytes(storageRef, file)
    .then((snapshot) => {
      console.log("Uploaded a blob or file!", snapshot);
    })
    .catch((error) => {
      console.error("Error uploading file: ", error);
    });
  const url = await getDownloadURL(storageRef);
  await finishSetUpWithProfilePic(id, data, url);
  return url;
};

export const verifyUserEmail = async (id) => {
  const returnUrl = site;
  await sendEmailVerification(auth.currentUser, {
    url: returnUrl,
  })
    .then(() => {
      console.log("Email verification sent!");
      return { status: "success" };
    })
    .catch((error) => {
      console.log(error);
    });
};

export const uploadMultipleImagesAndReturnArray = async (files, id) => {
  let urls = [];
  for (let i = 0; i < files.length; i++) {
    const photoID = generateRandomID();
    const storageRef = ref(storage, `spaces/${id}/${photoID}`);
    await uploadBytes(storageRef, files[i])
      .then((snapshot) => {
        console.log("Uploaded a blob or file!", snapshot);
      })
      .catch((error) => {
        console.error("Error uploading file: ", error);
      });
    const url = await getDownloadURL(storageRef);
    urls.push({
      url: url,
      id: photoID,
      spaceID: id,
    });
  }
  return urls;
};

export const createLeads = async (data) => {
  const leadRef = doc(db, "leads", data.id);
  await setDoc(leadRef, {
    ...data,
    id: data.id,
  });
  return { status: "success" };
};

export const getAllLeads = async (uid) => {
  const leadsRef = collection(db, "leads");
  const q = query(leadsRef, where("leadParams", "==", uid));
  const querySnapshot = await getDocs(q);
  let leadsArray = [];
  querySnapshot.forEach((doc) => {
    leadsArray.push({ ...doc.data(), leadID: doc.id });
  });
  return leadsArray;
};

export const updateLead = async (id, data) => {
  const leadRef = doc(db, "leads", id);
  await updateDoc(leadRef, {
    ...data,
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const publishSpace = async (id, data, files) => {
  const postID = generateRandomID();
  const legntToLoop = files.length;
  const photos = await uploadMultipleImagesAndReturnArray(
    files,
    postID,
    legntToLoop
  );
  const spaceRef = doc(db, "spaces", postID);
  await setDoc(spaceRef, {
    ...data,
    photos: photos,
    mainPhoto: photos[0].url,
    id: postID,
    createdAt: new Date(),
    userID: id,
  });
  const userRef = doc(db, "users", id);
  await updateDoc(userRef, {
    spaces: arrayUnion(postID),
    lastUpdated: new Date(),
    addedSpaces: true,
  });
  const locationRef = doc(db, "locations", data.locationLink);
  await updateDoc(locationRef, {
    spaces: arrayUnion(postID),
    mainSpace: postID,
  });

  return { status: "success", id: postID };
};

export const addUploadDocument = async (data, id) => {
  const docRef = doc(db, "documents", id);
  await setDoc(docRef, {
    ...data,
    id: id,
    createdAt: new Date(),
  });
  return { status: "success" };
};

export const updateSpaceWithFiles = async (
  id,
  data,
  files,
  deletedPhotos,
  userID
) => {
  const spaceRef = doc(db, "spaces", id);
  if (files) {
    const photos = await uploadMultipleImagesAndReturnArray(files, id);
    const newPhotosArray = [...data.photos, ...photos];
    await updateDoc(spaceRef, {
      ...data,
      photos: newPhotosArray,
      mainPhoto: data.mainPhoto,
      lastUpdated: new Date(),
    });
    await addDoc(collection(db, "deletedPhotos"), {
      photos: deletedPhotos,
    });
  } else {
    await updateDoc(spaceRef, {
      ...data,
      lastUpdated: new Date(),
    });
    const userRef = doc(db, "users", userID);
    await updateDoc(userRef, {
      lastActive: new Date(),
    });
  }

  return { status: "success" };
};

export const updateSpace = async (id, data, deletedPhotos, userID) => {
  const spaceRef = doc(db, "spaces", id);
  await updateDoc(spaceRef, {
    ...data,
    lastUpdated: new Date(),
  });
  await addDoc(collection(db, "deletedPhotos"), {
    photos: deletedPhotos,
    deletionDate: new Date(),
  });
  const userRef = doc(db, "users", userID);
  await updateDoc(userRef, {
    lastActive: new Date(),
  });
  return { status: "success" };
};

export const getSpaceData = async (id) => {
  const spaceRef = doc(db, "spaces", id);
  const spaceData = await getDoc(spaceRef);
  if (spaceData.exists()) {
    return spaceData.data();
  } else {
    return null;
  }
};

export const deleteSpace = async (id, userID) => {
  const spaceRef = doc(db, "spaces", id);
  const userRef = doc(db, "users", userID);
  await updateDoc(spaceRef, {
    deleted: true,
    lastUpdated: new Date(),
    userID: null,
    posterUserID: userID,
  });
  await updateDoc(userRef, {
    spaces: arrayRemove(id),
    lastUpdated: new Date(),
  });
  await addDoc(collection(db, "deletedSpaces"), {
    spaceID: id,
    deletionDate: new Date(),
  });
  return { status: "success" };
};

export const updateLocationLink = async (user, link, spaceIDS, name) => {
  const spaceRef = doc(db, "locations", link);

  const locationData = await getDoc(spaceRef);

  if (locationData.exists()) {
    return { status: "error", message: "Link already in use" };
  } else {
    await setDoc(spaceRef, {
      locationLink: link,
      name: "",
      spaces: spaceIDS,
      userID: user.id,
      pageTitle: "Shared Space",
      pageDescription:
        "This is your description. Please update it before using this page. Select the top right purple button to edit this page.",
      mainSpace: "",
      hostEmail: user.email,
      hostPhone: user.phone || "",
      extraTitle: "Headline Title Here",
      secondaryColor: "indigo",
      pageSubheading: "Subheading Here",
      pageColor: "emerald",
      extraDescription:
        "This is your extra information. Please update it before using this page. Select the top right purple button to edit this page.",
      extraTag: "Subheading",
      hex: "#059669",
      ctaHeadline: "Call to Action",
      ctaDescription:
        "This is your call to action description. Please update it.",
    });
    await updateDoc(doc(db, "users", user.id), {
      linkConfigured: true,
      link: link,
    });
    return { status: "success" };
  }
};

export const handleNewSaveLocationData = async (data) => {
  const locationRef = doc(db, "locations", data.locationLink);
  await updateDoc(locationRef, {
    ...data,
  });
  return { status: "success" };
};

export const handleGetSpacesFromUids = async (uidArray) => {
  let spacesArray = [];
  for (let i = 0; i < uidArray?.length; i++) {
    const spacesRef = doc(db, "spaces", uidArray[i]);
    const spaceData = await getDoc(spacesRef);
    if (spaceData.exists()) {
      spacesArray.push({ ...spaceData.data(), id: spaceData.id });
    }
  }
  return spacesArray;
};

export const updateSpaceViews = async (id) => {
  const locationRef = doc(db, "spaces", id);
  try {
    await updateDoc(locationRef, {
      views: increment(1),
    });
    return { status: "success" };
  } catch (error) {
    console.log(error);
    return error;
  } finally {
    console.log("Views updated");
  }
};

export const updateUser = async (id, data) => {
  const userRef = doc(db, "users", id);
  await updateDoc(userRef, {
    ...data,
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const updateUsersMeta = async (id, data) => {
  const userRef = doc(db, "users", id);
  await updateDoc(userRef, {
    meta: data,
    linkConfigured: true,
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const getSpaceLocation = async (locLinkName) => {
  if (locLinkName) {
    const locationRef = doc(db, "locations", locLinkName);
    const locationData = await getDoc(locationRef);
    if (locationData.exists()) {
      return locationData.data();
    } else {
      return [];
    }
  } else {
    return;
  }
};

export const createBooking = async (data, userID) => {
  const bookingID = generateRandomID();
  const bookingRef = doc(db, "bookings", bookingID);
  const spaceRef = doc(db, "spaces", data.spaceID);
  const userRef = doc(db, "users", userID);

  await setDoc(bookingRef, {
    ...data,
    createdAt: new Date(),
    id: bookingID,
    providerSigned: false,
    renterSigned: false,
  });
  await updateDoc(spaceRef, {
    bookings: arrayUnion(bookingID),
  });
  await updateDoc(userRef, {
    bookingsCreated: increment(1),
  });

  return { status: "success" };
};

export const getBookings = async (id) => {
  const bookingRef = doc(db, "bookings", id);
  const bookingData = await getDoc(bookingRef);
  if (bookingData.exists()) {
    return bookingData.data();
  } else {
    return [];
  }
};

export const getAllBookings = async (spaceID) => {
  const bookingsRef = collection(db, "bookings");
  const q = query(bookingsRef, where("spaceID", "==", spaceID));
  const querySnapshot = await getDocs(q);
  let bookingsArray = [];
  querySnapshot.forEach((doc) => {
    bookingsArray.push({ ...doc.data(), id: doc.id });
  });
  return bookingsArray;
};

export const updateBooking = async (id, data, userID) => {
  const bookingRef = doc(db, "bookings", id);
  const userRef = doc(db, "users", userID);
  await updateDoc(bookingRef, {
    ...data,
    lastUpdated: new Date(),
  });
  await updateDoc(userRef, {
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const newInterest = async (data, userID) => {
  const interestID = generateRandomID();
  const interestRef = doc(db, "interests", interestID);
  const userRef = doc(db, "users", userID);

  await setDoc(interestRef, {
    ...data,
    createdAt: new Date(),
    id: interestID,
  });
  await updateDoc(userRef, {
    interests: arrayUnion(interestID),
  });

  return { status: "success" };
};

export const renterFormSubmission = async (data) => {
  const submissionID = generateRandomID();
  const submissionRef = doc(db, "renterForms", submissionID);

  await setDoc(submissionRef, {
    ...data,
    createdAt: new Date(),
    id: submissionID,
  });

  return { status: "success", id: submissionID };
};

export const getRenterFormSubmission = async (id) => {
  const submissionRef = doc(db, "renterForms", id);
  const submissionData = await getDoc(submissionRef);
  if (submissionData.exists()) {
    return { status: "success", data: submissionData.data() };
  } else {
    return { status: "error", message: "No submission found" };
  }
};

export const updateRenterFormSubmission = async (id, data) => {
  const submissionRef = doc(db, "renterForms", id);
  await updateDoc(submissionRef, {
    ...data,
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const updateRenter = async (id, data) => {
  const userRef = doc(db, "renters", id);
  await updateDoc(userRef, {
    ...data,
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const createAndAddRenter = async (data, lok, renterID) => {
  const renterRef = doc(db, "renters", renterID);
  const locationRef = doc(db, "locations", lok);
  await setDoc(renterRef, {
    ...data,
    createdAt: new Date(),
  });
  await updateDoc(locationRef, {
    renters: arrayUnion(renterID),
  });
  return { status: "success" };
};

export const createNewLead = async (data) => {
  const leadRef = doc(db, "leads", data.id);
  const locationRef = doc(db, "locations", data.leadParams);
  // const userRef = doc(db, "users", data.userID);

  await setDoc(leadRef, data);

  await updateDoc(locationRef, {
    leads: arrayUnion(data.id),
  });

  // await updateDoc(userRef, {
  //   notifications: arrayUnion(data),
  // });

  return { status: "success" };
};

export const getLead = async (leadID) => {
  const leadRef = doc(db, "leads", leadID);
  const snapshot = await getDoc(leadRef);
  if (snapshot.exists()) {
    return snapshot.data();
  } else {
    return null;
  }
};

export const deleteRenter = async (id, linkID) => {
  const renterRef = doc(db, "renters", id);
  const userRef = doc(db, "locations", linkID);
  await deleteDoc(renterRef);
  await updateDoc(userRef, {
    renters: arrayRemove(id),
    lastUpdated: new Date(),
  });
  return { status: "success" };
};

export const addLeadNotes = async (id, data) => {
  const leadRef = doc(db, "leads", id);
  await updateDoc(leadRef, {
    notes: arrayUnion(data),
  });
  return { status: "success" };
};

export const deleteLead = async (id, data) => {
  const leadRef = doc(db, "leads", id);
  const locationRef = doc(db, "locations", data.leadParams);
  await deleteDoc(leadRef);
  await updateDoc(locationRef, {
    leads: arrayRemove(id),
  });
  return { status: "success" };
};

