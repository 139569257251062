import { EyeIcon, Minus } from "lucide-react";
import React, { useEffect, useState } from "react";
import { UserAuth } from "../../context/AuthContext";

const statsInit = [
  {
    name: "Total Views",
    stat: 0,
    previousStat: "70,946",
    change: "12%",
    changeType: "increase",
  },
  {
    name: "Total Bookings",
    stat: 0,
    previousStat: "56.14%",
    change: "2.02%",
    changeType: "increase",
  },
  {
    name: "Total Earned",
    stat: "$0",
    previousStat: "28.62%",
    change: "4.05%",
    changeType: "decrease",
  },
];

export default function BookingsList() {
  const { spaceViews } = UserAuth();
  const [stats, setStats] = useState(statsInit);

  const colors = [
    "bg-emerald-500",
    "bg-orange-500",
    "bg-indigo-500",
    "bg-yellow-500",
    "bg-pink-500",
    "bg-purple-500",
    "bg-cyan-500",
  ];
  

  useEffect(() => {
    setStats((prev) =>
      prev.map((item) => {
        if (item.name === "Total Views") {
          return { ...item, stat: spaceViews };
        }
        return item;
      })
    );
  }, [spaceViews]);

  return (
    <>
      <div>
        <dl className=" grid grid-cols-1 gap-2 lg:grid-cols-3">
          {stats.map((item, index) => (
            <div className={`overflow-hidden rounded-lg ${colors[index]} shadow`}>
              <div key={item.name} className="px-4 py-5 mt-2 bg-white">
                <dt className="truncate lg:text-xs text-sm xl:text-sm font-medium text-gray-500">
                  {item.name}
                </dt>
                <dd className="mt-1 lg:text-xl text-3xl xl:text-3xl  font-bold tracking-tight text-zinc-600">
                  {item.stat}
                </dd>
              </div>
            </div>
          ))}
        </dl>
      </div>
    </>
  );
}
